import React, { useContext, useEffect, useRef, useState } from "react";
import { AddLocalStorageData, GetLocalStorageData, RemoveLocalStorageData } from "../Helpers/Utilities";
import { useNavigate } from "react-router-dom";
import PatientDetails from "../Components/PatientInformation";
import { DefaultAGGridProps, ErrorMessages, HTTPResponse, Role, ToastMessageType } from "../Helpers/Enums";
import { PatientService } from "../WebApiServices/Patient.service";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../Contexts/AppContextProvider";
import PatientSummary from "./PatientSummary";
import { CommonError_Msgs, PatientDashboard_Msgs } from "../Helpers/HelperText";
import ConfirmPopUp from "../Components/ConfirmPopUp";
import * as XLSX from 'xlsx';
import JSZip from "jszip";
import { saveAs } from "file-saver";
const Dashboard = () => {

  const navigate = useNavigate();
  const [allPatients, setAllPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const searchRef = useRef("");
  const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);
  const [viewSummary, setViewSummary] = useState(false)

  let userDetails = GetLocalStorageData("user")
  userDetails = userDetails && JSON.parse(userDetails);

  const userRole = userDetails?.userRole ?? 0;
  const centerId = userDetails?.centerId ?? 0;
  const userId = userDetails?.userId ?? 0;

  const [pidToUnlock, setPidToUnlock] = useState();
  const [showUnlockConfirm, setShowUnlockConfirm] = useState(false);

  const iconStyle = {
    width: 25,
    margin: 10,
    cursor: "pointer",
  };
  useEffect(() => {
    RemoveLocalStorageData("patientId");
    //RemoveLocalStorageData("uniqueEncryptedPatientId");
    //RemoveLocalStorageData("surveyDate")
    RemoveLocalStorageData("patient")
    //centerId = GetLocalStorageData("centerId");
    const FetchData = async () => {
      ToggleLoader(true)
      await GetAllPatients();
      ToggleLoader(false)
    }
    FetchData();
  }, [])
  useEffect(() => {
    setFilteredPatients(allPatients?.filter((a) =>
      a.uniqueEncryptedPatientId?.replace(/\s/g, '').toLowerCase().includes(searchQuery.replace(/\s/g, '').toLowerCase())
    )
      .sort((a) => a.employeeName));
  }, [searchQuery, allPatients])

  const GetAllPatients = async () => {
    try {
      // ToggleLoader(true)
      const response = await PatientService.GetAllPatients(centerId);
      const status = response.status;
      if (status !== HTTPResponse.OK) {

         if (status === HTTPResponse.Unauthorized)
           HandleSessionTimeout();
         else if (status === HTTPResponse.BadRequest)
          throw response.error;
      } else {
        const { data } = response;
        setAllPatients(data);
      }
      setLoading(false);
    } catch {
      setLoading(false);
      ShowToast(ErrorMessages.LOAD_ERROR, ToastMessageType.Failed);
    }
  }
  const HandleSearch = () => {
    setSearchQuery(searchRef.current.value);
  }
  const AddNew = () => {
    return navigate("/Introduction");
  };
  const Edit = (patientID, uniqueEncryptedPatientId, surveyDate, locked, submit, centerId, createdBy) => {
    debugger
    AddLocalStorageData("patientId", patientID);
    //AddLocalStorageData("uniqueEncryptedPatientId", uniqueEncryptedPatientId);
    //AddLocalStorageData("surveyDate", surveyDate);

    const ptArr = {
      patientId: patientID,
      uniqueEncryptedPatientId: uniqueEncryptedPatientId,
      surveyDate: surveyDate,
      locked: locked,
      submit: submit,
      ptCenterId: centerId,
      abstractorId: createdBy
    };
    AddLocalStorageData("patient", JSON.stringify(ptArr))
    return navigate("/Introduction");
  };
  const UnlockClick = (patientId) => {
    setShowUnlockConfirm(true);
    setPidToUnlock(patientId);
  }
  //Unlock CONFIRM
  const YesClick = async (patientId) => {
    
    ToggleLoader(true);
    await UnlockPatient(patientId)
    setShowUnlockConfirm(false)
    ToggleLoader(false);

  }
  const NoClick = () => {
    setShowUnlockConfirm(false)
  }
  const UnlockPatient = async (patientId) => {
    try {
      const payload = {
        pid: patientId,
        userId: userId
      }
      //ToggleLoader(true);
      const response = await PatientService.UnlockPatient(payload)
      //ToggleLoader(false);

    if (response?.status === HTTPResponse.Unauthorized) {
        HandleSessionTimeout();
      }
      else if (response?.status === HTTPResponse.OK) {
        await GetAllPatients();
        ShowToast(PatientDashboard_Msgs.UnlockSuccessMsg, ToastMessageType.Success);
      }
      else
        throw response.error;
      //ToggleLoader(false);



    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
    }
  }
  // GRID
  const columnDefs = [
    {
      headerName: "Patient ID",
      field: "patientId",
      width: 200,
      cellRenderer: (params) => <div
      > {params.data.uniqueEncryptedPatientId} </div>,
    },
    {
      headerName: "Center",
      field: "centerName",
      width: 300,
      //minWidth: 150,
      cellRenderer: (params) => <div
      > {params.data.centerName}</div>,
    },
    {
      headerName: "Last Edited On (UTC)",
      width: 256,
      cellRenderer: (params) => <div
      > {params.data.lastEditedOn}</div>,
      cellClass: "td",
    },
    // EDIT
    {
      width: 90,
      className: " td-th-width",
      cellRenderer: (params) => (
        <img
          src="../Assets/images/Icon-awesome-edit.png"
          alt="edit"
          style={iconStyle}
          onClick={() => Edit(params.data.patientID, params.data.uniqueEncryptedPatientId, params.data.abstractionDate, params.data.isLocked ?? false, params.data.submit, params.data.centerId, params.data.createdBy)}
          title="Edit Patient"
        />
      ),
      cellClass: "td",
    },
    // Unlock
    {
      width: 90,
      className: " td-th-width",
      cellRenderer: (params) => (
        params?.data?.canUnlock && <img
          src="../Assets/images/Icon-awesome-key.png"
          alt="Unlock"
          style={iconStyle}
          title="Unlock"
          onClick={() => { UnlockClick(params.data.patientID) }}
        />
      ),
      cellClass: "td",
    },
    // Locked
    {
      width: 90,
      className: " td-th-width",
      cellRenderer: (params) => (
        params?.data?.isLocked && <img
          src="../Assets/images/lock.png"
          alt="Lock"
          style={iconStyle}
          title="Locked"
        />
      ),
      cellClass: "td",
    },
    //Export
    {
      width: 90,
      className: " td-th-width",
      cellRenderer: (params) => (
        params?.data?.isLocked && <img
          src="../Assets/images/file.png"
          alt="Export Patient"
          style={iconStyle}
          title="Export Patient"
          onClick={() => { ExportData(params.data.patientID, 1, params.data.uniqueEncryptedPatientId) }}
        />
      ),
      cellClass: "td",
    },
    //Export status
    {
      width: 90,
      className: " td-th-width",
      cellRenderer: (params) => (params?.data?.isLocked && //sifter 11153
        <img
          src={params?.data?.exported ? "../Assets/images/green-radio.png" : "../Assets/images/red-radio.png"}
          alt={params?.data?.exported ? "Exported" : "Not Exported"}
          style={iconStyle}
          title={params?.data?.exported ? "Exported" : "Not Exported"}
        />
      ),
      cellClass: "td",
    },
  ];
  const defaultColDef = {
    resizable: true,
    sortable: true,
    // flex: 1,
    cellClass: "td no-border",
    //headerClass: 'ag-cell-header'
  };
  const gridOptions = {
    columnDefs,
    defaultColDef,
    rowHeight: DefaultAGGridProps.ROW_HEIGHT,
    pagination: true,
    paginationPageSize: DefaultAGGridProps.ROWS_PER_PAGE,
  };
  const agGridLocaleText = {
    noRowsToShow: DefaultAGGridProps.NOT_FOUND,
  };
  const GetTableHeight = () => {
    return 100 + (filteredPatients?.length < 4
      ? 50 * 4
      : filteredPatients?.length < 11
        ? 50 * filteredPatients?.length
        : 50 * 10);
  };
  const CloseModal = () => {
    setViewSummary(false);
  }
  const ViewSummary = () => {
    setViewSummary(true);
  }
  const ExportData = async (patientId, exportType, uniquePID) => {
    try {
      ToggleLoader(true)
      const response = await PatientService.GetPatientDataToExport(patientId, exportType, userId);
      ToggleLoader(false)

        const { status } = response;
      if (status !== HTTPResponse.OK) {

        if (status === HTTPResponse.Unauthorized)
          HandleSessionTimeout();
        else
          throw response.error;
      }
      else {
        const zipFileName = patientId === 0 ?
          exportType === 1 ? "AllPatients" : "AllTerminatedPatients"
          : exportType === 1 ? `Patient_${uniquePID}` : "";

        await DownloadPatientData(response.data, zipFileName)

        //update export staus image in grid
        if (patientId !== 0) {
          const updatedList = allPatients.map((pt) => ({
            ...pt,
            exported: pt.patientID === patientId ? true : pt.exported,
          }));
          setAllPatients(updatedList);
        }
        else
          await GetAllPatients();

        ShowToast(PatientDashboard_Msgs.ExportSuccess, ToastMessageType.Success);
        }


    } catch {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const DownloadPatientData = async (dataSet, zipFileName) => {
    try {
      const zip = new JSZip();
      const excelFiles = [];
      const wopts = { bookType: 'xlsx', bookSST: true, type: 'array' };

      let wbArr = [];
      let wb;
      const asyncTasks = dataSet?.map(({ name, isMain, tableData, columns }) => {
        //CREATE WORKSHEET for each table
        const ws = XLSX.utils.json_to_sheet(tableData, { header: columns });


        if (isMain) {
          //CREATE WORKBOOK only for the main page
          wb = XLSX.utils.book_new();
          wbArr = [...wbArr]
          wbArr.push(wb)
        }
        //APPEND SHEET TO CURRENT WORKBOOK
        XLSX.utils.book_append_sheet(wb, ws, name);//Workbook, Worksheet, Sheet name
      });

      //Use XLSX.write to convert the Excel file content to array buffer
      wbArr.forEach((sheet) => {
        const blob = XLSX.write(sheet, wopts);
        excelFiles.push({ name: sheet.SheetNames[0], file: blob });
      })      

      await Promise.all(asyncTasks);

      excelFiles.forEach(({ name, file }) => {
        zip.file(`${name}.xlsx`, file, { base64: true });
      });

      zip.generateAsync({ type: 'blob' }).then(async (content) => {
        // Save the ZIP file on the client-side
        saveAs(content, zipFileName);

        // Create a File object from the blob content
        const zipFile = new File([content], zipFileName);

        // Send the ZIP file to the server
        const formData = new FormData();
        formData.append('zipFile', zipFile);

        try {
          // Assuming PatientService.SaveZipFile is an asynchronous function that makes a POST request to the server
          const response = await PatientService.SaveZipFile(formData);
          const { status } = response;
          if (status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          }
          else if (status === HTTPResponse.OK) {
            await GetAllPatients();
          }
          else
            throw response.error;
         

          //console.log('ZIP file sent to the server successfully.');
        } catch (error) {
          //console.error('Error sending ZIP file to the server:', error);
        }
      });

    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed)
    }
  }
  return (
    <>
      <section className="patient-details-section">
        <PatientDetails />
      </section>
      <section className="main-common-page-section main-common-page-section-min-ht">
        <div className="container">
          <div className="summary-tabs">
            <div className="summary-tabs-div" onClick={ViewSummary} title="Summary" style={{ width: "13%" }}>
              <img width="23px" src="../Assets/images/patient-summary.png" alt="" />
              <p>View Patient Summary</p>
            </div>
            {(userRole === Role.Admin) &&
              <>
                <div className="summary-tabs-div" onClick={() => { ExportData(0, 1) }} title="Export Patients" style={{ width: "10%" }}>
                  <img width="30px" src="../Assets/images/export-patients.png" alt="" />
                  <p>Export Patients</p>
                </div>
                <div className="summary-tabs-div" title="Export Terminated Patients" onClick={() => { ExportData(0, 2) }} style={{ width: "15%" }}>
                  <img width="30px" src="../Assets/images/export-terminated-patients.png" alt="" />
                  <p>Export Terminated Patients</p>
                </div>
              </>
            }
          </div>
          <div className="para-div">
            <p>
              Thank you for participating in this chart review study. If you have any
              questions regarding the use of this form, please contact the Analysis
              Group project team via REALAVA2.0@analysisgroup.com.
            </p>
          </div>
          {!loading &&
            <div className="row search-add-btn">
              <div className="col-6 col-sm-5 col-md-5 col-lg-4">
                <div className="searchBar">
                  <input
                    className="searchQueryInput-class"
                    id="searchQueryInput"
                    type="text"
                    name="searchQueryInput"
                    placeholder="Patient ID"
                    ref={searchRef}
                    onChange={HandleSearch}
                  />
                  <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit"
                  >
                    <img src="../Assets/images/Icon-feather-search.png" width="20px" />
                  </button>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div className="">
                  <button
                    className="btn btn-outline-light btn-lg add-new-patient"
                    type="submit" onClick={AddNew}
                  >
                    + Add New Patient
                  </button>
                </div>
              </div>
            </div>}
          <div className="user-tab">

            <div className="user-tab">
              {loading ? (
                <></>
              )
                :
                (<div
                  className="ag-theme-alpine "
                  style={{
                    height: filteredPatients?.length === 0 ? 322 : GetTableHeight(), //DefaultAGGridProps.TABLE_HEIGHT,
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    rowData={filteredPatients}
                    gridOptions={{ ...gridOptions, localeText: agGridLocaleText }}
                    onPaginationChanged={() => {
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
                )
              }
            </div>
          </div>
        </div>
      </section>


      {viewSummary && (
        <PatientSummary
          CloseModal={CloseModal}
        />
      )}

      {showUnlockConfirm && <ConfirmPopUp id={pidToUnlock} YesClick={YesClick} NoClick={NoClick} title={PatientDashboard_Msgs.UnlockConfirmMsg} />}

    </>
  );
};

export default Dashboard;
