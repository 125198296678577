import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ErrorField from "./ErrorField";
import { CommonError_Msgs, TreatmentAfterAVA_Msgs } from "../Helpers/HelperText";

const SwitchedTreatment = ({
    hideDay,
    hideMonth,
    marginLeft = true,
    ctrlId,
    HandleDateChange,
    optionId,
    defDate,
    defMonth,
    defYear,
    date,
    selectedValue,
    selectedOthervalue,
    HandleOptionsSelected,
    HandleOtherOptionValue,
    showSoftError1,
    showSoftError2,
    showSoftErrorMsg1,
    showSoftErrorMsg2,
    EmptyFields,
    q45AEmpty,
    q45BEmpty,
    q45BAEmpty,
    showSoftAErrorMsg
}) => {
    const defaultDate = "MM/DD/YYYY";
    const defaultMonth = "MM";
    const defaultYear = "YYYY";
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
    const [selectedYear, setSelectedYear] = useState(defaultYear);

    //RadioList Part
    const [selectedOption, setSelectedOption] = useState(selectedValue);
    const [otherText, setOtherText] = useState(selectedOthervalue);
    const otherTextInputRef = useRef(null);

    const options = [
        { id: 1, value: 'Lack of efficacy with AVA' },
        { id: 2, value: 'Lower likelihood of adverse event occurrence compared to AVA' },
        { id: 3, value: 'Covered by patient insurance/more affordable than AVA' },
        { id: 4, value: 'Fewer contraindications with other prescribed medication' },
        { id: 5, value: 'Patient preference' },
        { id: 7, value: 'Other, please specify:' },
        { id: 99, value: 'Unknown/no reason indicated' },
    ];


    const handleRadioChange = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedOption(parseInt(newSelectedOption));
        setOtherText('');

        if (newSelectedOption === 7) {
            otherTextInputRef.current.focus();
        }
        HandleOptionsSelected(ctrlId, parseInt(newSelectedOption), optionId);
    };

    const handleOtherTextChange = (event) => {
        setOtherText(event.target.value);
        setSelectedOption(7);

        HandleOtherOptionValue(7, event.target.value, optionId)
    };

    const yearArray = [];
    const monthArray = Array.from({ length: 12 }, (_, index) =>
        index < 9 ? `0${index + 1}` : index + 1
    );
    const today = new Date();

    for (let i = today.getFullYear(); i >= 1921; i--) {
        yearArray.push(i);
    }

    const preventManualInput = (e) => {
        e.preventDefault();
    };
    let inputProps = {
        readOnly: true,
        onBeforeInput: preventManualInput,
        value: date != null && date != "" ? date : selectedDate,
        style: {
            width: "130px",
            marginRight: "5px",
            height: "25px",
            marginLeft: marginLeft === true ? "5px" : "0",
        },
    };
    const HandleFullDateChange = (e) => {
        if (e.isValid()) {
            setSelectedDate(e.format("MM/DD/yyyy"));
            HandleDateChange(ctrlId, e.format("MM/DD/yyyy"), optionId);
        }
    };
    const HandleYearSelection = (e) => {
        const year = e.target.textContent;
        setSelectedYear(year);
        HandleDateChange(ctrlId, year, optionId, 2); //year selected send 2
    };
    const HandleMonthSelection = (e) => {
        const month = e.target.textContent;
        setSelectedMonth(month);
        HandleDateChange(ctrlId, month, optionId, 1); //month selected send 1
    };
    /* <summary>
    Name: SJ
    description: Set date to defaut value
    <summary>*/
    useEffect(() => {
        if (defDate) setSelectedDate(defaultDate);
        if (defMonth) setSelectedMonth(defaultMonth);
        if (defYear) setSelectedYear(defaultYear);
    }, [defDate, defMonth, defYear]);

    /* <summary>
    Name:SJ
    description: Set the min and max dates for the calendar
    <summary>*/
    const SetDateRange = (current) => {
        return current.isSameOrAfter("01/01/1921");//&& current.isSameOrBefore("12/01/2023");
    }
    return (
        <>
            <div className="sub-question-bot-sapce-ul">
                <div className="answer-list-text">
                    <span className="question-weight">a.</span>
                    <span className="quest-text-pad" style={{ display: 'flex' }}>
                        <span className="question-weight">
                            Date of initiation:{" "}
                        </span>
                        {!hideDay && !hideMonth && (
                            <Datetime
                                inputProps={inputProps}
                                dateFormat="MM/DD/yyyy"
                                timeFormat={false}
                                className="datepicker"
                                onChange={HandleFullDateChange}
                                closeOnSelect="true"
                                isValidDate={SetDateRange}
                            />
                        )}
                        {hideDay && !hideMonth && (
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant=""
                                    id="dropdown-basic"
                                    className="custom-dropdown-button"
                                >
                                    {date != null && date !== "" && (`${date}`?.split('/')[0] ?? "") !== "" ? `${date}`?.split('/')[0] : selectedMonth}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="custom-dropdown-menu">
                                    {monthArray?.map((m, i) => (
                                        <Dropdown.Item
                                            as="button"
                                            key={i + 1}
                                            className="custom-dropdown-item"
                                            style={{ width: "90px" }}
                                            onClick={HandleMonthSelection}
                                            closeonselect="true"
                                        >
                                            {m}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        {hideDay && (
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant=""
                                    id="dropdown-basic"
                                    className="custom-dropdown-button"
                                >
                                    {date != null && date !== "" && (`${date}`?.split('/').pop() ?? "") !== "" ? `${date}`?.split('/').pop() : selectedYear}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="custom-dropdown-menu">
                                    {yearArray?.map((y, i) => (
                                        <Dropdown.Item
                                            as="button"
                                            key={i}
                                            className="custom-dropdown-item"
                                            style={{ width: "90px" }}
                                            onClick={HandleYearSelection}
                                            closeonselect="true"
                                        >
                                            {y}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        (If only month and year are known, please enter “15” for
                        the day)
                    </span>
                </div>
                <ErrorField
                    show={q45AEmpty ? true : q45AEmpty}
                    message={showSoftAErrorMsg}
                />
                <ErrorField
                    show={showSoftError1 ? true : showSoftError1}
                    message={showSoftErrorMsg1}
                />
                <ErrorField
                    show={showSoftError2 ? true : showSoftError2}
                    message={showSoftErrorMsg2}
                />
            </div>
            <div className="sub-question-bot-sapce-ul">
                <div className="answer-list-text question-weight">
                    <span>b.</span>
                    <span className="quest-text-pad">
                        What was the primary reason the patient switched to this treatment following AVA discontinuation?
                    </span>
                </div>
                <div>
                    {options.map((option) => (
                        <div key={option.id} className="answer-list-text answer-pad-left">
                            <label>
                                <input
                                    type="radio"
                                    value={option.id}
                                    checked={selectedOption === option.id}
                                    onChange={handleRadioChange}
                                />
                                <span className="radio-text-padding">{option.value}</span>
                            </label>
                            {option.id === 7 && (
                                <input
                                    type="text"
                                    className="input-dash"
                                    ref={otherTextInputRef}
                                    value={otherText}
                                    onChange={handleOtherTextChange}
                                />
                            )}
                        </div>
                    ))}
                </div>
                <ErrorField
                    show={q45BEmpty ? true : q45BEmpty}
                    message={(TreatmentAfterAVA_Msgs.Q45bEmptyErrorMsg)}
                />
                <ErrorField
                    show={q45BAEmpty ? true : q45BAEmpty}
                    message={(TreatmentAfterAVA_Msgs.Q45baEmptyErrorMsg)}
                />
                {/* <ErrorField
                    show={EmptyFields ? true : EmptyFields}
                    message={CommonError_Msgs.REQUIRED}
                /> */}
            </div>
        </>
    );
};

export default SwitchedTreatment;
