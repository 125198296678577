import React, { useState, useEffect, useContext } from "react";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import { PageDesignConstants, SideMenuItems, HTTPResponse, ToastMessages, ToastMessageType } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import DateControl from "../../Components/DateControl";
import ErrorField from "../../Components/ErrorField";
import {
    SubtractMonths,
    GetLoggedInUserID, GetLocalStorageData
} from "../../Helpers/Utilities";
import { CustomErrorMessages, MessageRequired_Msg, CommonError_Msgs, TreatmentHistory_Msg } from "../../Helpers/HelperText";
import { TreatmentService } from "../../WebApiServices/Treatment.service";
import { AppContext } from "../../Contexts/AppContextProvider";

const NUMBER_REGEX = /^[0-9]{0,9}$/;
const TreatmentHistory = () => {
    const navigatePaths = {
        prevPage: "/Demographics",
        currPage: "/TreatmentHistory",
        nextPage: "/Questionnaire",
    };

const{ToggleLoader, ShowToast, HandleSessionTimeout}=useContext(AppContext);

    //For Validation Errors
    const [error, setError] = useState({
        title: false,
    });

    //Main Table
    const [thDetails, setThDetails] = useState({
        itppriortreatmentid: 0,
        q24a1_pre_ava_ever_elt: false,
        q24a2_pre_ava_ever_romi: false,
        q24a3_pre_ava_ever_lusu: false,
        q24a4_pre_ava_ever_steroids: false,
        q24a5_pre_ava_ever_ivig: false,
        q24a6_pre_ava_ever_antid: false,
        q24a7_pre_ava_ever_ritux: false,
        q24a8_pre_ava_ever_fosta: false,
        q24a9_pre_ava_ever_immuno: false,
        q24a10_pre_ava_ever_none: "",
        q24b1_pre_3mo_ava_elt: false,
        q24b2_pre_3mo_ava_romi: false,
        q24b3_pre_3mo_ava_lusu: false,
        q24b4_pre_3mo_ava_steroids: false,
        q24b5_pre_3mo_ava_ivig: false,
        q24b6_pre_3mo_ava_antid: false,
        q24b7_pre_3mo_ava_ritux: false,
        q24b8_pre_3mo_ava_fosta: false,
        q24b9_pre_3mo_ava_immuno: false,
        q24b10_pre_3mo_ava_none: "",
        q29_ritux_immuno_and_ava: "",
        q30_pre_ava_splenectomy: "",
        q30_pre_ava_splen_dt: "",
        q30_pre_ava_splen_dt_unk: "",
        defDateQ30: false,
        q30Empty: false,
        q24a_Empty: false,
        q24b_Empty: false,
        q30_NotSelected: false,
        q30lessindexdate: false
    });

    const handleCheckboxChange = (e, data,typeid) => {
      
        // Update the checkbox field and clear the selected radio value    
        const { value, name } = e.target;
        setThDetails({
            ...thDetails,
            q24a10_pre_ava_ever_none: "",
            [name]: !thDetails[name],
            [data]:false,
        });

        switch (data){
            case "q24b1_pre_3mo_ava_elt":
            case "q24b2_pre_3mo_ava_romi": 
            case "q24b3_pre_3mo_ava_lusu":   
            { 
                let newList = [...q25dosefreq];
                const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);
        
                if (checkIfListExist && checkIfListExist.length > 0 ) {
                    newList = newList.filter((x) => x.treatmenttypeid !== typeid); 
                    setq25DoseFreq(newList);
                }
            
            }
            break;
            case "q24b4_pre_3mo_ava_steroids":
                {

                    let newList = [...q28steroid];
                    const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);
            
                    if (checkIfListExist && checkIfListExist.length > 0 ) {
                        newList = newList.filter((x) => x.treatmenttypeid !== typeid); 
                        setq28steroid(newList);
                    }
                }
                break;
            case "q24b5_pre_3mo_ava_ivig":
            case "q24b6_pre_3mo_ava_antid":
                {
                    let newList = [...q26ava];
                    const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);
            
                    if (checkIfListExist && checkIfListExist.length > 0 ) {
                        newList = newList.filter((x) => x.treatmenttypeid !== typeid); 
                        setQ26ava(newList);
                }    
            }
            break;
            case "q24b8_pre_3mo_ava_fosta":
                {
                    let newList = [...q27fosta];
                    const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);
            
                    if (checkIfListExist && checkIfListExist.length > 0 ) {
                        newList = newList.filter((x) => x.treatmenttypeid !== typeid);
                        setq27fosta(newList);
                }   
                };
                break;
            case "q24b7_pre_3mo_ava_ritux":
            case "q24b9_pre_3mo_ava_immuno":
                {
                    let newList = [...q29ava];
        const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);

        if (checkIfListExist && checkIfListExist.length > 0 ) {
            newList = newList.filter((x) => x.treatmenttypeid !== typeid); 
            setq29ava(newList);
                }
            }
            break;
            case "q24b10_pre_3mo_ava_none":{
                const updatedThDetails = {
                    ...thDetails,
                    q24b1_pre_3mo_ava_elt: false,
                    q24b2_pre_3mo_ava_romi: false,
                    q24b3_pre_3mo_ava_lusu: false,
                    q24b4_pre_3mo_ava_steroids: false,
                    q24b5_pre_3mo_ava_ivig: false,
                    q24b6_pre_3mo_ava_antid: false,
                    q24b7_pre_3mo_ava_ritux: false,
                    q24b8_pre_3mo_ava_fosta: false,
                    q24b9_pre_3mo_ava_immuno: false,
                    q24b_Empty: false,
                };
        
                setThDetails(updatedThDetails);
                setq25DoseFreq([])
                setQ26ava([])
                setq27fosta([])
                setq29ava([])
                setq28steroid([])
            }
            break;
            default:
                break;

            
        }
    }



    const handleQ25CheckboxChange = (e) => {
        // Update the checkbox field and clear the selected radio value
        const { name, id, value } = e.target;
        let dosagearray = [...q25dosefreq];
        dosagearray[id].q25h_pre_ava_tx_disc = value;
        dosagearray[id].q25h_empty = false;
        if (value == 0) {
            dosagearray[id].q25i_pre_ava_tx_disc_dt = "";
            dosagearray[id].q25i_pre_ava_disc_dt_unk = false;
            dosagearray[id].q25i_empty = false;
            dosagearray[id].q25ibefordosedate=false;
            dosagearray[id].q25ibeforetherapydate=false;
        }
        setq25DoseFreq(dosagearray);
    };



    const HandleRadioChangeQ24a = (event) => {
        const radioValueQ24a = event.target.value;
        const updatedThDetails = {
            ...thDetails,
            q24a10_pre_ava_ever_none: radioValueQ24a,
            q24a1_pre_ava_ever_elt: false,
            q24a2_pre_ava_ever_romi: false,
            q24a3_pre_ava_ever_lusu: false,
            q24a4_pre_ava_ever_steroids: false,
            q24a5_pre_ava_ever_ivig: false,
            q24a6_pre_ava_ever_antid: false,
            q24a7_pre_ava_ever_ritux: false,
            q24a8_pre_ava_ever_fosta: false,
            q24a9_pre_ava_ever_immuno: false,

            q24b1_pre_3mo_ava_elt: false,
            q24b2_pre_3mo_ava_romi: false,
            q24b3_pre_3mo_ava_lusu: false,
            q24b4_pre_3mo_ava_steroids: false,
            q24b5_pre_3mo_ava_ivig: false,
            q24b6_pre_3mo_ava_antid: false,
            q24b7_pre_3mo_ava_ritux: false,
            q24b8_pre_3mo_ava_fosta: false,
            q24b9_pre_3mo_ava_immuno: false,
            q24a_Empty: false,
            // ... other checkbox fields you want to clear
        };
        setThDetails(updatedThDetails);
        setq25DoseFreq([])
        //to clear on select of radio click
        setQ26ava([])
        setq27fosta([])
        setq29ava([])
        setq28steroid([])
    };

    const HandleRadioChangeQ24b = (event) => {
        const radioValue2 = event.target.value;
        // Clear all checkbox fields
        const updatedThDetails = {
            ...thDetails,
            q24b10_pre_3mo_ava_none: radioValue2,
            q24b1_pre_3mo_ava_elt: false,
            q24b2_pre_3mo_ava_romi: false,
            q24b3_pre_3mo_ava_lusu: false,
            q24b4_pre_3mo_ava_steroids: false,
            q24b5_pre_3mo_ava_ivig: false,
            q24b6_pre_3mo_ava_antid: false,
            q24b7_pre_3mo_ava_ritux: false,
            q24b8_pre_3mo_ava_fosta: false,
            q24b9_pre_3mo_ava_immuno: false,
            q24b_Empty: false,
            // ... other checkbox fields you want to clear
        };

        setThDetails(updatedThDetails);
        setq25DoseFreq([])
        //to clear on select of radio click
        setQ26ava([])
        setq27fosta([])
        setq29ava([])
        setq28steroid([])
    };



    // Question 25
    const [q25dosefreq, setq25DoseFreq] = useState([]);
    const handleCheckboxChangeQ25 = (fieldName, treatmentname, typeid, e) => {
        // Update the checkbox field and clear the selected radio value  

        setThDetails({
            ...thDetails,
            q24b10_pre_3mo_ava_none: "",
            q24b_Empty: false,
            [fieldName]: !thDetails[fieldName],
        });

        let newList = [...q25dosefreq];
        const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);

        if (checkIfListExist && checkIfListExist.length > 0 && !e.target.checked) {
            newList = newList.filter((x) => x.treatmenttypeid !== typeid); // Remove the item with a matching treatmentid
        } else {
            // Create a new item and add it to the list
            const newItem = {
                treatmenttypeid: typeid,
                treatmentid: fieldName,
                treatmentName: "",
                Itpdosefrequencyid: 0,
                q25a_pre_ava_a1_tx_start_dt: "",
                q25a_pre_ava_a1_tx_start_dt_unk: "",
                q25b_pre_ava_b1_tx_start_dose: "",
                q25c_pre_ava_c1_tx_start_num: "",
                q25c_pre_ava_c1_tx_start_freq: "",
                q25c_pre_ava_c1_tx_start_dose_unk: "",
                q25d_pre_ava_d1_tx_dose_change: "",
                //repeater
                doseChangeListQ25: [],
                q25h_pre_ava_tx_disc: "",
                q25i_pre_ava_tx_disc_dt: "",
                q25i_pre_ava_disc_dt_unk: "",
                defDateQ25: false,
                q25datechange: false,
                q25newdosage: false,
                q25newfreq: false,
                q25doseChange: false,
                q25dosagelessindexdt: false,
                q25dateDiscChange: false,
                q25defdateDisc: false,
                q25a_empty: false,
                q25b_empty: false,
                q25c_empty: false,
                q25d_empty: false,

                q25h_empty: false,
                q25i_empty: false,
                q25alessindexdate: false,
                q25ibeforetherapydate:false,
                q25ibefordosedate:false,
                q25iBeforeIndexDate:false,
                q25adoseInput: false,
                q25cfreqInput: false

            };
            if (fieldName === "q24b1_pre_3mo_ava_elt") {
                newItem.treatmentName = treatmentname;
            } else if (fieldName === "q24b2_pre_3mo_ava_romi") {
                newItem.treatmentName = treatmentname;
            } else if (fieldName === "q24b3_pre_3mo_ava_lusu") {
                newItem.treatmentName = treatmentname;
            }
            newList.push(newItem);
        }
        setq25DoseFreq(newList);
    };
    //to add the preater row in list


    function addQ25RepeaterRow(e, treatmentData) {

        const { name, id, value } = e.target;
        const checkIfListExist = q25dosefreq.findIndex(
            (x) => x.treatmenttypeid === treatmentData.treatmenttypeid
        );
        if (checkIfListExist > -1) {
            let dosagearray = [...q25dosefreq];
            dosagearray[id].q25d_pre_ava_d1_tx_dose_change = value;
            dosagearray[id].q25d_empty = false;
            dosagearray[id].q25ibefordosedate=false;
            setq25DoseFreq(dosagearray);

            if (value == "1") {
                treatmentData.doseChangeListQ25.push({
                    Itpdosefrequencyid: 0,
                    q25dosecount: 1,
                    doseId: Math.random(),
                    q25e_pre_ava_e1_tx_dose_change_dt: "",
                    q25f_pre_ava_f1_tx_change_dose: "",
                    q25g_pre_ava_g1_tx_change_num: "",
                    q25g_pre_ava_g1_tx_change_freq: "",
                    q25rpt_empty: false,
                    q25e_empty:false,
                    q25f_empty:false,
                    q25g_empty:false,
                    q25elessindexdate: false,
                    q25eafterindexdate: false,
                    q25fdoseInput: false,
                    q25gFreqInput: false,

                });
            } else {
                treatmentData.doseChangeListQ25 = [];

            }
        }

        q25dosefreq[checkIfListExist] = treatmentData;
        setThDetails((prev) => ({
            ...prev,
            q25dosefreq,
        }));
    }


    const adddDoseClickQ25 = (treatmentid) => {
        const index = q25dosefreq.findIndex((x) => x.treatmenttypeid === treatmentid);

        if (index > -1) {
            let count = q25dosefreq[index].doseChangeListQ25[q25dosefreq[index].doseChangeListQ25.length - 1].q25dosecount;
            q25dosefreq[index].doseChangeListQ25.push({
                //repeater
                doseId: Math.random(),
                treatmenttypeid: treatmentid,
                q25dosecount: count + 1,
                q25e_pre_ava_e1_tx_dose_change_dt: "",
                q25f_pre_ava_f1_tx_change_dose: "",
                q25g_pre_ava_g1_tx_change_num: "",
                q25g_pre_ava_g1_tx_change_freq: "",
                q25rpt_empty: false,
                q25e_empty:false,
                q25f_empty:false,
                q25g_empty:false,
                q25elessindexdate: false,
                q25eafterindexdate: false,
                q25fdoseInput: false,
                q25gFreqInput: false,
            });
            setThDetails((prev) => ({
                ...prev,
                q25dosefreq,
            }));
        }
    };


    const handleDeleteDoseQ25 = (treatmentid, id) => {
         
        const index = q25dosefreq.findIndex((x) => x.treatmenttypeid === treatmentid);
        if (index > -1) {
            const list = q25dosefreq[index].doseChangeListQ25.filter(
                (x) => x.q25dosecount != id
            );
            //  q25dosefreq[index].doseChangeListQ25.filter(
            //   (x) => x.Itpdosefrequencyid != id
            // );
            q25dosefreq[index].doseChangeListQ25 = list;
            setThDetails((prev) => ({
                ...prev,
                q25dosefreq,
            }));
        }
    };


    //Question 26
    const [q26ava, setQ26ava] = useState([]);
    const HandleChangeAddQ26 = (fieldName, treatmentname, typeid, e) => {
        //to clear none radio button and check checkbox    

        setThDetails({
            ...thDetails,
            q24b10_pre_3mo_ava_none: "",
            //q25b_empty:false,
            q24b_Empty: false,
            [fieldName]: !thDetails[fieldName],
        });

        let newList = [...q26ava];
        const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);

        if (checkIfListExist && checkIfListExist.length > 0 && !e.target.checked) {
            newList = newList.filter((x) => x.treatmenttypeid !== typeid); // Remove the item with a matching treatmentid
        } else {
            // Create a new item and add it to the list
            const newItem = {
                treatmentid: fieldName,
                treatmentName: "",
                treatmenttypeid: typeid,
                avaListQ26: [
                    {
                        avaId: Math.random(),
                        treatmenttypeid: typeid,
                        treatmentid: fieldName,
                        Avainitiationid: 0,
                        q26Count: 1,
                        q26a_pre_ava_a1_tx_start_dt: "",
                        q26a_pre_ava_a1_tx_start_dt_unk: "",
                        q26b_pre_ava_b1_tx_end_dt: "",
                        q26b_pre_ava_b1_tx_end_dt_unk: "",
                        defDateQ26a: false,
                        defDateQ26b: false,
                        q26bdatechange: false,
                        q26adatechange: false,
                        q26a_Empty: false,
                        q26b_Empty: false,
                        q26alessindex: false,
                        q26aThreeMonthsIndexdate:false,
                        q26blessthanstartdate:false,
                        Q26bAfterFollowEndDate:false,
                    },
                ],
            };

            if (fieldName === "q24b5_pre_3mo_ava_ivig") {
                newItem.treatmentName = treatmentname;
            } else if (fieldName === "q24b6_pre_3mo_ava_antid") {
                newItem.treatmentName = treatmentname;
            }
            newList.push(newItem);
        }
        setQ26ava(newList);
    };


    function addQ26RepeaterDetailsRow(e, treatmentData) {

        const index = q26ava.findIndex(
            (x) => x.treatmenttypeid === treatmentData.treatmenttypeid
        );
        if (index > -1) {
            const updatedQ26ava = [...q26ava]; // Create a new array to avoid mutating state
            let count = updatedQ26ava[index].avaListQ26[updatedQ26ava[index].avaListQ26.length - 1].q26Count;
            updatedQ26ava[index].avaListQ26.push({
                avaId: Math.random(),
                treatmenttypeid: treatmentData.treatmenttypeid,
                q26Count: count + 1,
                q26a_pre_ava_a1_tx_start_dt: "",
                q26a_pre_ava_a1_tx_start_dt_unk: "",
                q26b_pre_ava_b1_tx_end_dt: "",
                q26b_pre_ava_b1_tx_end_dt_unk: "",
                q26a_Empty: false,
                q26b_Empty: false,
                q26alessindex: false,
                q26aThreeMonthsIndexdate:false,
                q26blessthanstartdate:false,
                Q26bAfterFollowEndDate:false,
            });
            setQ26ava(updatedQ26ava); // Update the state with the new array
        }
    }


    const handleDeleteAVAQ26 = (treatmentid, id) => {
         
        const index = q26ava.findIndex((x) => x.treatmenttypeid === treatmentid);
        if (index > -1) {
            const updatedQ26ava = [...q26ava];
            const list = updatedQ26ava[index].avaListQ26.filter((x) => x.q26Count != id);
            updatedQ26ava[index].avaListQ26 = list;
            setQ26ava(updatedQ26ava);
        }
    };



    //Question 27
    const [q27fosta, setq27fosta] = useState([]);
    const HandleChangeAddQ27 = (fieldName, treatmentname, typeid, e) => {
        //to clear none radio button and check checkbox    
        setThDetails({
            ...thDetails,
            q24b10_pre_3mo_ava_none: "",
            q24b_Empty: false,
            [fieldName]: !thDetails[fieldName],
        });

        let newList = [...q27fosta];
        const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);

        if (checkIfListExist && checkIfListExist.length > 0 && !e.target.checked) {
            newList = newList.filter((x) => x.treatmenttypeid !== typeid); // Remove the item with a matching treatmentid
        } else {
            // Create a new item and add it to the list
            const newItem = {
                treatmentid: fieldName,
                treatmentName: "",
                treatmenttypeid: typeid,
                Fostamatinibinitiationid: 0,
                q27a_pre_ava_fosta_start_dt: "",
                q27a_pre_ava_fosta_start_dt_unk: "",
                q27b_pre_ava_fosta_start_dose: "",
                q27b_pre_ava_fosta_start_dose_oth: "",
                q27c_pre_ava_fosta_dose_change: "",
                doseChangeListQ27: [],
                q27f_pre_ava_fosta_disc: "",
                q27g_pre_ava_fosta_disc_dt: "",
                q27g_pre_ava_fosta_disc_dt_unk: "",
                defDateQ27: false,
                q27datechange: false,
                q27dateDiscChange: false,
                q27defdateDisc: false,
                q27a_empty: false,
                q27b_empty: false,
                q27c_empty: false,
                q27f_empty: false,
                q27g_empty: false,
                q27lessindexdate: false,
                q27gbeforeTherapydate:false,
                q27gBeforeDoseDate:false,

                //repeater
                // doseChangeListQ25: [],

            };
            if (fieldName === "q24b8_pre_3mo_ava_fosta") {
                newItem.treatmentName = treatmentname;
            }
            newList.push(newItem);
        }
        setq27fosta(newList);
    };


    function addQ27RepeaterRow(e, treatmentData) {
        const { name, value } = e.target;
        const checkIfListExist = q27fosta.findIndex(
            (x) => x.treatmenttypeid === treatmentData.treatmenttypeid
        );
        if (checkIfListExist > -1) {
            treatmentData.q27c_pre_ava_fosta_dose_change = value;
            treatmentData.q27c_empty = false;
            if (value == "1") {
                treatmentData.doseChangeListQ27.push({
                    doseId: Math.random(),
                    q27doseCount: 1,
                    q27d_pre_ava_fosta_dose_chg_d1_dt: "",
                    q27e_pre_ava_fosta_dose_chg_e1: "",
                    q27e_pre_ava_fosta_change_dose_oth: "",
                    q27rpt_empty: false,
                    q27d_empty:false,
                    q27e_empty:false,
                    q27dbeforeindexdate: false,
                });
            } else {
                treatmentData.doseChangeListQ27 = [];
                treatmentData.q27gBeforeDoseDate=false;
            }
        }
        q27fosta[checkIfListExist] = treatmentData;
        setThDetails((prev) => ({
            ...prev,
            q27fosta,
        }));

    }


    const adddDoseClickQ27 = (treatmentid) => {
        const index = q27fosta.findIndex((x) => x.treatmenttypeid === treatmentid);
        if (index > -1) {
            let count = q27fosta[index].doseChangeListQ27[q27fosta[index].doseChangeListQ27.length - 1].q27doseCount;

            q27fosta[index].doseChangeListQ27.push({
                //repeater
                doseId: Math.random(),
                treatmenttypeid: treatmentid,
                q27doseCount: count + 1,
                q27d_pre_ava_fosta_dose_chg_d1_dt: "",
                q27e_pre_ava_fosta_dose_chg_e1: "",
                q27e_pre_ava_fosta_change_dose_oth: "",
                q27rpt_empty: false,
                q27d_empty:false,
                q27e_empty:false,
                q27dbeforeindexdate: false,

            });
            setThDetails((prev) => ({
                ...prev,
                q27fosta,
            }));
        }
    };


    const handleDeleteDoseQ27 = (treatmentid, id) => {

        const index = q27fosta.findIndex((x) => x.treatmenttypeid === treatmentid);
        if (index > -1) {
            const list = q27fosta[index].doseChangeListQ27.filter(
                (x) => x.q27doseCount != id
            );
            q27fosta[index].doseChangeListQ27 = list;
            setThDetails((prev) => ({
                ...prev,
                q27fosta,
            }));
        }
    };


    //question 29
    const [q29ava, setq29ava] = useState([])
    const HandleChangeAddQ29 = (fieldName, treatmentname, typeid, e) => {
        //to clear none radio button and check checkbox
        setThDetails({
            ...thDetails,
            q24b10_pre_3mo_ava_none: "",
            // q25b_empty:false,
            q24b_Empty: false,
            [fieldName]: !thDetails[fieldName],
        });

        let newList = [...q29ava];
        const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);

        if (checkIfListExist && checkIfListExist.length > 0 && !e.target.checked) {
            newList = newList.filter((x) => x.treatmenttypeid !== typeid); // Remove the item with a matching treatmentid
        } else {
            // Create a new item and add it to the list
            const newItem = {
                treatmentid: fieldName,
                treatmentName: "",
                Avarituximabid: 0,
                treatmenttypeid: typeid,
                q29_ritux_immuno_and_ava: "",
                q29Empty: false,



            };
            if (fieldName === "q24b7_pre_3mo_ava_ritux") {
                newItem.treatmentName = treatmentname;
            } else if (fieldName === "q24b9_pre_3mo_ava_immuno") {
                newItem.treatmentName = treatmentname;
            }
            newList.push(newItem);
        }
        setq29ava(newList);
    };


    //Question 28
    const [q28steroid, setq28steroid] = useState([])
    const HandleCheckboxChangeQ28 = (fieldName, treatmentname, typeid, e) => {
        // Update the checkbox field and clear the selected radio value 
      
        setThDetails({
            ...thDetails,
            q24b10_pre_3mo_ava_none: "",
            q24b_Empty: false,
            [fieldName]: !thDetails[fieldName],
        });

        let newList = [...q28steroid];
        const checkIfListExist = newList.filter((x) => x.treatmenttypeid === typeid);

        if (checkIfListExist && checkIfListExist.length > 0 && !e.target.checked) {
            newList = newList.filter((x) => x.treatmenttypeid !== typeid); // Remove the item with a matching treatmentid
        } else {
            // Create a new item and add it to the list
            const newItem = {
                treatmentid: fieldName,
                treatmentName: "",
                RowSequenceNo: "",
                treatmenttypeid: typeid,
                courseListQ28: [
                    {
                        treatmenttypeid: typeid,
                        RowSequenceNoId: Math.random(),
                        treatmentid: fieldName,
                        RowSequenceNo: 1,
                        q28a_steroid_type: "",
                        q28b_start_dt: "",
                        q28b_start_dt_unk: "",
                        q28c_start_dose: "",
                        q28c_start_dose_unk: "",
                        q28d_start_num: "",
                        q28d_start_freq: "",
                        q28d_freq_unk: "",
                        q28e_dose_change: "",
                        //repeater
                        clickListQ28: [],
                        q28i_tx_disc: "",
                        q28j_tx_disc_dt: "",
                        q28j_tx_disc_dt_unk: "",
                        defDateQ28: false,
                        q28datechange: false,
                        q28newdosage: false,
                        q28newfreq: false,
                        q28doseChange: false,
                        q28dosagelessindexdt: false,
                        q28dateDiscChange: false,
                        q28defdateDisc: false,

                        q28a_empty: false,
                        q28b_empty: false,
                        q28c_empty: false,
                        q28d_empty: false,
                        q28e_empty: false,
                        q28i_empty: false,
                        q28j_empty: false,
                        q28alessindexdate: false,
                        q28jlessindexdate:false,
                        q28jAfterindexdate:false,
                        q28jBeforeDosedate:false,
                        q28cDoseInput: false,
                        q28dFreqInput: false



                    },
                ],
            };

            if (fieldName === "q24b4_pre_3mo_ava_steroids") {
                newItem.treatmentName = treatmentname;
            }
            newList.push(newItem);
        }
        setq28steroid(newList);
    };

    function addQ28RepeaterCourse(e, treatmentData) {
         
        const index = q28steroid.findIndex(
            (x) => x.treatmenttypeid === treatmentData.treatmenttypeid
        );
        if (index > -1) {
            const updatedQ28steroid = [...q28steroid]; // Create a new array to avoid mutating state
            let seqNo = updatedQ28steroid[index].courseListQ28[updatedQ28steroid[index].courseListQ28.length - 1].RowSequenceNo
            updatedQ28steroid[index].courseListQ28.push({
                RowId: Math.random(),
                treatmenttypeid: treatmentData.treatmenttypeid,
                treatmenttypeid: treatmentData.treatmenttypeid,
                RowSequenceNo: seqNo + 1,
                Steriodcourseid: 0,
                q28a_steroid_type: "",
                q28b_start_dt: "",
                q28b_start_dt_unk: "",
                q28c_start_dose: "",
                q28c_start_dose_unk: " ",
                q28d_start_num: "",
                q28d_start_freq: "",
                q28d_freq_unk: "",
                q28e_dose_change: "",
                //repeater
                clickListQ28: [],

                //rept
                q28i_tx_disc: "",
                q28j_tx_disc_dt: "",
                q28j_tx_disc_dt_unk: "",

                q28a_empty: false,
                q28b_empty: false,
                q28c_empty: false,
                q28d_empty: false,
                q28e_empty: false,
                q28i_empty: false,
                q28j_empty: false,
                q28alessindexdate: false,
                q28jlessindexdate:false,
                q28jAfterindexdate:false,
                q28jBeforeDosedate:false,
                q28cDoseInput: false,
                q28dFreqInput: false
            });

            setq28steroid(updatedQ28steroid); // Update the state with the new array
        }
    }

    const handleDeleteSteroidQ28 = (treatmentid, id) => {
         
        const index = q28steroid.findIndex((x) => x.treatmenttypeid === treatmentid);
        if (index > -1) {
            const updatedQ28steroid = [...q28steroid];
            const list = updatedQ28steroid[index].courseListQ28.filter((x) => x.RowSequenceNo != id);
            updatedQ28steroid[index].courseListQ28 = list;
            setq28steroid(updatedQ28steroid);
        }
    };

    function addQ28RepeaterRow(e, treatmentData, courseListData) {
     
        const { name, id, value } = e.target;
        const checkIfListExist = q28steroid.findIndex(
            (x) => x.treatmenttypeid === treatmentData.treatmenttypeid
        );
        if (checkIfListExist > -1) {
            // treatmentData.q28e_dose_change = value;
            let mainListData = q28steroid[checkIfListExist];
            const index = mainListData.courseListQ28.findIndex(
                (x) => x.RowSequenceNo === courseListData.RowSequenceNo
            );

            if (index > -1) {
                let courseData = mainListData.courseListQ28[index];



                let dosagearray = [...q28steroid];
                dosagearray[0].courseListQ28[id].q28e_dose_change = value;
                dosagearray[0].courseListQ28[id].q28e_empty = false;
                setq28steroid(dosagearray);

                if (value == "1") {
                    courseData.clickListQ28.push({
                        doseId: Math.random(),
                        Steriodcourseid: 0,
                        q28doseCount: 1,
                        q28f_dose_change_dt: "",
                        q28g_change_dose: "",
                        q28h_change_num: "",
                        q28h_change_freq: "",
                        q28rpt_empty: false,
                        q28f_empty:false,
                        q28g_empty:false,
                        q28h_empty:false,
                        q28eafterindexdate: false,
                        q28ebeforeindexdate: false,
                        q28gdoseInput: false,
                        q28hFreqInput: false,
                    });
                }
                else {
                    courseData.clickListQ28 = [];
                    courseData.q28jAfterindexdate=false;
                    courseData.q28jBeforeDosedate=false;
                    

                }

            }
            setThDetails((prev) => ({
                ...prev,
                q28steroid,
            }));
        }

    }

    const addDoseClickQ28 = (e, treatmentData, courseListData) => {

         

        const { name, id, value } = e.target;
        const checkIfListExist = q28steroid.findIndex(
            (x) => x.treatmenttypeid === treatmentData.treatmenttypeid
        );
        if (checkIfListExist > -1) {
            let mainListData = q28steroid[checkIfListExist];
            const index = mainListData.courseListQ28.findIndex(
                (x) => x.RowSequenceNo === courseListData.RowSequenceNo
            );

            if (index > -1) {
                let courseData = mainListData.courseListQ28[index];
                let count = courseData.clickListQ28[courseData.clickListQ28.length - 1].q28doseCount;

                courseData.clickListQ28.push({
                    doseId: Math.random(),
                    q28doseCount: count + 1,
                    q28f_dose_change_dt: "",
                    q28g_change_dose: "",
                    q28h_change_num: "",
                    q28h_change_freq: "",
                    q28rpt_empty: false,
                    q28f_empty:false,
                    q28g_empty:false,
                    q28h_empty:false,
                    q28eafterindexdate: false,
                    q28ebeforeindexdate: false,
                    q28gdoseInput: false,
                    q28hFreqInput: false,

                });
            }

        }
        setThDetails((prev) => ({
            ...prev,
            q28steroid,
        }));
    };



    //question 30
    const [Q30Date, setQ30Date] = useState([])

    const HandleChangeq30 = (e) => {

        const { name, id, value } = e.target;
        switch (name) {

            case "q30_pre_ava_splenectomy":
            case "q30_pre_ava_splen_dt":
            case "q30_pre_ava_splen_dt_unk":
                {
                    setThDetails((prev) => ({
                        ...prev,
                        [name]: value,
                    }));

                    //clears the date field o n click if radio button
                    if (e.target.name === "q30_pre_ava_splen_dt_unk") {
                        // Clear the date field when the radio button is clicked
                        setThDetails((prev) => ({
                            ...prev,
                            q30_pre_ava_splen_dt: "", // Clear the date field
                            q30Empty: false,
                            q30_NotSelected: false,
                            q30lessindexdate: false,

                        }));
                        setQ30Date([]);
                    } else if (e.target.value === "0") {
                        setThDetails((prev) => ({
                            ...prev,
                            q30_pre_ava_splen_dt: "", // Clear the date field
                            q30_pre_ava_splen_dt_unk: "",
                            q30Empty: false,
                            q30_NotSelected: false,
                            q30lessindexdate: false,
                        }));
                        setQ30Date([]);

                    }



                    //clears the date field for other date

                    break;
                }
            default:
                break;
        }
    };


    //save
    const onsaveclick = async (validateForm) => {
        try{
        ToggleLoader(true);
        let status =validateForm? validate():false;
        if (status === true) {
            setShowTopErrMsg(true);
            ToggleLoader(false);
        }
        else {
            setShowTopErrMsg(false);

            let data = {
                ItpTreatmentPriorModel: {
                    ItptreatmentspriorDetails: {
                        Itptreatmentspriorid: thDetails.itppriortreatmentid,
                        PreAvaEverElt: thDetails.q24a1_pre_ava_ever_elt,
                        PreAvaEverRomi: thDetails.q24a2_pre_ava_ever_romi,
                        PreAvaEverLusu: thDetails.q24a3_pre_ava_ever_lusu,
                        PreAvaEverSteroids: thDetails.q24a4_pre_ava_ever_steroids,
                        PreAvaEverIvig: thDetails.q24a5_pre_ava_ever_ivig,
                        PreAvaEverAntid: thDetails.q24a6_pre_ava_ever_antid,
                        PreAvaEverRitux: thDetails.q24a7_pre_ava_ever_ritux,
                        PreAvaEverFosta: thDetails.q24a8_pre_ava_ever_fosta,
                        PreAvaEverImmuno: thDetails.q24a9_pre_ava_ever_immuno,
                        PreAvaEverNone: thDetails.q24a10_pre_ava_ever_none,

                        Pre3moAvaElt: thDetails.q24b1_pre_3mo_ava_elt,
                        Pre3moAvaRomi: thDetails.q24b2_pre_3mo_ava_romi,
                        Pre3moAvaLusu: thDetails.q24b3_pre_3mo_ava_lusu,
                        Pre3moAvaSteroids: thDetails.q24b4_pre_3mo_ava_steroids,
                        Pre3moAvaIvig: thDetails.q24b5_pre_3mo_ava_ivig,
                        Pre3moAvaAntid: thDetails.q24b6_pre_3mo_ava_antid,
                        Pre3moAvaRitux: thDetails.q24b7_pre_3mo_ava_ritux,
                        Pre3moAvaFosta: thDetails.q24b8_pre_3mo_ava_fosta,
                        Pre3moAvaImmuno: thDetails.q24b9_pre_3mo_ava_immuno,
                        Pre3moAvaNone: thDetails.q24b10_pre_3mo_ava_none,


                        //RituxImmunoAndAva: thDetails.q29_ritux_immuno_and_ava,
                        PreAvaSplenectomy: thDetails.q30_pre_ava_splenectomy,
                        PreAvaSplenDt: thDetails.q30_pre_ava_splen_dt,
                        PreAvaSplenDtUnk: thDetails.q30_pre_ava_splen_dt_unk === "1",

                    },
                    ItpdosefrequencyList: [],
                    AvaInitiationList: [],
                    FostamatinibInitiationList: [],
                    SteriodcourseList: [],
                    AvarituximabList: [],
                    patientid: GetLocalStorageData("patientId") ?? 0,
                    UserID: GetLoggedInUserID(),
                    IsValidated:validateForm,
                },
            };

            if (q25dosefreq.length > 0) {
                for (let i = 0; i < q25dosefreq.length; i++) {



                    if (q25dosefreq[i].doseChangeListQ25.length > 0) {
                        for (let j = 0; j < q25dosefreq[i].doseChangeListQ25.length; j++) {

                            let Q25 = {
                                Itptreatmenttypeid: q25dosefreq[i].treatmenttypeid,

                                PreAvaA1TxStartDt: q25dosefreq[i].q25a_pre_ava_a1_tx_start_dt,
                                PreAvaA1TxStartDtUnk: q25dosefreq[i].q25a_pre_ava_a1_tx_start_dt_unk == "1",
                                PreAvaB1TxStartDose: q25dosefreq[i].q25b_pre_ava_b1_tx_start_dose,
                                PreAvaC1TxStartNum: q25dosefreq[i].q25c_pre_ava_c1_tx_start_num,
                                PreAvaC1TxStartFreq: q25dosefreq[i].q25c_pre_ava_c1_tx_start_freq,
                                PreAvaC1TxStartDoseUnk: q25dosefreq[i].q25c_pre_ava_c1_tx_start_dose_unk == "1",
                                PreAvaD1TxDoseChange: q25dosefreq[i].q25d_pre_ava_d1_tx_dose_change,
                                PreAvaTxDisc: q25dosefreq[i].q25h_pre_ava_tx_disc,
                                PreAvaTxDiscDt: q25dosefreq[i].q25i_pre_ava_tx_disc_dt,
                                PreAvaDiscDtUnk: q25dosefreq[i].q25i_pre_ava_disc_dt_unk == "1",
                                PreAvaE1TxDoseChangeDt:
                                    q25dosefreq[i].doseChangeListQ25[j].q25e_pre_ava_e1_tx_dose_change_dt,
                                PreAvaF1TxChangeDose: q25dosefreq[i].doseChangeListQ25[j].q25f_pre_ava_f1_tx_change_dose,
                                PreAvaG1TxChangeNum: q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_num,
                                PreAvaG1TxChangeFreq: q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_freq,
                                Itpdosefrequencyid: q25dosefreq[i].doseChangeListQ25[j].Itpdosefrequencyid,

                            };

                            data.ItpTreatmentPriorModel.ItpdosefrequencyList.push(Q25);
                        }
                    }
                    else {
                        let Q25 = {
                            Itptreatmenttypeid: q25dosefreq[i].treatmenttypeid,
                            Itpdosefrequencyid: q25dosefreq[i].Itpdosefrequencyid,
                            PreAvaA1TxStartDt: q25dosefreq[i].q25a_pre_ava_a1_tx_start_dt,
                            PreAvaA1TxStartDtUnk: q25dosefreq[i].q25a_pre_ava_a1_tx_start_dt_unk == "1",
                            PreAvaB1TxStartDose: q25dosefreq[i].q25b_pre_ava_b1_tx_start_dose,
                            PreAvaC1TxStartNum: q25dosefreq[i].q25c_pre_ava_c1_tx_start_num,
                            PreAvaC1TxStartFreq: q25dosefreq[i].q25c_pre_ava_c1_tx_start_freq,
                            PreAvaC1TxStartDoseUnk: q25dosefreq[i].q25c_pre_ava_c1_tx_start_dose_unk == "1",
                            PreAvaD1TxDoseChange: q25dosefreq[i].q25d_pre_ava_d1_tx_dose_change,
                            PreAvaTxDisc: q25dosefreq[i].q25h_pre_ava_tx_disc,
                            PreAvaTxDiscDt: q25dosefreq[i].q25i_pre_ava_tx_disc_dt,
                            PreAvaDiscDtUnk: q25dosefreq[i].q25i_pre_ava_disc_dt_unk == "1",

                        };
                        data.ItpTreatmentPriorModel.ItpdosefrequencyList.push(Q25);

                    }

                }
            }

            if (q26ava.length > 0) {

                for (let j = 0; j < q26ava.length; j++) {
                    if (q26ava[j].avaListQ26.length > 0) {
                        for (let i = 0; i < q26ava[j].avaListQ26.length; i++) {

                            let Q26 = {
                                Avainitiationid: q26ava[j].avaListQ26[i].Avainitiationid,
                                Itptreatmenttypeid: q26ava[j].treatmenttypeid,
                                PreAvaA1TxStartDt: q26ava[j].avaListQ26[i].q26a_pre_ava_a1_tx_start_dt,
                                PreAvaA1TxStartDtUnk: q26ava[j].avaListQ26[i].q26a_pre_ava_a1_tx_start_dt_unk == "99",
                                PreAvaB1TxEndDt: q26ava[j].avaListQ26[i].q26b_pre_ava_b1_tx_end_dt,
                                PreAvaB1TxEndDtUnk: q26ava[j].avaListQ26[i].q26b_pre_ava_b1_tx_end_dt_unk == "99",

                            };


                            data.ItpTreatmentPriorModel.AvaInitiationList.push(Q26);
                        }
                    }
                }
            }

            if (q27fosta.length > 0) {
                for (let i = 0; i < q27fosta.length; i++) {

                    if (q27fosta[i].doseChangeListQ27.length > 0) {
                        for (let j = 0; j < q27fosta[i].doseChangeListQ27.length; j++) {

                            let Q27 = {

                                Itptreatmenttypeid: q27fosta[i].treatmenttypeid,
                                Fostamatinibinitiationid: q27fosta[i].doseChangeListQ27[j].Fostamatinibinitiationid,
                                PreAvaFostaStartDt: q27fosta[i].q27a_pre_ava_fosta_start_dt,
                                PreAvaFostaStartDtUnk: q27fosta[i].q27a_pre_ava_fosta_start_dt_unk == "1",
                                PreAvaFostaStartDose: q27fosta[i].q27b_pre_ava_fosta_start_dose,
                                PreAvaFostaStartDoseOth: q27fosta[i].q27b_pre_ava_fosta_start_dose_oth,
                                PreAvaFostaDoseChange: q27fosta[i].q27c_pre_ava_fosta_dose_change,

                                PreAvaFostaDisc: q27fosta[i].q27f_pre_ava_fosta_disc,
                                PreAvaFostaDiscDt: q27fosta[i].q27g_pre_ava_fosta_disc_dt,
                                PreAvaFostaDiscDtUnk: q27fosta[i].q27g_pre_ava_fosta_disc_dt_unk == "1",


                                PreAvaFostaDoseChgD1Dt:
                                    q27fosta[i].doseChangeListQ27[j].q27d_pre_ava_fosta_dose_chg_d1_dt,
                                PreAvaFostaDoseChgE1: q27fosta[i].doseChangeListQ27[j].q27e_pre_ava_fosta_dose_chg_e1,
                                PreAvaFostaChangeDoseOth: q27fosta[i].doseChangeListQ27[j].q27e_pre_ava_fosta_change_dose_oth,
                            };

                            data.ItpTreatmentPriorModel.FostamatinibInitiationList.push(Q27);
                        }
                    }
                    else {

                        let Q27 = {

                            Itptreatmenttypeid: q27fosta[i].treatmenttypeid,
                            Fostamatinibinitiationid: q27fosta[i].Fostamatinibinitiationid,
                            PreAvaFostaStartDt: q27fosta[i].q27a_pre_ava_fosta_start_dt,
                            PreAvaFostaStartDtUnk: q27fosta[i].q27a_pre_ava_fosta_start_dt_unk == "1",
                            PreAvaFostaStartDose: q27fosta[i].q27b_pre_ava_fosta_start_dose,
                            PreAvaFostaStartDoseOth: q27fosta[i].q27b_pre_ava_fosta_start_dose_oth,
                            PreAvaFostaDoseChange: q27fosta[i].q27c_pre_ava_fosta_dose_change,

                            PreAvaFostaDisc: q27fosta[i].q27f_pre_ava_fosta_disc,
                            PreAvaFostaDiscDt: q27fosta[i].q27g_pre_ava_fosta_disc_dt,
                            PreAvaFostaDiscDtUnk: q27fosta[i].q27g_pre_ava_fosta_disc_dt_unk == "1"
                        }

                        data.ItpTreatmentPriorModel.FostamatinibInitiationList.push(Q27);

                    }
                }
            }

            if (q28steroid.length > 0) {
                if (q28steroid[0].courseListQ28.length > 0) {
                    for (let i = 0; i < q28steroid[0].courseListQ28.length; i++) {


                        if (q28steroid[0].courseListQ28[i].clickListQ28.length > 0) {
                            for (let j = 0; j < q28steroid[0].courseListQ28[i].clickListQ28.length; j++) {

                                let Q28 = {

                                    Itptreatmenttypeid: q28steroid[0].treatmenttypeid,
                                    PreAvaA1SteroidType: q28steroid[0].courseListQ28[i].q28a_steroid_type,
                                    PreAvaB1TxStartDt: q28steroid[0].courseListQ28[i].q28b_start_dt,
                                    PreAvaB1SterStartDtUnk: q28steroid[0].courseListQ28[i].q28b_start_dt_unk == "99",
                                    PreAvaC1TxStartDose: q28steroid[0].courseListQ28[i].q28c_start_dose,
                                    PreAvaC1TxStartDoseUnk: q28steroid[0].courseListQ28[i].q28c_start_dose_unk == "99",
                                    PreAvaD1TxStartNum: q28steroid[0].courseListQ28[i].q28d_start_num,
                                    PreAvaD1TxStartFreq: q28steroid[0].courseListQ28[i].q28d_start_freq,
                                    PreAvaD1SterFreqUnk: q28steroid[0].courseListQ28[i].q28d_freq_unk == "99",
                                    PreAvaE1TxDoseChange: q28steroid[0].courseListQ28[i].q28e_dose_change,
                                    PreAvaI1TxDisc: q28steroid[0].courseListQ28[i].q28i_tx_disc,
                                    PreAvaJ1TxDiscDt: q28steroid[0].courseListQ28[i].q28j_tx_disc_dt,
                                    PreAvaJ1TxDiscDtUnk: q28steroid[0].courseListQ28[i].q28j_tx_disc_dt_unk == "99",

                                    Steriodcourseid: q28steroid[0].courseListQ28[i].clickListQ28[j].Steriodcourseid,
                                    RowSequenceNo: q28steroid[0].courseListQ28[i].RowSequenceNo == 0 ? i + 1 : q28steroid[0].courseListQ28[i].RowSequenceNo,

                                    PreAvaF1x1TxDoseChangeDt:
                                        q28steroid[0].courseListQ28[i].clickListQ28[j].q28f_dose_change_dt,
                                    PreAvaG1x1TxChangeDose: q28steroid[0].courseListQ28[i].clickListQ28[j].q28g_change_dose,
                                    PreAvaH1x1TxChangeNum: q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_num,
                                    PreAvaH1x1TxChangeFreq: q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_freq,
                                };

                                data.ItpTreatmentPriorModel.SteriodcourseList.push(Q28);
                            }
                        }
                        else {
                            let Q28 = {

                                Itptreatmenttypeid: q28steroid[0].treatmenttypeid,
                                Steriodcourseid: q28steroid[0].courseListQ28[i].Steriodcourseid,
                                RowSequenceNo: q28steroid[0].courseListQ28[i].RowSequenceNo == 0 ? i + 1 : q28steroid[0].courseListQ28[i].RowSequenceNo,
                                PreAvaA1SteroidType: q28steroid[0].courseListQ28[i].q28a_steroid_type,
                                PreAvaB1TxStartDt: q28steroid[0].courseListQ28[i].q28b_start_dt,
                                PreAvaB1SterStartDtUnk: q28steroid[0].courseListQ28[i].q28b_start_dt_unk == "99",
                                PreAvaC1TxStartDose: q28steroid[0].courseListQ28[i].q28c_start_dose,
                                PreAvaC1TxStartDoseUnk: q28steroid[0].courseListQ28[i].q28c_start_dose_unk == "99",
                                PreAvaD1TxStartNum: q28steroid[0].courseListQ28[i].q28d_start_num,
                                PreAvaD1TxStartFreq: q28steroid[0].courseListQ28[i].q28d_start_freq,
                                PreAvaD1SterFreqUnk: q28steroid[0].courseListQ28[i].q28d_freq_unk == "99",
                                PreAvaE1TxDoseChange: q28steroid[0].courseListQ28[i].q28e_dose_change,
                                PreAvaI1TxDisc: q28steroid[0].courseListQ28[i].q28i_tx_disc,
                                PreAvaJ1TxDiscDt: q28steroid[0].courseListQ28[i].q28j_tx_disc_dt,
                                PreAvaJ1TxDiscDtUnk: q28steroid[0].courseListQ28[i].q28j_tx_disc_dt_unk == "99",
                            }
                            data.ItpTreatmentPriorModel.SteriodcourseList.push(Q28);

                        }
                    }
                }
            }

            if (q29ava.length > 0) {
                for (let i = 0; i < q29ava.length; i++) {
                    let Q29 = {

                        Itptreatmenttypeid: q29ava[i].treatmenttypeid,
                        Avarituximabid: q29ava[i].Avarituximabid,
                        RituxImmunoAndAva: q29ava[i].q29_ritux_immuno_and_ava,
                    };

                    data.ItpTreatmentPriorModel.AvarituximabList.push(Q29);
                }
            }

            let response = await TreatmentService.SaveAllTreatment(
                data.ItpTreatmentPriorModel
            );
            if (response?.status !== HTTPResponse.OK) {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    throw response.error;
            }else{
                await GettreatmentData();
                ToggleLoader(false);
                ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            }
            return true;


        }
        return false;
    }catch{
        ToggleLoader(false);        
        ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
    };
    /* <summary>
    date: 25-10-2023
    Name: PB
    description: required fields validation.
    <summary>*/
    function validate() {

        let indexDateValue = indexdate != "" ? new Date(indexdate) : new Date();
        let q30Empty = thDetails.q30_pre_ava_splenectomy === "" ? true : false;
        //q24  
        let q24a_Empty =
            thDetails.q24a1_pre_ava_ever_elt === false &&
                thDetails.q24a1_pre_ava_ever_elt === false &&
                thDetails.q24a2_pre_ava_ever_romi === false &&
                thDetails.q24a3_pre_ava_ever_lusu === false &&
                thDetails.q24a4_pre_ava_ever_steroids === false &&
                thDetails.q24a5_pre_ava_ever_ivig === false &&
                thDetails.q24a6_pre_ava_ever_antid === false &&
                thDetails.q24a7_pre_ava_ever_ritux === false &&
                thDetails.q24a8_pre_ava_ever_fosta === false &&
                thDetails.q24a9_pre_ava_ever_immuno === false &&
                thDetails.q24a10_pre_ava_ever_none === ""
                ? true
                : false;
        // let q24b_Empty =
        //     thDetails.q24b1_pre_3mo_ava_elt === false &&
        //         thDetails.q24b2_pre_3mo_ava_romi === false &&
        //         thDetails.q24b3_pre_3mo_ava_lusu === false &&
        //         thDetails.q24b4_pre_3mo_ava_steroids === false &&
        //         thDetails.q24b5_pre_3mo_ava_ivig === false &&
        //         thDetails.q24b6_pre_3mo_ava_antid === false &&
        //         thDetails.q24b7_pre_3mo_ava_ritux === false &&
        //         thDetails.q24b8_pre_3mo_ava_fosta === false &&
        //         thDetails.q24b9_pre_3mo_ava_immuno === false &&
        //         thDetails.q24b10_pre_3mo_ava_none === ""
        //         ? true
        //         : false;
        //q30
        let q30_NotSelected =
            (thDetails.q30_pre_ava_splenectomy === "1" || thDetails.q30_pre_ava_splenectomy === 1) &&
                thDetails.q30_pre_ava_splen_dt === "" &&
                thDetails.q30_pre_ava_splen_dt_unk == ""
                ? true
                : false;

        /* <summary>
        date: 25-10-2023
        Name: pb
        description: repeater logic 
        <summary>*/

        //val 29 -- working 
        let Q29hasError = false;
        let dosagearray = q29ava;
        if (
            thDetails.q24b7_pre_3mo_ava_ritux === true ||
            thDetails.q24b9_pre_3mo_ava_immuno === true
        ) {

            for (let i = 0; i < q29ava.length; i++) {

                dosagearray[i].q29Empty =
                    q29ava[i].q29_ritux_immuno_and_ava === ""

                if (
                    dosagearray[i].q29Empty
                )
                    Q29hasError = true;
            }
            setq29ava(dosagearray);
        }
        //val 26  

        let Q26HasError = false
        let q26array = [...q26ava];
        if (thDetails.q24b6_pre_3mo_ava_antid === true || thDetails.q24b5_pre_3mo_ava_ivig === true) {

            for (let i = 0; i < q26ava.length; i++) {
                for (let j = 0; j < q26ava[i].avaListQ26.length; j++) {
                  q26array[i].avaListQ26[j].q26alessindex= false;
                  q26array[i].avaListQ26[j].q26blessthanstartdate=false;
                  q26array[i].avaListQ26[j].q26aThreeMonthsIndexdate=false;
                  q26array[i].avaListQ26[j].Q26bAfterFollowEndDate=false;

                    q26array[i].avaListQ26[j].q26a_Empty =
                        q26ava[i].avaListQ26[j].q26a_pre_ava_a1_tx_start_dt == ""

                    q26array[i].avaListQ26[j].q26b_Empty =
                        q26ava[i].avaListQ26[j].q26b_pre_ava_b1_tx_end_dt === ""

                    if (
                        q26array[i].avaListQ26[j].q26a_Empty ||
                        q26array[i].avaListQ26[j].q26b_Empty
                    )
                        Q26HasError = true;
                }
            }
            setQ26ava(q26array);
        }

        // val 25 

        let Q25hasError = false;
        let q25array = [...q25dosefreq]
        if (thDetails.q24b1_pre_3mo_ava_elt === true || thDetails.q24b2_pre_3mo_ava_romi === true ||
            thDetails.q24b3_pre_3mo_ava_lusu === true) {

            for (let i = 0; i < q25dosefreq.length; i++) {
              
              q25array[i].q25alessindexdate= false;
              q25array[i].q25ibefordosedate= false;
              q25array[i].q25ibeforetherapydate= false;
              q25array[i].q25iBeforeIndexDate=false;

                q25array[i].q25a_empty =
                    q25dosefreq[i].q25a_pre_ava_a1_tx_start_dt === "" &&
                    q25dosefreq[i].q25a_pre_ava_a1_tx_start_dt_unk === ""

                q25array[i].q25b_empty =
                    q25dosefreq[i].q25b_pre_ava_b1_tx_start_dose === "" && (q25dosefreq[i].q25c_pre_ava_c1_tx_start_dose_unk === "" || q25dosefreq[i].q25c_pre_ava_c1_tx_start_dose_unk === false)

                if (q25dosefreq[i].q25b_pre_ava_b1_tx_start_dose !== "")
                    q25array[i].q25adoseInput = q25dosefreq[i].q25b_pre_ava_b1_tx_start_dose < 1501 && q25dosefreq[i].q25b_pre_ava_b1_tx_start_dose > 0 ? false : true;

                q25array[i].q25c_empty =
                    (q25dosefreq[i].q25c_pre_ava_c1_tx_start_num === "" &&
                        (q25dosefreq[i].q25c_pre_ava_c1_tx_start_freq === "" || q25dosefreq[i].q25c_pre_ava_c1_tx_start_freq === 0 )&&
                        (q25dosefreq[i].q25c_pre_ava_c1_tx_start_dose_unk === "" || q25dosefreq[i].q25c_pre_ava_c1_tx_start_dose_unk === false)) == true ? true :
                        ((q25dosefreq[i].q25c_pre_ava_c1_tx_start_num !== "" &&
                            q25dosefreq[i].q25c_pre_ava_c1_tx_start_freq === "" || q25dosefreq[i].q25c_pre_ava_c1_tx_start_freq === 0) || (q25dosefreq[i].q25c_pre_ava_c1_tx_start_num === "" &&
                                q25dosefreq[i].q25c_pre_ava_c1_tx_start_freq !== "" &&  q25dosefreq[i].q25c_pre_ava_c1_tx_start_freq !== 0)) == true ? true : false

                if (q25dosefreq[i].q25c_pre_ava_c1_tx_start_num !== "")
                    q25array[i].q25cfreqInput = q25dosefreq[i].q25c_pre_ava_c1_tx_start_num < 1501 && q25dosefreq[i].q25c_pre_ava_c1_tx_start_num > 0 ? false : true;


                q25array[i].q25d_empty =
                    q25dosefreq[i].q25d_pre_ava_d1_tx_dose_change === "" ? true : false;

                //repeater e -f - & g . 
                let Q25DoseError = false;

                for (let j = 0; j < q25dosefreq[i].doseChangeListQ25.length; j++) {
                  q25array[i].doseChangeListQ25[j].q25elessindexdate=false;
                  q25array[i].doseChangeListQ25[j].q25eafterindexdate=false;
                    q25array[i].doseChangeListQ25[j].q25rpt_empty =
                        (q25dosefreq[i].doseChangeListQ25[j].q25e_pre_ava_e1_tx_dose_change_dt === "" &&
                            q25dosefreq[i].doseChangeListQ25[j].q25f_pre_ava_f1_tx_change_dose === "" &&
                            q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_num === "" &&
                            (q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_freq === "" || 
                            q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_freq === 0)) == true ? true : false;

                            if(q25array[i].doseChangeListQ25[j].q25rpt_empty==false){
                            q25array[i].doseChangeListQ25[j].q25e_empty=q25dosefreq[i].doseChangeListQ25[j].q25e_pre_ava_e1_tx_dose_change_dt===""
                            q25array[i].doseChangeListQ25[j].q25f_empty=q25dosefreq[i].doseChangeListQ25[j].q25f_pre_ava_f1_tx_change_dose===""
                            q25array[i].doseChangeListQ25[j].q25g_empty=
                            ((q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_freq == "" ||
                             q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_freq ==0) &&
                            q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_num == ""==true?true:
                            ((q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_num !== "" &&
                            q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_freq === "" 
                            || q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_freq=== 0) 
                            || (q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_num=== "" &&
                            q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_freq !== "" && 
                             q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_freq !== 0)) == true ? true : false)
                            }

                    if (q25array[i].doseChangeListQ25[j].q25f_pre_ava_f1_tx_change_dose !== "")
                        q25array[i].doseChangeListQ25[j].q25fdoseInput = q25dosefreq[i].doseChangeListQ25[j].q25f_pre_ava_f1_tx_change_dose < 1501 && q25dosefreq[i].doseChangeListQ25[j].q25f_pre_ava_f1_tx_change_dose > 0 ? false : true;

                    if (q25array[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_num !== "")
                        q25array[i].doseChangeListQ25[j].q25gFreqInput = q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_num < 1501 && q25dosefreq[i].doseChangeListQ25[j].q25g_pre_ava_g1_tx_change_num > 0 ? false : true;


                    if (q25array[i].doseChangeListQ25[j].q25rpt_empty == true || q25array[i].doseChangeListQ25[j].q25fdoseInput == true ||
                        q25array[i].doseChangeListQ25[j].q25gFreqInput == true || q25array[i].doseChangeListQ25[j].q25g_empty==true ||
                        q25array[i].doseChangeListQ25[j].q25f_empty==true || q25array[i].doseChangeListQ25[j].q25e_empty==true) 
                        Q25DoseError = true
                }

                q25array[i].q25h_empty =
                    q25dosefreq[i].q25h_pre_ava_tx_disc === ""

                q25array[i].q25i_empty =
                    q25dosefreq[i].q25h_pre_ava_tx_disc == "1" && (
                        q25dosefreq[i].q25i_pre_ava_disc_dt_unk === "" &&
                        q25dosefreq[i].q25i_pre_ava_tx_disc_dt === "")

                if (
                    q25array[i].q25a_empty ||
                    q25array[i].q25b_empty ||
                    q25array[i].q25adoseInput ||
                    q25array[i].q25cfreqInput ||
                    q25array[i].q25c_empty ||
                    q25array[i].q25d_empty ||
                    Q25DoseError == true ||
                    q25array[i].q25h_empty ||
                    q25array[i].q25i_empty

                ) Q25hasError = true;
                setq25DoseFreq(q25array);
            }
        }

        //val 27
        let Q27hasError = false;

        let q27array = [...q27fosta]
        if (thDetails.q24b8_pre_3mo_ava_fosta === true) {

            for (let i = 0; i < q27fosta.length; i++) {


              q27array[i].q27lessindexdate=false;
              q27array[i].q27gbeforeTherapydate=false;
              q27array[i].q27gBeforeDoseDate=false;
            
                q27array[i].q27a_empty =
                    q27fosta[i].q27a_pre_ava_fosta_start_dt === "" &&
                    q27fosta[i].q27a_pre_ava_fosta_start_dt_unk === ""

                q27array[i].q27b_empty =
                    q27fosta[i].q27b_pre_ava_fosta_start_dose === "" ||
                    (q27fosta[i].q27b_pre_ava_fosta_start_dose === "3" && q27fosta[i].q27b_pre_ava_fosta_start_dose_oth === "")

                q27array[i].q27c_empty =
                    q27fosta[i].q27c_pre_ava_fosta_dose_change === ""

                q27array[i].q27f_empty =
                    q27fosta[i].q27f_pre_ava_fosta_disc === "" ? true : false;

 
                q27array[i].q27g_empty =
                    q27fosta[i].q27f_pre_ava_fosta_disc == "1" &&
                    (q27fosta[i].q27g_pre_ava_fosta_disc_dt === "" &&
                        q27fosta[i].q27g_pre_ava_fosta_disc_dt_unk === "")

                let Q27DoseError = false;

                for (let j = 0; j < q27fosta[i].doseChangeListQ27.length; j++) {
                  q27array[i].doseChangeListQ27[j].q27dbeforeindexdate=false;
                    q27array[i].doseChangeListQ27[j].q27rpt_empty =
                        (q27fosta[i].doseChangeListQ27[j].q27d_pre_ava_fosta_dose_chg_d1_dt === "" &&
                            q27fosta[i].doseChangeListQ27[j].q27e_pre_ava_fosta_dose_chg_e1 === "" ) == true ? true : false;

                    if(q27array[i].doseChangeListQ27[j].q27rpt_empty==false)
                    {
                      q27array[i].doseChangeListQ27[j].q27d_empty =q27fosta[i].doseChangeListQ27[j].q27d_pre_ava_fosta_dose_chg_d1_dt === ""
                      q27array[i].doseChangeListQ27[j].q27e_empty=
                      (q27fosta[i].doseChangeListQ27[j].q27e_pre_ava_fosta_dose_chg_e1 == "" ||
                                (q27fosta[i].doseChangeListQ27[j].q27e_pre_ava_fosta_dose_chg_e1 === "3" &&
                                    q27fosta[i].doseChangeListQ27[j].q27e_pre_ava_fosta_change_dose_oth === ""))
                    }


                    if (q27fosta[i].doseChangeListQ27[j].q27rpt_empty == true || q27array[i].doseChangeListQ27[j].q27d_empty==true ||
                      q27array[i].doseChangeListQ27[j].q27e_empty==true)
                        Q27DoseError = true
                }

                if (
                    q27array[i].q27a_empty ||
                    q27array[i].q27b_empty ||
                    q27array[i].q27f_empty ||
                    q27array[i].q27g_empty ||
                    q27array[i].q27c_empty ||
                    Q27DoseError == true
                )
                    Q27hasError = true;
                setq27fosta(q27array);

            }
        }

        //28
        let Q28hasError = false;
        let q28array = [...q28steroid]
        if (thDetails.q24b4_pre_3mo_ava_steroids === true) {


            for (let i = 0; i < q28steroid[0].courseListQ28.length; i++) {

              q28array[0].courseListQ28[i].q28alessindexdate=false;
              q28array[0].courseListQ28[i].q28jAfterindexdate=false;
              q28array[0].courseListQ28[i].q28jlessindexdate=false;
              q28array[0].courseListQ28[i].q28jBeforeDosedate=false;
             
                q28array[0].courseListQ28[i].q28a_empty =
                    q28steroid[0].courseListQ28[i].q28a_steroid_type === ""

                q28array[0].courseListQ28[i].q28b_empty =
                    q28steroid[0].courseListQ28[i].q28b_start_dt === ""

                q28array[0].courseListQ28[i].q28c_empty =
                    q28steroid[0].courseListQ28[i].q28c_start_dose === ""

                if (q28steroid[0].courseListQ28[i].q28c_start_dose !== "")
                    q28array[0].courseListQ28[i].q28cDoseInput = q28steroid[0].courseListQ28[i].q28c_start_dose < 1501 && q28steroid[0].courseListQ28[i].q28c_start_dose > 0 ? false : true;


                    q28array[0].courseListQ28[i].q28d_empty =
                    (q28steroid[0].courseListQ28[i].q28d_start_num === "" &&
                    (q28steroid[0].courseListQ28[i].q28d_start_freq === ""|| q28steroid[0].courseListQ28[i].q28d_start_freq === 0)) == true ? true :
                        ((q28steroid[0].courseListQ28[i].q28d_start_num !== "" &&
                        q28steroid[0].courseListQ28[i].q28d_start_freq === ""|| q28steroid[0].courseListQ28[i].q28d_start_freq === 0) || (q28steroid[0].courseListQ28[i].q28d_start_num==="" &&
                        q28steroid[0].courseListQ28[i].q28d_start_freq !== ""&& q28steroid[0].courseListQ28[i].q28d_start_freq !== 0)) == true ? true : false


                if (q28steroid[0].courseListQ28[i].q28c_start_dose !== "")
                    q28array[0].courseListQ28[i].q28dFreqInput = q28steroid[0].courseListQ28[i].q28d_start_num < 1501 && q28steroid[0].courseListQ28[i].q28d_start_num > 0 ? false : true;


                q28array[0].courseListQ28[i].q28e_empty =
                    q28steroid[0].courseListQ28[i].q28e_dose_change === "" ? true : false;

                q28array[0].courseListQ28[i].q28i_empty =
                    q28steroid[0].courseListQ28[i].q28i_tx_disc === ""

                q28array[0].courseListQ28[i].q28j_empty =
                    q28steroid[0].courseListQ28[i].q28i_tx_disc == "1" &&
                    (q28steroid[0].courseListQ28[i].q28j_tx_disc_dt === "")

                let Q28DoseError = false;

                for (let j = 0; j < q28array[0].courseListQ28[i].clickListQ28.length; j++) {
                  q28array[0].courseListQ28[i].clickListQ28[j].q28eafterindexdate=false;
                  q28array[0].courseListQ28[i].clickListQ28[j].q28ebeforeindexdate=false;
                    q28array[0].courseListQ28[i].clickListQ28[j].q28rpt_empty =
                        (q28steroid[0].courseListQ28[i].clickListQ28[j].q28f_dose_change_dt === "" &&
                            q28steroid[0].courseListQ28[i].clickListQ28[j].q28g_change_dose === "" &&
                            q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_num === "" &&
                            (q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_freq === "" ||
                             q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_freq === 0)) == true ? true : false;

                    if(q28array[0].courseListQ28[i].clickListQ28[j].q28rpt_empty===false)
                    {
                      q28steroid[0].courseListQ28[i].clickListQ28[j].q28f_empty=q28steroid[0].courseListQ28[i].clickListQ28[j].q28f_dose_change_dt === "";
                      q28steroid[0].courseListQ28[i].clickListQ28[j].q28g_empty=q28steroid[0].courseListQ28[i].clickListQ28[j].q28g_change_dose === "";
                      q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_empty=
                      ((q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_freq == "" ||
                      q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_freq ==0) &&
                      q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_num == ""==true?true:
                     ((q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_num !== "" &&
                     q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_freq === "" 
                     || q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_freq=== 0) 
                     || (q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_num=== "" &&
                     q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_freq !== "" && 
                     q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_freq !== 0)) == true ? true : false)
                    }


                    if (q28steroid[0].courseListQ28[i].clickListQ28[j].q28g_change_dose !== "")
                        q28array[0].courseListQ28[i].clickListQ28[j].q28gdoseInput = q28steroid[0].courseListQ28[i].clickListQ28[j].q28g_change_dose < 1501 && q28steroid[0].courseListQ28[i].clickListQ28[j].q28g_change_dose > 0 ? false : true;


                    if (q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_num !== "")
                        q28array[0].courseListQ28[i].clickListQ28[j].q28hFreqInput = q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_num < 1501 && q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_change_num > 0 ? false : true;

                    if (q28array[0].courseListQ28[i].clickListQ28[j].q28rpt_empty == true || q28steroid[0].courseListQ28[i].clickListQ28[j].q28gdoseInput == true ||
                        q28steroid[0].courseListQ28[i].clickListQ28[j].q28hFreqInput == true || q28steroid[0].courseListQ28[i].clickListQ28[j].q28f_empty==true ||
                        q28steroid[0].courseListQ28[i].clickListQ28[j].q28g_empty==true || q28steroid[0].courseListQ28[i].clickListQ28[j].q28h_empty==true)
                        Q28DoseError = true



                }


                if (
                    q28array[0].courseListQ28[i].q28a_empty ||
                    q28array[0].courseListQ28[i].q28b_empty ||
                    q28array[0].courseListQ28[i].q28c_empty ||
                    q28array[0].courseListQ28[i].q28d_empty ||
                    q28array[0].courseListQ28[i].q28cDoseInput ||
                    q28array[0].courseListQ28[i].q28dFreqInput ||
                    q28array[0].courseListQ28[i].q28e_empty ||
                    q28array[0].courseListQ28[i].q28i_empty ||
                    // q28array[0].courseListQ28[i].q28j_empty ||
                    Q28DoseError == true
                )
                    Q28hasError = true;




                setq28steroid(q28array);

            }
        }

        setThDetails({
            ...thDetails,
            q30Empty: q30Empty,
            q24a_Empty: q24a_Empty,
          //  q24b_Empty: q24b_Empty,
            q30_NotSelected: q30_NotSelected,
            q30lessindexdate:false,

        });
        if (
            q30Empty ||
            q24a_Empty ||
          //  q24b_Empty ||
            q30_NotSelected ||
            Q25hasError ||
            Q26HasError ||
            Q29hasError ||
            Q27hasError ||
            Q28hasError

        ) {
            return true;
        }
        else {
            return false;
        }


    }


    const [frequencytime, setfrequencytime] = useState([]);
    const [indexdate, setindexdate] = useState("");
    const [endfollowupdate, setendfollowupdate] = useState("");
    const [indexDateMinus3Months, setindexDateMinus3Months] = useState("");
    const [indexDateMinus1Day, setindexDateMinus1Day] = useState("");
    const pid = GetLocalStorageData("patientId") ?? 0;

    const GettreatmentData = async () => {

        try {

            const response = await TreatmentService.GetAllTreatment(pid);

            //setfrequencytime(response.data.frequencytime);

            if (response?.status !== HTTPResponse.OK) {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    throw response.error;
            }else{
                setindexdate(response.data.indexDate);
                setendfollowupdate(response.data.endFollowUpDate);
                setindexDateMinus3Months(response.data.indexDateMinus3Months);
                setindexDateMinus1Day(response.data.indexDateMinus1Day)
                ;
            
                setfrequencytime(response.data.frequencytimeList);
                //Q24 Q30
                if (response.data.itptreatmentspriorDetails != null) {

                    setThDetails({
                        ...thDetails,
                        itppriortreatmentid:
                            response.data.itptreatmentspriorDetails.itptreatmentspriorid != null
                                ? response.data.itptreatmentspriorDetails.itptreatmentspriorid
                                : 0,
                        q24a1_pre_ava_ever_elt:
                            response.data.itptreatmentspriorDetails.preAvaEverElt != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverElt
                                : false,
                        q24a2_pre_ava_ever_romi:
                            response.data.itptreatmentspriorDetails.preAvaEverRomi != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverRomi
                                : false,
                        q24a3_pre_ava_ever_lusu:
                            response.data.itptreatmentspriorDetails.preAvaEverLusu != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverLusu
                                : false,
                        q24a4_pre_ava_ever_steroids:
                            response.data.itptreatmentspriorDetails.preAvaEverSteroids != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverSteroids
                                : false,
                        q24a5_pre_ava_ever_ivig:
                            response.data.itptreatmentspriorDetails.preAvaEverIvig != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverIvig
                                : false,
                        q24a6_pre_ava_ever_antid:
                            response.data.itptreatmentspriorDetails.preAvaEverAntid != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverAntid
                                : false,
                        q24a7_pre_ava_ever_ritux:
                            response.data.itptreatmentspriorDetails.preAvaEverRitux != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverRitux
                                : false,
                        q24a8_pre_ava_ever_fosta:
                            response.data.itptreatmentspriorDetails.preAvaEverFosta != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverFosta
                                : false,
                        q24a9_pre_ava_ever_immuno:
                            response.data.itptreatmentspriorDetails.preAvaEverImmuno != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverImmuno
                                : false,
                        q24a10_pre_ava_ever_none:
                            response.data.itptreatmentspriorDetails.preAvaEverNone != null
                                ? response.data.itptreatmentspriorDetails.preAvaEverNone
                                : "",


                        q24b1_pre_3mo_ava_elt:
                            response.data.itptreatmentspriorDetails.pre3moAvaElt != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaElt
                                : false,

                        q24b2_pre_3mo_ava_romi:
                            response.data.itptreatmentspriorDetails.pre3moAvaRomi != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaRomi
                                : false,
                        q24b3_pre_3mo_ava_lusu:
                            response.data.itptreatmentspriorDetails.pre3moAvaLusu != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaLusu
                                : false,
                        q24b4_pre_3mo_ava_steroids:
                            response.data.itptreatmentspriorDetails.pre3moAvaSteroids != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaSteroids
                                : false,
                        q24b5_pre_3mo_ava_ivig:
                            response.data.itptreatmentspriorDetails.pre3moAvaIvig != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaIvig
                                : false,
                        q24b6_pre_3mo_ava_antid:
                            response.data.itptreatmentspriorDetails.pre3moAvaAntid != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaAntid
                                : false,
                        q24b7_pre_3mo_ava_ritux:
                            response.data.itptreatmentspriorDetails.pre3moAvaRitux != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaRitux
                                : false,
                        q24b8_pre_3mo_ava_fosta:
                            response.data.itptreatmentspriorDetails.pre3moAvaFosta != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaFosta
                                : false,
                        q24b9_pre_3mo_ava_immuno:
                            response.data.itptreatmentspriorDetails.pre3moAvaImmuno != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaImmuno
                                : false,
                        q24b10_pre_3mo_ava_none:
                            response.data.itptreatmentspriorDetails.pre3moAvaNone != null
                                ? response.data.itptreatmentspriorDetails.pre3moAvaNone
                                : "",

                        q30_pre_ava_splenectomy:
                            response.data.itptreatmentspriorDetails.preAvaSplenectomy != null
                                ? response.data.itptreatmentspriorDetails.preAvaSplenectomy
                                : "",
                        q30_pre_ava_splen_dt:
                            response.data.itptreatmentspriorDetails.preAvaSplenDt != null
                                ? response.data.itptreatmentspriorDetails.preAvaSplenDt
                                : "",
                        q30_pre_ava_splen_dt_unk:
                            response.data.itptreatmentspriorDetails.preAvaSplenDtUnk == true ? "1" : "",

                        q30Empty: false,
                        q24a_Empty: false,
                        q24b_Empty: false,
                        q30_NotSelected: false,
                        q30lessindexdate: false
                    });
                }

                //Q25
                if (
                    response.data.itpdosefrequencyList != null &&
                    response.data.itpdosefrequencyList.length > 0
                ) {
                    let i = 0;

                    let dosagearray = [];
                    let typeid = [];
                    for (i; i < response.data.itpdosefrequencyList.length; i++) {

                        if (!typeid.includes(response.data.itpdosefrequencyList[i].itptreatmenttypeid
                        )) {
                            typeid.push(response.data.itpdosefrequencyList[i].itptreatmenttypeid
                            );

                            let dosagecomponent = {


                                treatmenttypeid:
                                    response.data.itpdosefrequencyList[i].itptreatmenttypeid
                                        != null
                                        ? response.data.itpdosefrequencyList[i].itptreatmenttypeid
                                        : 0,
                                treatmentName:
                                    response.data.itpdosefrequencyList[i].treatmentName
                                        != null
                                        ? response.data.itpdosefrequencyList[i].treatmentName
                                        : "",


                                q25a_pre_ava_a1_tx_start_dt:
                                    response.data.itpdosefrequencyList[i].preAvaA1TxStartDt != null
                                        ? response.data.itpdosefrequencyList[i].preAvaA1TxStartDt
                                        : "",
                                q25a_pre_ava_a1_tx_start_dt_unk:
                                    response.data.itpdosefrequencyList[i].preAvaA1TxStartDtUnk === true
                                        ? "1"
                                        : "",
                                q25b_pre_ava_b1_tx_start_dose:
                                    response.data.itpdosefrequencyList[i].preAvaB1TxStartDose != null
                                        ? response.data.itpdosefrequencyList[i].preAvaB1TxStartDose
                                        : "",
                                q25c_pre_ava_c1_tx_start_num:
                                    response.data.itpdosefrequencyList[i].preAvaC1TxStartNum != null
                                        ? response.data.itpdosefrequencyList[i].preAvaC1TxStartNum
                                        : "",
                                q25c_pre_ava_c1_tx_start_freq:
                                    response.data.itpdosefrequencyList[i].preAvaC1TxStartFreq != null
                                        ? response.data.itpdosefrequencyList[i].preAvaC1TxStartFreq
                                        : "",
                                q25c_pre_ava_c1_tx_start_dose_unk:
                                    response.data.itpdosefrequencyList[i].preAvaC1TxStartDoseUnk === true
                                        ? "1"
                                        : "",
                                q25d_pre_ava_d1_tx_dose_change:
                                    response.data.itpdosefrequencyList[i].preAvaD1TxDoseChange != null
                                        ? response.data.itpdosefrequencyList[i].preAvaD1TxDoseChange
                                        : "",
                                q25h_pre_ava_tx_disc:
                                    response.data.itpdosefrequencyList[i].preAvaTxDisc != null
                                        ? response.data.itpdosefrequencyList[i].preAvaTxDisc
                                        : "",
                                q25i_pre_ava_tx_disc_dt:
                                    response.data.itpdosefrequencyList[i].preAvaTxDiscDt != null
                                        ? response.data.itpdosefrequencyList[i].preAvaTxDiscDt
                                        : "",
                                q25i_pre_ava_disc_dt_unk:
                                    response.data.itpdosefrequencyList[i].preAvaDiscDtUnk === true
                                        ? "1"
                                        : "",
                                doseChangeListQ25: [],
                                q25a_empty: false,
                                q25b_empty: false,
                                q25c_empty: false,
                                q25d_empty: false,
                                q25h_empty: false,
                                q25i_empty: false,
                                q25alessindexdate: false,
                                q25ibeforetherapydate:false,
                                q25ibefordosedate:false,
                                q25iBeforeIndexDate:false,
                                q25adoseInput: false,
                                q25cfreqInput: false,


                            }
                            dosagearray.push(dosagecomponent);
                        }


                        let dosefrequency = {

                            Itpdosefrequencyid:
                                response.data.itpdosefrequencyList[i].itpdosefrequencyid != null
                                    ? response.data.itpdosefrequencyList[i].itpdosefrequencyid
                                    : 0,
                            q25e_pre_ava_e1_tx_dose_change_dt:
                                response.data.itpdosefrequencyList[i].preAvaE1TxDoseChangeDt != null
                                    ? response.data.itpdosefrequencyList[i].preAvaE1TxDoseChangeDt
                                    : "",

                            q25f_pre_ava_f1_tx_change_dose:
                                response.data.itpdosefrequencyList[i].preAvaF1TxChangeDose != null
                                    ? response.data.itpdosefrequencyList[i].preAvaF1TxChangeDose
                                    : "",
                            q25g_pre_ava_g1_tx_change_num:
                                response.data.itpdosefrequencyList[i].preAvaG1TxChangeNum != null
                                    ? response.data.itpdosefrequencyList[i].preAvaG1TxChangeNum
                                    : "",
                            q25g_pre_ava_g1_tx_change_freq:
                                response.data.itpdosefrequencyList[i].preAvaG1TxChangeFreq != null
                                    ? response.data.itpdosefrequencyList[i].preAvaG1TxChangeFreq
                                    : "",
                            q25dosecount: i + 1,

                            q25rpt_empty: false,
                            q25e_empty:false,
                            q25f_empty:false,
                            q25g_empty:false,
                            q25elessindexdate: false,
                            q25eafterindexdate: false,
                            q25fdoseInput: false,
                            q25gFreqInput: false,
                        };

                        const checkIfListExist = dosagearray.findIndex(
                            (x) => x.treatmenttypeid === response.data.itpdosefrequencyList[i].itptreatmenttypeid
                        );
                        if (dosagearray[checkIfListExist].q25d_pre_ava_d1_tx_dose_change == "1") {

                            dosagearray[checkIfListExist].doseChangeListQ25.push(dosefrequency);
                        }


                    }
                    setq25DoseFreq(dosagearray);
                }

                //Q26
                if (
                    response.data.avaInitiationList != null &&
                    response.data.avaInitiationList.length > 0
                ) {
                    let i = 0;
                    let dosagearray = [];
                    let typeid = [];
                    for (i; i < response.data.avaInitiationList.length; i++) {

                        if (!typeid.includes(response.data.avaInitiationList[i].itptreatmenttypeid
                        )) {
                            typeid.push(response.data.avaInitiationList[i].itptreatmenttypeid
                            );
                            let dosagecomponent = {
                                treatmenttypeid:
                                    response.data.avaInitiationList[i].itptreatmenttypeid
                                        != null
                                        ? response.data.avaInitiationList[i].itptreatmenttypeid
                                        : 0,
                                treatmentName:
                                    response.data.avaInitiationList[i].treatmentName
                                        != null
                                        ? response.data.avaInitiationList[i].treatmentName
                                        : "",
                                avaListQ26: []
                            }
                            dosagearray.push(dosagecomponent);
                        }


                        let dosefrequency = {

                            Avainitiationid:
                                response.data.avaInitiationList[i].avainitiationid != null
                                    ? response.data.avaInitiationList[i].avainitiationid
                                    : 0,
                            q26a_pre_ava_a1_tx_start_dt:
                                response.data.avaInitiationList[i].preAvaA1TxStartDt != null
                                    ? response.data.avaInitiationList[i].preAvaA1TxStartDt
                                    : "",

                            q26a_pre_ava_a1_tx_start_dt_unk:
                                response.data.avaInitiationList[i].preAvaA1TxStartDtUnk === true
                                    ? "99"
                                    : "",

                            q26b_pre_ava_b1_tx_end_dt:
                                response.data.avaInitiationList[i].preAvaB1TxEndDt != null
                                    ? response.data.avaInitiationList[i].preAvaB1TxEndDt
                                    : "",
                            q26b_pre_ava_b1_tx_end_dt_unk:
                                response.data.avaInitiationList[i].preAvaB1TxEndDtUnk === true
                                    ? "99"
                                    : "",
                            q26Count: i + 1,
                            q26a_Empty: false,
                            q26b_Empty: false,
                            q26alessindex: false,
                            q26aThreeMonthsIndexdate:false,
                            q26blessthanstartdate:false,
                            Q26bAfterFollowEndDate:false,
                        };

                        const checkIfListExist = dosagearray.findIndex(
                            (x) => x.treatmenttypeid === response.data.avaInitiationList[i].itptreatmenttypeid
                        );
                        dosagearray[checkIfListExist].avaListQ26.push(dosefrequency);


                    }
                    setQ26ava(dosagearray);
                }

                //Q29
                if (
                    response.data.avarituximabList != null &&
                    response.data.avarituximabList.length > 0
                ) {
                    let i = 0;
                    let dosagearray = [];
                    let typeid = [];
                    for (i; i < response.data.avarituximabList.length; i++) {


                        let dosagecomponent = {
                            treatmenttypeid:
                                response.data.avarituximabList[i].itptreatmenttypeid
                                    != null
                                    ? response.data.avarituximabList[i].itptreatmenttypeid
                                    : 0,
                            treatmentName:
                                response.data.avarituximabList[i].treatmentName
                                    != null
                                    ? response.data.avarituximabList[i].treatmentName
                                    : "",
                            q29_ritux_immuno_and_ava:
                                response.data.avarituximabList[i].rituxImmunoAndAva
                                    != null
                                    ? response.data.avarituximabList[i].rituxImmunoAndAva
                                    : 0,
                            q29Empty: false,
                        }
                        dosagearray.push(dosagecomponent);
                    }
                    setq29ava(dosagearray);
                }

                //Q27
                if (
                    response.data.fostamatinibInitiationList != null &&
                    response.data.fostamatinibInitiationList.length > 0
                ) {
                    let i = 0;
                    let dosagearray = [];
                    let typeid = [];
                    for (i; i < response.data.fostamatinibInitiationList.length; i++) {

                        if (!typeid.includes(response.data.fostamatinibInitiationList[i].itptreatmenttypeid
                        )) {
                            typeid.push(response.data.fostamatinibInitiationList[i].itptreatmenttypeid
                            );
                            let dosagecomponent = {
                                treatmenttypeid:
                                    response.data.fostamatinibInitiationList[i].itptreatmenttypeid
                                        != null
                                        ? response.data.fostamatinibInitiationList[i].itptreatmenttypeid
                                        : 0,
                                treatmentName:
                                    response.data.fostamatinibInitiationList[i].treatmentName
                                        != null
                                        ? response.data.fostamatinibInitiationList[i].treatmentName
                                        : "",
                                Fostamatinibinitiationid:
                                    response.data.fostamatinibInitiationList[i].fostamatinibinitiationid != null
                                        ? response.data.fostamatinibInitiationList[i].fostamatinibinitiationid
                                        : 0,

                                q27a_pre_ava_fosta_start_dt:
                                    response.data.fostamatinibInitiationList[i].preAvaFostaStartDt
                                        != null
                                        ? response.data.fostamatinibInitiationList[i].preAvaFostaStartDt
                                        : "",
                                q27a_pre_ava_fosta_start_dt_unk:
                                    response.data.fostamatinibInitiationList[i].preAvaFostaStartDtUnk == true ? "1" : "",
                                q27b_pre_ava_fosta_start_dose:
                                    response.data.fostamatinibInitiationList[i].preAvaFostaStartDose
                                        != null
                                        ? response.data.fostamatinibInitiationList[i].preAvaFostaStartDose
                                        : "",
                                q27b_pre_ava_fosta_start_dose_oth:
                                    response.data.fostamatinibInitiationList[i].preAvaFostaStartDoseOth
                                        != ""
                                        ? response.data.fostamatinibInitiationList[i].preAvaFostaStartDoseOth
                                        : "",
                                q27c_pre_ava_fosta_dose_change:
                                    response.data.fostamatinibInitiationList[i].preAvaFostaDoseChange
                                        != null
                                        ? response.data.fostamatinibInitiationList[i].preAvaFostaDoseChange
                                        : "",
                                q27f_pre_ava_fosta_disc:
                                    response.data.fostamatinibInitiationList[i].preAvaFostaDisc
                                        != null
                                        ? response.data.fostamatinibInitiationList[i].preAvaFostaDisc
                                        : "",
                                q27g_pre_ava_fosta_disc_dt:
                                    response.data.fostamatinibInitiationList[i].preAvaFostaDiscDt
                                        != null
                                        ? response.data.fostamatinibInitiationList[i].preAvaFostaDiscDt
                                        : "",
                                q27g_pre_ava_fosta_disc_dt_unk:
                                    response.data.fostamatinibInitiationList[i].preAvaFostaDiscDtUnk == true ? "1" : "",

                                doseChangeListQ27: [],
                                q27a_empty: false,
                                q27b_empty: false,
                                q27f_empty: false,
                                q27g_empty: false,
                                q27c_empty: false,
                                q27lessindexdate: false,
                                q27gbeforeTherapydate:false,
                                q27gBeforeDoseDate:false,
                            }
                            dosagearray.push(dosagecomponent);
                        }


                        let dosefrequency = {

                            Fostamatinibinitiationid:
                                response.data.fostamatinibInitiationList[i].fostamatinibinitiationid != null
                                    ? response.data.fostamatinibInitiationList[i].fostamatinibinitiationid
                                    : 0,
                            q27d_pre_ava_fosta_dose_chg_d1_dt:
                                response.data.fostamatinibInitiationList[i].preAvaFostaDoseChgD1Dt != null
                                    ? response.data.fostamatinibInitiationList[i].preAvaFostaDoseChgD1Dt
                                    : "",

                            q27e_pre_ava_fosta_dose_chg_e1:
                                response.data.fostamatinibInitiationList[i].preAvaFostaDoseChgE1 != null
                                    ? response.data.fostamatinibInitiationList[i].preAvaFostaDoseChgE1
                                    : "",

                            q27e_pre_ava_fosta_change_dose_oth:
                                response.data.fostamatinibInitiationList[i].preAvaFostaChangeDoseOth
                                    != ""
                                    ? response.data.fostamatinibInitiationList[i].preAvaFostaChangeDoseOth
                                    : "",
                            q27doseCount: i + 1,
                            q27rpt_empty: false,
                            q27d_empty:false,
                            q27e_empty:false,
                            q27dbeforeindexdate: false,
                        };

                        const checkIfListExist = dosagearray.findIndex(
                            (x) => x.treatmenttypeid === response.data.fostamatinibInitiationList[i].itptreatmenttypeid
                        );

                        if (dosagearray[checkIfListExist].q27c_pre_ava_fosta_dose_change == "1")
                            dosagearray[checkIfListExist].doseChangeListQ27.push(dosefrequency);


                    }
                    setq27fosta(dosagearray);
                }

                //Q28
                if (
                    response.data.steriodcourseList != null &&
                    response.data.steriodcourseList.length > 0
                ) {
                    
                    let i = 0;
                    let dosagearray = [];
                    let typeid = [];
                    let courseid = [];
                    for (i; i < response.data.steriodcourseList.length; i++) {

                        if (!typeid.includes(response.data.steriodcourseList[i].itptreatmenttypeid
                        )) {
                            typeid.push(response.data.steriodcourseList[i].itptreatmenttypeid
                            );
                            let dosagecomponent = {
                                treatmenttypeid:
                                    response.data.steriodcourseList[i].itptreatmenttypeid
                                        != null
                                        ? response.data.steriodcourseList[i].itptreatmenttypeid
                                        : 0,
                                treatmentName:
                                    response.data.steriodcourseList[i].treatmentName
                                        != null
                                        ? response.data.steriodcourseList[i].treatmentName
                                        : "",
                                courseListQ28: []
                            }
                            dosagearray.push(dosagecomponent);
                        }


                        if (!courseid.includes(response.data.steriodcourseList[i].rowSequenceNo
                        )) {
                            courseid.push(response.data.steriodcourseList[i].rowSequenceNo
                            );
                            let steroidcomponent = {

                                RowSequenceNo:
                                    response.data.steriodcourseList[i].rowSequenceNo != null
                                        ? response.data.steriodcourseList[i].rowSequenceNo
                                        : 0,
                                Steriodcourseid:
                                    response.data.steriodcourseList[i].steriodcourseid != null
                                        ? response.data.steriodcourseList[i].steriodcourseid
                                        : 0,
                                q28a_steroid_type:
                                    response.data.steriodcourseList[i].preAvaA1SteroidType === ""
                                        ? ""
                                        : response.data.steriodcourseList[i].preAvaA1SteroidType,

                                q28b_start_dt:
                                    response.data.steriodcourseList[i].preAvaB1TxStartDt != null
                                        ? response.data.steriodcourseList[i].preAvaB1TxStartDt
                                        : "",

                                q28b_start_dt_unk:
                                    response.data.steriodcourseList[i].preAvaB1SterStartDtUnk === true
                                        ? "99"
                                        : "",

                                q28c_start_dose:
                                    response.data.steriodcourseList[i].preAvaC1TxStartDose != null
                                        ? response.data.steriodcourseList[i].preAvaC1TxStartDose
                                        : "",

                                q28c_start_dose_unk:
                                    response.data.steriodcourseList[i].preAvaC1TxStartDoseUnk === true
                                        ? "99"
                                        : "",

                                q28d_start_num:
                                    response.data.steriodcourseList[i].preAvaD1TxStartNum != null
                                        ? response.data.steriodcourseList[i].preAvaD1TxStartNum
                                        : "",

                                q28d_start_freq:
                                    response.data.steriodcourseList[i].preAvaD1TxStartFreq != null
                                        ? response.data.steriodcourseList[i].preAvaD1TxStartFreq
                                        : "",

                                q28d_freq_unk:
                                    response.data.steriodcourseList[i].preAvaD1SterFreqUnk === true
                                        ? "99"
                                        : "",

                                q28e_dose_change:
                                    response.data.steriodcourseList[i].preAvaE1TxDoseChange != null
                                        ? response.data.steriodcourseList[i].preAvaE1TxDoseChange
                                        : "",

                                q28i_tx_disc:
                                    response.data.steriodcourseList[i].preAvaI1TxDisc != null
                                        ? response.data.steriodcourseList[i].preAvaI1TxDisc
                                        : "",

                                q28j_tx_disc_dt_unk:
                                    response.data.steriodcourseList[i].preAvaJ1TxDiscDtUnk === true
                                        ? "99"
                                        : "",

                                q28j_tx_disc_dt:
                                    response.data.steriodcourseList[i].preAvaJ1TxDiscDt != null
                                        ? response.data.steriodcourseList[i].preAvaJ1TxDiscDt
                                        : "",

                                clickListQ28: [],
                                q28a_empty: false,
                                q28b_empty: false,
                                q28c_empty: false,
                                q28d_empty: false,
                                q28e_empty: false,
                                q28i_empty: false,
                                q28j_empty: false,
                                q28alessindexdate: false,
                                q28jAfterindexdate:false,
                                q28jBeforeDosedate:false,
                                q28jlessindexdate:false,
                                q28cDoseInput: false,
                                q28dFreqInput: false,
                            }



                            const checkIfListExist = dosagearray.findIndex(
                                (x) => x.treatmenttypeid === response.data.steriodcourseList[i].itptreatmenttypeid
                            );

                            dosagearray[checkIfListExist].courseListQ28.push(steroidcomponent);

                        }

                        let dosefrequency = {

                            Steriodcourseid:
                                response.data.steriodcourseList[i].steriodcourseid != null
                                    ? response.data.steriodcourseList[i].steriodcourseid
                                    : 0,
                            q28f_dose_change_dt:
                                response.data.steriodcourseList[i].preAvaF1x1TxDoseChangeDt != null
                                    ? response.data.steriodcourseList[i].preAvaF1x1TxDoseChangeDt
                                    : "",

                            q28g_change_dose:
                                response.data.steriodcourseList[i].preAvaG1x1TxChangeDose != null
                                    ? response.data.steriodcourseList[i].preAvaG1x1TxChangeDose
                                    : "",

                            q28h_change_num:
                                response.data.steriodcourseList[i].preAvaH1x1TxChangeNum != null
                                    ? response.data.steriodcourseList[i].preAvaH1x1TxChangeNum
                                    : "",
                            q28h_change_freq:
                                response.data.steriodcourseList[i].preAvaH1x1TxChangeFreq != null
                                    ? response.data.steriodcourseList[i].preAvaH1x1TxChangeFreq
                                    : "",

                            q28doseCount: i + 1,
                            q28rpt_empty: false,
                            q28f_empty:false,
                            q28g_empty:false,
                            q28h_empty:false,
                            q28eafterindexdate: false,
                            q28ebeforeindexdate: false,
                            q28gdoseInput: false,
                            q28hFreqInput: false,
                        };

                        const checkIfsteroidListExist = dosagearray.findIndex(
                            (x) => x.treatmenttypeid === response.data.steriodcourseList[i].itptreatmenttypeid
                        );
                        const checkIfdoseListExist = dosagearray[checkIfsteroidListExist].courseListQ28.findIndex(
                            (x) => x.RowSequenceNo === response.data.steriodcourseList[i].rowSequenceNo
                        );

                        if (dosagearray[checkIfsteroidListExist].courseListQ28[checkIfdoseListExist].q28e_dose_change == "1")
                            dosagearray[checkIfsteroidListExist].courseListQ28[checkIfdoseListExist].clickListQ28.push(dosefrequency);


                    }


                    setq28steroid(dosagearray);
                }
            }

        } catch {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    };


    const [Q25aDate, setQ25aDate] = useState();

    const HandleDateChange = (name, date, optionId) => {

        let indexDateValue = indexdate != "" ? new Date(indexdate) : new Date();
        let indexThreeMonthsDatevalue= indexdate != "" ? new Date(indexDateMinus3Months) : new Date();
        const lastIndex = name.lastIndexOf("-");
        const idx = name.slice(lastIndex + 1);
        switch (optionId) {
            case "q25a_pre_ava_a1_tx_start_dt": {
                let dosagearray = [...q25dosefreq];
                dosagearray[idx].q25a_pre_ava_a1_tx_start_dt = date;
                dosagearray[idx].q25a_pre_ava_a1_tx_start_dt_unk = false;

                dosagearray[idx].q25datechange = false;

                dosagearray[idx].defDateQ25 = false;
                dosagearray[idx].q25a_empty = false;


                if (dosagearray[idx].q25a_pre_ava_a1_tx_start_dt != "") {
                    let inittherapydate = new Date(dosagearray[idx].q25a_pre_ava_a1_tx_start_dt);
                    let initdisctdate = new Date(dosagearray[idx].q25i_pre_ava_tx_disc_dt);

                    if (inittherapydate >= indexDateValue) {
                        dosagearray[idx].q25alessindexdate = true;
                    }
                    else dosagearray[idx].q25alessindexdate = false;

                    if (initdisctdate <= inittherapydate) {
                        dosagearray[idx].q25ibeforetherapydate = true;
                    }
                    else dosagearray[idx].q25ibeforetherapydate = false;

                    if(dosagearray[idx].doseChangeListQ25.length>0)
                    {
                          for (let i = 0; i < dosagearray[idx].doseChangeListQ25.length; i++) {
                            let doseDate=new Date(dosagearray[idx].doseChangeListQ25[i].q25e_pre_ava_e1_tx_dose_change_dt);
                            if (doseDate <= inittherapydate) {
                                dosagearray[idx].doseChangeListQ25[i].q25eafterindexdate = true;
                            }
                            else dosagearray[idx].doseChangeListQ25[i].q25eafterindexdate = true;

                          }

                    }

                }
                else dosagearray[idx].q25alessindexdate = false;

                setq25DoseFreq(dosagearray);
            }
                break;
            case "q25i_pre_ava_tx_disc_dt": {
                
                let dosagearray = [...q25dosefreq];
                dosagearray[idx].q25i_pre_ava_tx_disc_dt = date;
                dosagearray[idx].q25i_pre_ava_disc_dt_unk = false;
                dosagearray[idx].q25defdateDisc = false;
                dosagearray[idx].q25i_empty = false;


                if (dosagearray[idx].q25i_pre_ava_tx_disc_dt != "") {
                    let initdisctdate = new Date(dosagearray[idx].q25i_pre_ava_tx_disc_dt);
                    let inittherapydate = new Date(dosagearray[idx].q25a_pre_ava_a1_tx_start_dt);

                    if (initdisctdate > indexDateValue) {
                        dosagearray[idx].q25iBeforeIndexDate = true;
                    }
                    else dosagearray[idx].q25iBeforeIndexDate = false;

                    
                    if (initdisctdate <= inittherapydate) {
                        dosagearray[idx].q25ibeforetherapydate = true;
                    }
                    else dosagearray[idx].q25ibeforetherapydate = false;

                    if(dosagearray[idx].doseChangeListQ25.length>0)
                    {
                          for (let i = 0; i < dosagearray[idx].doseChangeListQ25.length; i++) {
                            let doseDate=new Date(dosagearray[idx].doseChangeListQ25[i].q25e_pre_ava_e1_tx_dose_change_dt);
                            if (initdisctdate <= doseDate) {
                                dosagearray[idx].q25ibefordosedate = true;
                            }
                            else  dosagearray[idx].q25ibefordosedate = false
                          }

                    }
                }
                //dosagearray[idx].q34datechange = false;
                setq25DoseFreq(dosagearray);
            }
                break;
            case "q25e_pre_ava_e1_tx_dose_change_dt": {

                const arry = name.split("-");
                const parentidx = arry[2];
                const childidx = arry[1];
                let dosagearray = [...q25dosefreq];
                dosagearray[parentidx].doseChangeListQ25[childidx].q25e_pre_ava_e1_tx_dose_change_dt = date;

                dosagearray[parentidx].doseChangeListQ25[childidx].q25e_empty =false ;

                if (dosagearray[parentidx].doseChangeListQ25[childidx].q25e_pre_ava_e1_tx_dose_change_dt != "") {
                    let initdosedate = new Date(dosagearray[parentidx].doseChangeListQ25[childidx].q25e_pre_ava_e1_tx_dose_change_dt);
                    let inittherapydate = new Date(dosagearray[parentidx].q25a_pre_ava_a1_tx_start_dt);

                    if (initdosedate >= indexDateValue) {
                        dosagearray[parentidx].doseChangeListQ25[childidx].q25elessindexdate = true;
                    }
                    else dosagearray[parentidx].doseChangeListQ25[childidx].q25elessindexdate = false;


                    if (initdosedate <= inittherapydate) {
                        dosagearray[parentidx].doseChangeListQ25[childidx].q25eafterindexdate = true;
                    }
                    else dosagearray[parentidx].doseChangeListQ25[childidx].q25eafterindexdate = false;

                    if(dosagearray[parentidx].q25ibefordosedate !==""){
                    let initdisctdate = new Date(dosagearray[parentidx].q25i_pre_ava_tx_disc_dt);
                    if(initdisctdate<=initdosedate){
                        dosagearray[parentidx].q25ibefordosedate = true;
                    }
                    else
                    dosagearray[parentidx].q25ibefordosedate = false;
                }

                }
                //dosagearray[idx].q34datechange = false;
                setq25DoseFreq(dosagearray);
            }
                break;

            case "q28b_start_dt": {

                let dosagearray = [...q28steroid];
                dosagearray[0].courseListQ28[idx].q28b_start_dt = date;
                // dosagearray[0].courseListQ28[idx].q28b_start_dt_unk = false;
                dosagearray[0].courseListQ28[idx].q28datechange = false;
                dosagearray[0].courseListQ28[idx].defDateQ28 = false;
                dosagearray[0].courseListQ28[idx].q28b_empty = false;

                if (dosagearray[0].courseListQ28[idx].q28b_start_dt != "") {
                    let inittherapydate = new Date(dosagearray[0].courseListQ28[idx].q28b_start_dt);
                    let initdisctdate = new Date(dosagearray[0].courseListQ28[idx].q28j_tx_disc_dt);

                    if (inittherapydate >= indexDateValue) {
                        dosagearray[0].courseListQ28[idx].q28alessindexdate = true;
                    } else dosagearray[0].courseListQ28[idx].q28alessindexdate = false;

                    if (initdisctdate <= inittherapydate) {
                        dosagearray[0].courseListQ28[idx].q28jAfterindexdate = true;
                    }
                    else dosagearray[0].courseListQ28[idx].q28jAfterindexdate = false;

                    
                    if(dosagearray[0].courseListQ28[idx].clickListQ28.length>0)
                    {
                          for (let i = 0; i < dosagearray[0].courseListQ28[idx].clickListQ28.length; i++) {
                            let doseDate=new Date(dosagearray[0].courseListQ28[idx].clickListQ28[i].q28f_dose_change_dt);
                            if (doseDate <= inittherapydate) {
                                dosagearray[0].courseListQ28[idx].clickListQ28[i].q28ebeforeindexdate = true;
                            }
                            else dosagearray[0].courseListQ28[idx].clickListQ28[i].q28ebeforeindexdate = false;

                          }

                    }
                }
                else dosagearray[0].courseListQ28[idx].q28alessindexdate = false;
                setq28steroid(dosagearray);
            }
                break;
            case "q28j_tx_disc_dt": {
              
                let dosagearray = [...q28steroid];
                dosagearray[0].courseListQ28[idx].q28j_tx_disc_dt = date;
                // dosagearray[0].courseListQ28[idx].q28j_tx_disc_dt_unk = false;
                dosagearray[0].courseListQ28[idx].q28defdateDisc = false;
                dosagearray[0].courseListQ28[idx].q28j_empty = false;

                if (dosagearray[0].courseListQ28[idx].q28j_tx_disc_dt != "") {
                    let initdisctdate = new Date(dosagearray[0].courseListQ28[idx].q28j_tx_disc_dt);
                    let inittherapydate = new Date(dosagearray[0].courseListQ28[idx].q28b_start_dt);

                    if (initdisctdate >= indexDateValue) {
                        dosagearray[0].courseListQ28[idx].q28jlessindexdate = true;
                    } else dosagearray[0].courseListQ28[idx].q28jlessindexdate = false;

                    if (initdisctdate <= inittherapydate) {
                        dosagearray[0].courseListQ28[idx].q28jAfterindexdate = true;
                    }
                    else dosagearray[0].courseListQ28[idx].q28jAfterindexdate = false;

                    if(dosagearray[0].courseListQ28[idx].clickListQ28.length>0)
                    {
                          for (let i = 0; i < dosagearray[0].courseListQ28[idx].clickListQ28.length; i++) {
                            let doseDate=new Date(dosagearray[0].courseListQ28[idx].clickListQ28[i].q28f_dose_change_dt);
                            if (initdisctdate <= doseDate) {
                                dosagearray[0].courseListQ28[idx].q28jBeforeDosedate = true;
                            }
                            else dosagearray[0].courseListQ28[idx].q28jBeforeDosedate = false;

                          }

                    }
                }
                setq28steroid(dosagearray);
            }
                break;
            case "q28f_dose_change_dt": {

                const arry = name.split("-");
                const parentidx = arry[2];
                const childidx = arry[1];
                let dosagearray = [...q28steroid];
                dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28f_dose_change_dt = date;
                dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28f_empty =false;

                if (dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28f_dose_change_dt != "") {
                    let initdosedate = new Date(dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28f_dose_change_dt);
                    let inittherapydate = new Date(dosagearray[0].courseListQ28[parentidx].q28b_start_dt);
                    let initdisctdate = new Date(dosagearray[0].courseListQ28[parentidx].q28j_tx_disc_dt);

                    if (initdosedate >= indexDateValue) {
                        dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28eafterindexdate = true;
                    }
                    else dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28eafterindexdate = false;

                    if (initdosedate <= inittherapydate) {
                        dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28ebeforeindexdate = true;
                    }
                    else dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28ebeforeindexdate = false;
              
                    if (initdisctdate <= initdosedate) {
                        dosagearray[0].courseListQ28[parentidx].q28jBeforeDosedate = true;
                    }
                    else dosagearray[0].courseListQ28[parentidx].q28jBeforeDosedate = false;
                }

                setq28steroid(dosagearray);
            }
                break;

            case "q26a_pre_ava_a1_tx_start_dt": {
                const arry = name.split("-");

                const parentidx = arry[1];
                const childidx = arry[2];
                let dosagearray = [...q26ava];
                dosagearray[parentidx].avaListQ26[childidx].q26a_pre_ava_a1_tx_start_dt = date;
                //dosagearray[parentidx].avaListQ26[childidx].q26a_pre_ava_a1_tx_start_dt_unk = false;
                dosagearray[parentidx].avaListQ26[childidx].q26adatechange = false;
                dosagearray[parentidx].avaListQ26[childidx].defDateQ26a = false;
                dosagearray[parentidx].avaListQ26[childidx].q26a_Empty = false

                if (dosagearray[parentidx].avaListQ26[childidx].q26a_pre_ava_a1_tx_start_dt != "") {
                    let initdate = new Date(dosagearray[parentidx].avaListQ26[childidx].q26a_pre_ava_a1_tx_start_dt);
                    if (initdate >= indexDateValue) {
                        dosagearray[parentidx].avaListQ26[childidx].q26alessindex = true;
                    } else dosagearray[parentidx].avaListQ26[childidx].q26alessindex = false;

                    // if(initdate<indexThreeMonthsDatevalue){
                    //     dosagearray[parentidx].avaListQ26[childidx].q26aThreeMonthsIndexdate = true;
                    // } else dosagearray[parentidx].avaListQ26[childidx].q26aThreeMonthsIndexdate = false;

                }
                else dosagearray[parentidx].avaListQ26[childidx].q26alessindex = false;

                setQ26ava(dosagearray);
            }
                break;
            case "q26b_pre_ava_b1_tx_end_dt": {
              
                const arry = name.split("-");
                const parentidx = arry[1];
                const childidx = arry[2];
                let dosagearray = [...q26ava];
                dosagearray[parentidx].avaListQ26[childidx].q26b_pre_ava_b1_tx_end_dt = date;
                // dosagearray[parentidx].avaListQ26[childidx].q26b_pre_ava_b1_tx_end_dt_unk = false;
                dosagearray[parentidx].avaListQ26[childidx].q26bdatechange = false;
                dosagearray[parentidx].avaListQ26[childidx].defDateQ26b = false;
                dosagearray[parentidx].avaListQ26[childidx].q26b_Empty = false
                if (dosagearray[parentidx].avaListQ26[childidx].q26b_pre_ava_b1_tx_end_dt != "") {
                    let startDate = new Date(dosagearray[parentidx].avaListQ26[childidx].q26a_pre_ava_a1_tx_start_dt);
                    let endDate = new Date(dosagearray[parentidx].avaListQ26[childidx].q26b_pre_ava_b1_tx_end_dt);
                    let followup=new Date(endfollowupdate);
                    if (endDate < startDate) {
                        dosagearray[parentidx].avaListQ26[childidx].q26blessthanstartdate = true;
                    } else dosagearray[parentidx].avaListQ26[childidx].q26blessthanstartdate = false;

                    if(endDate>followup)
                    dosagearray[parentidx].avaListQ26[childidx].Q26bAfterFollowEndDate = true;
                 else dosagearray[parentidx].avaListQ26[childidx].Q26bAfterFollowEndDate = false;

                }else
                dosagearray[parentidx].avaListQ26[childidx].q26blessthanstartdate = false;


                setQ26ava(dosagearray);
            }
                break;

            case "q27a_pre_ava_fosta_start_dt": {
                let dosagearray = [...q27fosta];
                dosagearray[idx].q27a_pre_ava_fosta_start_dt = date;
                dosagearray[idx].q27a_pre_ava_fosta_start_dt_unk = false;
                dosagearray[idx].q27datechange = false;
                dosagearray[idx].defDateQ27 = false;
                dosagearray[idx].q27a_empty = false;


                if (dosagearray[idx].q27a_pre_ava_fosta_start_dt != "") {
                    let inittherapydate = new Date(dosagearray[idx].q27a_pre_ava_fosta_start_dt);
                    let initdisctdate = new Date(dosagearray[idx].q27g_pre_ava_fosta_disc_dt);

                    if (inittherapydate >= indexDateValue) {
                        dosagearray[idx].q27lessindexdate = true;
                    } else dosagearray[idx].q27lessindexdate = false;

                    if (initdisctdate <= inittherapydate) {
                        dosagearray[idx].q27gbeforeTherapydate = true;
                    }
                    else dosagearray[idx].q27gbeforeTherapydate = false;
                }
                else dosagearray[idx].q27lessindexdate = false;


                setq27fosta(dosagearray);
            }
                break;


            case "q27g_pre_ava_fosta_disc_dt": {
                let dosagearray = [...q27fosta];
                dosagearray[idx].q27g_pre_ava_fosta_disc_dt = date;
                dosagearray[idx].q27g_pre_ava_fosta_disc_dt_unk = false;
                dosagearray[idx].q27dateDiscChange = false;
                dosagearray[idx].q27defdateDisc = false;
                dosagearray[idx].q27g_empty = false;

                if (dosagearray[idx].q27g_pre_ava_fosta_disc_dt != "") {
                    let initdisctdate = new Date(dosagearray[idx].q27g_pre_ava_fosta_disc_dt);
                    let inittherapydate = new Date(dosagearray[idx].q27a_pre_ava_fosta_start_dt);
                   

                    if (initdisctdate <= inittherapydate) {
                        dosagearray[idx].q27gbeforeTherapydate = true;
                    }
                    else dosagearray[idx].q27gbeforeTherapydate = false;

                    
                    if(dosagearray[idx].doseChangeListQ27.length>0)
                    {
                          for (let i = 0; i < dosagearray[idx].doseChangeListQ27.length; i++) {
                            let doseDate=new Date(dosagearray[idx].doseChangeListQ27[i].q27d_pre_ava_fosta_dose_chg_d1_dt);
                            if (initdisctdate <= doseDate) {
                                dosagearray[idx].q27gBeforeDoseDate = true;
                            }
                            else dosagearray[idx].q27gBeforeDoseDate = false;

                          }

                    }
                }



                setq27fosta(dosagearray);
            }
                break;

            case "q27d_pre_ava_fosta_dose_chg_d1_dt":
                 
                const arry = name.split("-");
                const parentidx = arry[2];
                const childidx = arry[1]; {
                    let dosagearray = [...q27fosta];
                    dosagearray[parentidx].doseChangeListQ27[childidx].q27d_pre_ava_fosta_dose_chg_d1_dt = date;
                    dosagearray[parentidx].doseChangeListQ27[childidx].q27d_empty =false;

                    if (dosagearray[parentidx].doseChangeListQ27[childidx].q27d_pre_ava_fosta_dose_chg_d1_dt != "") {
                        let initdosedate = new Date(dosagearray[parentidx].doseChangeListQ27[childidx].q27d_pre_ava_fosta_dose_chg_d1_dt);
                        let inittherapydate = new Date(dosagearray[parentidx].q27a_pre_ava_fosta_start_dt);
                        let initdisctdate = new Date(dosagearray[parentidx].q27g_pre_ava_fosta_disc_dt);

                        if (initdosedate <= inittherapydate) {
                            dosagearray[parentidx].doseChangeListQ27[childidx].q27dbeforeindexdate = true;
                        }
                        else dosagearray[parentidx].doseChangeListQ27[childidx].q27dbeforeindexdate = false;

                        if (initdisctdate <= initdosedate) {
                            dosagearray[parentidx].q27gBeforeDoseDate = true;
                        }
                        else dosagearray[parentidx].q27gBeforeDoseDate = false;
                    }

                    setq27fosta(dosagearray);
                }
                break;

            case "q30_pre_ava_splen_dt": {
                if (name === "q30_pre_ava_splen_dt") {

                    let lessindexdate = false;

                    if (date != "") {
                        let inittherapydate = new Date(date);
                        if (inittherapydate >= indexDateValue) {
                            lessindexdate = true;
                        }
                    }
                    setThDetails((prev) => ({
                        ...prev,
                        [name]: date,
                        q30_NotSelected: false,
                        q30lessindexdate: lessindexdate,
                    }));
                }

                //on click of date field the radio button is checked as false
                if (name === "q25a_pre_ava_a1_tx_start_dt") {
                    setThDetails((prev) => ({
                        ...prev,
                        q25a_pre_ava_a1_tx_start_dt_unk: false,
                    }));
                    setQ25aDate(false);
                }
                //next date controller
                if (name === "q30_pre_ava_splen_dt") {
                    setThDetails((prev) => ({
                        ...prev,
                        [name]: date,
                        q30_pre_ava_splen_dt_unk: false,
                        defDateQ30: false,
                        q30_NotSelected: false
                    }));
                }

                break;
            }

            default:
                break;
        }
    };

    // Question 26



    useEffect(() => {
        
        async function FetchData() {
            if (!isNaN(pid) && Number(pid) !== 0) {
              
              ToggleLoader(true);
              await GettreatmentData();
              ToggleLoader(false);
            }
          }
           FetchData();
        //GettreatmentData();
    }, []);

    const HandleChangeFrequencyUnknwn = (e) => {


        const { name, id, value } = e.target;
        let dosagearray = [...q25dosefreq];
        dosagearray[id].q25c_pre_ava_c1_tx_start_num = "";
        dosagearray[id].q25c_pre_ava_c1_tx_start_freq = "";
        dosagearray[id].q25b_pre_ava_b1_tx_start_dose = "";
        dosagearray[id].q25c_pre_ava_c1_tx_start_dose_unk = value;
        dosagearray[id].q25cfreqInput = false;
        dosagearray[id].q25c_empty = false;
        dosagearray[id].q25adoseInput = false;
        dosagearray[id].q25b_empty = false;


        // dosagearray[id].q34datechange = false;
        setq25DoseFreq(dosagearray);
    }

    const HandleChangeDisctunknwn = (e) => {


        const { name, id, value } = e.target;
        let dosagearray = [...q25dosefreq];
        dosagearray[id].q25i_pre_ava_tx_disc_dt = "";
        dosagearray[id].q25i_pre_ava_disc_dt_unk = value;
        dosagearray[id].q25defdateDisc = true;
        dosagearray[id].q25i_empty = false;
        dosagearray[id].q25ibefordosedate=false;
        dosagearray[id].q25ibeforetherapydate=false;
        dosagearray[id].q25iBeforeIndexDate=false;
        // dosagearray[id].q34datechange = false;
        setq25DoseFreq(dosagearray);
    }

    const HandleChangeUnknwn = (e) => {


        const { name, id, value } = e.target;
        let dosagearray = [...q25dosefreq];
        dosagearray[id].q25a_pre_ava_a1_tx_start_dt = "";
        dosagearray[id].q25a_pre_ava_a1_tx_start_dt_unk = value;
        dosagearray[id].defDateQ25 = true;
        dosagearray[id].q25a_empty = false;
        dosagearray[id].q25alessindexdate = false;
        // dosagearray[id].q34datechange = false;
        setq25DoseFreq(dosagearray);
    }

    const HandlePreAVAdosageChange = (e) => {
 
        const { name, id, value } = e.target;
        switch (name) {
            case "q25b_pre_ava_b1_tx_start_dose": {
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...q25dosefreq];
                dosagearray[id][name] = value;
                dosagearray[id].q25b_empty = false;
                dosagearray[id].q25c_pre_ava_c1_tx_start_dose_unk = false;
                if (dosagearray[id][name] !== "")
                    dosagearray[id].q25adoseInput = dosagearray[id][name] < 1501 && dosagearray[id][name] > 0 ? false : true;

                setq25DoseFreq(dosagearray);
                break;
            }
            case "q25c_pre_ava_c1_tx_start_num": {
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...q25dosefreq];
                dosagearray[id][name] = value;
                dosagearray[id].q25c_pre_ava_c1_tx_start_dose_unk = false;

                if (dosagearray[id][name] !== "")
                    dosagearray[id].q25cfreqInput = dosagearray[id][name] < 1501 && dosagearray[id][name] > 0 ? false : true;

                dosagearray[id].q25c_empty =false;
                setq25DoseFreq(dosagearray);
                break;
            }
            case "q25c_pre_ava_c1_tx_start_freq": {
                let dosagearray = [...q25dosefreq];
                dosagearray[id][name] = parseInt(value);
                dosagearray[id].q25c_pre_ava_c1_tx_start_dose_unk = false;
                dosagearray[id].q25c_empty =false;
                setq25DoseFreq(dosagearray);
                break;
            }

            case "q25f_pre_ava_f1_tx_change_dose": {

                const arry = id.split("-");
                const parentidx = arry[1];
                const childidx = arry[0];
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...q25dosefreq];
                dosagearray[parentidx].doseChangeListQ25[childidx][name] = value;

                dosagearray[parentidx].doseChangeListQ25[childidx].q25f_empty = false;
                if (dosagearray[parentidx].doseChangeListQ25[childidx].q25f_pre_ava_f1_tx_change_dose !== "")
                    dosagearray[parentidx].doseChangeListQ25[childidx].q25fdoseInput = dosagearray[parentidx].doseChangeListQ25[childidx].q25f_pre_ava_f1_tx_change_dose < 1501 && dosagearray[parentidx].doseChangeListQ25[childidx].q25f_pre_ava_f1_tx_change_dose > 0 ? false : true;

                //  dosagearray[id].q34newdosage = false;
                setq25DoseFreq(dosagearray);
                break;
            }
            case "q25g_pre_ava_g1_tx_change_num": {

                const arry = id.split("-");
                const parentidx = arry[1];
                const childidx = arry[0];
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...q25dosefreq];
                dosagearray[parentidx].doseChangeListQ25[childidx][name] = value;

                dosagearray[parentidx].doseChangeListQ25[childidx].q25g_empty =false;
               
                   if (dosagearray[parentidx].doseChangeListQ25[childidx].q25g_pre_ava_g1_tx_change_num !== "")
                    dosagearray[parentidx].doseChangeListQ25[childidx].q25gFreqInput = dosagearray[parentidx].doseChangeListQ25[childidx].q25g_pre_ava_g1_tx_change_num < 1501 && dosagearray[parentidx].doseChangeListQ25[childidx].q25g_pre_ava_g1_tx_change_num > 0 ? false : true;

                setq25DoseFreq(dosagearray);
                break;
            }
            case "q25g_pre_ava_g1_tx_change_freq": {

                const arry = id.split("-");
                const parentidx = arry[1];
                const childidx = arry[0];
                let dosagearray = [...q25dosefreq];
                dosagearray[parentidx].doseChangeListQ25[childidx][name] = parseInt(value);

                dosagearray[parentidx].doseChangeListQ25[childidx].q25g_empty =false;
                setq25DoseFreq(dosagearray);
                break;
            }

            case "q28c_start_dose": {
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...q28steroid];
                dosagearray[0].courseListQ28[id][name] = value;
                // dosagearray[0].courseListQ28[id].q28c_start_dose_unk=false;
                dosagearray[0].courseListQ28[id].q28c_empty = false;
                if (dosagearray[0].courseListQ28[id][name] !== "")
                    dosagearray[0].courseListQ28[id].q28cDoseInput = dosagearray[0].courseListQ28[id][name] < 1501 && dosagearray[0].courseListQ28[id][name] > 0 ? false : true;

                setq28steroid(dosagearray);
                break;
            }
            case "q28d_start_num": {
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...q28steroid];
                dosagearray[0].courseListQ28[id][name] = value;
                // dosagearray[0].courseListQ28[id].q28d_freq_unk=false;
                if (dosagearray[0].courseListQ28[id][name] !== "")
                    dosagearray[0].courseListQ28[id].q28dFreqInput = dosagearray[0].courseListQ28[id][name] < 1501 && dosagearray[0].courseListQ28[id][name] > 0 ? false : true;

                dosagearray[0].courseListQ28[id].q28d_empty =false;
                setq28steroid(dosagearray);
                break;
            }
            case "q28d_start_freq": {
                let dosagearray = [...q28steroid];
                dosagearray[0].courseListQ28[id][name] = parseInt(value);
                // dosagearray[0].courseListQ28[id].q28d_freq_unk=false;
                dosagearray[0].courseListQ28[id].q28d_empty =false;
                setq28steroid(dosagearray);
                break;
            }

            case "q28g_change_dose": {
                const arry = id.split("-");
                const parentidx = arry[1];
                const childidx = arry[0];
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...q28steroid];

                dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx][name] = value;
                dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28g_empty =false;
                if (dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx][name] !== "")
                    dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28gdoseInput = dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx][name] < 1501 && dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx][name] > 0 ? false : true;


                setq28steroid(dosagearray);
                break;
            }
            case "q28h_change_num": {
                const arry = id.split("-");
                const parentidx = arry[1];
                const childidx = arry[0];
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...q28steroid];
                dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx][name] = value;

                dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28h_empty =false;


                if (dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx][name] !== "")
                    dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28hFreqInput = dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx][name] < 1501 && dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx][name] > 0 ? false : true;


                setq28steroid(dosagearray);
                break;
            }
            case "q28h_change_freq": {
                const arry = id.split("-");
                const parentidx = arry[1];
                const childidx = arry[0];
                let dosagearray = [...q28steroid];
                dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx][name] = parseInt(value);
                dosagearray[0].courseListQ28[parentidx].clickListQ28[childidx].q28h_empty =false;

                setq28steroid(dosagearray);
                break;
            }
            default:
                break;
        }
    };

    function formatInput(e) {

        let checkIfNum;
        if (e.key !== undefined) {
            checkIfNum = e.key === "e" || e.key === "-" || e.key == "E";
        } else if (e.keyCode !== undefined) {
            checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    }

    const HandleSteroidChange = (e) => {

        const { name, id, value } = e.target;
        const arry = name.split("-");
        const parentidx = arry[1];
        const childidx = arry[2];
        const steroidname = arry[0];
        switch (steroidname) {
            case "q28a_steroid_type":
                {
                    let dosagearray = [...q28steroid];
                    dosagearray[0].courseListQ28[childidx].q28a_steroid_type = value;

                    dosagearray[0].courseListQ28[childidx].q28a_empty = false;

                    setq28steroid(dosagearray);
                }
                break;

            default:
                break;
        }
    };
    const HandleSteroidChangeUnknwn = (e) => {


        const { name, id, value } = e.target;
        let dosagearray = [...q28steroid];
        dosagearray[0].courseListQ28[id].q28b_start_dt = "";
        dosagearray[0].courseListQ28[id].q28b_start_dt_unk = value;
        dosagearray[0].courseListQ28[id].defDateQ28 = true;
        dosagearray[0].courseListQ28[id].q28b_empty = false;
        dosagearray[0].courseListQ28[id].q28alessindexdate = false;
        setq28steroid(dosagearray);
    }
    const HandleChangeSteroidFrequencyUnknwn = (e) => {


        const { name, id, value } = e.target;
        let dosagearray = [...q28steroid];
        dosagearray[0].courseListQ28[id].q28d_start_num = "";
        dosagearray[0].courseListQ28[id].q28d_start_freq = "";
        dosagearray[0].courseListQ28[id].q28d_freq_unk = value;
        dosagearray[0].courseListQ28[id].q28d_empty = false;
        setq28steroid(dosagearray);
    }
    const HandleChangeSteroidDoseUnknwn = (e) => {


        const { name, id, value } = e.target;
        let dosagearray = [...q28steroid];
        dosagearray[0].courseListQ28[id].q28c_start_dose = "";
        dosagearray[0].courseListQ28[id].q28c_start_dose_unk = value;
        dosagearray[0].courseListQ28[id].q28c_empty = false;
        setq28steroid(dosagearray);
    }


    const handleDeleteDoseQ28 = (treatmentid, item, id) => {
         
        const checkIfListExist = q28steroid.findIndex(
            (x) => x.treatmenttypeid === treatmentid.treatmenttypeid
        );
        if (checkIfListExist > -1) {
            // treatmentData.q28e_dose_change = value;
            let mainListData = q28steroid[checkIfListExist];
            const index = mainListData.courseListQ28.findIndex(
                (x) => x.RowSequenceNo === item.RowSequenceNo
            );

            if (index > -1) {
                let courseData = mainListData.courseListQ28[index];

                const list = courseData.clickListQ28.filter(
                    (x) => x.q28doseCount != id
                );
                courseData.clickListQ28 = list;
            }

        }

        setThDetails((prev) => ({
            ...prev,
            q28steroid,
        }));

    };

    const handleQ28CheckboxChange = (e) => {
        // Update the checkbox field and clear the selected radio value
        const { name, id, value } = e.target;
        let dosagearray = [...q28steroid];
        dosagearray[0].courseListQ28[id].q28i_tx_disc = value;
        dosagearray[0].courseListQ28[id].q28i_empty = false;
        dosagearray[0].courseListQ28[id].q28jlessindexdate=false;
        dosagearray[0].courseListQ28[id].q28jAfterindexdate=false;
        dosagearray[0].courseListQ28[id].q28jBeforeDosedate=false;

        if (value == 0) {
            dosagearray[0].courseListQ28[id].q28j_tx_disc_dt = "";
            dosagearray[0].courseListQ28[id].q28j_tx_disc_dt_unk = false;
            dosagearray[0].courseListQ28[id].q28j_empty = false;

        }
        setq28steroid(dosagearray);


    };

    const HandleChangeSteroidDisctunknwn = (e) => {
        const { name, id, value } = e.target;
        let dosagearray = [...q28steroid];
        dosagearray[0].courseListQ28[id].q28j_tx_disc_dt = "";
        dosagearray[0].courseListQ28[id].q28j_tx_disc_dt_unk = value;
        dosagearray[0].courseListQ28[id].q28defdateDisc = true;
        dosagearray[0].courseListQ28[id].q28j_empty = false;
        setq28steroid(dosagearray);
    }


    const HandleChangeSplenectomyDisctunknwn = (e) => {


        const { name, id, value } = e.target;


        const updatedThDetails = {
            ...thDetails,
            q30_pre_ava_splen_dt: "",
            q30_pre_ava_splen_dt_unk: value,
            defDateQ30: true,
            q30lessindexdate: false,
            q30_NotSelected:false,
            // ... other checkbox fields you want to clear
        };

        setThDetails(updatedThDetails);
    }

    const HandleQ26ChangeUnknwn = (e) => {


        const { name, id, value } = e.target;
        const arry = name.split("-");
        const option = arry[0];
        const parentidx = arry[1]
        const childidx = arry[2];
        switch (option) {
            case "q26a_pre_ava_a1_tx_start_dt_unk": {
                let dosagearray = [...q26ava];

                dosagearray[parentidx].avaListQ26[childidx].q26a_pre_ava_a1_tx_start_dt = "";
                dosagearray[parentidx].avaListQ26[childidx].q26a_pre_ava_a1_tx_start_dt_unk = value;
                dosagearray[parentidx].avaListQ26[childidx].defDateQ26a = true;
                dosagearray[parentidx].avaListQ26[childidx].q26a_Empty = false;
                dosagearray[parentidx].avaListQ26[childidx].q26alessindex = false;
                dosagearray[parentidx].avaListQ26[childidx].q26aThreeMonthsIndexdate=false;
                // dosagearray[id].q34datechange = false;
                setQ26ava(dosagearray);
            }
                break;
            case "q26b_pre_ava_b1_tx_end_dt_unk": {

                let dosagearray = [...q26ava];
                dosagearray[parentidx].avaListQ26[childidx].q26b_pre_ava_b1_tx_end_dt = "";
                dosagearray[parentidx].avaListQ26[childidx].q26b_pre_ava_b1_tx_end_dt_unk = value;
                dosagearray[parentidx].avaListQ26[childidx].defDateQ26b = true;
                dosagearray[parentidx].avaListQ26[childidx].q26b_Empty = false;
                dosagearray[parentidx].avaListQ26[childidx].q26blessthanstartdate=false;
                dosagearray[parentidx].avaListQ26[childidx].Q26bAfterFollowEndDate=false;
                // dosagearray[id].q34datechange = false;
                setQ26ava(dosagearray);
            }
                break;
            default:
                break;
        }

    }



    const handleQ29CheckboxChange = (e) => {
        // Update the checkbox field and clear the selected radio value


        const { name, id, value } = e.target;


        let dosagearray = [...q29ava];
        dosagearray[id].q29_ritux_immuno_and_ava = value;
        dosagearray[id].q29Empty = false;


        setq29ava(dosagearray);

    };

    const HandleQ27ChangeUnknwn = (e) => {


        const { name, id, value } = e.target;
        let dosagearray = [...q27fosta];
        dosagearray[id].q27a_pre_ava_fosta_start_dt = "";
        dosagearray[id].q27a_pre_ava_fosta_start_dt_unk = value;
        dosagearray[id].defDateQ27 = true;
        dosagearray[id].q27a_empty = false;
        dosagearray[id].q27lessindexdate = false;

        // dosagearray[id].q34datechange = false;
        setq27fosta(dosagearray);
    }


    const HandleFostaDosageradioChange = (e) => {

        const { name, id, value } = e.target;
        switch (id) {
            case "q27b_pre_ava_fosta_start_dose":
                {
                    let dosagearray = [...q27fosta];
                    dosagearray[name].q27b_pre_ava_fosta_start_dose = value;


                    if (value != 3) dosagearray[name].q27b_pre_ava_fosta_start_dose_oth = "";
                    //not working 
                    dosagearray[name].q27b_empty = false;
                    setq27fosta(dosagearray);
                }
                break;
            case "q27b_pre_ava_fosta_start_dose_oth":
                {
                    let dosagearray = [...q27fosta];
                    dosagearray[name][id] = value;
                    dosagearray[name].q27b_empty = false;

                    setq27fosta(dosagearray);
                }
                break;
            default:
                break;
        }
    };


    const handleQ27CheckboxChange = (e) => {
        // Update the checkbox field and clear the selected radio value


        const { name, id, value } = e.target;
        let dosagearray = [...q27fosta];
        dosagearray[id].q27f_pre_ava_fosta_disc = value;
        dosagearray[id].q27f_empty = false;
        dosagearray[id].q27gbeforeTherapydate=false;
        dosagearray[id].q27gBeforeDoseDate=false;


        if (value == 0) {
            dosagearray[id].q27g_pre_ava_fosta_disc_dt = "";
            dosagearray[id].q27g_pre_ava_fosta_disc_dt_unk = false;

        }
        setq27fosta(dosagearray);
    };



    const HandleChangeDiscQ27tunknwn = (e) => {


        const { name, id, value } = e.target;
        let dosagearray = [...q27fosta];
        dosagearray[id].q27g_pre_ava_fosta_disc_dt = "";
        dosagearray[id].q27g_pre_ava_fosta_disc_dt_unk = value;
        dosagearray[id].q27defdateDisc = true;
        dosagearray[id].q27g_empty = false;
        dosagearray[id].q27gbeforeTherapydate=false;
        dosagearray[id].q27gBeforeDoseDate=false;

        // dosagearray[id].q34datechange = false;
        setq27fosta(dosagearray);
    }


    const HandleFostafrequentDosageradioChange = (e) => {
 
        const { name, id, value } = e.target;
        const arry = name.split("-");
        const parentidx = arry[1];
        const childidx = arry[0];
        switch (childidx) {
            case "q27e_pre_ava_fosta_dose_chg_e1":
                {
                    let dosagearray = [...q27fosta];
                    dosagearray[0].doseChangeListQ27[parentidx][id] = value;
                    dosagearray[0].doseChangeListQ27[parentidx].q27e_empty =false;
                    if (value != 3) dosagearray[0].doseChangeListQ27[parentidx].q27e_pre_ava_fosta_change_dose_oth = "";
                    setq27fosta(dosagearray);
                }
                break;
            case "q27e_pre_ava_fosta_change_dose_oth":
                {
                    let dosagearray = [...q27fosta];
                    dosagearray[0].doseChangeListQ27[parentidx][id] = value;
                    dosagearray[0].doseChangeListQ27[parentidx].q27e_empty =false;
                    setq27fosta(dosagearray);
                }
                break;
            default:
                break;
        }
    };



    const [showTopErrMsg, setShowTopErrMsg] = useState(false);


    return (

        <>

            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={onsaveclick} />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.TreatmentHistory} />
                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <>
                                <div className="survey-section-content-header">
                                    <h4>OTHER ITP-RELATED TREATMENTS PRIOR TO AVA INITIATION</h4>
                                </div>
                                {showTopErrMsg && (
                                    <div className="clean-error ">
                                        <ErrorField
                                            show={showTopErrMsg}
                                            message={"Please ensure that all mandatory fields are filled out before proceeding."}
                                        />
                                    </div>
                                )}
                                <div className="survey-question-section">
                                    <div className="survey-question-content">
                                        <div style={PageDesignConstants.PageDescriptionStyle}>
                                            <i>
                                                This section will ask you about the ITP treatments that
                                                the patient received {""}
                                                before initiating AVA.
                                            </i>
                                        </div>
                                        <div className="question-answer">
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>24.</span>
                                                    <span className="quest-text-pad">
                                                        Did the patient receive any of the following ITP
                                                        treatments in the time periods specified? Select all
                                                        that apply.
                                                    </span>

                                                </div>
                                                <div className="answer-pad-left double-dig-answer">
                                                    <div className="table-top">
                                                        <table>
                                                            <tbody>
                                                                <tr className="tab-row-border">
                                                                    <th />
                                                                    <th className="tab-cell-border tab-cell-padding" />
                                                                    <th className="tab-cell-border tab-cell-padding tab-cell-width">
                                                                        Ever used (i.e., any time before{" "}
                                                                        <span
                                                                            style={{
                                                                                color: PageDesignConstants.fontColor,
                                                                            }}
                                                                        >
                                                                            {indexdate}
                                                                        </span>
                                                                        )?
                                                                        
                                                                    </th>
                                                                    <th className="tab-cell-border tab-cell-padding tab-cell-width">
                                                                        Used in the 3 months prior to AVA initiation
                                                                        (i.e., between{" "}
                                                                        <span
                                                                            style={{
                                                                                color: PageDesignConstants.fontColor,
                                                                            }}
                                                                        >
                                                                            {indexDateMinus3Months}
                                                                        </span>{" "}
                                                                        and{" "}
                                                                        <span
                                                                            style={{
                                                                                color: PageDesignConstants.fontColor,
                                                                            }}
                                                                        >
                                                                            {indexDateMinus1Day}
                                                                        </span>{" "}
                                                                        )
                                                                       
                                                                    </th>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        1.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">Eltrombopag</span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24a1_pre_ava_ever_elt"
                                                                            name="q24a1_pre_ava_ever_elt"
                                                                            checked={thDetails.q24a1_pre_ava_ever_elt}
                                                                            onChange={(e) =>
                                                                                handleCheckboxChange(e, "q24b1_pre_3mo_ava_elt",1)
                                                                            }
                                                                        />

                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24b1_pre_3mo_ava_elt"
                                                                            checked={thDetails.q24b1_pre_3mo_ava_elt}
                                                                            onChange={(e) =>
                                                                                handleCheckboxChangeQ25(
                                                                                    "q24b1_pre_3mo_ava_elt", "Eltrombopag", 1,
                                                                                    e
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                    thDetails.q24a1_pre_ava_ever_elt === true
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        2.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">Romiplostim</span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24a2_pre_ava_ever_romi"
                                                                            name="q24a2_pre_ava_ever_romi"
                                                                            checked={
                                                                                thDetails.q24a2_pre_ava_ever_romi
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleCheckboxChange(e, "q24b2_pre_3mo_ava_romi",2)
                                                                            }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24b2_pre_3mo_ava_romi"
                                                                            checked={thDetails.q24b2_pre_3mo_ava_romi}
                                                                            onChange={(e) =>
                                                                                handleCheckboxChangeQ25(
                                                                                    "q24b2_pre_3mo_ava_romi", "Romiplostim", 2,
                                                                                    e
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                thDetails.q24a2_pre_ava_ever_romi === true
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        3.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">Lusutrombopag</span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24a3_pre_ava_ever_lusu"
                                                                            name="q24a3_pre_ava_ever_lusu"
                                                                            checked={
                                                                                thDetails.q24a3_pre_ava_ever_lusu
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleCheckboxChange(e, "q24b3_pre_3mo_ava_lusu",3)
                                                                            }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24b3_pre_3mo_ava_lusu"
                                                                            checked={thDetails.q24b3_pre_3mo_ava_lusu}
                                                                            onChange={(e) =>
                                                                                handleCheckboxChangeQ25(
                                                                                    "q24b3_pre_3mo_ava_lusu", "Lusutrombopag", 3,
                                                                                    e
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                thDetails.q24a3_pre_ava_ever_lusu === true
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        4.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Steroids (i.e., dexamethasone, prednisone,
                                                                            prednisolone)
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24a4_pre_ava_ever_steroids"
                                                                            name="q24a4_pre_ava_ever_steroids"
                                                                            checked={
                                                                                thDetails.q24a4_pre_ava_ever_steroids
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleCheckboxChange(e, "q24b4_pre_3mo_ava_steroids",4)
                                                                            }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24b4_pre_3mo_ava_steroids"
                                                                            checked={
                                                                                thDetails.q24b4_pre_3mo_ava_steroids
                                                                            }
                                                                            onChange={(e) =>
                                                                                HandleCheckboxChangeQ28(
                                                                                    "q24b4_pre_3mo_ava_steroids", "Steroids", 4,
                                                                                    e
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                thDetails.q24a4_pre_ava_ever_steroids === true
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        5.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Intravenous immunoglobulin (IVIG)
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24a5_pre_ava_ever_ivig"
                                                                            name="q24a5_pre_ava_ever_ivig"
                                                                            checked={
                                                                                thDetails.q24a5_pre_ava_ever_ivig
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleCheckboxChange(e, "q24b5_pre_3mo_ava_ivig",5)
                                                                            }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24b5_pre_3mo_ava_ivig"
                                                                            checked={thDetails.q24b5_pre_3mo_ava_ivig}
                                                                            onChange={(e) =>
                                                                                HandleChangeAddQ26(
                                                                                    "q24b5_pre_3mo_ava_ivig", "Intravenous immunoglobulin (IVIG)", 5,
                                                                                    e
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                thDetails.q24a5_pre_ava_ever_ivig === true
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>

                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        6.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Anti-D immunoglobulin
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24a6_pre_ava_ever_antid"
                                                                            name="q24a6_pre_ava_ever_antid"
                                                                            checked={
                                                                                thDetails.q24a6_pre_ava_ever_antid
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleCheckboxChange(e, "q24b6_pre_3mo_ava_antid",6)
                                                                            }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24b6_pre_3mo_ava_antid"
                                                                            checked={
                                                                                thDetails.q24b6_pre_3mo_ava_antid
                                                                            }
                                                                            onChange={(e) =>
                                                                                HandleChangeAddQ26(
                                                                                    "q24b6_pre_3mo_ava_antid", "Anti-D immunoglobulin", 6,
                                                                                    e
                                                                                )
                                                                            }

                                                                            disabled={
                                                                                thDetails.q24a6_pre_ava_ever_antid === true
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>

                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        7.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">Rituximab</span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24a7_pre_ava_ever_ritux"
                                                                            name="q24a7_pre_ava_ever_ritux"
                                                                            checked={
                                                                                thDetails.q24a7_pre_ava_ever_ritux
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleCheckboxChange(e, "q24b7_pre_3mo_ava_ritux",7)
                                                                            }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24b7_pre_3mo_ava_ritux"
                                                                            checked={
                                                                                thDetails.q24b7_pre_3mo_ava_ritux
                                                                            }
                                                                            onChange={(e) =>
                                                                                HandleChangeAddQ29(
                                                                                    "q24b7_pre_3mo_ava_ritux", "Rituximab", 7,
                                                                                    e
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                thDetails.q24a7_pre_ava_ever_ritux === true
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        8.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Fostamatinib disodium
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24a8_pre_ava_ever_fosta"
                                                                            name="q24a8_pre_ava_ever_fosta"
                                                                            checked={
                                                                                thDetails.q24a8_pre_ava_ever_fosta
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleCheckboxChange(e, "q24b8_pre_3mo_ava_fosta",8)
                                                                            }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24b8_pre_3mo_ava_fosta"
                                                                            checked={
                                                                                thDetails.q24b8_pre_3mo_ava_fosta
                                                                            }
                                                                            onChange={(e) =>
                                                                                HandleChangeAddQ27(
                                                                                    "q24b8_pre_3mo_ava_fosta", "Fostamatinib disodium", 8,
                                                                                    e
                                                                                )
                                                                            }

                                                                            disabled={
                                                                                thDetails.q24a8_pre_ava_ever_fosta === true
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        9.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Immunosuppressants (i.e., azathioprine,
                                                                            cyclophosphamide, cyclosporine, danazol,
                                                                            dapsone, mycophenolate mofetil, vinca
                                                                            alkaloids [vinblastine, vinorelbine,
                                                                            vincristine])
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24a9_pre_ava_ever_immuno"
                                                                            name="q24a9_pre_ava_ever_immuno"
                                                                            checked={
                                                                                thDetails.q24a9_pre_ava_ever_immuno
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleCheckboxChange(e, "q24b9_pre_3mo_ava_immuno",9)
                                                                            }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="q24b9_pre_3mo_ava_immuno"
                                                                            checked={
                                                                                thDetails.q24b9_pre_3mo_ava_immuno
                                                                            }
                                                                            onChange={(e) =>
                                                                                HandleChangeAddQ29(
                                                                                    "q24b9_pre_3mo_ava_immuno", "Immunosuppressants", 9,
                                                                                    e
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                thDetails.q24a9_pre_ava_ever_immuno === true
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        10.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">None of the above</span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            id="q24a10_pre_ava_ever_none"
                                                                            value="99"
                                                                            checked={
                                                                                thDetails.q24a10_pre_ava_ever_none ===
                                                                                "99" || thDetails.q24a10_pre_ava_ever_none ===
                                                                                99
                                                                            }
                                                                            onChange={HandleRadioChangeQ24a}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            id="q24b10_pre_3mo_ava_none"
                                                                            value="99"
                                                                            checked={
                                                                                thDetails.q24b10_pre_3mo_ava_none ===
                                                                                "99" || thDetails.q24b10_pre_3mo_ava_none ===
                                                                                99
                                                                            }
                                                                            onChange={HandleRadioChangeQ24b}
                                                                            disabled={
                                                                               ( thDetails.q24a10_pre_ava_ever_none ===
                                                                                "99" || thDetails.q24a10_pre_ava_ever_none ===
                                                                                99)
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                               
                                                                </tr>
                                                             
                                                            </tbody>
                                                        </table>
                                                        <ErrorField
                                                                                show={(thDetails.q24a_Empty && thDetails.q24b_Empty)? true : (thDetails.q24a_Empty?true:thDetails.q24b_Empty)}
                                                                                message={(thDetails.q24a_Empty && thDetails.q24b_Empty)? TreatmentHistory_Msg.Q24 : (thDetails.q24a_Empty?TreatmentHistory_Msg.Q24a:TreatmentHistory_Msg.Q24b)}
                                                                            />
                                                    </div>
                           
                                                    {/* If options 1, 2, or 3 (therapy names: eltrombopag, romiplostim, lusutrombopag) are selected in Q22b, ask sequentially for each one that was selected */}

                                                    {/* Q 25 */}
                                                    {/* {(thDetails.q24a1_pre_ava_ever_elt  || thDetails.q24a2_pre_ava_ever_romi) &&  ({    */}

                                                    {q25dosefreq.length > 0 ? (
                                                        q25dosefreq.map((data, index) => {

                                                            return (
                                                                <div
                                                                    className="dependent-section mt-2"
                                                                    key={index}
                                                                >
                                                                    <div className="answer-list-text question-weight">
                                                                    <span>25. ({data.treatmentName})</span> 
                                                                        </div>

                                                                    <div className="answer-list-text question-weight">
                                                                        <span></span>
                                                                        <div>
                                                                            <span className="radio-text-padding">
                                                                                Please enter the start/end dates and
                                                                                details of dosages/frequencies that the
                                                                                patient received of{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    {data.treatmentName}
                                                                                </span>{" "}
                                                                                between{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    {indexDateMinus3Months}
                                                                                </span>{" "}
                                                                                to{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    {indexDateMinus1Day}
                                                                                </span>
                                                                                .
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="answer-pad-left">
                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">
                                                                                    a.
                                                                                </span>
                                                                                <span
                                                                                    className="quest-text-pad"
                                                                                    style={{ display: "flex" }}
                                                                                >
                                                                                    <span className="question-weight">
                                                                                        Date of{" "}
                                                                                        <span
                                                                                            style={{
                                                                                                color:
                                                                                                    PageDesignConstants.fontColor,
                                                                                            }}
                                                                                        >
                                                                                            {data.treatmentName}
                                                                                        </span>{" "}
                                                                                        initiation{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        ctrlId={
                                                                                            "q25a_pre_ava_a1_tx_start_dt-" + index
                                                                                        }
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        defDate={data.defDateQ25}
                                                                                        optionId={"q25a_pre_ava_a1_tx_start_dt"}
                                                                                        date={data.q25a_pre_ava_a1_tx_start_dt}
                                                                                    />
                                                                                    (If only month and year are known,
                                                                                    please enter “15” for the day)
                                                                                </span>
                                                                            </div>
                                                                           
                                                                            {/* pb */}
                                                                            <div className="answer-list-text answer-pad-left">

                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={1}
                                                                                    name={"q25a_pre_ava_a1_tx_start_dt_unk-" + index}
                                                                                    checked={
                                                                                        data.q25a_pre_ava_a1_tx_start_dt_unk ===
                                                                                        "1" || data.q25a_pre_ava_a1_tx_start_dt_unk ===
                                                                                        1
                                                                                    }
                                                                                    onChange={HandleChangeUnknwn}
                                                                                />


                                                                                <span className="radio-text-padding">
                                                                                    Date of therapy initiation is unknown
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q25a_empty ? true : data.q25alessindexdate}
                                                                                message={data.q25a_empty
                                                                                    ? (data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25aEltrombopagDate : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25aRomiplostimDate : TreatmentHistory_Msg.Q25aLusutrombopagDate)
                                                                                    : (data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25aEltrombopag : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25aRomiplostim : TreatmentHistory_Msg.Q25aLusutrombopag)}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="question-weight">
                                                                                <span>
                                                                                    Starting Dose of{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                PageDesignConstants.fontColor,
                                                                                        }}
                                                                                    >
                                                                                        {data.treatmentName}
                                                                                    </span>
                                                                                    :
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text question-weight">
                                                                                <span className="">b.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Dosage:{" "}

                                                                                    <input
                                                                                        className="sub-input-min-dash"
                                                                                        type="text"
                                                                                        name="q25b_pre_ava_b1_tx_start_dose"
                                                                                        id={index}
                                                                                        value={data.q25b_pre_ava_b1_tx_start_dose}
                                                                                        onChange={HandlePreAVAdosageChange}
                                                                                        onKeyDown={formatInput}
                                                                                    /> {data.treatmentName == "Romiplostim" ? "μg" : "mg"}

                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q25b_empty ? true : data.q25adoseInput}
                                                                                message={data.q25b_empty ?(data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25bEltrombopag : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25bRomiplostim : TreatmentHistory_Msg.Q25bLusutrombopag)
                                                                                  : TreatmentHistory_Msg.QdosageFreq}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">
                                                                                    c.
                                                                                </span>
                                                                                <span className="quest-text-pad">
                                                                                    <span className="question-weight">
                                                                                        Frequency:{" "}
                                                                                    </span>

                                                                                    <input
                                                                                        className="sub-input-min-dash"
                                                                                        type="text"
                                                                                        name="q25c_pre_ava_c1_tx_start_num"
                                                                                        id={index}
                                                                                        value={data.q25c_pre_ava_c1_tx_start_num}
                                                                                        onChange={HandlePreAVAdosageChange}
                                                                                        onKeyDown={formatInput}
                                                                                    />
                                                                                    time(s) per
                                                                                    <select
                                                                                        className="freq-dropdown"
                                                                                        id={index}
                                                                                        name="q25c_pre_ava_c1_tx_start_freq"
                                                                                        onChange={HandlePreAVAdosageChange}
                                                                                        value={data.q25c_pre_ava_c1_tx_start_freq}
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        {frequencytime?.map((i) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={i.frequencytimeid}
                                                                                                    value={i.frequencytimeid}
                                                                                                >
                                                                                                    {i.frequencytimetxt}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </select>
                                                                                </span>

                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q25cfreqInput ? true : data.q25cfreqInput}
                                                                                message={TreatmentHistory_Msg.QdosageFreq}
                                                                            />
                                                                            <ErrorField
                                                                                show={data.q25c_empty ? true : data.q25c_empty}
                                                                                message={(data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25cEltrombopag : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25cRomiplostim : TreatmentHistory_Msg.Q25cLusutrombopag)}
                                                                            />
                                                                            <div className="answer-list-text answer-pad-left">


                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={1}
                                                                                    name={"q25c_pre_ava_c1_tx_start_dose_unk-" + index}
                                                                                    checked={
                                                                                        data.q25c_pre_ava_c1_tx_start_dose_unk ===
                                                                                        "1" || data.q25c_pre_ava_c1_tx_start_dose_unk ===
                                                                                        1
                                                                                    }
                                                                                    onChange={HandleChangeFrequencyUnknwn}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Starting dose is unknown
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce padding-bot answer-pad-left">
                                                                            <div className="answer-list-text question-weight">
                                                                                <span className="">d.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Was the dose and/or frequency changed
                                                                                    at any time between{" "}
                                                                                        therapy initiation
                                                                                    {" "}
                                                                                    and{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                PageDesignConstants.fontColor,
                                                                                        }}
                                                                                    >
                                                                                        {indexdate}
                                                                                    </span>
                                                                                    ?
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                {/* pbnow */}

                                                                                <input
                                                                                    type="radio"
                                                                                    name={"q25d_pre_ava_d1_tx_dose_change-" + index}
                                                                                    id={index}
                                                                                    value={1}

                                                                                    checked={
                                                                                        data.q25d_pre_ava_d1_tx_dose_change ===
                                                                                        "1" || data.q25d_pre_ava_d1_tx_dose_change === 1

                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        addQ25RepeaterRow(e, data)
                                                                                    }
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Yes
                                                                                </span>
                                                                            </div>

                                                                            {/* loop section 1 25efg pb indumati*/}
                                                                            {data.doseChangeListQ25.length > 0 ? (
                                                                                <div>
                                                                                    {data.doseChangeListQ25.map(
                                                                                        (ele, idx) => {

                                                                                            return (
                                                                                                <div key={idx}>
                                                                                                    <div className="loop-section">
                                                                                                        <div className="loop-section-pad-left">
                                                                                                            <div className="loop-head">
                                                                                                                <span className="question-weight">
                                                                                                                    Dose {idx + 1}
                                                                                                                </span>
                                                                                                                {idx != 0 ? (
                                                                                                                    <div className="delete-btn-div ">
                                                                                                                        <img
                                                                                                                            width="16px"
                                                                                                                            src="../Assets/images/Icon-material-delete.png"
                                                                                                                            alt=""
                                                                                                                            onClick={() =>
                                                                                                                                handleDeleteDoseQ25(
                                                                                                                                    data.treatmenttypeid,
                                                                                                                                    ele.q25dosecount

                                                                                                                                )
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <></>
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                <div className="answer-list-text question-weight">
                                                                                                                    <span>e.</span>
                                                                                                                    <span className="quest-text-pad">
                                                                                                                        What was the date of
                                                                                                                        the dose change and
                                                                                                                        the new dose and
                                                                                                                        frequency?{" "}
                                                                                                                    </span>
                                                                                                                </div>


                                                                                                                <div
                                                                                                                    className="option-with-input answer-pad-left"
                                                                                                                    style={{
                                                                                                                        display: "flex",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <DateControl
                                                                                                                        marginLeft={false}
                                                                                                                        ctrlId={"q25e_pre_ava_e1_tx_dose_change_dt-" + (idx) + "-" + index}
                                                                                                                        HandleDateChange={HandleDateChange}
                                                                                                                        optionId={"q25e_pre_ava_e1_tx_dose_change_dt"}

                                                                                                                        date={ele.q25e_pre_ava_e1_tx_dose_change_dt}

                                                                                                                    />
                                                                                                                    (If only month and
                                                                                                                    year are known, please
                                                                                                                    enter “15” for the
                                                                                                                    day)
                                                                                                                </div>
                                                                                                                <ErrorField
                                                                                                            show={ele.q25e_empty ? true : ele.q25e_empty}
                                                                                                            message={TreatmentHistory_Msg.Q25e}
                                                                                                        />

                                                                                                                <ErrorField
                                                                                                                    show={ele.q25elessindexdate ? true : ele.q25eafterindexdate}
                                                                                                                    message={ele.q25elessindexdate ? (data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25eDoseBeforeEltrombopag : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25eDoseBeforeRomiplostim : TreatmentHistory_Msg.Q25eDoseBeforeLusutrombopag) :
                                                                                                                        (data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25eDoseAfterEltrombopag : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25eDoseAfterRomiplostim : TreatmentHistory_Msg.Q25eDoseAfterLusutrombopag)}
                                                                                                                />

                                                                                                            </div>
                                                                                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                <div className="answer-list-text question-weight">
                                                                                                                    <span className="">
                                                                                                                        f.
                                                                                                                    </span>
                                                                                                                    <span className="quest-text-pad">
                                                                                                                        Dosage:{" "}
                                                                                                                        <input
                                                                                                                            className="sub-input-min-dash"
                                                                                                                            type="text"
                                                                                                                            name="q25f_pre_ava_f1_tx_change_dose"
                                                                                                                            id={idx + "-" + index}
                                                                                                                            value={ele.q25f_pre_ava_f1_tx_change_dose}
                                                                                                                            onChange={HandlePreAVAdosageChange}
                                                                                                                            onKeyDown={formatInput}
                                                                                                                        />{data.treatmentName == "Romiplostim" ? "μg" : "mg"} {" "}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <ErrorField
                                                                                                            show={ele.q25f_empty ? true : ele.q25f_empty}
                                                                                                            message={TreatmentHistory_Msg.Q25f}
                                                                                                        />
                                                                                                                <ErrorField
                                                                                                                    show={ele.q25fdoseInput ? true : ele.q25fdoseInput}
                                                                                                                    message={TreatmentHistory_Msg.QdosageFreq}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                <div className="answer-list-text">
                                                                                                                    <span className="question-weight">
                                                                                                                        g.
                                                                                                                    </span>
                                                                                                                    <span className="quest-text-pad">
                                                                                                                        <span className="question-weight">
                                                                                                                            Frequency:{" "}
                                                                                                                        </span>
                                                                                                                        <input
                                                                                                                            className="sub-input-min-dash"
                                                                                                                            type="text"
                                                                                                                            name="q25g_pre_ava_g1_tx_change_num"
                                                                                                                            id={idx + "-" + index}
                                                                                                                            value={ele.q25g_pre_ava_g1_tx_change_num}
                                                                                                                            onChange={HandlePreAVAdosageChange}
                                                                                                                            onKeyDown={formatInput}
                                                                                                                        />
                                                                                                                        time(s) per
                                                                                                                        <select
                                                                                                                            className="freq-dropdown"
                                                                                                                            id={idx + "-" + index}
                                                                                                                            name="q25g_pre_ava_g1_tx_change_freq"
                                                                                                                            onChange={HandlePreAVAdosageChange}
                                                                                                                            value={ele.q25g_pre_ava_g1_tx_change_freq}
                                                                                                                        >
                                                                                                                            <option value={0}>Select</option>
                                                                                                                            {frequencytime?.map((i) => {
                                                                                                                                return (
                                                                                                                                    <option
                                                                                                                                        key={i.frequencytimeid}
                                                                                                                                        value={i.frequencytimeid}
                                                                                                                                    >
                                                                                                                                        {i.frequencytimetxt}
                                                                                                                                    </option>
                                                                                                                                );
                                                                                                                            })}
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <ErrorField
                                                                                                            show={ele.q25g_empty ? true : ele.q25g_empty}
                                                                                                            message={TreatmentHistory_Msg.Q25g}
                                                                                                        />
                                                                                                                <ErrorField
                                                                                                                    show={ele.q25gFreqInput ? true : ele.q25gFreqInput}
                                                                                                                    message={TreatmentHistory_Msg.QdosageFreq} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <ErrorField
                                                                                                            show={ele.q25rpt_empty ? true : ele.q25rpt_empty}
                                                                                                            message={TreatmentHistory_Msg.Q25efg}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                    <div className="sub-question-bot-sapce answer-pad-left mt-2">
                                                                                        <div className="answer-list-text">
                                                                                            <a
                                                                                                onClick={(e) =>
                                                                                                    adddDoseClickQ25(
                                                                                                        data.treatmenttypeid
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    width="16px"
                                                                                                    src="../Assets/images/plus.png"
                                                                                                    alt=""
                                                                                                />
                                                                                            </a>
                                                                                            <a className="radio-text-padding"
                                                                                              onClick={(e) =>
                                                                                                adddDoseClickQ25(
                                                                                                    data.treatmenttypeid
                                                                                                )}>
                                                                                                Click here to enter another dose
                                                                                                change for {" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        color:
                                                                                                            PageDesignConstants.fontColor,
                                                                                                    }}
                                                                                                >
                                                                                                    {data.treatmentName}
                                                                                                </span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <></>
                                                                            )}

                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={0}
                                                                                    name={"q25d_pre_ava_d1_tx_dose_change-" + index}
                                                                                    checked={
                                                                                        data.q25d_pre_ava_d1_tx_dose_change ==
                                                                                        "0" || data.q25d_pre_ava_d1_tx_dose_change === 0
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        addQ25RepeaterRow(e, data)
                                                                                    }
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    No
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q25d_empty ? true : data.q25d_empty}
                                                                                message={TreatmentHistory_Msg.Q25d}
                                                                            />
                                                                        </div>

                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text question-weight">
                                                                                <span className="">h.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Did the patient discontinue{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                PageDesignConstants.fontColor,
                                                                                        }}
                                                                                    >
                                                                                        {data.treatmentName}
                                                                                    </span>{" "}
                                                                                    at any time?
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={1}

                                                                                    name={"q25h_pre_ava_tx_disc-" + index}
                                                                                    checked={
                                                                                        data.q25h_pre_ava_tx_disc == "1" || data.q25h_pre_ava_tx_disc == 1
                                                                                    }
                                                                                    onChange={
                                                                                        handleQ25CheckboxChange
                                                                                    }
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Yes
                                                                                </span>
                                                                            </div>
                                                                            {/* pb -nyt */}
                                                                            {data.q25h_pre_ava_tx_disc == "1" && (
                                                                                <div className="answer-pad-left">
                                                                                    <div className="dependent-sub-section">
                                                                                        <div className="sub-question-bot-sapce">
                                                                                            <div className="answer-list-text">
                                                                                                <span className="question-weight">
                                                                                                    i.
                                                                                                </span>
                                                                                                <span className="quest-text-pad">
                                                                                                    <span
                                                                                                        style={{
                                                                                                            display: "flex",
                                                                                                        }}
                                                                                                    >
                                                                                                        <span className="question-weight">
                                                                                                            Date of{" "}
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color:
                                                                                                                        PageDesignConstants.fontColor,
                                                                                                                }}
                                                                                                            >
                                                                                                                {data.treatmentName}
                                                                                                            </span>{" "}
                                                                                                            end/discontinuation:
                                                                                                        </span>

                                                                                                        <DateControl
                                                                                                            ctrlId={
                                                                                                                "q25i_pre_ava_tx_disc_dt-" + index
                                                                                                            }
                                                                                                            HandleDateChange={HandleDateChange}
                                                                                                            defDate={data.q25defdateDisc}
                                                                                                            optionId={"q25i_pre_ava_tx_disc_dt"}
                                                                                                            date={data.q25i_pre_ava_tx_disc_dt}
                                                                                                        />
                                                                                                    </span>
                                                                                                    (If only month and year are
                                                                                                    known, please enter “15” for
                                                                                                    the day)
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="answer-list-text answer-pad-left">

                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={index}
                                                                                                    value={1}
                                                                                                    name={"q25i_pre_ava_disc_dt_unk-" + index}
                                                                                                    checked={
                                                                                                        data.q25i_pre_ava_disc_dt_unk ===
                                                                                                        "1" || data.q25i_pre_ava_disc_dt_unk ===
                                                                                                        1
                                                                                                    }
                                                                                                    onChange={HandleChangeDisctunknwn}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    Date of{" "}
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color:
                                                                                                                PageDesignConstants.fontColor,
                                                                                                        }}
                                                                                                    >
                                                                                                        {data.treatmentName}
                                                                                                    </span>{" "}
                                                                                                    discontinuation is unknown
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={data.q25i_empty ? true : data.q25i_empty}
                                                                                            message={(data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25iEltrombopag : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25iRomiplostim : TreatmentHistory_Msg.Q25iLusutrombopag)}
                                                                                        />
                                                                                        <ErrorField
                                                                                show={data.q25iBeforeIndexDate?true:data.q25iBeforeIndexDate}
                                                                                message={TreatmentHistory_Msg.Q25iIndex}                                                                                   
                                                                            />
                                                                              <ErrorField
                                                                                show={data.q25ibeforetherapydate?true:data.q25ibeforetherapydate}
                                                                                message={
                                                                                    (data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25iBeforeTherapyEltrombopag : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25iBeforeTherapyRomiplostim : TreatmentHistory_Msg.Q25iBeforeTherapyLusutrombopag)}
                                                                            />
                                                                                 <ErrorField
                                                                                show={data.q25ibefordosedate?true:data.q25ibefordosedate}
                                                                                message={
                                                                                    (data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25iBeforeDoseEltrombopag : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25iBeforeDoseRomiplostim : TreatmentHistory_Msg.Q25iBeforeDoseLusutrombopag)
                                                                                    }
                                                                            />
                                                                          
                                                                                    </div>

                                                                                </div>
                                                                            )}

                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={0}

                                                                                    name={"q25h_pre_ava_tx_disc-" + index}
                                                                                    checked={
                                                                                        data.q25h_pre_ava_tx_disc == "0"
                                                                                    }
                                                                                    onChange={
                                                                                        handleQ25CheckboxChange
                                                                                    }
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    No, the patient is still taking this
                                                                                    therapy.
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q25h_empty ? true : data.q25h_empty}
                                                                                message={(data.treatmentName == "Eltrombopag" ? TreatmentHistory_Msg.Q25hEltrombopag : data.treatmentName == "Romiplostim" ? TreatmentHistory_Msg.Q25hRomiplostim : TreatmentHistory_Msg.Q25hLusutrombopag)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {/* If option 5 or 6 (therapy names: IVIG, Anti-D immunoglobulin) is selected in Q22b, ask sequentially for each selected therapy */}
                                                    {/* Q 26 */}

                                                    {
                                                        q26ava.length > 0 && (
                                                            <div>
                                                                {q26ava.map((data, idx) => {

                                                                    return (
                                                                        <div
                                                                            className="dependent-section mt-2"
                                                                            key={idx} 
                                                                        >

                                                                    <div className="answer-list-text question-weight">
                                                                    <span>26. ({data.treatmentName})</span> 
                                                                        </div>
                                                                            <div className="answer-list-text question-weight">
                                                                                <span></span>
                                                                                <span className="radio-text-padding">
                                                                                    Please enter all dates that the
                                                                                    patient received{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                PageDesignConstants.fontColor,
                                                                                        }}
                                                                                    >

                                                                                        {data.treatmentName}
                                                                                    </span>{" "}
                                                                                    in the 3 months prior to AVA
                                                                                    initiation (i.e., between{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                PageDesignConstants.fontColor,
                                                                                        }}
                                                                                    >
                                                                                        {indexDateMinus3Months}
                                                                                    </span>{" "}
                                                                                    to{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            color:
                                                                                                PageDesignConstants.fontColor,
                                                                                        }}
                                                                                    >
                                                                                        {indexDateMinus1Day}
                                                                                    </span>
                                                                                    ).
                                                                                </span>
                                                                            </div>

                                                                            {data.avaListQ26 &&
                                                                                data.avaListQ26.length > 0 ? (
                                                                                <div>
                                                                                    {data.avaListQ26.map(
                                                                                        (item, index) => {

                                                                                            return (
                                                                                                <div
                                                                                                    className="answer-pad-left"
                                                                                                    key={index}
                                                                                                >
                                                                                                    <div className="loop-section">
                                                                                                        <div className="loop-head">
                                                                                                            <span className="question-weight">
                                                                                                                Course {index + 1}
                                                                                                            </span>
                                                                                                            {index != 0 ? (
                                                                                                                <div className="delete-btn-div">
                                                                                                                    <img
                                                                                                                        width="16px"
                                                                                                                        src="../Assets/images/Icon-material-delete.png"
                                                                                                                        alt=""
                                                                                                                        onClick={(e) =>
                                                                                                                            handleDeleteAVAQ26(
                                                                                                                                data.treatmenttypeid
                                                                                                                                ,
                                                                                                                                item.q26Count
                                                                                                                            )
                                                                                                                        }
                                                                                                                    />
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <></>
                                                                                                            )}
                                                                                                        </div>
                                                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                            <div className="answer-list-text">
                                                                                                                <span className="question-weight">
                                                                                                                    a.
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    className="quest-text-pad"
                                                                                                                    style={{
                                                                                                                        display: "flex",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="question-weight">
                                                                                                                        Start date:{" "}
                                                                                                                    </span>
                                                                                                                    <DateControl
                                                                                                                        ctrlId={
                                                                                                                            "q26a_pre_ava_a1_tx_start_dt-" + idx + "-" + index
                                                                                                                        }
                                                                                                                        HandleDateChange={HandleDateChange}
                                                                                                                        defDate={item.defDateQ26a}
                                                                                                                        optionId={"q26a_pre_ava_a1_tx_start_dt"}
                                                                                                                        date={item.q26a_pre_ava_a1_tx_start_dt}
                                                                                                                    />

                                                                                                                    (If only month and
                                                                                                                    year are known, please
                                                                                                                    enter “15” for the
                                                                                                                    day)
                                                                                                                </span>
                                                                                                            </div>

                                                                                                            <ErrorField
                                                                                                                show={item.q26a_Empty ? true : item.q26alessindex}
                                                                                                                message={item.q26a_Empty ? (data.treatmentName == "Anti-D immunoglobulin" ? TreatmentHistory_Msg.Q26aimmunoglobulinStart : TreatmentHistory_Msg.Q26aIVIGStart):
                                                                                                                   (data.treatmentName == "Anti-D immunoglobulin" ? TreatmentHistory_Msg.Q26aimmunoglobulin : TreatmentHistory_Msg.Q26aIVIG) }
                                                                                                            />
                                                                                                        </div>


                                                                                                        <div className="sub-question-bot-sapce  answer-pad-left">
                                                                                                            <div className="answer-list-text">
                                                                                                                <span className="question-weight">
                                                                                                                    b.
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    className="quest-text-pad"
                                                                                                                    style={{
                                                                                                                        display: "flex",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="question-weight">
                                                                                                                        End date:{" "}
                                                                                                                    </span>
                                                                                                                    <DateControl
                                                                                                                        ctrlId={
                                                                                                                            "q26b_pre_ava_b1_tx_end_dt-" + idx + "-" + index
                                                                                                                        }
                                                                                                                        HandleDateChange={HandleDateChange}
                                                                                                                        defDate={item.defDateQ26b}
                                                                                                                        optionId={"q26b_pre_ava_b1_tx_end_dt"}
                                                                                                                        date={item.q26b_pre_ava_b1_tx_end_dt}
                                                                                                                    />
                                                                                                                    (If only month and
                                                                                                                    year are known, please
                                                                                                                    enter “15” for the
                                                                                                                    day)
                                                                                                                </span>
                                                                                                            </div>
                                                                                                          
                                                                                                            <ErrorField
                                                                                                                show={item.q26b_Empty ? true : item.q26blessthanstartdate}
                                                                                                                message={item.q26b_Empty ? (data.treatmentName == "Anti-D immunoglobulin" ? TreatmentHistory_Msg.Q26bimmunoglobulinEnd : TreatmentHistory_Msg.Q26bIVIGEnd):
                                                                                                                    (item.q26blessthanstartdate ? (data.treatmentName == "Anti-D immunoglobulin" ? TreatmentHistory_Msg.Q26bimmunoglobulin : TreatmentHistory_Msg.Q26bIVIG) : "")}
                                                                                                            />

                                                                                                                <ErrorField
                                                                                                                show={item.Q26bAfterFollowEndDate ? true : item.Q26bAfterFollowEndDate}
                                                                                                                message={data.treatmentName == "Anti-D immunoglobulin" ? TreatmentHistory_Msg.Q26bimmunoglobulinInvalidSoft : TreatmentHistory_Msg.Q26bIVIGInvalidSoft}
                                                                                                            />
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                    <div className="sub-question-bot-sapce answer-pad-left mt-2">
                                                                                        <div className="answer-list-text">
                                                                                            <a
                                                                                                onClick={(e) =>
                                                                                                    addQ26RepeaterDetailsRow(
                                                                                                        e,
                                                                                                        data
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <img
                                                                                                    width="18px"
                                                                                                    src="../Assets/images/plus.png"
                                                                                                    alt=""
                                                                                                />
                                                                                            </a>
                                                                                            <a className="radio-text-padding"
                                                                                              onClick={(e) =>
                                                                                                addQ26RepeaterDetailsRow(
                                                                                                    e,
                                                                                                    data
                                                                                                )}>
                                                                                                Select to add another course of{" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        color:
                                                                                                            PageDesignConstants.fontColor,
                                                                                                    }}
                                                                                                >
                                                                                                    {data.treatmentName}
                                                                                                </span>{" "}
                                                                                                treatment
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}{" "}
                                                            </div>
                                                        )
                                                        //  : (
                                                        //   <></>
                                                        // )
                                                    }
                                                    {/* If option 8 (Fostamatinib) is selected in Q22b */}
                                                    {/* Q27 */}

                                                    {q27fosta.length > 0 ? (
                                                        q27fosta.map((data, index) => {

                                                            return (
                                                                <div className="dependent-section mt-2" key={index}>
                                                                    <div className="answer-list-text question-weight">
                                                                        <span>27.</span>

                                                                        <div>
                                                                            <span className="radio-text-padding">
                                                                                Please enter the dates and dosages that the patient received 
                                                                                {" "}
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                fostamatinib disodium 
                                                                                </span>{" "}
                                                                                in the 3 months prior to AVA initiation
                                                                                (i.e., between{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    {indexDateMinus3Months}
                                                                                </span>{" "}
                                                                                and{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    {indexDateMinus1Day}
                                                                                </span>
                                                                                ).{" "}If the patient received multiple courses of this treatment, please consider the most recent course in the 3 months prior to AVA initiation.
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="answer-pad-left">
                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text question-weight">
                                                                                <span>a.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Date of {" "}
                                                                                    <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    fostamatinib disodium 
                                                                                    </span>{" "}
                                                                                    initiation
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-pad-left">
                                                                                If only month and year are known, please enter “15” for the day
                                                                                <DateControl
                                                                                    ctrlId={
                                                                                        "q27a_pre_ava_fosta_start_dt-" + index
                                                                                    }
                                                                                    HandleDateChange={HandleDateChange}
                                                                                    defDate={data.defDateQ27}
                                                                                    optionId={"q27a_pre_ava_fosta_start_dt"}
                                                                                    date={data.q27a_pre_ava_fosta_start_dt}
                                                                                />
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={1}
                                                                                    name={"q27a_pre_ava_fosta_start_dt_unk-" + index}
                                                                                    checked={
                                                                                        data.q27a_pre_ava_fosta_start_dt_unk ===
                                                                                        "1" || data.q27a_pre_ava_fosta_start_dt_unk ===
                                                                                        1
                                                                                    }
                                                                                    onChange={HandleQ27ChangeUnknwn}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Date of therapy initiation is unknown{" "}
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q27a_empty ? true : data.q27lessindexdate}
                                                                                message={data.q27a_empty ? TreatmentHistory_Msg.Q27aDate : TreatmentHistory_Msg.Q27a}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text question-weight">
                                                                                <span>b.</span>
                                                                                <span className="quest-text-pad">
                                                                                    What was the starting dose of 
                                                                                    {" "}
                                                                                    <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    fostamatinib
                                                                                    disodium
                                                                                    </span>{" "}
                                                                                    ?
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">

                                                                                <input
                                                                                    type="radio"
                                                                                    id="q27b_pre_ava_fosta_start_dose"
                                                                                    name={index}
                                                                                    value={1}
                                                                                    checked={
                                                                                        data.q27b_pre_ava_fosta_start_dose === "1" ||
                                                                                        data.q27b_pre_ava_fosta_start_dose === 1
                                                                                    }
                                                                                    onChange={(e) => HandleFostaDosageradioChange(e)}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    100 mg twice daily{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="q27b_pre_ava_fosta_start_dose"
                                                                                    name={index}
                                                                                    value={2}
                                                                                    checked={
                                                                                        data.q27b_pre_ava_fosta_start_dose === "2" ||
                                                                                        data.q27b_pre_ava_fosta_start_dose === 2
                                                                                    }
                                                                                    onChange={(e) => HandleFostaDosageradioChange(e)}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    150 mg twice daily{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="q27b_pre_ava_fosta_start_dose"
                                                                                    name={index}
                                                                                    value={3}
                                                                                    checked={
                                                                                        data.q27b_pre_ava_fosta_start_dose === "3" ||
                                                                                        data.q27b_pre_ava_fosta_start_dose === 3
                                                                                    }
                                                                                    onChange={(e) => HandleFostaDosageradioChange(e)}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Other, please specify dose and frequency:{" "}
                                                                                    <input
                                                                                        className="input-dash"
                                                                                        type="text"
                                                                                        id="q27b_pre_ava_fosta_start_dose_oth"
                                                                                        disabled={
                                                                                            data.q27b_pre_ava_fosta_start_dose !=
                                                                                            "3" ||
                                                                                            data.q27b_pre_ava_fosta_start_dose != 3
                                                                                        }
                                                                                        name={index}
                                                                                        value={
                                                                                            data.q27b_pre_ava_fosta_start_dose_oth
                                                                                        }
                                                                                        onChange={(e) => HandleFostaDosageradioChange(e)}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="q27b_pre_ava_fosta_start_dose"
                                                                                    name={index}
                                                                                    value={99}
                                                                                    checked={
                                                                                        data.q27b_pre_ava_fosta_start_dose === "99" ||
                                                                                        data.q27b_pre_ava_fosta_start_dose === 99
                                                                                    }
                                                                                    onChange={HandleFostaDosageradioChange}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Starting dose is unknown{" "}
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q27b_empty ? true : data.q27b_empty}
                                                                                message={TreatmentHistory_Msg.Q27b}
                                                                            />
                                                                        </div>

                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text question-weight">
                                                                                <span>c.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Was the dose and/or frequency changed at
                                                                                    any time between initiation of
                                                                                    fostamatinib to{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            color: PageDesignConstants.fontColor,
                                                                                        }}
                                                                                    >
                                                                                        {endfollowupdate}
                                                                                    </span>
                                                                                    ?
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="q27c_pre_ava_fosta_dose_change"
                                                                                    value={1}
                                                                                    checked={
                                                                                        data.q27c_pre_ava_fosta_dose_change ===
                                                                                        "1" || data.q27c_pre_ava_fosta_dose_change === 1
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        addQ27RepeaterRow(e, data)
                                                                                    } />



                                                                                <span className="radio-text-padding">
                                                                                    Yes
                                                                                </span>
                                                                            </div>
                                                                            {/* q27 repeater loop */}
                                                                            {data.doseChangeListQ27.length > 0 ? (
                                                                                <div>
                                                                                    {data.doseChangeListQ27.map(
                                                                                        (ele, idx) => {
                                                                                            return (
                                                                                                <div key={idx}>

                                                                                                    <div className="loop-section">
                                                                                                        <div className="loop-section-pad-left">
                                                                                                            <div className="loop-head">
                                                                                                                <span className="question-weight">
                                                                                                                    Dose {idx + 1}
                                                                                                                </span>
                                                                                                                {idx != 0 ? (
                                                                                                                    <div className="delete-btn-div ">
                                                                                                                        <img
                                                                                                                            width="16px"
                                                                                                                            src="../Assets/images/Icon-material-delete.png"
                                                                                                                            alt=""
                                                                                                                            onClick={() =>
                                                                                                                                handleDeleteDoseQ27(
                                                                                                                                    data.treatmenttypeid,
                                                                                                                                    ele.q27doseCount
                                                                                                                                )
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>


                                                                                                                ) : (
                                                                                                                    <></>
                                                                                                                )}
                                                                                                            </div>

                                                                                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                <div className="answer-list-text question-weight">
                                                                                                                    <span>d.</span>
                                                                                                                    <span className="quest-text-pad">
                                                                                                                        <span
                                                                                                                            style={{ display: "flex" }}
                                                                                                                        >
                                                                                                                            <span className="question-weight">
                                                                                                                                What was the date of the
                                                                                                                                dose change?{" "}
                                                                                                                            </span>
                                                                                                                            <DateControl
                                                                                                                                marginLeft={false}
                                                                                                                                ctrlId={"q27d_pre_ava_fosta_dose_chg_d1_dt-" + (idx) + "-" + index}
                                                                                                                                HandleDateChange={HandleDateChange}
                                                                                                                                optionId={"q27d_pre_ava_fosta_dose_chg_d1_dt"}

                                                                                                                                date={ele.q27d_pre_ava_fosta_dose_chg_d1_dt}

                                                                                                                            />
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className="option-with-input answer-pad-left">
                                                                                                                    (If only month and year are known,
                                                                                                                    please enter “15” for the day)
                                                                                                                </div>
                                                                                                                <ErrorField
                                                                                                            show={ele.q27d_empty ? true : ele.q27d_empty}
                                                                                                            message={TreatmentHistory_Msg.Q27d}
                                                                                                        />
                                                                                                                <ErrorField
                                                                                                                    show={ele.q27dbeforeindexdate ? true : ele.q27dbeforeindexdate}
                                                                                                                    message={ TreatmentHistory_Msg.Q27dDoseAfter}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                <div className="answer-list-text question-weight">
                                                                                                                    <span className="">e.</span>
                                                                                                                    <span className="quest-text-pad">
                                                                                                                        What was the new dose and
                                                                                                                        frequency?
                                                                                                                    </span>
                                                                                                                </div>

                                                                                                                <div className="answer-list-text answer-pad-left">

                                                                                                                    <input
                                                                                                                        type="radio"
                                                                                                                        id="q27e_pre_ava_fosta_dose_chg_e1"
                                                                                                                        name={"q27e_pre_ava_fosta_dose_chg_e1-" + idx}
                                                                                                                        value={1}
                                                                                                                        checked={
                                                                                                                            ele.q27e_pre_ava_fosta_dose_chg_e1 === "1" ||
                                                                                                                            ele.q27e_pre_ava_fosta_dose_chg_e1 === 1
                                                                                                                        }
                                                                                                                        onChange={HandleFostafrequentDosageradioChange}
                                                                                                                    />
                                                                                                                    <span className="radio-text-padding">
                                                                                                                        100 mg twice daily
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className="answer-list-text answer-pad-left">


                                                                                                                    <input
                                                                                                                        type="radio"
                                                                                                                        id="q27e_pre_ava_fosta_dose_chg_e1"
                                                                                                                        name={"q27e_pre_ava_fosta_dose_chg_e1-" + idx}
                                                                                                                        value={2}
                                                                                                                        checked={
                                                                                                                            ele.q27e_pre_ava_fosta_dose_chg_e1 === "2" ||
                                                                                                                            ele.q27e_pre_ava_fosta_dose_chg_e1 === 2
                                                                                                                        }
                                                                                                                        onChange={HandleFostafrequentDosageradioChange}
                                                                                                                    />
                                                                                                                    <span className="radio-text-padding">
                                                                                                                        150 mg twice daily
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className="answer-list-text answer-pad-left">
                                                                                                                    <input
                                                                                                                        type="radio"
                                                                                                                        id="q27e_pre_ava_fosta_dose_chg_e1"
                                                                                                                        name={"q27e_pre_ava_fosta_dose_chg_e1-" + idx}
                                                                                                                        value={3}
                                                                                                                        checked={
                                                                                                                            ele.q27e_pre_ava_fosta_dose_chg_e1 === "3" ||
                                                                                                                            ele.q27e_pre_ava_fosta_dose_chg_e1 === 3
                                                                                                                        }
                                                                                                                        onChange={HandleFostafrequentDosageradioChange}
                                                                                                                    />
                                                                                                                    <span className="radio-text-padding">
                                                                                                                        Other, please specify dose and
                                                                                                                        frequency:
                                                                                                                        <input
                                                                                                                            className="input-dash"
                                                                                                                            type="text"
                                                                                                                            id="q27e_pre_ava_fosta_change_dose_oth"
                                                                                                                            disabled={
                                                                                                                                ele.q27e_pre_ava_fosta_dose_chg_e1 !=
                                                                                                                                "3" ||
                                                                                                                                ele.q27e_pre_ava_fosta_dose_chg_e1 != 3
                                                                                                                            }
                                                                                                                            name={"q27e_pre_ava_fosta_change_dose_oth-" + idx}
                                                                                                                            value={
                                                                                                                                ele.q27e_pre_ava_fosta_change_dose_oth
                                                                                                                            }
                                                                                                                            onChange={
                                                                                                                                HandleFostafrequentDosageradioChange
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <ErrorField
                                                                                                            show={ele.q27e_empty ? true : ele.q27e_empty}
                                                                                                            message={TreatmentHistory_Msg.Q27e}
                                                                                                        />
                                                                                                        <ErrorField
                                                                                                            show={ele.q27rpt_empty ? true : ele.q27rpt_empty}
                                                                                                            message={TreatmentHistory_Msg.Q27de}
                                                                                                        />
                                                                                                    </div>

                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}

                                                                                    <div className="answer-pad-left">
                                                                                        <div className="sub-question-bot-sapce answer-pad-left mt-2">
                                                                                            <div className="answer-list-text">
                                                                                                <a onClick={(e) =>
                                                                                                    adddDoseClickQ27(
                                                                                                        data.treatmenttypeid
                                                                                                    )
                                                                                                }>
                                                                                                    <img
                                                                                                        width="18px"
                                                                                                        src="../Assets/images/plus.png"
                                                                                                        alt=""
                                                                                                    />
                                                                                                </a>
                                                                                                <a className="radio-text-padding"
                                                                                                  onClick={(e) =>
                                                                                                    adddDoseClickQ27(
                                                                                                        data.treatmenttypeid
                                                                                                    )}>
                                                                                                    Click here to enter another dose
                                                                                                    change for fostamatinib
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (<></>)}

                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="q27c_pre_ava_fosta_dose_change"
                                                                                    name="q27c_pre_ava_fosta_dose_change"
                                                                                    value={0}
                                                                                    checked={
                                                                                        data.q27c_pre_ava_fosta_dose_change ===
                                                                                        "0" || data.q27c_pre_ava_fosta_dose_change === 0
                                                                                    }
                                                                                    onChange={(e) =>
                                                                                        addQ27RepeaterRow(e, data)
                                                                                    }

                                                                                />

                                                                                <span className="radio-text-padding">
                                                                                    No
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q27c_empty ? true : data.q27c_empty}
                                                                                message={TreatmentHistory_Msg.Q27c}
                                                                            />
                                                                        </div>

                                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                                            <div className="answer-list-text question-weight">
                                                                                <span>f.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Did the patient discontinue 
                                                                                    {" "}
                                                                                    <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    fostamatinib
                                                                                    disodium </span>{" "}
                                                                                    
                                                                                    at any time?
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={1}

                                                                                    name={"q27f_pre_ava_fosta_disc"}
                                                                                    checked={
                                                                                        data.q27f_pre_ava_fosta_disc === "1" || data.q27f_pre_ava_fosta_disc === 1
                                                                                    }
                                                                                    onChange={
                                                                                        handleQ27CheckboxChange
                                                                                    }
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Yes
                                                                                </span>
                                                                            </div>
                                                                            {data.q27f_pre_ava_fosta_disc == "1" && (
                                                                                <div className="answer-pad-left">
                                                                                    <div className="dependent-sub-section">
                                                                                        <div className="sub-question-bot-sapce">
                                                                                            <div className="answer-list-text">
                                                                                                <span className="question-weight">
                                                                                                    g.
                                                                                                </span>
                                                                                                <span className="quest-text-pad">
                                                                                                    <span style={{ display: "flex" }}>
                                                                                                        <span className="question-weight">
                                                                                                            Date of 
                                                                                                            {" "}
                                                                                                            <span
                                                                                        style={{
                                                                                            color: PageDesignConstants.fontColor,
                                                                                        }}
                                                                                    >
                                                                                                            fostamatinib disodium</span>{" "}
                                                                                                            discontinuation:{" "}
                                                                                                        </span>
                                                                                                        <DateControl
                                                                                                            ctrlId={
                                                                                                                "q27g_pre_ava_fosta_disc_dt-" + index
                                                                                                            }
                                                                                                            HandleDateChange={HandleDateChange}
                                                                                                            defDate={data.q27defdateDisc}
                                                                                                            optionId={"q27g_pre_ava_fosta_disc_dt"}
                                                                                                            date={data.q27g_pre_ava_fosta_disc_dt}
                                                                                                        />
                                                                                                    </span>
                                                                                                    (If only month and year are known,
                                                                                                    please enter “15” for the day)
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="answer-list-text answer-pad-left">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={index}
                                                                                                    value={1}
                                                                                                    name={"q27g_pre_ava_fosta_disc_dt_unk-" + index}
                                                                                                    checked={
                                                                                                        data.q27g_pre_ava_fosta_disc_dt_unk ===
                                                                                                        "1" || data.q27g_pre_ava_fosta_disc_dt_unk ===
                                                                                                        1
                                                                                                    }
                                                                                                    onChange={HandleChangeDiscQ27tunknwn}
                                                                                                />
                                                                                                <span className="radio-text-padding">
                                                                                                    Date of fostamatinib discontinuation
                                                                                                    is unknown{" "}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={data.q27g_empty ? true : data.q27g_empty}
                                                                                            message={TreatmentHistory_Msg.Q27g}
                                                                                        />
                                                                                  <ErrorField
                                                                                show={data.q27gbeforeTherapydate ? true : data.q27gBeforeDoseDate}
                                                                                message={data.q27gbeforeTherapydate ?TreatmentHistory_Msg.Q27gBeforeTherapyDate:TreatmentHistory_Msg.Q27gBeforeDoseDate}
                                                                            />
                                                                                    </div>


                                                                                </div>)}
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={0}

                                                                                    name={"q27f_pre_ava_fosta_disc"}
                                                                                    checked={
                                                                                        data.q27f_pre_ava_fosta_disc === "0" || data.q27f_pre_ava_fosta_disc === 0
                                                                                    }
                                                                                    onChange={
                                                                                        handleQ27CheckboxChange
                                                                                    }
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    No, patient was still taking this
                                                                                    treatment at the end of follow up.
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q27f_empty ? true : data.q27f_empty}
                                                                                message={TreatmentHistory_Msg.Q27f}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (<></>)
                                                    }
                                                    {/* If option 4 (steroids) is selected in Q22b */}
                                                    {/* Q 28 */}
                                                    {q28steroid.length > 0 ? (

                                                        q28steroid.map((data, idx) => {

                                                            return (
                                                                <div>
                                                                    <div className="dependent-section mt-2" key={idx}>
                                                                        <div className="answer-list-text question-weight">
                                                                            <span>28.</span>
                                                                            <span className="radio-text-padding">
                                                                                Please provide information on each course of
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                 {" "}steroids
                                                                                </span> {" "} received in the 3 months before AVA
                                                                                initiation (from{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color: PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    {indexDateMinus3Months}
                                                                                </span>{" "}
                                                                                to{" "}
                                                                                <span
                                                                                    style={{
                                                                                        color: PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >
                                                                                    {indexDateMinus1Day}
                                                                                </span>
                                                                                ), including initial dose and dose changes.
                                                                            </span>
                                                                        </div>
                                                                        <div className="answer-pad-left">

                                                                            {/* course loop section */}
                                                                            {data.courseListQ28 &&
                                                                                data.courseListQ28.length > 0 ? (
                                                                                <div>
                                                                                    {data.courseListQ28.map(
                                                                                        (item, index) => {
                                                                                            return (
                                                                                                <div className="loop-section" key={index}>
                                                                                                    <div className="loop-head">
                                                                                                        <span className="question-weight">
                                                                                                            Course {index + 1}
                                                                                                        </span>
                                                                                                        {index != 0 ? (
                                                                                                            <div className="delete-btn-div">
                                                                                                                <img
                                                                                                                    onClick={(e) =>
                                                                                                                        handleDeleteSteroidQ28(
                                                                                                                            data.treatmenttypeid,
                                                                                                                            item.RowSequenceNo
                                                                                                                        )
                                                                                                                    }
                                                                                                                    width="16px"
                                                                                                                    src="../Assets/images/Icon-material-delete.png"
                                                                                                                    alt=""
                                                                                                                />
                                                                                                            </div>) : (<></>)}

                                                                                                    </div>

                                                                                                    <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                        <div className="answer-list-text question-weight">
                                                                                                            <span>a.</span>
                                                                                                            <span className="quest-text-pad">
                                                                                                                Type of steroid received
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="answer-list-text answer-pad-left">

                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"q28a_steroid_type"}
                                                                                                                name={"q28a_steroid_type-" + idx + "-" + index}
                                                                                                                value={1}
                                                                                                                checked={
                                                                                                                    item.q28a_steroid_type === "1" ||
                                                                                                                    item.q28a_steroid_type === 1
                                                                                                                }
                                                                                                                onChange={(e) => HandleSteroidChange(e)}

                                                                                                            />
                                                                                                            <span className="radio-text-padding">
                                                                                                                Dexamethasone
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="answer-list-text answer-pad-left">
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"q28a_steroid_type"}
                                                                                                                name={"q28a_steroid_type-" + idx + "-" + index}
                                                                                                                value={2}
                                                                                                                checked={
                                                                                                                    item.q28a_steroid_type === "2" ||
                                                                                                                    item.q28a_steroid_type === 2
                                                                                                                }
                                                                                                                onChange={(e) => HandleSteroidChange(e)}
                                                                                                            />
                                                                                                            <span className="radio-text-padding">
                                                                                                                Prednisone
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="answer-list-text answer-pad-left">
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={"q28a_steroid_type"}
                                                                                                                name={"q28a_steroid_type-" + idx + "-" + index}
                                                                                                                value={3}
                                                                                                                checked={
                                                                                                                    item.q28a_steroid_type === "3" ||
                                                                                                                    item.q28a_steroid_type === 3
                                                                                                                }
                                                                                                                onChange={(e) => HandleSteroidChange(e)}
                                                                                                            />
                                                                                                            <span className="radio-text-padding">
                                                                                                                Prednisolone
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <ErrorField
                                                                                                            show={item.q28a_empty ? true : item.q28a_empty}
                                                                                                            message={TreatmentHistory_Msg.Q28aSteroid}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                        <div className="answer-list-text question-weight">
                                                                                                            <span>b.</span>
                                                                                                            <span className="quest-text-pad">
                                                                                                                Date of initiation of  {" "}<span
                                                                                    style={{
                                                                                        color:
                                                                                            PageDesignConstants.fontColor,
                                                                                    }}
                                                                                >steroids</span>{" "}
                                                                                                                <div>
                                                                                                                    <span>
                                                                                                                        If only month and year are known,
                                                                                                                        please enter “15” for the day.
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="answer-list-text answer-pad-left">
                                                                                                            <DateControl
                                                                                                                ctrlId={
                                                                                                                    "q28b_start_dt-" + index
                                                                                                                }
                                                                                                                HandleDateChange={HandleDateChange}
                                                                                                                defDate={item.defDateQ28}
                                                                                                                optionId={"q28b_start_dt"}
                                                                                                                date={item.q28b_start_dt}
                                                                                                            />
                                                                                                        </div>
                                                                                                      
                                                                                                        <ErrorField
                                                                                                            show={item.q28b_empty ? true : item.q28alessindexdate}
                                                                                                            message={item.q28b_empty ? TreatmentHistory_Msg.Q28b : TreatmentHistory_Msg.Q28a}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                        <div className="question-weight">
                                                                                                            <span>Initial dose:</span>
                                                                                                        </div>
                                                                                                        <div className="answer-list-text">
                                                                                                            <span className="question-weight">
                                                                                                                c.
                                                                                                            </span>
                                                                                                            <span className="quest-text-pad">
                                                                                                                <span className="question-weight">
                                                                                                                    Dosage:{" "}
                                                                                                                </span>
                                                                                                                <input
                                                                                                                    className="sub-input-min-dash"
                                                                                                                    type="text"
                                                                                                                    name="q28c_start_dose"
                                                                                                                    id={index}
                                                                                                                    value={item.q28c_start_dose}
                                                                                                                    onChange={HandlePreAVAdosageChange}
                                                                                                                    onKeyDown={formatInput} />{" "}mg
                                                                                                                {/* {(item.q28a_steroid_type == "1" || item.q28a_steroid_type == 1) ? "mg/ml" : (item.q28a_steroid_type == "" ? "" : "mg")} */}
                                                                                                            </span>

                                                                                                        </div>
                                                                                                       
                                                                                                        <ErrorField
                                                                                                            show={item.q28c_empty ? true : item.q28cDoseInput}
                                                                                                            message={item.q28c_empty ? TreatmentHistory_Msg.Q28c : TreatmentHistory_Msg.QdosageFreq}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                        <div className="answer-list-text">
                                                                                                            <span className="question-weight">
                                                                                                                d.
                                                                                                            </span>
                                                                                                            <span className="quest-text-pad">
                                                                                                                <span className="question-weight">
                                                                                                                    Frequency:{" "}
                                                                                                                </span>
                                                                                                                <input
                                                                                                                    className="sub-input-min-dash"
                                                                                                                    type="text"
                                                                                                                    name="q28d_start_num"
                                                                                                                    id={index}
                                                                                                                    value={item.q28d_start_num}
                                                                                                                    onChange={HandlePreAVAdosageChange}
                                                                                                                    onKeyDown={formatInput}
                                                                                                                />
                                                                                                                time(s) per
                                                                                                                <select
                                                                                                                    className="freq-dropdown"
                                                                                                                    id={index}
                                                                                                                    name="q28d_start_freq"
                                                                                                                    onChange={HandlePreAVAdosageChange}
                                                                                                                    value={item.q28d_start_freq}
                                                                                                                >
                                                                                                                    <option value={0}>Select</option>
                                                                                                                    {frequencytime?.map((i) => {
                                                                                                                        return (
                                                                                                                            <option
                                                                                                                                key={i.frequencytimeid}
                                                                                                                                value={i.frequencytimeid}
                                                                                                                            >
                                                                                                                                {i.frequencytimetxt}
                                                                                                                            </option>
                                                                                                                        );
                                                                                                                    })}
                                                                                                                </select>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                       
                                                                                                        <ErrorField
                                                                                                            show={item.q28d_empty ? true : item.q28dFreqInput}
                                                                                                            message={item.q28d_empty ? TreatmentHistory_Msg.Q28d : TreatmentHistory_Msg.QdosageFreq}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="sub-question-bot-sapce sub-question-bot-sapce-none answer-pad-left">
                                                                                                        <div className="answer-list-text question-weight">
                                                                                                            <span>e.</span>
                                                                                                            <span className="quest-text-pad">
                                                                                                                Did the dose change during this course
                                                                                                                of  {" "}
                                                                                                                <span
                                                                                                             style={{
                                                                                                              color:
                                                                                                                 PageDesignConstants.fontColor,
                                                                                                                  }}
                                                                                                             >
                                                                                                                steroids</span>{" "}
                                                                                                                 at any time prior to AVA
                                                                                                                initiation on <span
                                                                                                                    style={{
                                                                                                                        color:
                                                                                                                            PageDesignConstants.fontColor,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {indexdate}
                                                                                                                </span>?
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="answer-list-text answer-pad-left">
                                                                                                            <input type="radio"
                                                                                                                id={index}
                                                                                                                value={1}
                                                                                                                name={"q28e_dose_change-" + index}
                                                                                                                checked={item.q28e_dose_change === "1" || item.q28e_dose_change === 1}

                                                                                                                onChange={(e) => addQ28RepeaterRow(e, data, item)}
                                                                                                            />
                                                                                                            <span className="radio-text-padding">
                                                                                                                Yes
                                                                                                            </span>
                                                                                                        </div>

                                                                                                        {item.clickListQ28.length > 0 ? (
                                                                                                            <div>
                                                                                                                {item.clickListQ28.map(
                                                                                                                    (ele, idx) => {
                                                                                                                        return (
                                                                                                                            <div className="sub-loop-section" key={idx}>
                                                                                                                                <div className="loop-head">
                                                                                                                                    <span className="question-weight">
                                                                                                                                        Dose {idx + 1}
                                                                                                                                    </span>
                                                                                                                                    {idx != 0 ? (
                                                                                                                                        <div className="delete-btn-div ">
                                                                                                                                            <img
                                                                                                                                                width="16px"
                                                                                                                                                src="../Assets/images/Icon-material-delete.png"
                                                                                                                                                alt=""
                                                                                                                                                onClick={() =>
                                                                                                                                                    handleDeleteDoseQ28(
                                                                                                                                                        data,
                                                                                                                                                        item,
                                                                                                                                                        ele.q28doseCount

                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                    ) : (
                                                                                                                                        <></>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                                <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                                    <div className="answer-list-text question-weight">
                                                                                                                                        <span>f.</span>
                                                                                                                                        <span className="quest-text-pad">
                                                                                                                                            What was the date of the dose change
                                                                                                                                            and the new 
                                                                                                                                            {" "}
                                                                                                                                            <span
                                                                                                                                            style={{
                                                                                                                                              color: PageDesignConstants.fontColor,
                                                                                                                                                  }}
                                                                                                                                            >steroid 
                                                                                                                                            </span>{" "}
                                                                                                                                            dose and
                                                                                                                                            frequency?
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <div className="answer-list-text answer-pad-left">
                                                                                                                                        <DateControl
                                                                                                                                            marginLeft={false}
                                                                                                                                            ctrlId={"q28f_dose_change_dt-" + (idx) + "-" + index}
                                                                                                                                            HandleDateChange={HandleDateChange}
                                                                                                                                            optionId={"q28f_dose_change_dt"}

                                                                                                                                            date={ele.q28f_dose_change_dt}

                                                                                                                                        />
                                                                                                                                        <span>
                                                                                                                                            (If only month and year are known,
                                                                                                                                            please enter “15” for the day.)
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <ErrorField
                                                                                                                                        show={ele.q28f_empty ? true : ele.q28f_empty}
                                                                                                                                        message={TreatmentHistory_Msg.Q28f}
                                                                                                                                    />
                                                                                                                                    <ErrorField
                                                                                                                                        show={ele.q28eafterindexdate ? true : ele.q28ebeforeindexdate}
                                                                                                                                        message={ele.q28eafterindexdate ? TreatmentHistory_Msg.Q28eDoseAfter : TreatmentHistory_Msg.Q28eDoseBefore}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                                    <div className="answer-list-text ">
                                                                                                                                        <span className="question-weight">
                                                                                                                                            g.
                                                                                                                                        </span>
                                                                                                                                        <span className="quest-text-pad">
                                                                                                                                            <span className="question-weight">
                                                                                                                                                Dosage:{" "}
                                                                                                                                            </span>
                                                                                                                                            <input
                                                                                                                                                className="sub-input-min-dash"
                                                                                                                                                type="text"
                                                                                                                                                name="q28g_change_dose"
                                                                                                                                                id={idx + "-" + index}
                                                                                                                                                value={ele.q28g_change_dose}
                                                                                                                                                onChange={HandlePreAVAdosageChange}
                                                                                                                                                onKeyDown={formatInput}
                                                                                                                                            />{" "}mg
                                                                                                                                            {/* {(item.q28a_steroid_type == "1" || item.q28a_steroid_type == 1) ? "mg/ml" : (item.q28a_steroid_type == "" ? "" : "mg")} */}

                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <ErrorField
                                                                                                                                        show={ele.q28g_empty ? true : ele.q28g_empty}
                                                                                                                                        message={TreatmentHistory_Msg.Q28g}
                                                                                                                                    />
                                                                                                                                    <ErrorField
                                                                                                                                        show={ele.q28gdoseInput ? true : ele.q28gdoseInput}
                                                                                                                                        message={TreatmentHistory_Msg.QdosageFreq}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                                    <div className="answer-list-text">
                                                                                                                                        <span className="question-weight">
                                                                                                                                            h.
                                                                                                                                        </span>
                                                                                                                                        <span className="quest-text-pad">
                                                                                                                                            <span className="question-weight">
                                                                                                                                                Frequency:{" "}
                                                                                                                                            </span>
                                                                                                                                            <input
                                                                                                                                                className="sub-input-min-dash"
                                                                                                                                                type="text"
                                                                                                                                                name="q28h_change_num"
                                                                                                                                                id={idx + "-" + index}
                                                                                                                                                value={ele.q28h_change_num}
                                                                                                                                                onChange={HandlePreAVAdosageChange}
                                                                                                                                                onKeyDown={formatInput}
                                                                                                                                            />
                                                                                                                                            time(s) per
                                                                                                                                            <select
                                                                                                                                                className="freq-dropdown"
                                                                                                                                                id={idx + "-" + index}
                                                                                                                                                name="q28h_change_freq"
                                                                                                                                                onChange={HandlePreAVAdosageChange}
                                                                                                                                                value={ele.q28h_change_freq}
                                                                                                                                            >
                                                                                                                                                <option value={0}>Select</option>
                                                                                                                                                {frequencytime?.map((i) => {
                                                                                                                                                    return (
                                                                                                                                                        <option
                                                                                                                                                            key={i.frequencytimeid}
                                                                                                                                                            value={i.frequencytimeid}
                                                                                                                                                        >
                                                                                                                                                            {i.frequencytimetxt}
                                                                                                                                                        </option>
                                                                                                                                                    );
                                                                                                                                                })}
                                                                                                                                            </select>
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                    <ErrorField
                                                                                                                                        show={ele.q28hFreqInput ? true : ele.q28hFreqInput}
                                                                                                                                        message={TreatmentHistory_Msg.QdosageFreq}
                                                                                                                                    />
                                                                                                                                     <ErrorField
                                                                                                                                        show={ele.q28h_empty ? true : ele.q28h_empty}
                                                                                                                                        message={TreatmentHistory_Msg.Q28h}
                                                                                                                                    />
                                                                                                                                    <ErrorField
                                                                                                                                        show={ele.q28rpt_empty ? true : ele.q28rpt_empty}
                                                                                                                                        message={TreatmentHistory_Msg.Q28fgh}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        );

                                                                                                                    }
                                                                                                                )}
                                                                                                                <div className="sub-question-bot-sapce answer-pad-left mt-2">
                                                                                                                    <div className="answer-list-text">
                                                                                                                        <a

                                                                                                                            onClick={(e) => addDoseClickQ28(e, data, item)}
                                                                                                                        >
                                                                                                                            <img
                                                                                                                                width="18px"
                                                                                                                                src="../Assets/images/plus.png"
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                        </a>
                                                                                                                        <a className="radio-text-padding"
                                                                                                                          onClick={(e) => addDoseClickQ28(e, data, item)}>
                                                                                                                            Click here to enter another dose
                                                                                                                            change for this course of steroids
                                                                                                                        </a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : (<></>)}


                                                                                                        <div className="answer-list-text answer-pad-left">
                                                                                                            <input type="radio"
                                                                                                                id={index}
                                                                                                                value={0}
                                                                                                                name={"q28e_dose_change-" + index}
                                                                                                                checked={item.q28e_dose_change === "0" || item.q28e_dose_change === 0}
                                                                                                                onClick={(e) =>
                                                                                                                    addQ28RepeaterRow(
                                                                                                                        e, data, item
                                                                                                                    )
                                                                                                                } />
                                                                                                            <span className="radio-text-padding">
                                                                                                                No
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <ErrorField
                                                                                                            show={item.q28e_empty ? true : item.q28e_empty}
                                                                                                            message={TreatmentHistory_Msg.Q28e}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                        <div className="answer-list-text question-weight">
                                                                                                            <span>i.</span>
                                                                                                            <span className="quest-text-pad">
                                                                                                                Did the patient discontinue this course
                                                                                                                of  {" "}
                                                                                                                <span
                                                                                                             style={{
                                                                                                              color:
                                                                                                                 PageDesignConstants.fontColor,
                                                                                                                  }}
                                                                                                             >
                                                                                                                steroids 
                                                                                                                </span>{" "}
                                                                                                                prior to AVA initiation on{" "}
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color:
                                                                                                                            PageDesignConstants.fontColor,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {indexdate}
                                                                                                                </span>
                                                                                                                ?
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="answer-list-text answer-pad-left">
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={index}
                                                                                                                value={1}

                                                                                                                name={"q28i_tx_disc-" + index}
                                                                                                                checked={
                                                                                                                    item.q28i_tx_disc == "1" || item.q28i_tx_disc == 1
                                                                                                                }
                                                                                                                onChange={
                                                                                                                    handleQ28CheckboxChange
                                                                                                                }
                                                                                                            />
                                                                                                            <span className="radio-text-padding">
                                                                                                                Yes
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        {item.q28i_tx_disc == "1" && (
                                                                                                            <div className="answer-pad-left">
                                                                                                                <div className="dependent-sub-section">
                                                                                                                    <div className="sub-question-bot-sapce">
                                                                                                                        <div className="answer-list-text question-weight">
                                                                                                                            <span>j.</span>
                                                                                                                            <span className="quest-text-pad">
                                                                                                                                Date of {" "}
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                   color: PageDesignConstants.fontColor,
                                                                                                                                   }}
                                                                                                                                   >steroid</span>{" "}
                                                                                                                                end/discontinuation:{" "}
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                        <div className="answer-list-text answer-pad-left">

                                                                                                                            <DateControl
                                                                                                                                ctrlId={
                                                                                                                                    "q28j_tx_disc_dt-" + index
                                                                                                                                }
                                                                                                                                HandleDateChange={HandleDateChange}
                                                                                                                                defDate={item.q28defdateDisc}
                                                                                                                                optionId={"q28j_tx_disc_dt"}
                                                                                                                                date={item.q28j_tx_disc_dt}
                                                                                                                            />
                                                                                                                            <span>
                                                                                                                                (If only month and year are known,
                                                                                                                                please enter “15” for the day.)
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                      
                                                                                                                    </div>
                                                                                                                    <ErrorField
                                                                                                                        show={item.q28j_empty ? true : item.q28j_empty}
                                                                                                                        message={TreatmentHistory_Msg.Q28j}
                                                                                                                    />

                                                                                                                <ErrorField
                                                                                                                    show={item.q28jlessindexdate ? true : item.q28jAfterindexdate}
                                                                                                                    message={item.q28jlessindexdate ?  TreatmentHistory_Msg.Q28jLessIndexDate :TreatmentHistory_Msg.Q28jAfterIndexDate }
                                                                                                                />

                                                                                                                    <ErrorField
                                                                                                                    show={item.q28jBeforeDosedate ? true : item.q28jBeforeDosedate}
                                                                                                                    message={TreatmentHistory_Msg.Q28jBeforeDoseDate}
                                                                                                                />

                                                                                                                </div>
                                                                                                            </div>)}
                                                                                                        <div className="answer-list-text answer-pad-left">
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                id={index}
                                                                                                                value={0}

                                                                                                                name={"q28i_tx_disc-" + index}
                                                                                                                checked={
                                                                                                                    item.q28i_tx_disc === "0" || item.q28i_tx_disc === 0
                                                                                                                }
                                                                                                                onChange={
                                                                                                                    handleQ28CheckboxChange
                                                                                                                }
                                                                                                            />
                                                                                                            <span className="radio-text-padding">
                                                                                                                No
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <ErrorField
                                                                                                            show={item.q28i_empty ? true : item.q28i_empty}
                                                                                                            message={TreatmentHistory_Msg.Q28i}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                    <div className="sub-question-bot-sapce answer-pad-left mt-2">
                                                                                        <div className="answer-list-text">
                                                                                            <a onClick={(e) =>
                                                                                                addQ28RepeaterCourse(
                                                                                                    e,
                                                                                                    data
                                                                                                )}>
                                                                                                <img
                                                                                                    width="18px"
                                                                                                    src="../Assets/images/plus.png"
                                                                                                    alt=""
                                                                                                />
                                                                                            </a>
                                                                                            <a className="radio-text-padding"
                                                                                              onClick={(e) =>
                                                                                                addQ28RepeaterCourse(
                                                                                                    e,
                                                                                                    data
                                                                                                )}>
                                                                                                Click here to enter another course of
                                                                                                steroids between{" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: PageDesignConstants.fontColor,
                                                                                                    }}
                                                                                                >
                                                                                                    {indexDateMinus3Months}
                                                                                                </span>{" "}
                                                                                                and{" "}
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: PageDesignConstants.fontColor,
                                                                                                    }}
                                                                                                >
                                                                                                    {indexdate}
                                                                                                </span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (<></>)}



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (<></>)}





                                                    {/* [If options 7 or 9 (therapy names: rituximab, immunosuppressants) are selected in Q22b, please display the following question for each that is selected]  */}
                                                    {/* Q 29 */}
                                                    {q29ava.length > 0 ? (
                                                        q29ava.map((data, index) => {
                                                            return (
                                                                <div className="dependent-section mt-2" key={index}>
                                                                    <div className="answer-list-text question-weight">
                                                                        <span>29.</span>
                                                                        <span className="radio-text-padding">
                                                                            Was the patient still on{" "}
                                                                            <span
                                                                                style={{
                                                                                    color: PageDesignConstants.fontColor,
                                                                                }}
                                                                            >
                                                                                {data.treatmentName}
                                                                            </span>{" "}
                                                                            at the time of AVA initiation on{" "}
                                                                            <span
                                                                                style={{
                                                                                    color: PageDesignConstants.fontColor,
                                                                                }}
                                                                            >
                                                                                {indexdate}
                                                                            </span>
                                                                            ?
                                                                        </span>
                                                                    </div>
                                                                    <div className="pad-left-treatment">
                                                                        <div className="sub-question-bot-sapce">
                                                                            <div className="answer-list-text sub-indent-option">

                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={1}

                                                                                    name={"q29_ritux_immuno_and_ava-" + index}
                                                                                    checked={
                                                                                        data.q29_ritux_immuno_and_ava === "1" || data.q29_ritux_immuno_and_ava === 1
                                                                                    }
                                                                                    onChange={
                                                                                        handleQ29CheckboxChange
                                                                                    }
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Yes
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text sub-indent-option">

                                                                                <input
                                                                                    type="radio"
                                                                                    id={index}
                                                                                    value={0}

                                                                                    name={"q29_ritux_immuno_and_ava-" + index}
                                                                                    checked={
                                                                                        data.q29_ritux_immuno_and_ava === "0" || data.q29_ritux_immuno_and_ava === 0
                                                                                    }
                                                                                    onChange={
                                                                                        handleQ29CheckboxChange
                                                                                    }
                                                                                />
                                                                                <span className="radio-text-padding">No</span>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorField
                                                                            show={data.q29Empty}
                                                                            message={(data.treatmentName=="Rituximab"?TreatmentHistory_Msg.Q29Rituximab:TreatmentHistory_Msg.Q29Immunosuppressants)}
                                                                        />


                                                                    </div>
                                                                </div>);
                                                        })
                                                    ) : (<></>)}



                                                    {/* Q 30 */}

                                                    <div className="dependent-section mt-2">
                                                        <div className="answer-list-text question-weight">
                                                            <span>30.</span>
                                                            <span className="radio-text-padding">
                                                                Has the patient ever received a splenectomy
                                                                prior to initiation of AVA (i.e., before{" "}
                                                                <span
                                                                    style={{
                                                                        color: PageDesignConstants.fontColor,
                                                                    }}
                                                                >
                                                                    {indexdate}
                                                                </span>
                                                                )?
                                                            </span>
                                                        </div>
                                                        <div className="pad-left-treatment">
                                                            <div className="sub-question-bot-sapce">
                                                                <div className="answer-list-text sub-indent-option">
                                                                    <input
                                                                        type="radio"
                                                                        name="q30_pre_ava_splenectomy"
                                                                        checked={thDetails.q30_pre_ava_splenectomy === "1" || thDetails.q30_pre_ava_splenectomy === 1}
                                                                        value={1}
                                                                        onChange={HandleChangeq30}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        Yes
                                                                    </span>
                                                                </div>
                                                                {(thDetails.q30_pre_ava_splenectomy === "1" || thDetails.q30_pre_ava_splenectomy === 1) && (

                                                                    <div className="container">
                                                                        <div className="dependent-sub-section">
                                                                            <div className="">
                                                                                <div className="sub-question-bot-sapce">
                                                                                    <div className="question-weight">
                                                                                        <p>Date of splenectomy</p>
                                                                                    </div>
                                                                                    <div className="">
                                                                                        <div>
                                                                                            <span>
                                                                                                If only month and year are known,
                                                                                                please enter “15” for the day.
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="answer-list-text">


                                                                                            <DateControl
                                                                                                ctrlId={
                                                                                                    "q30_pre_ava_splen_dt"
                                                                                                }
                                                                                                HandleDateChange={HandleDateChange}
                                                                                                optionId={"q30_pre_ava_splen_dt"}
                                                                                                defDate={thDetails.defDateQ30}
                                                                                                date={thDetails.q30_pre_ava_splen_dt}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="answer-list-text">



                                                                                        <input
                                                                                            type="radio"
                                                                                            id={"q30_pre_ava_splen_dt_unk"}
                                                                                            value={1}
                                                                                            name={"q30_pre_ava_splen_dt_unk"}
                                                                                            checked={
                                                                                                thDetails.q30_pre_ava_splen_dt_unk ===
                                                                                                "1" || thDetails.q30_pre_ava_splen_dt_unk ===
                                                                                                1
                                                                                            }
                                                                                            onChange={HandleChangeSplenectomyDisctunknwn} />




                                                                                        <span className="radio-text-padding">
                                                                                            Date of splenectomy prior to AVA
                                                                                            initiation is unknown
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <ErrorField
                                                                                    show={thDetails.q30_NotSelected ? true : thDetails.q30lessindexdate}
                                                                                    message={thDetails.q30_NotSelected ? TreatmentHistory_Msg.Q30a : TreatmentHistory_Msg.Q30}
                                                                                />

                                                                            </div>
                                                                        </div>

                                                                    </div>)}

                                                                <div className="answer-list-text sub-indent-option">
                                                                    <input
                                                                        type="radio"
                                                                        name="q30_pre_ava_splenectomy"
                                                                        checked={
                                                                            thDetails.q30_pre_ava_splenectomy === "0" || thDetails.q30_pre_ava_splenectomy === 0
                                                                        }
                                                                        value={0}
                                                                        onChange={HandleChangeq30}
                                                                    />
                                                                    <span className="radio-text-padding">No</span>
                                                                </div>
                                                                <ErrorField
                                                                    show={thDetails.q30Empty ? true : thDetails.q30Empty}
                                                                    message={TreatmentHistory_Msg.Q30}
                                                                />
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={onsaveclick} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TreatmentHistory;
