import WebServiceWrapper from "./WebServiceWrapper";

export const TreatmentService = {
    GetAllTreatment,
    SaveAllTreatment
};

function GetAllTreatment(patientid) {
  return WebServiceWrapper.Get(`/treatment/GetAllTreatmentDetails?patientid=${patientid}`);
}

function SaveAllTreatment(data) {
    return WebServiceWrapper.Post(`/treatment/SaveAllTreatmentDetails`,data);//data
}

