import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import SideBarMenu from "../../Components/SideBarMenu";
import {
    HTTPResponse,
    PageDesignConstants,
    SideMenuItems,
    ToastMessageType,
    ToastMessages,
} from "../../Helpers/Enums";
import { CommonError_Msgs, AvaTreatment_Msgs } from "../../Helpers/HelperText";
import DateControl from "../../Components/DateControl";
import ErrorField from "../../Components/ErrorField";
import {
    GetLocalStorageData,
    SubtractMonths,IsDateAfterToday,
    GetLoggedInUserID,
} from "../../Helpers/Utilities";
import { AvatreatmentService } from "../../WebApiServices/Avatreatment.service";
import { useNavigate, useParams } from "react-router";
import { AppContext } from "../../Contexts/AppContextProvider";
const NUMBER_REGEX = /^[0-9]{0,9}$/;
const NUMBER_PLATELETREGEX=/^[0-9]*(\.[0-9]{0,2})?$/;
const Questionnaire = () => {
    const navigatePaths = {
        prevPage: "/TreatmentHistory",
        currPage: "/Questionnaire",
        nextPage: "/TreatmentAfterAVA",
    };
    const navigate = useNavigate();
    const {ToggleLoader, ShowToast, HandleSessionTimeout}=useContext(AppContext);
    const [avaDetails, setavaDetails] = useState({
        avatreatmentid: 0,
        ava_first_line: "",
        ava_any_reason_1: "",
        ava_any_reason_2: "",
        ava_any_reason_3: "",
        ava_any_reason_4: "",
        ava_any_reason_5: "",
        ava_any_reason_6: "",
        ava_any_reason_7: "",
        ava_any_reason_oth: "",
        ava_any_reason_oth_txt: "",
        ava_any_reason_unk: "",
        prim_reason_ava: "",
        prim_reason_ava_oth_txt: "",
        ava_start_dosage: "",
        ava_start_num: "",
        ava_start_freq: "",
        ava_dose_change: "",
        pt_ava_disc: "",
        ava_disc_dt: "",
        ava_disc_dt_unk: "",
        ava_disc_reason: "",
        ava_disc_pt_pref_oth_txt: "",
        ava_disc_oth_txt: "",
        q31Empty: false,
        q32Empty: false,
        q32Empty_OtherText:false,
        q32primaryEmpty: false,
        q33Empty: false,
        q34Empty: false,
        q33DoseInput:false,
        q33FreqInput:false,
        q35Empty: false,
        q35notselected: false,
        q35lessindexdt: false,
        q35futuredate:false,
        q35beforedosedate:false,
        Q35beforeFollowEndDate:false,
        q36Empty: false,
        q36txtEmpty: false,
        q37Empty: false,
        pt_ava_transfusionukn:false,
        q38Empty:false,
        q38UknSelected: false

    });
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);
    const pid = GetLocalStorageData("patientId") ?? 0;
    const [indexdate, setindexdate] = useState("");
    const [endfollowupdate, setendfollowupdate] = useState("");
    const [avaDosageDetails, setavaDosageDetails] = useState([
        {
            avadosageid: 0,
            ava_change_dt_a1: "",
            ava_change_dt_a1_unk: "",
            ava_new_dose_b1: "",
            ava_new_num_c1: "",
            ava_new_freq_c1: "",
            ava_change_reason_d1: "",
            ava_change_reason_d1_oth_txt: "",
            defDateQ34: false,
            q34datechange: false,
            q34newdosage: false,
            q34newfreq: false,
            q34DoseInput:false,
            q34FreqInput:false,
            q34changereason: false,
            q34dosagelessindexdt: false,
            q34futuredate:false,
            q34othertxt: false,
            q34dosageAfterFollowEndDate:false,
        },
    ]);
    const initialRows = Array.from({ length: 10 }, (_, index) => ({
        plateletcountid: 0,
        id: index + 1,
        pc_dt: "",
        pc_value: "",
        q37plateletlessindexdt: false,
        q37plateletmoreendfollowup: false,
        q37plateletInput:false,
        q37EmptyPlatelet:false,
        q37EmptyDate:false,
    }));

    const TransfusioninitialRows = Array.from({ length: 10 }, (_, index) => ({
        transfusioncountid: 0,
        id: index + 1,
        transfusion_dt_n: "",
        q38plateletlessindexdt: false,
        q38plateletmoreendfollowup: false,
    }));

    const [q37plateletdt, setq37plateletdt] = useState(false);
    const [q37plateletvalue, setq37plateletvalue] = useState(false);
    const [q38transfusiondt, setq38transfusiondt] = useState(false);

   
    const [avaplateletcountDetails, setavaplateletcountDetails] =
        useState(initialRows);
        const [avatransfusioncountDetails, setavatransfusioncountDetails] =
        useState(TransfusioninitialRows);
      
    const [frequencytime, setfrequencytime] = useState([]);
    const [defDateQ35, setDefDateQ35] = useState();
    const [navigateclick, setnavigateclick] = useState(false);
    const [index3months, setindex3months] = useState("");
    const [mindt, setmindt] = useState("");

    function onAVAfirstlineChange(value) {
        let checked = avaDetails.prim_reason_ava;
        if (value === 1)
            setavaDetails({
                ...avaDetails,
                ava_first_line: value,
                ava_any_reason_1: "",
                prim_reason_ava: checked === "1" ? "" : checked,
                q31Empty: false,
            });
        else
            setavaDetails({
                ...avaDetails,
                ava_first_line: value,
                ava_any_reason_7: "",
                prim_reason_ava: checked === "7" ? "" : checked,
                q31Empty: false,
            });
    }
    function onAVAprimereasoneChange(e) {
         
        let data = avaDetails;
        setavaDetails({
            ...avaDetails,
            prim_reason_ava: e.target.value,
            q32primaryEmpty: false,
        });
    }
    function HandleothtxtChange(e) {
         
        setavaDetails({ ...avaDetails, ava_any_reason_oth_txt: e.target.value,q32Empty_OtherText:false });
    }
    function onAVAstartdosageChange(e) {
         
        let data = avaDetails;

        if (!NUMBER_REGEX.test(e.target.value)) {
            return;
        }
        if (e.target.id === "ava_start_dosage") {
             
            setavaDetails({
                ...avaDetails,
                [e.target.id]: e.target.value,
                q33Empty:
                    data.q33Empty &&
                        (data.ava_start_num === "" || data.ava_start_freq === "" || data.ava_start_freq === 0)
                        ? true
                        : false,
                        q33DoseInput:e.target.value==""?false :(e.target.value!=="" && (e.target.value < 1501 && e.target.value > 0 )? false : true),
                    });
 } else {
            setavaDetails({
                ...avaDetails,
                [e.target.id]: e.target.value,
                q33Empty:
                    data.q33Empty &&
                        (data.ava_start_dosage === "" || data.ava_start_freq === "" || data.ava_start_freq === 0)
                        ? true
                        : false,
                        q33FreqInput:e.target.value==""?false :(e.target.value!=="" && (e.target.value < 1501 && e.target.value > 0 )? false : true),


            });

        }
    }

    const HandleFieldChange = (e) => {
      
        let data = avaDetails;
        const { id, value } = e.target;
        switch (id) {
            case "ava_any_reason_1":
            case "ava_any_reason_2":
            case "ava_any_reason_3":
            case "ava_any_reason_4":
            case "ava_any_reason_5":
            case "ava_any_reason_6":
            case "ava_any_reason_7":
            case "ava_any_reason_oth":
            case "ava_any_reason_unk": {
                
                if (avaDetails[id] != "") {
                    setavaDetails((prev) => ({
                        ...prev,
                        [id]: "",
                        q32Empty_OtherText:false,
                        ava_any_reason_oth_txt:([id]=="ava_any_reason_oth")
                        ? "":((prev.ava_any_reason_oth=="1" || prev.ava_any_reason_oth==1)?
                        prev.ava_any_reason_oth_txt:""),
                        prim_reason_ava: ([id]=="ava_any_reason_1" && (avaDetails.prim_reason_ava=="1" ||avaDetails.prim_reason_ava==1))?"":
                                         (([id]=="ava_any_reason_2" && (avaDetails.prim_reason_ava=="2" ||avaDetails.prim_reason_ava==2))?"":
                                         (([id]=="ava_any_reason_3" && (avaDetails.prim_reason_ava=="3" ||avaDetails.prim_reason_ava==3))?"":
                                         (([id]=="ava_any_reason_4" && (avaDetails.prim_reason_ava=="4" ||avaDetails.prim_reason_ava==4))?"":
                                         (([id]=="ava_any_reason_5" && (avaDetails.prim_reason_ava=="5" ||avaDetails.prim_reason_ava==5))?"":
                                         (([id]=="ava_any_reason_6" && (avaDetails.prim_reason_ava=="6" ||avaDetails.prim_reason_ava==6))?"":
                                         (([id]=="ava_any_reason_7" && (avaDetails.prim_reason_ava=="7" ||avaDetails.prim_reason_ava==7))?"":
                                         (([id]=="ava_any_reason_oth" && (avaDetails.prim_reason_ava=="8" ||avaDetails.prim_reason_ava==8))?"":
                                         (([id]=="ava_any_reason_unk" && (avaDetails.prim_reason_ava=="99" ||avaDetails.prim_reason_ava==99))?"":avaDetails.prim_reason_ava))))))))


                    }));
                } else {
                    setavaDetails((prev) => ({
                        ...prev,
                        [id]: e.target.value,
                        q32Empty: false,
                        q32Empty_OtherText: ([id]=="ava_any_reason_oth")
                        ?  (prev.ava_any_reason_oth_txt===""?true:false)
                        :((prev.ava_any_reason_oth=="1" || prev.ava_any_reason_oth==1)
                            ?(prev.ava_any_reason_oth_txt===""?true:false)
                            :false),
                    }));

                }
                break;
            }
            default:
                break;
        }
    };
    function formatInput(e) {
        let checkIfNum;
        if (e.key !== undefined) {
            checkIfNum = e.key === "e" || e.key === "-" || e.key == "E";
        } else if (e.keyCode !== undefined) {
            checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    }
    useEffect(() => {
    
        async function FetchData() {
          if (!isNaN(pid) && Number(pid) !== 0) {
            
            ToggleLoader(true);
            await GettreatmentData();
            ToggleLoader(false);
          }
        }
         FetchData();

        // GettreatmentData();
    }, []);
    function validate() {
         
        let indexDateValue = indexdate != "" ? new Date(indexdate) : new Date();
        let endFollowUpDate =
            endfollowupdate != "" ? new Date(endfollowupdate) : new Date();
        let q35lessindexdt = false;
        let q35futuredate=false;
        let q31Empty = avaDetails.ava_first_line === "" ? true : false;
        let q32Empty =
            avaDetails.ava_any_reason_1 === "" &&
                avaDetails.ava_any_reason_2 === "" &&
                avaDetails.ava_any_reason_3 === "" &&
                avaDetails.ava_any_reason_4 === "" &&
                avaDetails.ava_any_reason_5 === "" &&
                avaDetails.ava_any_reason_6 === "" &&
                avaDetails.ava_any_reason_7 === "" &&
                avaDetails.ava_any_reason_oth === "" &&
                avaDetails.ava_any_reason_unk === ""
                // &&
                //avaDetails.prim_reason_ava === ""
                ? true
                : false;
        let q32primaryEmpty = (avaDetails.ava_any_reason_1 != "" ||
            avaDetails.ava_any_reason_2 != "" ||
            avaDetails.ava_any_reason_3 != "" ||
            avaDetails.ava_any_reason_4 != "" ||
            avaDetails.ava_any_reason_5 != "" ||
            avaDetails.ava_any_reason_6 != "" ||
            avaDetails.ava_any_reason_7 != "" ||
            avaDetails.ava_any_reason_oth != "" ||
            avaDetails.ava_any_reason_unk != "") &&
            avaDetails.prim_reason_ava === "";
        let q33Empty =
            avaDetails.ava_start_dosage === "" ||
                avaDetails.ava_start_num === "" ||
                avaDetails.ava_start_freq === "" || avaDetails.ava_start_freq === 0
                ? true
                : false;
        let q33DoseInput=false;
        let q33FreqInput=false;
        if (avaDetails.ava_start_dosage !== "")
         q33DoseInput = avaDetails.ava_start_dosage < 1501 && avaDetails.ava_start_dosage > 0 ? false : true;

        if (avaDetails.ava_start_num !== "")
        q33FreqInput = avaDetails.ava_start_num < 1501 && avaDetails.ava_start_num > 0 ? false : true;


        let q34Empty = avaDetails.ava_dose_change === "" ? true : false;
        let q35Empty = avaDetails.pt_ava_disc === "" ? true : false;
        avaDetails.q35beforedosedate=false;
        avaDetails.Q35beforeFollowEndDate=false;
        let q35notselected =
            (avaDetails.pt_ava_disc === "1" || avaDetails.pt_ava_disc === 1) &&
                avaDetails.ava_disc_dt === "" 
                ? true
                : false;
        let q36Empty =
            (avaDetails.pt_ava_disc === "1" || avaDetails.pt_ava_disc === 1) &&
                avaDetails.ava_disc_reason === ""
                ? true
                : false;
        let q36txtEmpty =
            (avaDetails.pt_ava_disc === "1" || avaDetails.pt_ava_disc === 1) && ((avaDetails.ava_disc_reason === "5" && avaDetails.ava_disc_pt_pref_oth_txt === "") || (avaDetails.ava_disc_reason === "6" && avaDetails.ava_disc_oth_txt === ""))
                ? true
                : false;
        if (avaDetails.ava_disc_dt != "") {
            let discdate = new Date(avaDetails.ava_disc_dt);
            if (discdate <= indexDateValue) {
                q35lessindexdt = true;
            }

            if (IsDateAfterToday(discdate)) {
                q35futuredate=true;
              }
        }
        let hasError = false;
        let dosagearray = avaDosageDetails;
        if (
            avaDetails.ava_dose_change === "1" ||
            avaDetails.ava_dose_change === 1
        ) {
            for (let i = 0; i < avaDosageDetails.length; i++) {
                 
                dosagearray[i].q34dosageAfterFollowEndDate=false;
                dosagearray[i].q34datechange =
                    avaDosageDetails[i].ava_change_dt_a1 === "" &&
                    avaDosageDetails[i].ava_change_dt_a1_unk === "";
                dosagearray[i].q34newdosage =
                    avaDosageDetails[i].ava_new_dose_b1 === "";
                dosagearray[i].q34newfreq =
                    avaDosageDetails[i].ava_new_freq_c1 === "" ||
                    avaDosageDetails[i].ava_new_num_c1 === "" || avaDosageDetails[i].ava_new_freq_c1 === 0;
                dosagearray[i].q34changereason =
                    avaDosageDetails[i].ava_change_reason_d1 === "";
                dosagearray[i].q34othertxt =
                    avaDosageDetails[i].ava_change_reason_d1 === "3" &&
                    avaDosageDetails[i].ava_change_reason_d1_oth_txt === "";
                if (avaDosageDetails[i].ava_change_dt_a1 != "") {
                    let dosageDate = new Date(avaDosageDetails[i].ava_change_dt_a1);
                    if (dosageDate <= indexDateValue) {
                        dosagearray[i].q34dosagelessindexdt = true;
                    }

                    if (IsDateAfterToday(dosageDate)) {
                        dosagearray[i].q34futuredate=true;
                      }
                }
                if (avaDosageDetails[i].ava_new_dose_b1 !== "")
                dosagearray[i].q34DoseInput = avaDosageDetails[i].ava_new_dose_b1 < 1501 && avaDosageDetails[i].ava_new_dose_b1 > 0 ? false : true;

            if (avaDosageDetails[i].ava_new_num_c1 !== "")
            dosagearray[i].q34FreqInput = avaDosageDetails[i].ava_new_num_c1 < 1501 && avaDosageDetails[i].ava_new_num_c1 > 0 ? false : true;

    

                if (
                    dosagearray[i].q34datechange ||
                    dosagearray[i].q34newdosage ||
                    dosagearray[i].q34newfreq ||
                    dosagearray[i].q34changereason ||
                    dosagearray[i].q34othertxt ||
                    dosagearray[i].q34dosagelessindexdt ||
                    dosagearray[i].q34FreqInput||
                    dosagearray[i].q34DoseInput||
                    dosagearray[i].q34futuredate
                )
                    hasError = true;
            }
            setavaDosageDetails(dosagearray);
        }
        let hasPlateletError = false;
        let hasTransfusionError=false;
        let plateletdata = avaplateletcountDetails;
        let transfusiondata= avatransfusioncountDetails;
        let q38Empty=avaDetails.pt_ava_transfusionukn===false && avatransfusioncountDetails.length===0?true:false;
        let comparedt = new Date();
        const parsedDate1 = new Date(endFollowUpDate);
        const parsedDate2 =
            avaDetails.ava_disc_dt != "" ? new Date(avaDetails.ava_disc_dt) : "";
        if (parsedDate2 != "") {
            comparedt = parsedDate1 < parsedDate2 ? parsedDate1 : parsedDate2;
        } else {
            comparedt = parsedDate1;
        }
        for (let i = 0; i < avaplateletcountDetails.length; i++) {
            
            if (i == 0) {
                if (plateletdata[i].pc_dt === "") setq37plateletdt(true);
                if (plateletdata[i].pc_value === "") setq37plateletvalue(true);
            }

            if( avaplateletcountDetails[i].pc_value != "")
            {
                plateletdata[i].q37plateletInput=avaplateletcountDetails[i].pc_value <= 2000 && avaplateletcountDetails[i].pc_value >= 0 ? false : true;
            
                if(plateletdata[i].pc_dt === "")
                    plateletdata[i].q37EmptyDate=true;
                else
                    plateletdata[i].q37EmptyDate=false;
            }

            if( avaplateletcountDetails[i].pc_dt != "")
            {
                if(plateletdata[i].pc_value === "")
                    plateletdata[i].q37EmptyPlatelet=true;
                else
                    plateletdata[i].q37EmptyPlatelet=false;
            }
            if (
                avaplateletcountDetails[i].pc_dt != "" &&
                avaplateletcountDetails[i].pc_value != ""
            ) {
                let plateletDate = new Date(avaplateletcountDetails[i].pc_dt);
                plateletdata[i].q37plateletlessindexdt =
                    plateletDate < new Date(index3months) ? true : false;
                plateletdata[i].q37plateletmoreendfollowup =
                    plateletDate > comparedt ? true : false;
            }
            if (
                (i === 0 &&
                    (plateletdata[i].pc_dt == "" || plateletdata[i].pc_dt == null) &&
                    (plateletdata[i].pc_value === "" || plateletdata[i].pc_value === null)) ||
                plateletdata[i].q37plateletlessindexdt ||
                plateletdata[i].q37plateletmoreendfollowup ||plateletdata[i].q37plateletInput
                || plateletdata[i].q37EmptyPlatelet || plateletdata[i].q37EmptyDate
            )
                hasPlateletError = true;
        }
        setavaplateletcountDetails(plateletdata);


if(avaDetails.pt_ava_transfusionukn===false){
        for (let i = 0; i < avatransfusioncountDetails.length; i++) {
             debugger;
            if (i == 0) {
                if (transfusiondata[i].transfusion_dt_n === "") setq38transfusiondt(true);
            }

            if (
                avatransfusioncountDetails[i].transfusion_dt_n != "" 
            ) {
                let transfusionDate = new Date(transfusiondata[i].transfusion_dt_n);
                transfusiondata[i].q38plateletlessindexdt =
                transfusionDate < new Date(index3months) ? true : false;
                transfusiondata[i].q38plateletmoreendfollowup =
                transfusionDate > comparedt ? true : false;
            }
            if (
                (i === 0 &&
                    (transfusiondata[i].transfusion_dt_n == "" || transfusiondata[i].transfusion_dt_n == null) ) ||
                    transfusiondata[i].q38plateletlessindexdt ||
                    transfusiondata[i].q38plateletmoreendfollowup 
                
            )
            hasTransfusionError = true;
        }
        setavatransfusioncountDetails(transfusiondata);
    }
        setavaDetails({
            ...avaDetails,
            q31Empty: q31Empty,
            q32Empty: q32Empty,
            q32primaryEmpty: q32primaryEmpty,
            q32Empty_OtherText:false,
            q33Empty: q33Empty,
            q34Empty: q34Empty,
            q35Empty: q35Empty,
            q35notselected: q35notselected,
            q36Empty: q36Empty,
            q36txtEmpty: q36txtEmpty,
            q35lessindexdt: q35lessindexdt,
            q38Empty:q38Empty,
            q35futuredate:q35futuredate,
            q38UknSelected:false,
        });
       
        if (
            q31Empty ||
            q32Empty ||
            q32primaryEmpty ||
            q33Empty ||
            q33DoseInput||
            q33FreqInput||
            q34Empty ||
            q35Empty ||
            q35notselected ||
            q36Empty ||
            q36txtEmpty ||
            hasError ||
            q35lessindexdt ||
            q35futuredate ||
            hasPlateletError ||
            hasTransfusionError||
            q38Empty
         
        ) {
            return true;
        }
        else {
            return false;
        }
    }
    const GettreatmentData = async () => {
        try {
             
            const response = await AvatreatmentService.GetAvatreatmentData(pid);
            if (response?.status !== HTTPResponse.OK) {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    throw response.error;
            }else{
             
                setfrequencytime(response.data.frequencytime);
                setindexdate(response.data.indexDate);
                setendfollowupdate(response.data.endFollowUpDate);
                if (response.data.indexDate != null && response.data.indexDate != "") {
                    let index_3months = SubtractMonths(new Date(response.data.indexDate), 3);
                    // Get the month, day, and year
                    const month = (index_3months.getMonth() + 1).toString().padStart(2, '0'); // months are zero-based
                    const day = index_3months.getDate().toString().padStart(2, '0');
                    const year = index_3months.getFullYear();

                    // Format the date in mm/dd/yyyy format
                    const formattedDate = `${month}/${day}/${year}`;
                    setindex3months(formattedDate);
                }


                let comparedt = "";
                const parsedDate1 = new Date(response.data.endFollowUpDate);
                const parsedDate2 =
                    response.data.avatreatmentDetails.avaDiscDt != "" && response.data.avatreatmentDetails.avaDiscDt != null ? new Date(response.data.avatreatmentDetails.avaDiscDt)
                        : "";
                if (parsedDate2 != "") {
                    comparedt = parsedDate1 < parsedDate2 ? response.data.endFollowUpDate : response.data.avatreatmentDetails.avaDiscDt;
                } else {
                    comparedt = response.data.endFollowUpDate;
                }
                setmindt(comparedt);
                if (response.data.avatreatmentDetails != null) {
                    setavaDetails({
                        ...avaDetails,
                        avatreatmentid:
                            response.data.avatreatmentDetails.avatreatmentid != null
                                ? response.data.avatreatmentDetails.avatreatmentid
                                : 0,
                        ava_first_line:
                            response.data.avatreatmentDetails.avaFirstLine != null
                                ? response.data.avatreatmentDetails.avaFirstLine
                                : "",
                        ava_any_reason_1:
                            response.data.avatreatmentDetails.avaAnyReason1 === true ? "1" : "",
                        ava_any_reason_2:
                            response.data.avatreatmentDetails.avaAnyReason2 === true ? "1" : "",
                        ava_any_reason_3:
                            response.data.avatreatmentDetails.avaAnyReason3 === true ? "1" : "",
                        ava_any_reason_4:
                            response.data.avatreatmentDetails.avaAnyReason4 === true ? "1" : "",
                        ava_any_reason_5:
                            response.data.avatreatmentDetails.avaAnyReason5 === true ? "1" : "",
                        ava_any_reason_6:
                            response.data.avatreatmentDetails.avaAnyReason6 === true ? "1" : "",
                        ava_any_reason_7:
                            response.data.avatreatmentDetails.avaAnyReason7 === true ? "1" : "",
                        ava_any_reason_oth:
                            response.data.avatreatmentDetails.avaAnyReasonOth === true
                                ? "1"
                                : "",
                        ava_any_reason_oth_txt:
                            response.data.avatreatmentDetails.avaAnyReasonOthTxt != null
                                ? response.data.avatreatmentDetails.avaAnyReasonOthTxt
                                : "",
                        ava_any_reason_unk:
                            response.data.avatreatmentDetails.avaAnyReasonUnk === true
                                ? "1"
                                : "",
                        prim_reason_ava:
                            response.data.avatreatmentDetails.primReasonAva != null
                                ? response.data.avatreatmentDetails.primReasonAva
                                : "",
                        prim_reason_ava_oth_txt:
                            response.data.avatreatmentDetails.primReasonAvaOthTxt != null
                                ? response.data.avatreatmentDetails.primReasonAvaOthTxt
                                : "",
                        ava_start_dosage:
                            response.data.avatreatmentDetails.avaStartDosage != null
                                ? response.data.avatreatmentDetails.avaStartDosage
                                : "",
                        ava_start_num:
                            response.data.avatreatmentDetails.avaStartNum != null
                                ? response.data.avatreatmentDetails.avaStartNum
                                : "",
                        ava_start_freq:
                            response.data.avatreatmentDetails.avaStartFreq != null
                                ? response.data.avatreatmentDetails.avaStartFreq
                                : "",
                        ava_dose_change:
                            response.data.avatreatmentDetails.avaDoseChange != null
                                ? response.data.avatreatmentDetails.avaDoseChange
                                : "",
                        pt_ava_disc:
                            response.data.avatreatmentDetails.ptAvaDisc != null
                                ? response.data.avatreatmentDetails.ptAvaDisc
                                : "",
                        ava_disc_dt:
                            response.data.avatreatmentDetails.avaDiscDt != null
                                ? response.data.avatreatmentDetails.avaDiscDt
                                : "",
                        
                        ava_disc_reason:
                            response.data.avatreatmentDetails.avaDiscReason != null
                                ? response.data.avatreatmentDetails.avaDiscReason
                                : "",
                        ava_disc_pt_pref_oth_txt:
                            response.data.avatreatmentDetails.avaDiscPtPrefOthTxt != null
                                ? response.data.avatreatmentDetails.avaDiscPtPrefOthTxt
                                : "",
                        ava_disc_oth_txt:
                            response.data.avatreatmentDetails.avaDiscOthTxt != null
                                ? response.data.avatreatmentDetails.avaDiscOthTxt
                                : "",
                        defDateQ35:
                            response.data.avatreatmentDetails.avaDiscDtUnk === true
                                ? true
                                : false,
                        pt_ava_transfusionukn:
                            response.data.avatreatmentDetails.ptAvaTransfusionUkn!=null
                            ? response.data.avatreatmentDetails.ptAvaTransfusionUkn
                            : false,
                        q31Empty: false,
                        q31lessindexdt: false,
                        q32Empty: false,
                        q32Empty_OtherText:false,
                        q32primaryEmpty: false,
                        q33Empty: false,
                        q33DoseInput:false,
                        q33FreqInput:false,
                        q34Empty: false,
                        q35Empty: false,
                        q35notselected: false,
                        q35lessindexdt: false,
                        q35futuredate:false,
                        q35beforedosedate:false,
                        Q35beforeFollowEndDate:false,
                        q36Empty: false,
                        q36txtEmpty: false,
                        q37Empty: false,
                        q38Empty:false,
                        q38UknSelected:false,
                    });
                }
                if (
                    response.data.avAdosageDetails != null &&
                    response.data.avAdosageDetails.length > 0
                ) {
                    let i = 0;
                    let dosagearray = [];
                    for (i; i < response.data.avAdosageDetails.length; i++) {
                        let dosagecomponent = {
                            avadosageid:
                                response.data.avAdosageDetails[i].avadosageid != null
                                    ? response.data.avAdosageDetails[i].avadosageid
                                    : 0,
                            ava_change_dt_a1:
                                response.data.avAdosageDetails[i].avaChangeDtA1 != null
                                    ? response.data.avAdosageDetails[i].avaChangeDtA1
                                    : "",
                            ava_change_dt_a1_unk:
                                response.data.avAdosageDetails[i].avaChangeDtA1Unk === true
                                    ? "99"
                                    : "",
                            ava_new_dose_b1:
                                response.data.avAdosageDetails[i].avaNewDoseB1 != null
                                    ? response.data.avAdosageDetails[i].avaNewDoseB1
                                    : "",
                            ava_new_num_c1:
                                response.data.avAdosageDetails[i].avaNewNumC1 != null
                                    ? response.data.avAdosageDetails[i].avaNewNumC1
                                    : "",
                            ava_new_freq_c1:
                                response.data.avAdosageDetails[i].avaNewFreqC1 != null
                                    ? response.data.avAdosageDetails[i].avaNewFreqC1
                                    : "",
                            ava_change_reason_d1:
                                response.data.avAdosageDetails[i].avaChangeReasonD1 != null
                                    ? response.data.avAdosageDetails[i].avaChangeReasonD1
                                    : "",
                            ava_change_reason_d1_oth_txt:
                                response.data.avAdosageDetails[i].avaChangeReasonD1OthTxt != null
                                    ? response.data.avAdosageDetails[i].avaChangeReasonD1OthTxt
                                    : "",
                            defDateQ34:
                                response.data.avAdosageDetails[i].avaChangeDtA1Unk === true
                                    ? true
                                    : false,
                            q34datechange: false,
                            q34newdosage: false,
                            q34DoseInput:false,
                            q34FreqInput:false,
                            q34newfreq: false,
                            q34changereason: false,
                            q34dosagelessindexdt: false,
                            q34dosageAfterFollowEndDate:false,
                            q34futuredate:false,
                            q34othertxt: false,
                        };
                        dosagearray.push(dosagecomponent);
                    }
                    setavaDosageDetails(dosagearray);
                }

                if (
                    response.data.avaplateletcountDetails != null &&
                    response.data.avaplateletcountDetails.length > 0
                ) {
                    
                    let i = 0;
                    let plateletarray = [];
                    for (i; i < response.data.avaplateletcountDetails.length; i++) {                  
                        let plateletcomponent = {
                            plateletcountid:
                                response.data.avaplateletcountDetails[i].plateletcountid != null
                                    ? response.data.avaplateletcountDetails[i].plateletcountid
                                    : 0,
                            id: i + 1,
                            pc_dt:
                                response.data.avaplateletcountDetails[i].pcDt != null
                                    ? response.data.avaplateletcountDetails[i].pcDt
                                    : "",
                            pc_value:
                                response.data.avaplateletcountDetails[i].pcValue != null
                                    ? response.data.avaplateletcountDetails[i].pcValue
                                    : "",
                        };
                        plateletarray.push(plateletcomponent);
                    }

                    

                    // let plateletlength = 10 - response.data.avaplateletcountDetails.length;

                    // const initialRows = Array.from(
                    //     { length: plateletlength },
                    //     (_, index) => ({
                    //         plateletcountid: 0,
                    //         id: response.data.avaplateletcountDetails.length + index + 1,
                    //         pc_dt: "",
                    //         pc_value: "",
                    //     })
                    // );
                    // let newarray = plateletarray.concat(initialRows);
                    setavaplateletcountDetails(plateletarray);
                }
                else{
                    setq37plateletdt(false);
                    setq37plateletvalue(false);
                }

                if (
                    response.data.avatransfusioncountDetails != null &&
                    response.data.avatransfusioncountDetails.length > 0
                ) {
                
                    let i = 0;
                    let transfusionarray = [];
                    for (i; i < response.data.avatransfusioncountDetails.length; i++) {               
                        let transfusioncomponent = {
                            transfusioncountid:
                                response.data.avatransfusioncountDetails[i].transfusioncountid != null
                                    ? response.data.avatransfusioncountDetails[i].transfusioncountid
                                    : 0,
                            id: i + 1,
                            transfusion_dt_n:
                                response.data.avatransfusioncountDetails[i].transfusionDt != null
                                    ? response.data.avatransfusioncountDetails[i].transfusionDt
                                    : "",
                            
                        };
                        transfusionarray.push(transfusioncomponent);
                    }
                    setavatransfusioncountDetails(transfusionarray);
                }
                else if (response.data.avatransfusioncountDetails.length==0)
                {
                    setq38transfusiondt(false);
                    let plateletlength = 10 - response.data.avatransfusioncountDetails.length;

                    const initialRows = Array.from(
                        { length: plateletlength },
                        (_, index) => ({
                            transfusioncountid: 0,
                            id: response.data.avatransfusioncountDetails.length + index + 1,
                            transfusion_dt_n: "",
                        })
                    );
                    //let newarray = plateletarray.concat(initialRows);
                    setavatransfusioncountDetails(initialRows);

                }
            }
        } catch {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    };
    function HandlechangeAVAdetails(e) {

        let data = avaDetails;
 if (e.target.name === "ava_dose_change" && e.target.value === "0") {
            setavaDetails({
                ...avaDetails,
                [e.target.name]: e.target.value,
                q34Empty: false,
                q35beforedosedate:false,
                Q35beforeFollowEndDate:false,
            });
            setDefDateQ35(true);
            setavaDosageDetails([
                {
                    ava_change_dt_a1: "",
                    ava_change_dt_a1_unk: "",
                    ava_new_dose_b1: "",
                    ava_new_num_c1: "",
                    ava_new_freq_c1: "",
                    ava_change_reason_d1: "",
                    ava_change_reason_d1_oth_txt: "",
                    defDateQ34: false,
                    q34datechange: false,
                    q34newdosage: false,
                    q34DoseInput:false,
                    q34FreqInput:false,
                    q34newfreq: false,
                    q34changereason: false,
                    q34dosagelessindexdt: false,
                    q34dosageAfterFollowEndDate:false,
                    q34futuredate:false,
                    q34othertxt: false,
                },
            ]);
        } else if (e.target.name === "pt_ava_disc") {
            setavaDetails({
                ...avaDetails,
                [e.target.name]: e.target.value,
                defDateQ35: true,
                ava_disc_dt: "",
                ava_disc_dt_unk: "",
                q35beforedosedate:false,
                Q35beforeFollowEndDate:false,
                q35Empty: false,
                q35futuredate:false,
                q35notselected:
                    e.target.value === "1" && data.q35notselected ? true : false,
                q36Empty: e.target.value === "1" && avaDetails.q36Empty ? true : false,
                q36txtEmpty: e.target.value === "1" && avaDetails.q36txtEmpty ? true : false,
                ava_disc_reason:
                    e.target.value === "0" ? "" : avaDetails.ava_disc_reason,
                ava_disc_pt_pref_oth_txt:
                    e.target.value === "0" ? "" : avaDetails.ava_disc_pt_pref_oth_txt,
                ava_disc_oth_txt:
                    e.target.value === "0" ? "" : avaDetails.ava_disc_oth_txt,
            });
        }else if (e.target.name === "pt_ava_transfusionukn") {
            let q38UknSelected=false;
            let transfusiondata = [...avatransfusioncountDetails];
            for(let i=0;i<transfusiondata.length;i++)
            {
                if(transfusiondata[i].transfusion_dt_n!="")
                q38UknSelected=true;
            }
            setq38transfusiondt(false);
            setavaDetails({
                ...avaDetails,
                [e.target.name]: !avaDetails[e.target.name],
                q38Empty: false,
                q38UknSelected: !avaDetails[e.target.name]==true?q38UknSelected:false,
            });

        } else if (e.target.name === "ava_start_freq") {
            setavaDetails({
                ...avaDetails,
                [e.target.name]: parseInt(e.target.value),
                q33Empty:
                    data.q33Empty &&
                        (data.ava_start_num === "" || data.ava_start_dosage === "" || e.target.value === 0)
                        ? true
                        : false,
            });
        } else if (e.target.name === "ava_dose_change" && e.target.value === "1") {
            setavaDetails({
                ...avaDetails,
                [e.target.name]: e.target.value,
                q34Empty: false,
               
            });
        }
        
         else if (e.target.name === "ava_disc_reason") {
            setavaDetails({
                ...avaDetails,
                [e.target.name]: e.target.value,
                ava_disc_pt_pref_oth_txt: "",
                ava_disc_oth_txt: "",
                q36Empty: false,
            });
        }
        else if (e.target.name === "ava_disc_pt_pref_oth_txt") {
            setavaDetails({
                ...avaDetails,
                [e.target.name]: e.target.value,
                q36txtEmpty: avaDetails.q36txtEmpty && avaDetails.ava_disc_reason === "5" ? false : avaDetails.q36txtEmpty,
            });
        }
        else if (e.target.name === "ava_disc_oth_txt") {
            setavaDetails({
                ...avaDetails,
                [e.target.name]: e.target.value,
                q36txtEmpty: avaDetails.q36txtEmpty && avaDetails.ava_disc_reason === "6" ? false : avaDetails.q36txtEmpty,
            });
        }
        else {
            setavaDetails({ ...avaDetails, [e.target.name]: e.target.value });
        }
    }
    function addavaDosageDetailsRow(e) {
         
        setavaDosageDetails((prev) => [
            ...prev,
            {
                avatreatmentid: 0,
                ava_change_dt_a1: "",
                ava_change_dt_a1_unk: "",
                ava_new_dose_b1: "",
                ava_new_num_c1: "",
                ava_new_freq_c1: "",
                ava_change_reason_d1: "",
                ava_change_reason_d1_oth_txt: "",
                defDateQ34: false,
                q34datechange: false,
                q34newdosage: false,
                q34DoseInput:false,
                q34FreqInput:false,
                q34newfreq: false,
                q34changereason: false,
                q34dosagelessindexdt: false,
                q34dosageAfterFollowEndDate:false,
                q34futuredate:false,
                q34othertxt: false,
            },
        ]);
    }
    function addPlateletsDetailsRow(e) {
         
        let index = avaplateletcountDetails.length;
        setavaplateletcountDetails((prev) => [
            ...prev,
            {
                plateletcountid: 0,
                id: index + 1,
                pc_dt: "",
                pc_value: "",
            },
        ]);
    }

    function addTransfusionDetailsRow(e) {
         
        let index = avatransfusioncountDetails.length;
        setavatransfusioncountDetails((prev) => [
            ...prev,
            {
                plateletcountid: 0,
                id: index + 1,
                transfusion_dt_n: "",
                
            },
        ]);
    }
    const HandleDateChange = (name, date, optionId, index) => {
         
        let indexDateValue = indexdate != "" ? new Date(indexdate) : new Date();
        const lastIndex = name.lastIndexOf("-");
        const idx = name.slice(lastIndex + 1);
        switch (optionId) {
            case "ava_change_dt_a1":
                {
                    let dosagearray = [...avaDosageDetails];
                    dosagearray[idx][optionId] = date;
                    dosagearray[idx].ava_change_dt_a1_unk = "";
                    dosagearray[idx].defDateQ34 = false;
                    dosagearray[idx].q34datechange = false;
                    dosagearray[idx].q34futuredate=false;
                    let dosageDate = new Date(date);
                    if (
                        dosageDate <= indexDateValue &&
                        dosagearray[idx].q34dosagelessindexdt
                    ) {
                        dosagearray[idx].q34dosagelessindexdt = true;
                    } else {
                        dosagearray[idx].q34dosagelessindexdt = false;
                    }

                    const parsedDate1 = new Date(endfollowupdate);

                    if(dosageDate>parsedDate1)
                    {
                        dosagearray[idx].q34dosageAfterFollowEndDate=true;
                    }
                    else{
                        dosagearray[idx].q34dosageAfterFollowEndDate=false;
   
                    }

                    if (avaDetails.ava_disc_dt != "") {
                      
                        let discdate = new Date(avaDetails.ava_disc_dt);
                        if(discdate<=dosageDate)
                        avaDetails.q35beforedosedate=true;
                        else
                        avaDetails.q35beforedosedate=false;

                    }
                    setavaDosageDetails(dosagearray);
                }
                break;
            case "ava_disc_dt":
                {
                    let q35lessindexdt = false;
                    let q35beforedosedate=false;
                    let Q35beforeFollowEndDate=false;
                    let q35futuredate=false;
                    if ([optionId] != "") {
                        let discdate = new Date(date);
                        if (discdate <= indexDateValue) {
                            q35lessindexdt = true;
                        }
                        
                        const parsedDate1 = new Date(endfollowupdate);

                        if(discdate>parsedDate1)
                        {
                            Q35beforeFollowEndDate=true;
                        }
                        else{
                            Q35beforeFollowEndDate=false;
       
                        }
    

                        for(let i=0;i<avaDosageDetails.length;i++){
                          
                        if(avaDosageDetails[i].ava_change_dt_a1 !=="" && [optionId] != ""){
                            let dosedate = new Date(avaDosageDetails[i].ava_change_dt_a1);
                            if(discdate<=dosedate)
                            q35beforedosedate=true;
                            else
                            q35beforedosedate=false;
                        }
                    }
                    }
                    let comparedt = "";
                    const parsedDate1 = new Date(endfollowupdate);
                    const parsedDate2 =
                        new Date(date);
                    if (parsedDate2 != "") {
                        comparedt = parsedDate1 < parsedDate2 ? endfollowupdate : date;
                    } else {
                        comparedt = endfollowupdate;
                    }
                    setmindt(comparedt);
                    setavaDetails({
                        ...avaDetails,
                        ava_disc_dt: date,
                        ava_disc_dt_unk: "",
                        q35notselected: false,
                        q35lessindexdt: q35lessindexdt,
                        q35beforedosedate:q35beforedosedate,
                        Q35beforeFollowEndDate:Q35beforeFollowEndDate,
                        q35futuredate: q35futuredate,
                    });
                    setDefDateQ35(false);
                }
                break;
            case "pc_dt":
                {
                     
                    let plateletdata = [...avaplateletcountDetails];
                    plateletdata[idx][optionId] = date;
                    let comparedt = new Date();
                    const parsedDate1 = new Date(endfollowupdate);
                    const parsedDate2 =
                        avaDetails.ava_disc_dt != ""
                            ? new Date(avaDetails.ava_disc_dt)
                            : "";
                    if (parsedDate2 != "") {
                        comparedt = parsedDate1 < parsedDate2 ? parsedDate1 : parsedDate2;
                    } else {
                        comparedt = parsedDate1;
                    }
                    for (let i = 0; i < avaplateletcountDetails.length; i++) {
                         
                        if (avaplateletcountDetails[idx].pc_value != "") {
                            let plateletDate = new Date(date);
                            plateletdata[idx].q37plateletlessindexdt =
                                plateletDate < new Date(index3months) ? true : false;
                            plateletdata[idx].q37plateletmoreendfollowup =
                                plateletDate > comparedt ? true : false;
                        }
                    }
                    plateletdata[idx].q37EmptyDate=false;
                    if (idx === "0") setq37plateletdt(false);
                    setavaplateletcountDetails(plateletdata);
                }
                break;
                case "transfusion_dt_n":
                {
                 
                    let transfusiondata = [...avatransfusioncountDetails];
                    transfusiondata[idx][optionId] = date;
                    let comparedt = new Date();
                    const parsedDate1 = new Date(endfollowupdate);
                    const parsedDate2 =
                        avaDetails.ava_disc_dt != ""
                            ? new Date(avaDetails.ava_disc_dt)
                            : "";
                    if (parsedDate2 != "") {
                        comparedt = parsedDate1 < parsedDate2 ? parsedDate1 : parsedDate2;
                    } else {
                        comparedt = parsedDate1;
                    }
                    for (let i = 0; i < avatransfusioncountDetails.length; i++) {
                         
                       
                            let transfusionDate = new Date(date);
                            transfusiondata[idx].q38plateletlessindexdt =
                            transfusionDate < new Date(index3months) ? true : false;
                                transfusiondata[idx].q38plateletmoreendfollowup =
                                transfusionDate > comparedt ? true : false;
                        
                    }
                    if (idx === "0") setq38transfusiondt(false);
                    setavatransfusioncountDetails(transfusiondata);

                    avaDetails.q38UknSelected=false;
                    avaDetails.pt_ava_transfusionukn=false;
                }
                break;
            default:
                break;
        }
    };
    const HandleDatechangeunk = (e) => {
         
        const { name, id, value } = e.target;
        let dosagearray = [...avaDosageDetails];
        dosagearray[id].ava_change_dt_a1 = "";
        dosagearray[id].ava_change_dt_a1_unk = value;
        dosagearray[id].defDateQ34 = true;
        dosagearray[id].q34datechange = false;
        dosagearray[id].q34dosagelessindexdt = false;
        dosagearray[id].q34dosageAfterFollowEndDate=false;
        dosagearray[id].q34futuredate = false;
        setavaDosageDetails(dosagearray);
    };
    const HandleAVAdosageChange = (e) => {
         
        const { name, id, value } = e.target;
        switch (name) {
            case "ava_new_dose_b1": {
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...avaDosageDetails];
                dosagearray[id][name] = value;
                dosagearray[id].q34newdosage = false;
                if (dosagearray[id][name] !== "")
                dosagearray[id].q34DoseInput = dosagearray[id][name] < 1501 && dosagearray[id][name] > 0 ? false : true;

                setavaDosageDetails(dosagearray);
                break;
            }
            case "ava_new_num_c1": {
                if (!NUMBER_REGEX.test(value)) {
                    return;
                }
                let dosagearray = [...avaDosageDetails];
                dosagearray[id][name] = value;
                if (dosagearray[id].q34newfreq === true) {
                    dosagearray[id].q34newfreq =
                        dosagearray[id].ava_new_freq_c1 != "" || dosagearray[id].ava_new_freq_c1 === 0 ? false : true;
                }
                if (dosagearray[id][name] !== "")
                dosagearray[id].q34FreqInput = dosagearray[id][name] < 1501 && dosagearray[id][name] > 0 ? false : true;


                setavaDosageDetails(dosagearray);
                break;
            }
            case "ava_new_freq_c1": {
                let dosagearray = [...avaDosageDetails];
                dosagearray[id][name] = parseInt(value);
                if (dosagearray[id].q34newfreq === true) {
                    dosagearray[id].q34newfreq =
                        dosagearray[id].ava_new_num_c1 != "" || e.target.value === 0 ? false : true;
                }
                setavaDosageDetails(dosagearray);
                break;
            }
            case "ava_change_reason_d1":
            case "ava_change_reason_d1_oth_txt": {
                let dosagearray = [...avaDosageDetails];
                dosagearray[id][name] = value;
                setavaDosageDetails(dosagearray);
                break;
            }
            default:
                break;
        }
    };
    const HandleAVAdosageradioChange = (e) => {
         
        const { name, id, value } = e.target;
        switch (id) {
            case "ava_change_reason_d1":
                {
                    let dosagearray = [...avaDosageDetails];
                    dosagearray[name][id] = value;
                    dosagearray[name].q34othertxt = false;
                    dosagearray[name].q34changereason = false;
                    if (value != 3) dosagearray[name].ava_change_reason_d1_oth_txt = "";
                    setavaDosageDetails(dosagearray);
                }
                break;
            case "ava_change_reason_d1_oth_txt":
                {
                    let dosagearray = [...avaDosageDetails];
                    dosagearray[name][id] = value;
                    dosagearray[name].q34othertxt = false;
                    dosagearray[name].q34changereason = false;
                    setavaDosageDetails(dosagearray);
                }
                break;
            default:
                break;
        }
    };
    function HandleplateletChange(e) {
        
        const { name, id, value } = e.target;
        if (!NUMBER_PLATELETREGEX.test(value)) {
            return;
        }
        let plateletarray = [...avaplateletcountDetails];
        plateletarray[id][name] = value;
        
        if (id === "0") setq37plateletvalue(false);
        if(plateletarray[id][name] !="")
        plateletarray[id].q37plateletInput=plateletarray[id][name] <=2000 && plateletarray[id][name] >= 0 ? false : true;
        plateletarray[id].q37EmptyPlatelet=false;
        setavaplateletcountDetails(plateletarray);
    }
    const onsaveclick = async (validateForm) => {
        try{
            ToggleLoader(true);
            let status =validateForm? validate():false;
            if (status === true) {
                setShowTopErrMsg(true);
                ToggleLoader(false);
            }
            else {
                setShowTopErrMsg(false);
            
                let data = {
                    AvatreatmentDetailsModel: {
                        AvatreatmentDetails: {
                            Avatreatmentid: avaDetails.avatreatmentid,
                            AvaFirstLine: avaDetails.ava_first_line,
                            AvaAnyReason1: avaDetails.ava_any_reason_1 === "1",
                            AvaAnyReason2: avaDetails.ava_any_reason_2 === "1",
                            AvaAnyReason3: avaDetails.ava_any_reason_3 === "1",
                            AvaAnyReason4: avaDetails.ava_any_reason_4 === "1",
                            AvaAnyReason5: avaDetails.ava_any_reason_5 === "1",
                            AvaAnyReason6: avaDetails.ava_any_reason_6 === "1",
                            AvaAnyReason7: avaDetails.ava_any_reason_7 === "1",
                            AvaAnyReasonOth: avaDetails.ava_any_reason_oth === "1",
                            AvaAnyReasonOthTxt: avaDetails.ava_any_reason_oth_txt,
                            AvaAnyReasonUnk: avaDetails.ava_any_reason_unk === "1",
                            PrimReasonAva: avaDetails.prim_reason_ava,
                            PrimReasonAvaOthTxt: avaDetails.prim_reason_ava_oth_txt,
                            AvaStartDosage: avaDetails.ava_start_dosage,
                            AvaStartNum: avaDetails.ava_start_num,
                            AvaStartFreq: avaDetails.ava_start_freq,
                            AvaDoseChange: avaDetails.ava_dose_change,
                            PtAvaDisc: avaDetails.pt_ava_disc,
                            AvaDiscDt: avaDetails.ava_disc_dt,
                            AvaDiscReason: avaDetails.ava_disc_reason,
                            AvaDiscPtPrefOthTxt: avaDetails.ava_disc_pt_pref_oth_txt,
                            AvaDiscOthTxt: avaDetails.ava_disc_oth_txt,
                            PtAvaTransfusionUkn:avaDetails.pt_ava_transfusionukn,
                        },
                        AVAdosageDetails: [],
                        AvaplateletcountDetails: [],
                        AvatransfusioncountDetails:[],
                        
                        patientid: GetLocalStorageData("patientId") ?? 0,
                        UserID: GetLoggedInUserID(),
                        IsValidated:validateForm,
                    },
                };
                if (avaDosageDetails.length > 0) {
                    for (let i = 0; i < avaDosageDetails.length; i++) {
                        
                        let AVAdosage = {
                            Avadosageid: avaDosageDetails[i].avadosageid,
                            AvaChangeDtA1: avaDosageDetails[i].ava_change_dt_a1,
                            AvaChangeDtA1Unk: avaDosageDetails[i].ava_change_dt_a1_unk === "99",
                            AvaNewDoseB1: avaDosageDetails[i].ava_new_dose_b1,
                            AvaNewNumC1: avaDosageDetails[i].ava_new_num_c1,
                            AvaNewFreqC1: avaDosageDetails[i].ava_new_freq_c1,
                            AvaChangeReasonD1: avaDosageDetails[i].ava_change_reason_d1,
                            AvaChangeReasonD1OthTxt:
                                avaDosageDetails[i].ava_change_reason_d1_oth_txt,
                        };
                        data.AvatreatmentDetailsModel.AVAdosageDetails.push(AVAdosage);
                    }
                }

                
                let plateletdata = avaplateletcountDetails.filter(
                    (x) => x.pc_dt != "" || x.pc_value != ""
                );
                if (plateletdata.length > 0) {
                    for (let k = 0; k < plateletdata.length; k++) {
                        let AVAplatelet = {
                            Plateletcountid: plateletdata[k].plateletcountid,
                            PcDt: plateletdata[k].pc_dt,
                            PcValue: plateletdata[k].pc_value,
                        };
                        data.AvatreatmentDetailsModel.AvaplateletcountDetails.push(
                            AVAplatelet
                        );
                    }
                }

                if(avaDetails.pt_ava_transfusionukn===false)
                {

                let transfusiondata = avatransfusioncountDetails.filter(
                    (x) => x.transfusion_dt_n != "" 
                );

                if (transfusiondata.length > 0) {
                    for (let k = 0; k < transfusiondata.length; k++) {
                        let AVATransfusion = {
                            Transfusioncountid: transfusiondata[k].transfusioncountid,
                            TransfusionDt: transfusiondata[k].transfusion_dt_n,
                        };
                        data.AvatreatmentDetailsModel.AvatransfusioncountDetails.push(
                            AVATransfusion
                        );
                    }
                }
                }
                else
                {
                    let newarray=[];
                    setavatransfusioncountDetails(newarray);

                }
                let response = await AvatreatmentService.SaveAvatreatmentData(
                    data.AvatreatmentDetailsModel
                );
                if (response?.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized)
                        HandleSessionTimeout();
                    else
                        throw response.error;
                }else{
                    await GettreatmentData();
                    ToggleLoader(false);
                    ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                }
                return true;
            }
            return false;
        }catch{
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    };
    function deletedosage(id) {
         
        const newDosageArray = [...avaDosageDetails];
        newDosageArray.splice(id, 1);
        let ii = newDosageArray;
        setavaDosageDetails(newDosageArray);
    }
    function deleteplatelet(id) {
        const newPlateletArray = [...avaplateletcountDetails];
        newPlateletArray.splice(id, 1);
        let ii = newPlateletArray;
        setavaplateletcountDetails(newPlateletArray);
    }
    function deletetransfusion(id) {  
        const newTransfusionArray = [...avatransfusioncountDetails];
        newTransfusionArray.splice(id, 1);
        let ii = newTransfusionArray;
        setavatransfusioncountDetails(newTransfusionArray);
    }

    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate
                            paths={navigatePaths}
                            Save={onsaveclick}

                        />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.Questionnaire} />
                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>AVA TREATMENT</h4>
                            </div>
                            {showTopErrMsg && (
                                <div className="clean-error ">
                                    <ErrorField
                                        show={showTopErrMsg}
                                        message={CommonError_Msgs.Hard_Empty}
                                    />
                                </div>
                            )}
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div style={PageDesignConstants.PageDescriptionStyle}>
                                        <i>
                                            This section will ask you detailed questions about the
                                            patient’s AVA treatment.
                                        </i>
                                        <i></i>
                                    </div>
                                    <div className="question-answer">
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>31.</span>
                                                <span className="quest-text-pad">
                                                    Was AVA used as 1<sup>st</sup> line treatment for ITP?
                                                </span>
                                            </div>
                                            <div className="double-dig-answer">
                                                <div className="answer-list-text">
                                                    <input
                                                        type="radio"
                                                        name="ava_first_line"
                                                        value={1}
                                                        checked={avaDetails.ava_first_line === 1}
                                                        onChange={(e) => onAVAfirstlineChange(1)}
                                                    />
                                                    <span className="radio-text-padding">Yes</span>
                                                </div>
                                                <div className="answer-list-text">
                                                    <input
                                                        type="radio"
                                                        name="ava_first_line"
                                                        value={0}
                                                        checked={avaDetails.ava_first_line === 0}
                                                        onChange={(e) => onAVAfirstlineChange(0)}
                                                    />
                                                    <span className="radio-text-padding">
                                                        No, patient switched to AVA from another ITP
                                                        treatment.
                                                    </span>
                                                </div>
                                            </div>
                                            <ErrorField
                                                show={avaDetails.q31Empty}
                                                message={AvaTreatment_Msgs.Q31}
                                            />
                                        </div>

                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>32.</span>
                                                <span className="quest-text-pad">
                                                    What were the reasons for initiating AVA for the
                                                    treatment of primary ITP?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="answer-list-text">
                                                    <div className="table-top">
                                                        <table>
                                                            <tbody>
                                                                <tr className="tab-row-border">
                                                                    <th />
                                                                    <th className="tab-cell-border tab-cell-padding" />
                                                                    <th className="tab-cell-border tab-cell-padding tab-cell-width">
                                                                        <span style={{ fontWeight: "500" }}>
                                                                            a. Select all reasons for initiating AVA.
                                                                        </span>
                                                                    </th>
                                                                    <th className="tab-cell-border tab-cell-padding tab-cell-width">
                                                                        <span style={{ fontWeight: "500" }}>
                                                                            b. Select the primary reason for
                                                                            initiating AVA.
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                                <tr className="tab-row-border" style={{display:avaDetails.ava_first_line === 0 ?"":"none"}} >
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        1.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Lack of efficacy with prior ITP treatment
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="ava_any_reason_1"
                                                                            value={1}
                                                                            onChange={HandleFieldChange}
                                                                            checked={
                                                                                avaDetails.ava_any_reason_1 === "1" ||
                                                                                avaDetails.ava_any_reason_1 === 1
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    avaDetails.ava_first_line === 0 ||
                                                                                        avaDetails.ava_first_line === "0"
                                                                                        ? ""
                                                                                        : "none",
                                                                            }}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            name="prim_reason_ava"
                                                                            value={1}
                                                                            checked={
                                                                                avaDetails.prim_reason_ava === "1" ||
                                                                                    avaDetails.prim_reason_ava === 1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    avaDetails.ava_first_line === 0 ||
                                                                                        avaDetails.ava_first_line === "0"
                                                                                        ? ""
                                                                                        : "none",
                                                                            }}
                                                                            disabled={
                                                                                (avaDetails.ava_first_line === 0 ||
                                                                                    avaDetails.ava_first_line === "0") &&
                                                                                    (avaDetails.ava_any_reason_1 === "1" ||
                                                                                        avaDetails.ava_any_reason_1 === 1)
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onChange={onAVAprimereasoneChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        2.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Lower likelihood of adverse event
                                                                            occurrence compared to other ITP
                                                                            treatments
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="ava_any_reason_2"
                                                                            checked={
                                                                                avaDetails.ava_any_reason_2 === "1" ||
                                                                                avaDetails.ava_any_reason_2 === 1
                                                                            }
                                                                            value={1}
                                                                            onChange={HandleFieldChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            name="prim_reason_ava"
                                                                            value={2}
                                                                            checked={
                                                                                avaDetails.prim_reason_ava === "2" ||
                                                                                    avaDetails.prim_reason_ava === 2
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            // style={{
                                                                            //   display:
                                                                            //     avaDetails.ava_any_reason_2 === "1" ||
                                                                            //     avaDetails.ava_any_reason_2 === 1
                                                                            //       ? ""
                                                                            //       : "none",
                                                                            // }}
                                                                            disabled={
                                                                                avaDetails.ava_any_reason_2 === "1" ||
                                                                                    avaDetails.ava_any_reason_2 === 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onChange={onAVAprimereasoneChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        3.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Covered by patient insurance/more
                                                                            affordable than other ITP treatments
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="ava_any_reason_3"
                                                                            checked={
                                                                                avaDetails.ava_any_reason_3 === "1" ||
                                                                                avaDetails.ava_any_reason_3 === 1
                                                                            }
                                                                            value={1}
                                                                            onChange={HandleFieldChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            name="prim_reason_ava"
                                                                            value={3}
                                                                            checked={
                                                                                avaDetails.prim_reason_ava === "3" ||
                                                                                    avaDetails.prim_reason_ava === 3
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            // style={{
                                                                            //   display:
                                                                            //     avaDetails.ava_any_reason_3 === "1" ||
                                                                            //     avaDetails.ava_any_reason_3 === 1
                                                                            //       ? ""
                                                                            //       : "none",
                                                                            // }}
                                                                            disabled={
                                                                                avaDetails.ava_any_reason_3 === "1" ||
                                                                                    avaDetails.ava_any_reason_3 === 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onChange={onAVAprimereasoneChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        4.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Fewer contraindications with other
                                                                            prescribed medication
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                avaDetails.ava_any_reason_4 === "1" ||
                                                                                avaDetails.ava_any_reason_4 === 1
                                                                            }
                                                                            id="ava_any_reason_4"
                                                                            value={1}
                                                                            onChange={HandleFieldChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            name="prim_reason_ava"
                                                                            value={4}
                                                                            checked={
                                                                                avaDetails.prim_reason_ava === "4" ||
                                                                                    avaDetails.prim_reason_ava === 4
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            // style={{
                                                                            //   display:
                                                                            //     avaDetails.ava_any_reason_4 === "1" ||
                                                                            //     avaDetails.ava_any_reason_4 === 1
                                                                            //       ? ""
                                                                            //       : "none",
                                                                            // }}
                                                                            disabled={
                                                                                avaDetails.ava_any_reason_4 === "1" ||
                                                                                    avaDetails.ava_any_reason_4 === 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onChange={onAVAprimereasoneChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        5.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            More convenient than other options (e.g.,
                                                                            able to take at home; no meal
                                                                            restrictions)
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                avaDetails.ava_any_reason_5 === "1" ||
                                                                                avaDetails.ava_any_reason_5 === 1
                                                                            }
                                                                            id="ava_any_reason_5"
                                                                            value={1}
                                                                            onChange={HandleFieldChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            name="prim_reason_ava"
                                                                            value={5}
                                                                            checked={
                                                                                avaDetails.prim_reason_ava === "5" ||
                                                                                    avaDetails.prim_reason_ava === 5
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            // style={{
                                                                            //   display:
                                                                            //     avaDetails.ava_any_reason_5 === "1" ||
                                                                            //     avaDetails.ava_any_reason_5 === 1
                                                                            //       ? ""
                                                                            //       : "none",
                                                                            // }}
                                                                            disabled={
                                                                                avaDetails.ava_any_reason_5 === "1" ||
                                                                                    avaDetails.ava_any_reason_5 === 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onChange={onAVAprimereasoneChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        6.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">Patient preference</span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                avaDetails.ava_any_reason_6 === "1"
                                                                            }
                                                                            id="ava_any_reason_6"
                                                                            value={1}
                                                                            onChange={HandleFieldChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            name="prim_reason_ava"
                                                                            value={6}
                                                                            checked={
                                                                                avaDetails.prim_reason_ava === "6" ||
                                                                                    avaDetails.prim_reason_ava === 6
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            // style={{
                                                                            //   display:
                                                                            //     avaDetails.ava_any_reason_6 === "1" ||
                                                                            //     avaDetails.ava_any_reason_6 === 1
                                                                            //       ? ""
                                                                            //       : "none",
                                                                            // }}
                                                                            disabled={
                                                                                avaDetails.ava_any_reason_6 === "1" ||
                                                                                    avaDetails.ava_any_reason_6 === 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onChange={onAVAprimereasoneChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border" style={{display:avaDetails.ava_first_line === 1 ?"":"none"}}>
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        7.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Most efficacious treatment option to
                                                                            increase platelet counts
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                avaDetails.ava_any_reason_7 === "1" ||
                                                                                avaDetails.ava_any_reason_7 === 1
                                                                            }
                                                                            id="ava_any_reason_7"
                                                                            value={1}
                                                                            onChange={HandleFieldChange}
                                                                            style={{
                                                                                display:
                                                                                    avaDetails.ava_first_line === 1 ||
                                                                                        avaDetails.ava_first_line === 1
                                                                                        ? ""
                                                                                        : "none",
                                                                            }}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            name="prim_reason_ava"
                                                                            value={7}
                                                                            checked={
                                                                                avaDetails.prim_reason_ava === "7" ||
                                                                                    avaDetails.prim_reason_ava === 7
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    avaDetails.ava_first_line === 1
                                                                                        ? ""
                                                                                        : "none",
                                                                            }}
                                                                            disabled={
                                                                                avaDetails.ava_any_reason_7 === "1"
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onChange={onAVAprimereasoneChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>

                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        8.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Other, please specify:{" "}
                                                                            <input
                                                                                className="input-dash"
                                                                                type="text"
                                                                                name="ava_any_reason_oth_txt"
                                                                                disabled={
                                                                                    avaDetails.ava_any_reason_oth === "1" ||
                                                                                        avaDetails.ava_any_reason_oth === 1
                                                                                        ? false
                                                                                        : true
                                                                                }
                                                                                onChange={HandleothtxtChange}
                                                                                value={
                                                                                    avaDetails.ava_any_reason_oth_txt
                                                                                }
                                                                                style={{
                                                                                    display:
                                                                                    avaDetails.ava_any_reason_oth === "1" ||
                                                                                    avaDetails.ava_any_reason_oth === 1
                                                                                            ? ""
                                                                                            : "none",
                                                                                }}
                                                                            />
                                                                        </span>
                                                                        
                                                                        <ErrorField
                                                                            show={avaDetails.q32Empty_OtherText ? true : avaDetails.q32Empty_OtherText}
                                                                            message={AvaTreatment_Msgs.Q32EmptyOtherText}
                                                                        />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                avaDetails.ava_any_reason_oth === "1" ||
                                                                                avaDetails.ava_any_reason_oth === 1
                                                                            }
                                                                            id="ava_any_reason_oth"
                                                                            
                                                                            value={1}
                                                                            onChange={HandleFieldChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            name="prim_reason_ava"
                                                                            value={8}
                                                                          
                                                                            disabled={
                                                                                avaDetails.ava_any_reason_oth === "1" ||
                                                                                    avaDetails.ava_any_reason_oth === 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            checked={
                                                                                avaDetails.prim_reason_ava === "8" ||
                                                                                    avaDetails.prim_reason_ava === 8
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={onAVAprimereasoneChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                                <tr className="tab-row-border">
                                                                    <td className="table-list-text tab-cell-padding">
                                                                        9.
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding">
                                                                        <span className="">
                                                                            Unknown/no reason indicated
                                                                        </span>
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                avaDetails.ava_any_reason_unk === "1" ||
                                                                                avaDetails.ava_any_reason_unk === 1
                                                                            }
                                                                            id="ava_any_reason_unk"
                                                                            value={1}
                                                                            onChange={HandleFieldChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                    <td className="tab-cell-border tab-cell-padding tab-cell-checkbox">
                                                                        <input
                                                                            type="radio"
                                                                            name="prim_reason_ava"
                                                                            value={99}
                                                                            checked={
                                                                                avaDetails.prim_reason_ava === "99" ||
                                                                                    avaDetails.prim_reason_ava === 99
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            // style={{
                                                                            //   display:
                                                                            //     avaDetails.ava_any_reason_unk ===
                                                                            //       "1" ||
                                                                            //     avaDetails.ava_any_reason_unk === 1
                                                                            //       ? ""
                                                                            //       : "none",
                                                                            // }}
                                                                            disabled={
                                                                                avaDetails.ava_any_reason_unk === "1" ||
                                                                                    avaDetails.ava_any_reason_unk === 1
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onChange={onAVAprimereasoneChange}
                                                                        />
                                                                        <span className="radio-text-padding" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorField
                                                show={avaDetails.q32Empty ? true : avaDetails.q32primaryEmpty}
                                                message={avaDetails.q32Empty ? AvaTreatment_Msgs.Q32a : AvaTreatment_Msgs.Q32b}
                                            />
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>33.</span>
                                                <span className="quest-text-pad">
                                                    What was the patient's starting AVA dosage and
                                                    frequency?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <div>
                                                        <span className="">
                                                            <span className="question-weight">Dosage: </span>
                                                            <input
                                                                className="sub-input-min-dash"
                                                                type="text"
                                                                id="ava_start_dosage"
                                                                value={avaDetails.ava_start_dosage}
                                                                onChange={onAVAstartdosageChange}
                                                                onKeyDown={formatInput}
                                                            />{" "}
                                                            mg
                                                        </span>
                                                    </div>
                                                    <ErrorField
                                                     show={avaDetails.q33DoseInput ? true : avaDetails.q33DoseInput}
                                                     message={AvaTreatment_Msgs.QdosageFreq}
                                                      />
                                                    <div>
                                                        <span className="">
                                                            <span className="question-weight">
                                                                Frequency:{" "}
                                                            </span>{" "}
                                                            <input
                                                                className="sub-input-min-dash"
                                                                type="text"
                                                                id="ava_start_num"
                                                                value={avaDetails.ava_start_num}
                                                                onChange={onAVAstartdosageChange}
                                                                onKeyDown={formatInput}
                                                            />
                                                            time(s) per
                                                            <select
                                                                name="ava_start_freq"
                                                                className="freq-dropdown"
                                                                onChange={HandlechangeAVAdetails}
                                                                value={avaDetails.ava_start_freq}
                                                            >
                                                                <option value={0}>Select</option>
                                                                {frequencytime?.map((i) => {
                                                                    return (
                                                                        <option
                                                                            key={i.frequencytimeid}
                                                                            value={i.frequencytimeid}
                                                                        >
                                                                            {i.frequencytimetxt}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorField
                                                show={avaDetails.q33Empty?true:avaDetails.q33FreqInput}
                                                message={avaDetails.q33Empty?AvaTreatment_Msgs.Q33:AvaTreatment_Msgs.QdosageFreq}
                                            />
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>34.</span>
                                                <span className="quest-text-pad">
                                                    Was the dosage and/or frequency of AVA changed between{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {indexdate}
                                                    </span>{" "}
                                                    and{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {endfollowupdate}
                                                    </span>
                                                    ?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="answer-list-text">
                                                    <input
                                                        type="radio"
                                                        name="ava_dose_change"
                                                        value={1}
                                                        checked={
                                                            avaDetails.ava_dose_change == "1" ||
                                                            avaDetails.ava_dose_change == 1
                                                        }
                                                        onChange={HandlechangeAVAdetails}
                                                    />
                                                    <span className="radio-text-padding">Yes</span>
                                                </div>
                                                {(avaDetails.ava_dose_change == "1" ||
                                                    avaDetails.ava_dose_change == 1) &&
                                                    avaDosageDetails.length > 0 ? (
                                                    avaDosageDetails.map((data, idx) => (
                                                        <div className="dependent-section">
                                                            {idx === 0 ? (
                                                                <div className="question-weight">
                                                                    <p>
                                                                        Please enter the details of each new
                                                                        dose/frequency.
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div className="" style={{ marginTop: "-12px" }}>
                                                                <div className="loop-section-ul">
                                                                    <div className="loop-head">
                                                                        <span className="question-weight">
                                                                            Dose {idx + 1}
                                                                        </span>
                                                                        {idx != 0 ? (
                                                                            <div className="delete-btn-div">
                                                                                <img
                                                                                    width="16px"
                                                                                    src="../Assets/images/Icon-material-delete.png"
                                                                                    alt=""
                                                                                    onClick={(e) => deletedosage(idx)}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </div>
                                                                    <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                        <div
                                                                            className="answer-list-text"
                                                                            style={{ display: "flex" }}
                                                                        >
                                                                            <span className="question-weight">
                                                                                a.
                                                                            </span>
                                                                            <span
                                                                                className="quest-text-pad"
                                                                                style={{ display: "flex" }}
                                                                            >
                                                                                <span className="question-weight">
                                                                                    Date of change:{" "}
                                                                                </span>
                                                                                <DateControl
                                                                                    ctrlId={"ava_change_dt_a1-" + idx}
                                                                                    HandleDateChange={HandleDateChange}
                                                                                    optionId={"ava_change_dt_a1"}
                                                                                    defDate={data.defDateQ34}
                                                                                    date={data.ava_change_dt_a1}
                                                                                />
                                                                                (If only month and year are known,
                                                                                please enter “15” for the day)
                                                                            </span>
                                                                        </div>
                                                                        <div className="answer-list-text answer-pad-left">
                                                                            <input
                                                                                type="radio"
                                                                                name={"ava_change_dt_a1_unk-" + idx}
                                                                                value={99}
                                                                                id={idx}
                                                                                checked={
                                                                                    data.ava_change_dt_a1_unk === "99" ||
                                                                                    data.ava_change_dt_a1_unk === 99
                                                                                }
                                                                                onChange={HandleDatechangeunk}
                                                                            />
                                                                            <span className="radio-text-padding">
                                                                                Date of change is unknown{" "}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <ErrorField
                                                                        show={
                                                                            data.q34datechange
                                                                                ? data.q34datechange
                                                                                : (data.q34futuredate?data.q34futuredate: data.q34dosagelessindexdt)
                                                                        }
                                                                        message={
                                                                            data.q34datechange
                                                                                ? AvaTreatment_Msgs.Q34a
                                                                                :(data.q34futuredate?AvaTreatment_Msgs.FutureDate: AvaTreatment_Msgs.DateDosageChange)
                                                                        }
                                                                        
                                                                    />
                                                                     <ErrorField
                                                                        show={data.q34dosageAfterFollowEndDate?true:data.q34dosageAfterFollowEndDate} 
                                                                        message={AvaTreatment_Msgs.Q34DateInvalidSoft}
                                                                        
                                                                    />
                                                                    <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                        <div className="answer-list-text">
                                                                            <span className="question-weight">
                                                                                b.
                                                                            </span>
                                                                            <span className="quest-text-pad">
                                                                                <span className="question-weight">
                                                                                    New dosage:{" "}
                                                                                </span>
                                                                                <input
                                                                                    className="sub-input-min-dash"
                                                                                    type="text"
                                                                                    name="ava_new_dose_b1"
                                                                                    id={idx}
                                                                                    value={data.ava_new_dose_b1}
                                                                                    onChange={HandleAVAdosageChange}
                                                                                    onKeyDown={formatInput}
                                                                                />{" "}
                                                                                mg
                                                                            </span>
                                                                        </div>
                                                                        <ErrorField
                                                                            show={data.q34newdosage?true:data.q34DoseInput}
                                                                            message={data.q34newdosage?AvaTreatment_Msgs.Q34b:AvaTreatment_Msgs.QdosageFreq}
                                                                        />
                                                                    </div>
                                                                    <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                        <div className="answer-list-text">
                                                                            <span className="question-weight">
                                                                                c.
                                                                            </span>
                                                                            <span className="quest-text-pad">
                                                                                <span className="question-weight">
                                                                                    New frequency:{" "}
                                                                                </span>
                                                                                <input
                                                                                    className="sub-input-min-dash"
                                                                                    type="text"
                                                                                    name="ava_new_num_c1"
                                                                                    id={idx}
                                                                                    value={data.ava_new_num_c1}
                                                                                    onChange={HandleAVAdosageChange}
                                                                                    onKeyDown={formatInput}
                                                                                />
                                                                                time(s) per
                                                                                <select
                                                                                    className="freq-dropdown"
                                                                                    id={idx}
                                                                                    name="ava_new_freq_c1"
                                                                                    onChange={HandleAVAdosageChange}
                                                                                    value={data.ava_new_freq_c1}
                                                                                >
                                                                                    <option value={0}>Select</option>
                                                                                    {frequencytime?.map((i) => {
                                                                                        return (
                                                                                            <option
                                                                                                key={i.frequencytimeid}
                                                                                                value={i.frequencytimeid}
                                                                                            >
                                                                                                {i.frequencytimetxt}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </span>
                                                                        </div>
                                                                        <ErrorField
                                                                            show={data.q34newfreq?true:data.q34FreqInput}
                                                                            message={data.q34newfreq?AvaTreatment_Msgs.Q34c:AvaTreatment_Msgs.QdosageFreq}
                                                                        />
                                                                    </div>
                                                                    <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                        <div className="answer-list-text question-weight">
                                                                            <span>d.</span>
                                                                            <span className="quest-text-pad">
                                                                                What was the primary reason for the
                                                                                change in AVA dosage and/or frequency?
                                                                            </span>
                                                                        </div>
                                                                        <div className="">
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="ava_change_reason_d1"
                                                                                    name={idx}
                                                                                    value={1}
                                                                                    checked={
                                                                                        data.ava_change_reason_d1 === "1" ||
                                                                                        data.ava_change_reason_d1 === 1
                                                                                    }
                                                                                    onChange={HandleAVAdosageradioChange}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Lack of response to prior
                                                                                    dose/frequency{" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="ava_change_reason_d1"
                                                                                    name={idx}
                                                                                    value={2}
                                                                                    checked={
                                                                                        data.ava_change_reason_d1 === "2" ||
                                                                                        data.ava_change_reason_d1 === 2
                                                                                    }
                                                                                    onChange={HandleAVAdosageradioChange}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Tolerability (prior dose/frequency was
                                                                                    poorly tolerated by patient){" "}
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="ava_change_reason_d1"
                                                                                    name={idx}
                                                                                    value={3}
                                                                                    checked={
                                                                                        data.ava_change_reason_d1 === "3" ||
                                                                                        data.ava_change_reason_d1 === 3
                                                                                    }
                                                                                    onChange={HandleAVAdosageradioChange}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Other, please specify:{" "}
                                                                                    <input
                                                                                        className="input-dash"
                                                                                        type="text"
                                                                                        id="ava_change_reason_d1_oth_txt"
                                                                                        disabled={
                                                                                            data.ava_change_reason_d1 !=
                                                                                            "3" ||
                                                                                            data.ava_change_reason_d1 != 3
                                                                                        }
                                                                                        name={idx}
                                                                                        value={
                                                                                            data.ava_change_reason_d1_oth_txt
                                                                                        }
                                                                                        onChange={
                                                                                            HandleAVAdosageradioChange
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={data.q34othertxt}
                                                                                message={CommonError_Msgs.REQUIRED}
                                                                            />
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="ava_change_reason_d1"
                                                                                    name={idx}
                                                                                    value={99}
                                                                                    checked={
                                                                                        data.ava_change_reason_d1 ===
                                                                                        "99" ||
                                                                                        data.ava_change_reason_d1 === 99
                                                                                    }
                                                                                    onChange={HandleAVAdosageradioChange}
                                                                                />
                                                                                <span className="radio-text-padding">
                                                                                    Unknown/no reason indicated{" "}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorField
                                                                            show={data.q34changereason}
                                                                            message={AvaTreatment_Msgs.Q34d}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {avaDosageDetails.length === idx + 1 ? (
                                                                    <div className="sub-question-bot-sapce-ul mt-2">
                                                                        <div className="answer-list-text">
                                                                            <a>
                                                                                <img
                                                                                    width="18px"
                                                                                    onClick={addavaDosageDetailsRow}
                                                                                    src="../Assets/images/plus.png"
                                                                                    alt=""
                                                                                />
                                                                            </a>
                                                                            <a
                                                                                className="radio-text-padding"
                                                                                onClick={addavaDosageDetailsRow}
                                                                            >
                                                                                Select to enter another AVA dosage or
                                                                                frequency change
                                                                            </a>
                                                                            {/* <input type="checkbox"><span class="radio-text-padding">Click here to enter another dose change for <span>[therapy]</span> </span> */}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="answer-list-text">
                                                    <input
                                                        type="radio"
                                                        name="ava_dose_change"
                                                        checked={
                                                            avaDetails.ava_dose_change === "0" ||
                                                            avaDetails.ava_dose_change === 0
                                                        }
                                                        value={0}
                                                        onChange={HandlechangeAVAdetails}
                                                    />
                                                    <span className="radio-text-padding">No</span>
                                                </div>
                                            </div>
                                            <ErrorField
                                                show={avaDetails.q34Empty}
                                                message={`Please specify the dosage and/or frequency of AVA changed between '${indexdate}' and '${endfollowupdate}' .`}
                                            />
                                        </div>

                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>35.</span>
                                                <span className="quest-text-pad">
                                                    Did the patient discontinue AVA?
                                                </span>
                                            </div>
                                            <div className="double-dig-answer">
                                                <div className="answer-list-text">
                                                    <input
                                                        type="radio"
                                                        name="pt_ava_disc"
                                                        checked={
                                                            avaDetails.pt_ava_disc === "1" ||
                                                            avaDetails.pt_ava_disc === 1
                                                        }
                                                        value={1}
                                                        onChange={HandlechangeAVAdetails}
                                                    />
                                                    <span className="radio-text-padding">Yes</span>
                                                </div>
                                                {avaDetails.pt_ava_disc === "1" ||
                                                    avaDetails.pt_ava_disc === 1 ? (
                                                    <div className="dependent-section">
                                                        <div className="">
                                                            <div className="sub-question-bot-sapce-ul">
                                                                <span style={{ display: "flex" }}>
                                                                    <span className="question-weight">
                                                                        Date of discontinuation:{" "}
                                                                    </span>
                                                                    <DateControl
                                                                        ctrlId={"ava_disc_dt"}
                                                                        HandleDateChange={HandleDateChange}
                                                                        defDate={defDateQ35}
                                                                        optionId={"ava_disc_dt"}
                                                                        date={avaDetails.ava_disc_dt}
                                                                    />
                                                                    (If only month and year are known, please
                                                                    enter “15” for the day)
                                                                </span>
                                                                
                                                            </div>
                                                            <ErrorField
                                                                show={
                                                                    avaDetails.q35notselected
                                                                        ? true
                                                                        : (avaDetails.q35futuredate? avaDetails.q35futuredate
                                                                            :(avaDetails.q35lessindexdt
                                                                           ? true
                                                                           : avaDetails.q35beforedosedate))
                                                                }
                                                                message={
                                                                    avaDetails.q35notselected
                                                                        ? AvaTreatment_Msgs.Q35a
                                                                        :( avaDetails.q35futuredate?AvaTreatment_Msgs.FutureDate:
                                                                        (avaDetails.q35lessindexdt
                                                                            ? AvaTreatment_Msgs.DateAVAdiscontinue
                                                                            : AvaTreatment_Msgs.Q35BeforeDoseDate))
                                                                }
                                                            />

                                                                <ErrorField
                                                                show={avaDetails.Q35beforeFollowEndDate?true:avaDetails.Q35beforeFollowEndDate}
                                                                message={AvaTreatment_Msgs.Q35DateInvalidSoft}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="answer-list-text">
                                                    <input
                                                        type="radio"
                                                        name="pt_ava_disc"
                                                        checked={
                                                            avaDetails.pt_ava_disc === "0" ||
                                                            avaDetails.pt_ava_disc === 0
                                                        }
                                                        value={0}
                                                        onChange={HandlechangeAVAdetails}
                                                    />
                                                    <span className="radio-text-padding">No</span>
                                                </div>
                                            </div>
                                            <ErrorField
                                                show={avaDetails.q35Empty}
                                                message={AvaTreatment_Msgs.Q35}
                                            />
                                        </div>

                                        {avaDetails.pt_ava_disc === "1" ||
                                            avaDetails.pt_ava_disc === 1 ? (
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>36.</span>
                                                    <span className="quest-text-pad">
                                                        What was the primary reason for discontinuing AVA
                                                        treatment?{" "}
                                                    </span>
                                                </div>
                                                <div className=" double-dig-answer">
                                                    <div className="answer-list-text">
                                                        <input
                                                            type="radio"
                                                            name="ava_disc_reason"
                                                            checked={
                                                                avaDetails.ava_disc_reason === "1" ||
                                                                avaDetails.ava_disc_reason === 1
                                                            }
                                                            value={1}
                                                            onChange={HandlechangeAVAdetails}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Lack of efficacy/effectiveness (i.e., not reaching
                                                            a desired PC)
                                                        </span>
                                                    </div>
                                                    <div className="answer-list-text">
                                                        <input
                                                            type="radio"
                                                            name="ava_disc_reason"
                                                            checked={
                                                                avaDetails.ava_disc_reason === "2" ||
                                                                avaDetails.ava_disc_reason === 2
                                                            }
                                                            value={2}
                                                            onChange={HandlechangeAVAdetails}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Tolerability
                                                        </span>
                                                    </div>
                                                    <div className="answer-list-text">
                                                        <input
                                                            type="radio"
                                                            name="ava_disc_reason"
                                                            checked={
                                                                avaDetails.ava_disc_reason === "3" ||
                                                                avaDetails.ava_disc_reason === 3
                                                            }
                                                            value={3}
                                                            onChange={HandlechangeAVAdetails}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Adverse event
                                                        </span>
                                                    </div>
                                                    <div className="answer-list-text">
                                                        <input
                                                            type="radio"
                                                            name="ava_disc_reason"
                                                            checked={
                                                                avaDetails.ava_disc_reason === "4" ||
                                                                avaDetails.ava_disc_reason === 4
                                                            }
                                                            value={4}
                                                            onChange={HandlechangeAVAdetails}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Cost/insurance
                                                        </span>
                                                    </div>
                                                    <div className="answer-list-text">
                                                        <input
                                                            type="radio"
                                                            name="ava_disc_reason"
                                                            checked={
                                                                avaDetails.ava_disc_reason === "5" ||
                                                                avaDetails.ava_disc_reason === 5
                                                            }
                                                            value={5}
                                                            onChange={HandlechangeAVAdetails}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Patient preference (please specify):{" "}
                                                            <input
                                                                className="input-dash"
                                                                type="text"
                                                                disabled={
                                                                    avaDetails.ava_disc_reason != "5" ||
                                                                    avaDetails.ava_disc_reason != 5
                                                                }
                                                                name="ava_disc_pt_pref_oth_txt"
                                                                value={avaDetails.ava_disc_pt_pref_oth_txt}
                                                                onChange={HandlechangeAVAdetails}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="answer-list-text">
                                                        <input
                                                            type="radio"
                                                            name="ava_disc_reason"
                                                            checked={
                                                                avaDetails.ava_disc_reason === "6" ||
                                                                avaDetails.ava_disc_reason === 6
                                                            }
                                                            value={6}
                                                            onChange={HandlechangeAVAdetails}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Other, please specify:{" "}
                                                            <input
                                                                className="input-dash"
                                                                type="text"
                                                                disabled={
                                                                    avaDetails.ava_disc_reason != "6" ||
                                                                    avaDetails.ava_disc_reason != 6
                                                                }
                                                                name="ava_disc_oth_txt"
                                                                value={avaDetails.ava_disc_oth_txt}
                                                                onChange={HandlechangeAVAdetails}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="answer-list-text">
                                                        <input
                                                            type="radio"
                                                            name="ava_disc_reason"
                                                            checked={
                                                                avaDetails.ava_disc_reason === "99" ||
                                                                avaDetails.ava_disc_reason === 99
                                                            }
                                                            value={99}
                                                            onChange={HandlechangeAVAdetails}
                                                        />
                                                        <span className="radio-text-padding">
                                                            Unknown/no reason indicated
                                                        </span>
                                                    </div>
                                                </div>
                                                <ErrorField
                                                    show={avaDetails.q36Empty ? true : avaDetails.q36txtEmpty}
                                                    message={AvaTreatment_Msgs.Q36}
                                                />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>37.</span>
                                                <span className="quest-text-pad">
                                                    Please enter all platelet count dates and values
                                                    available for this patient between {""}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index3months}
                                                    </span>{" "}
                                                    and{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {mindt}
                                                    </span>{" "}
                                                    in chronological order.
                                                </span>
                                            </div>
                                            <div className="double-dig-answer">
                                                <div className="table-top">
                                                    <table>
                                                        <tbody>
                                                            <tr className="tab-row-border">
                                                                <th className="tab-cell-th tab-cell-border">
                                                                    Date
                                                                </th>
                                                                <th className="tab-cell-th tab-cell-border">
                                                                    Platelet Count
                                                                </th>
                                                                <th />
                                                            </tr>
                                                            {avaplateletcountDetails.length > 0 ? (
                                                                avaplateletcountDetails.map((data, idx) => (
                                                                    <tr className="tab-row-border">
                                                                        <td className="tab-cell-left tab-cell-border">
                                                                            <DateControl
                                                                                marginLeft={false}
                                                                                optionId={"pc_dt"}
                                                                                HandleDateChange={HandleDateChange}
                                                                                ctrlId={"pc_dt-" + idx}
                                                                                date={data.pc_dt}
                                                                            />
                                                                            {(q37plateletdt && idx === 0) ||
                                                                                data.q37plateletlessindexdt ||
                                                                                data.q37EmptyDate ||
                                                                                data.q37plateletmoreendfollowup ? (
                                                                                <ErrorField
                                                                                    show={
                                                                                        q37plateletdt && idx === 0
                                                                                            ? q37plateletdt
                                                                                            : (data.q37EmptyDate
                                                                                            ?   data.q37EmptyDate
                                                                                            :   (data.q37plateletlessindexdt
                                                                                                ? data.q37plateletlessindexdt
                                                                                                : data.q37plateletmoreendfollowup))
                                                                                    }

                                                                                    message={
                                                                                        q37plateletdt && idx === 0
                                                                                            ? AvaTreatment_Msgs.Q37a
                                                                                            :  (data.q37EmptyDate
                                                                                            ?   AvaTreatment_Msgs.Q37EmptyDate
                                                                                            :  (data.q37plateletlessindexdt
                                                                                                ? AvaTreatment_Msgs.PlateletlessthanIndex
                                                                                                : (data.q37plateletmoreendfollowup
                                                                                                    ?(avaDetails.ava_disc_dt != "" &&
                                                                                                        new Date(data.pc_dt) >
                                                                                                        new Date(
                                                                                                            avaDetails.ava_disc_dt
                                                                                                        )
                                                                                                        ? AvaTreatment_Msgs.PlateletmorethanAVAdisc
                                                                                                        : (new Date(data.pc_dt) >
                                                                                                            new Date(endfollowupdate)
                                                                                                            ? AvaTreatment_Msgs.PlateletmorethanEndFollowup
                                                                                                            : ""))
                                                                                                    : "")))
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </td>
                                                                        <td
                                                                            className="tab-cell-right tab-cell-border"
                                                                            style={{
                                                                                verticalAlign: "top",
                                                                                paddingTop: "7px",
                                                                            }}
                                                                        >
                                                                            <span className="radio-text-padding">
                                                                                <input
                                                                                    className="sub-input-dash"
                                                                                    type="number"
                                                                                    name="pc_value"
                                                                                    id={idx}
                                                                                    value={data.pc_value}
                                                                                    onChange={HandleplateletChange}
                                                                                    style={{ width: "40%" }}
                                                                                />
                                                                                x 10<sup>9</sup>/L{" "}
                                                                            </span>
                                                                            {(q37plateletvalue && idx === 0 )||data.q37plateletInput || data.q37EmptyPlatelet ? (
                                                                                <ErrorField
                                                                                    show={(q37plateletvalue && idx === 0)?q37plateletvalue:(data.q37EmptyPlatelet?data.q37EmptyPlatelet: data.q37plateletInput)}
                                                                                    message={(q37plateletvalue && idx === 0)?AvaTreatment_Msgs.Q37b:(data.q37EmptyPlatelet?AvaTreatment_Msgs.Q37EmptyPlatelet:AvaTreatment_Msgs.Qplatelet)}
                                                                                />
                                                                            ) : (
                                                                                
                                                                                <></>
                                                                            )}
                                                                        </td>
                                                                        {idx != 0 ? (
                                                                            <td className="tab-pad-8">
                                                                                <img
                                                                                    width="18px"
                                                                                    src="../Assets/images/Icon-material-delete.png"
                                                                                    alt=""
                                                                                    onClick={(e) => deleteplatelet(idx)}
                                                                                />
                                                                            </td>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <></>
                                                            )}

                                                            <tr>
                                                                <td className="tab-cell-left">
                                                                    <a>
                                                                        <img
                                                                            width="18px"
                                                                            src="../Assets/images/plus.png"
                                                                            alt=""
                                                                            onClick={addPlateletsDetailsRow}
                                                                        />
                                                                    </a>
                                                                    <a
                                                                        className="radio-text-padding"
                                                                        onClick={addPlateletsDetailsRow}
                                                                    >
                                                                        Select to add another platelet count
                                                                        observation
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>








                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>38.</span>
                                                <span className="quest-text-pad">
                                                    Please enter all platelet transfusion dates 
                                                    available for this patient between {""}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {index3months}
                                                    </span>{" "}
                                                    and{" "}
                                                    <span
                                                        style={{ color: PageDesignConstants.fontColor }}
                                                    >
                                                        {mindt}
                                                    </span>{" "}
                                                    in chronological order.
                                                </span>
                                            </div>
                                            
                                            <div className="answer-list-text double-dig-answer">
                                            <input
                                                 type="checkbox"
                                              
                                              
                                                name={"pt_ava_transfusionukn"}
                                                checked={
                                                    avaDetails.pt_ava_transfusionukn
                                                }
                                               onChange={HandlechangeAVAdetails}
                                            />
                                            <span className="radio-text-padding">
                                            Patient did not receive any platelet transfusions
                                            </span>
                                            
                                            </div>
                                            <div className="double-dig-answer">
                                            <ErrorField
                                            show={avaDetails.q38UknSelected?true:avaDetails.q38UknSelected}
                                            message={AvaTreatment_Msgs.TansfusionUnknwnSelected}
                                             />
                                           </div>
                                          
                                            <div></div>
                                            <div className="double-dig-answer">
                                                <div className="table-top">
                                                    <table>
                                                        <tbody>
                                                            <tr className="tab-row-border">
                                                                <th className="tab-cell-th tab-cell-border">
                                                                    Date
                                                                </th>
                                                                {/* <th className="tab-cell-th tab-cell-border">
                                                                    Platelet Count
                                                                </th> */}
                                                                <th />
                                                            </tr>
                                                            {avatransfusioncountDetails.length > 0 ? (
                                                                avatransfusioncountDetails.map((data, idx) => (
                                                                    <tr className="tab-row-border">
                                                                        <td className="tab-cell-left tab-cell-border">
                                                                            <DateControl
                                                                                marginLeft={false}
                                                                                optionId={"transfusion_dt_n"}
                                                                                HandleDateChange={HandleDateChange}
                                                                                ctrlId={"transfusion_dt_n-" + idx}
                                                                                date={data.transfusion_dt_n}
                                                                            />
                                                                            {(q38transfusiondt && idx === 0) ||
                                                                                data.q38plateletlessindexdt ||
                                                                                data.q38plateletmoreendfollowup ? (
                                                                                <ErrorField
                                                                                    show={
                                                                                        q38transfusiondt && idx === 0
                                                                                            ? q38transfusiondt
                                                                                            :   (data.q38plateletlessindexdt
                                                                                                ? data.q38plateletlessindexdt
                                                                                                : data.q38plateletmoreendfollowup)
                                                                                    }

                                                                                    message={
                                                                                        q38transfusiondt && idx === 0
                                                                                            ? AvaTreatment_Msgs.Q38a
                                                                                            :  (data.q38plateletlessindexdt
                                                                                                ? AvaTreatment_Msgs.TransfusionlessthanIndex
                                                                                                : (data.q38plateletmoreendfollowup
                                                                                                    ?(avaDetails.ava_disc_dt != "" &&
                                                                                                        new Date(data.transfusion_dt_n) >
                                                                                                        new Date(
                                                                                                            avaDetails.ava_disc_dt
                                                                                                        )
                                                                                                        ? AvaTreatment_Msgs.TransfusionmorethanAVAdisc
                                                                                                        : (new Date(data.transfusion_dt_n) >
                                                                                                            new Date(endfollowupdate)
                                                                                                            ? AvaTreatment_Msgs.TransfusionmorethanEndFollowup
                                                                                                            : ""))
                                                                                                    : ""))
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </td>

                                                                        {idx != 0 ? (
                                                                            <td className="tab-pad-8">
                                                                                <img
                                                                                    width="18px"
                                                                                    src="../Assets/images/Icon-material-delete.png"
                                                                                    alt=""
                                                                                    onClick={(e) => deletetransfusion(idx)}
                                                                                />
                                                                            </td>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <></>
                                                            )}

                                                            <tr>
                                                                <td className="tab-cell-left">
                                                                    <a>
                                                                        <img
                                                                            width="18px"
                                                                            src="../Assets/images/plus.png"
                                                                            alt=""
                                                                            onClick={addTransfusionDetailsRow}
                                                                        />
                                                                    </a>
                                                                    <a
                                                                        className="radio-text-padding"
                                                                        onClick={addTransfusionDetailsRow}
                                                                    >
                                                                        Select to add another platelet transfusion
                                                                        date
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate
                                    paths={navigatePaths}
                                    Save={onsaveclick}
                                 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Questionnaire;
