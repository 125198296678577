import React, { useState, useContext, useEffect } from "react";
import PatientDetails from "../../Components/PatientInformation";
import { HTTPResponse, SideMenuItems, ToastMessageType } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import { CommonError_Msgs, CommonMsgs } from "../../Helpers/HelperText";
import { PatientService } from "../../WebApiServices/Patient.service";
import { AddLocalStorageData, GetLocalStorageData, RemoveLocalStorageData } from "../../Helpers/Utilities";
import { AppContext } from "../../Contexts/AppContextProvider";
import { useNavigate } from "react-router-dom";

const ValidateSubmit = () => {

    const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout } = useContext(AppContext);
    const pid = GetLocalStorageData("patientId") ?? 0;
    const userId = GetLocalStorageData("userId") ?? 0;

    let patientData = GetLocalStorageData("patient");
    patientData = patientData && JSON.parse(patientData);
    const locked = patientData?.locked ?? false;
    const submit = patientData?.submit ?? 0;


    const [showSubmitBtn, setShowSubmitBtn] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    /* <summary>
    date: 07-11-2023
    Name: AP
    description: Display submit button if patient ready to be locked
    <summary>*/
    useEffect(() => {
debugger
        const FetchData = async () => {
            //if (isEligible) //not reqd as eligiblity check is done in service

           // ToggleLoader(true)
            if (!locked) {
                await SetPatientSubmitValue();
                setIsLoading(false)
            }
            else {
                setShowSubmitBtn(false)
                setIsLoading(false)
            }
           // ToggleLoader(false)
            //setIsLoading(false)
            //await AreAllFormsComplete();
        }
        FetchData();

        //if (submit === 1) {
        //FetchData();
        // } else
        //    setShowSubmitBtn(false);

    }, [submit, locked])

    /* <summary>
    date: 09-11-2023
    Name: AP
    description: Sets the patient submit value to 1 only if Eligible
    <summary>*/
    const SetPatientSubmitValue = async () => {

        try {
            const payload = {
                pid: pid,
                userId: userId
            }
            ToggleLoader(true)
            const response = await PatientService.SetPatientSubmitValue(payload);
            ToggleLoader(false)
            if (response?.status === HTTPResponse.OK) {
                // Set Patient Submit Value in LS
                const ptArr = { ...patientData }
                ptArr.submit = response?.data

                AddLocalStorageData("patient", JSON.stringify(ptArr))

                if (locked && response?.data === 2)
                    setShowSubmitBtn(false);
                if (response?.data === 1)
                    await AreAllFormsComplete()
                else
                    setShowSubmitBtn(false)
            }
            else {
                if (response?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                } else
                    throw (response.error)
            }

        } catch {
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }

    }
    const AreAllFormsComplete = async () => {
        
        try {
            const incompletePageIdsArr = []
            // if (submit === 1) {
            ToggleLoader(true)
            const validMenuItems = await PatientService.GetPatientConfigData(pid);//patient config data
            ToggleLoader(false)
            const {status}=validMenuItems;
            if(status !== HTTPResponse.OK){
                if (status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }
            }
            else{
                const { data } = validMenuItems;
                
                if (validMenuItems != null) {
                    if (!data.pd || data.pd !== 1) {
                        incompletePageIdsArr.push(SideMenuItems.Demographics);
                    }
                    if (!data.itpPriorAva || data.itpPriorAva !== 1) {
                        incompletePageIdsArr.push(SideMenuItems.TreatmentHistory);
                    }
                    if (!data.ava || data.ava !== 1) {//if null or not 1
                        incompletePageIdsArr.push(SideMenuItems.Questionnaire);
                    }
                    if (!data.itpFollAva || data.itpFollAva !== 1) {
                        incompletePageIdsArr.push(SideMenuItems.TreatmentHistoryAfterAVA);
                    }
                    if (!data.hru || data.hru !== 1) {
                        incompletePageIdsArr.push(SideMenuItems.HRU);
                    }
                    if (incompletePageIdsArr?.length === 0)
                        setShowSubmitBtn(true)
                }
            // } else
            //    setShowSubmitBtn(false)
            }
        } catch {

        }
    }

    const HandleSubmit = async () => {
        try {
            const payload = {
                pid: pid,
                userId: userId
            }
            
            ToggleLoader(true)
            const response = await PatientService.LockPatient(payload);
            ToggleLoader(false)
            if (response?.status === HTTPResponse.OK) {
                
                // Set Patient Locked Status in LS
                //submit=2 when patient is locked
                const ptArr = { ...patientData, locked: response?.data, submit: 2 }
                AddLocalStorageData("patient", JSON.stringify(ptArr))
                ShowToast(CommonMsgs.SubmitSuccess, ToastMessageType.Success);
            }
            else{
                
                if (response?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }else
                    throw (response.error) 
            }

        } catch {
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    }
    const ShowAlertBox = async () => {
        const choice = await ShowActionPopUp(
            "Alert",
            "Submitting this patient chart will prevent you from making further changes to the records.",
            "Ok", //Confirm button text            
        );
        if (!choice) {
            return;
        } else {
            HandleSubmit();
        }
    };
    const AddNew = () => {
        RemoveLocalStorageData("patientId");
        RemoveLocalStorageData("patient")
        return navigate("/Introduction");
    };
    const NavigateToDashboard = () => {
        navigate("/ManagePatients")
    }
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                </div>
            </section>
            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.ValidateSubmit} />
                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">

                                {isLoading ? <></> : (
                                    //showSubmitBtn === true ? <h4>Submit</h4> :
                                    locked ? <h4>SUBMITTED</h4> :
                                        <h4>VALIDATE & SUBMIT</h4>
                                )}

                            </div>
                            <div className="survey-introduction-section">
                                <div className="survey-introduction-content survey_Validate_submit">
                                    <div className="instructions">
                                        {
                                            isLoading ? <></> : (
                                                showSubmitBtn === true ?//Patient is ready to be submitted n locked
                                                    <center style={{ paddingTop: "100px", fontSize: "large" }}>
                                                        Please review the patient information you have entered. Click submit once you are ready to finish this chart.
                                                        Please note that once you submit the chart, no further changes can be made.
                                                    </center>
                                                    :
                                                    locked ?//&& submit === 2 //Patient is already submitted
                                                        <center style={{ paddingTop: "100px", fontSize: "large" }}>
                                                            Thank you for your participation.
                                                            Patient has been successfully submitted.
                                                            To add a new patient, please click on ‘Add new patient’ and to
                                                            return to the dashboard, click ‘Go to dashboard’.
                                                        </center>
                                                        ://Patient has some incomplete forms to be filled
                                                        <center style={{ paddingTop: "100px", fontSize: "large" }}>Some values have been left blank in the assessment form.
                                                            Please visit each page highlighted in <span style={{ color: "red" }}>red</span> to the left,
                                                            and click Save.
                                                        </center>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="survey-section-content-foot">
                                {showSubmitBtn &&
                                    <a
                                        className="btn save-btn"
                                        onClick={ShowAlertBox}
                                        style={{ fontWeight: "600", fontSize: "large" }}>Submit
                                    </a>
                                }
                                {locked &&
                                    <>
                                        <a
                                            className="btn save-btn"
                                            onClick={AddNew}
                                            style={{ fontWeight: "600", fontSize: "large", marginRight: "22px" }}>Add new patient
                                        </a>
                                        <a
                                            className="btn save-btn"
                                            onClick={NavigateToDashboard}
                                            style={{ fontWeight: "600", fontSize: "large" }}>Go to dashboard
                                        </a>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ValidateSubmit;
