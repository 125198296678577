import React from "react";

const Header = () => {
  return (
    <section className="header-section">
      <div className="container">
        <h5>           
          Real-world treatment patterns and outcomes in patients with 
          immune thrombocytopenia treated with Avatrombopag (AVA) in 
          the United States
        </h5>
        <h5>2.0 (REAL-AVA 2.0)</h5>
        <h4>PATIENT CASE REPORT FORM (CRF)</h4>
      </div>
    </section>
  );
};

export default Header;
