import React, { useContext, useEffect, useRef, useState } from "react";
import { AddLocalStorageData, GetLocalStorageData, GetLoggedInUserID, GetRandomAlphaNumber } from "../../Helpers/Utilities";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import { HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessageType, ToastMessages } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CommonError_Msgs } from "../../Helpers/HelperText";
import { PatientService } from "../../WebApiServices/Patient.service";

const Introduction = () => {

  //let patientID = GetLocalStorageData("patientId") ?? 0;
  //const uniqueEncryptedPatientId = GetLocalStorageData("uniqueEncryptedPatientId") ?? 0;
  const userId = GetLoggedInUserID() ?? 0;
  //const surveyDate = GetLocalStorageData("surveyDate") ?? 0;
  let patientData = GetLocalStorageData("patient")
  patientData = patientData && JSON.parse(patientData);

  const patientID = patientData?.patientId ?? 0;
  const centerId = patientData?.ptCenterId ?? 0;
  const abstractorId = patientData?.abstractorId ?? 0;
  const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
  const surveyDate = patientData?.surveyDate ?? "";

  const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext)
  const navigatePaths = {
    currPage: "/Introduction",
    nextPage: "/PatientScreening",
  };
  const [randomAlphabet, setRandomAlphabet] = useState();

  const SaveForm = async () => {
    try {
      if (patientID === 0)
        return await AddNewPatient();
      else {
        return true;
      }
    } catch {

    }
  }
  /* <summary>
date: 24-10-2023
Name: AP
description: Save New Patient 
<summary>*/
  const AddNewPatient = async () => {
    try {

      const payload = {
        AbstractorId: userId
      }
      ToggleLoader(true)
      const response = await PatientService.AddNewPatient(payload);
      ToggleLoader(false)
      if (response?.status === HTTPResponse.OK) {
        // setDetails({
        //   patientId: response?.data?.patientId,
        //   uniqueEncryptedPatientId: response?.data?.uniqueEncryptedPatientId,
        //   abstractionDate: response?.data?.abstractionDate
        // })
        AddLocalStorageData("patientId", response?.data?.patientId)
        //AddLocalStorageData("uniqueEncryptedPatientId", response?.data?.uniqueEncryptedPatientId);
        //AddLocalStorageData("surveyDate", response?.data?.abstractionDate);



        const ptArr = {
          patientId: response?.data?.patientId ?? 0,
          uniqueEncryptedPatientId: response?.data?.uniqueEncryptedPatientId ?? 0,
          surveyDate: response?.data?.abstractionDate ?? "",
          locked: false,
          ptCenterId: response?.data?.siteId ?? 0,
          abstractorId: response?.data?.abstractorId ?? 0
        };
        AddLocalStorageData("patient", JSON.stringify(ptArr))

        ShowToast("Patient has been saved successfully", ToastMessageType.Success);
        return true;
      } else {
        if(response?.status === HTTPResponse.Unauthorized){
          HandleSessionTimeout();
        }else{
          throw response.error;
        }
      }
    } catch {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  }
  /* <summary>
  date: 21-11-2023
  Name: AP
  description: Generate random alphabet
  <summary>*/
  useEffect(() => {
    GenerateRandomAlphabet()
  }, [])
  const GenerateRandomAlphabet = () => {
    setRandomAlphabet(GetRandomAlphaNumber())
  }

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SaveForm} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.Introduction} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>INTRODUCTION</h4>
              </div>
              <div className="survey-introduction-section">
                <div className="survey-introduction-content">
                  <div className="instructions">
                    <h5>INSTRUCTIONS</h5>
                    {/* <div class="note-list">
                              <span class="instructon-point-spacing font-style-spacing">[Assign a unique abstractor ID to respondent and unique Center ID code to each specific site]</span>
                          </div> */}
                    <div>
                      <ul>
                        <li className="font-style-spacing">
                          Patient ID # (Scrambled unique identifier):{" "}
                          <label className="font-style-value" htmlFor="">
                            {uniqueEncryptedPatientId !== 0 && uniqueEncryptedPatientId}
                          </label>
                        </li>
                        <li className="font-style-spacing">
                          Site / Center ID # (Center Identification Number):{" "}
                          <label className="font-style-value" htmlFor="">
                            {centerId !== 0 && centerId}
                          </label>
                        </li>
                        <li className="font-style-spacing">
                          Abstractor ID (Numeric identity of abstractor):{" "}
                          <label className="font-style-value" htmlFor="">
                            {abstractorId !== 0 && abstractorId}
                          </label>
                        </li>
                        <li className="font-style-spacing">
                          Date of Chart Data Abstraction Initiation:{" "}
                          <label className="font-style-value" htmlFor="">
                            {surveyDate !== 0 && surveyDate}

                          </label>
                        </li>
                      </ul>
                    </div>
                    <div className="">
                      <span>
                        The assigned unique patient identifier for this patient
                        is {" "}
                        {uniqueEncryptedPatientId !== 0 && (
                          <span
                            style={{ color: PageDesignConstants.fontColor }}
                          >
                            {uniqueEncryptedPatientId}
                          </span>
                        )}
                        . Please make a
                        note of the unique patient identifier for your records.
                        Unique patient identifiers will be used to help you keep
                        track of the charts you have abstracted for this study
                      </span>
                    </div>
                  </div>
                  <div className="note-border-box">
                    <div className="important-note">
                      <h5>IMPORTANT:</h5>
                      <div>
                        <ul>
                          <li>
                            Please note that the study includes only patients
                            with primary immune thrombocytopenia (ITP) who
                            initiated avatrombopag (AVA) treatment between July
                            2019 and June 2024. The patient may be alive or
                            deceased. The exact eligibility criteria are
                            detailed below.
                          </li>
                          <li>
                            If you have more than 30 patients who
                            meet the eligibility criteria below, then please
                            select 30 patients using the pre-specified random
                            selection methodology and only abstract data for
                            those selected patients.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <br />
                    <div className="meet-criteria">
                      <h5>PATIENT MUST MEET <u>ALL</u> OF THE FOLLOWING CRITERIA:</h5>
                      <div className="note-list">
                        <span>1.</span>
                        <span className="instructon-point-spacing">
                          Diagnosed with primary ITP
                        </span>
                      </div>
                      <div className="note-list">
                        <span>2.</span>
                        <span className="instructon-point-spacing">
                          Initiated treatment with AVA for ITP between July 2019
                          and June 2024
                        </span>
                      </div>
                      <div className="note-list">
                        <span>3.</span>
                        <span className="instructon-point-spacing">
                          At least 18 years of age at the date of AVA initiation
                        </span>
                      </div>
                      <div className="note-list">
                        <span>4.</span>
                        <span className="instructon-point-spacing">
                          Your medical center has complete medical records for
                          this patient for at least 3 months prior to AVA
                          initiation, including complete platelet count (PC) and
                          other ITP-related clinical data
                        </span>
                      </div>
                      <div className="note-list">
                        <span>5.</span>
                        <span className="instructon-point-spacing">
                          Patient has at least 1 PC record within 3 months prior
                          to AVA initiation
                        </span>
                      </div>
                      <div>
                        <div className="note-list">
                          <span>6.</span>
                          <span className="instructon-point-spacing">
                            Your medical center has complete medical records for
                            this patient for at least 6 months after AVA
                            initiation, including PC and other ITP-related
                            clinical data
                          </span>
                        </div>
                        <div className="sub-point-spacing">
                          <ul>
                            <li>
                              Patients who died within 6 months of AVA
                              initiation are still eligible for inclusion in
                              this study and require complete medical records
                              until their death
                            </li>
                            <li>
                              It is <u>not</u> required that the patient is currently
                              under your care, as long as you have at least 6
                              months of complete medical records for the patient
                              after they initiated AVA (or complete medical
                              records until death if the patient died within 6
                              months of initiating AVA)
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="note-list">
                        <span>7.</span>
                        <span className="instructon-point-spacing">
                          Patient must NOT have participated in any previous AVA
                          clinical trials, including the AVA-401 study, also
                          called “A Prospective Multi-center Open-label Study
                          Measuring Safety and Treatment Satisfaction in Adult
                          Subjects with Chronic Immune Thrombocytopenia (ITP)
                          after Switching to Avatrombopag from Eltrombopag or
                          Romiplostim”
                        </span>
                      </div>
                      <br />
                      <div className="note-list">
                        <span>
                          Patients are <u>not</u> required to stay on AVA for 6 months
                          to be eligible for the study. We are requiring
                          patients to have <b>a minimum of 6 months of complete
                            medical records (e.g., PC data, other treatments,
                            health-care resource use) following initiation of AVA, </b>
                          even if they discontinued AVA during that time.
                        </span>
                      </div>
                      <br />
                      <div className="note-list">
                        <span>
                          Thank you for participating in this chart review study. If you have any questions regarding the
                          use of this form, please contact the Analysis Group project team via REALAVA2.0@analysisgroup.com.
                        </span>
                      </div>
                      <div className="refresh-sec">
                        <div>
                          <span>
                            If your site has 30 or fewer patient charts that may be eligible for inclusion in the study,
                            please proceed with entering information for all patient charts at your site.
                            <br />
                            If your site has more than 30 patients who may be eligible for inclusion in the study,
                            please use the random letter generator below to generate a random letter.
                            Please proceed with entering data for all charts of patients whose last names begin with
                            the selected letter, going in alphabetical order. Once you have abstracted charts for
                            all patients whose last names’ begin with that letter, or if there are no patients whose
                            last names’ begin with that letter, please randomly generate a new letter and continue.
                            Please continue to generate random letters and abstract patient charts until you arrive at 30 charts.
                          </span>
                        </div>
                        <div>
                          <span className="refresh-letter">Random letter: {randomAlphabet}</span>{" "}
                          <a>
                            <img
                              onClick={GenerateRandomAlphabet}
                              width="25px"
                              src="../Assets/images/refresh-button.png"
                              alt="refresh" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SaveForm} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Introduction;
