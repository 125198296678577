import WebServiceWrapper from "./WebServiceWrapper";

export const TreatmentAfterAVAService = {
  GetTreatmentAfterAVAData,
  SaveTreatmentAfterAVAData
};

function GetTreatmentAfterAVAData(patientid) {
  debugger;
  return WebServiceWrapper.Get(`/treatmentAfterAva/GetTreatmentAfterAvaData?patientid=${patientid}`);
}
function SaveTreatmentAfterAVAData(data) {
  debugger;
  return WebServiceWrapper.Post(`/treatmentAfterAva/SaveTreatmentAfterAvaData`, data);
}