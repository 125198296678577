import React, { useContext, useEffect, useState } from 'react'
import PatientDetails from '../../Components/PatientInformation';
import SaveAndNavigate from '../../Components/SaveAndNavigate';
import { PageDesignConstants, SideMenuItems, HTTPResponse, ToastMessageType, ToastMessages } from '../../Helpers../../Helpers/Enums';
import SideBarMenu from '../../Components/SideBarMenu';
import { Dropdown } from 'react-bootstrap';
import ErrorField from "../../Components/ErrorField";
import { CommonError_Msgs, demographicScreen_Msgs } from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import { GetLocalStorageData, GetLoggedInUserID, isStringEmpty } from "../../Helpers/Utilities";
import { PatientDemographicsService } from "../../WebApiServices/PatientDemographics.service";


const Demographics = () => {
  const navigatePaths = {
    prevPage: "/PatientScreening",
    currPage: "/Demographics",
    nextPage: "/TreatmentHistory"
  }

  const pid = GetLocalStorageData("patientId") ?? 0;
  const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);

  useEffect(() => {

    async function FetchData() {
      loadUSStates()
      if (!isNaN(pid) && Number(pid) !== 0) {

          ToggleLoader(true);
          await LoadData();
          ToggleLoader(false);
      }
    }
    FetchData();
  }, [])

  const [ptDemographicsDetails, setPtDemographicsDetails] = useState({
    userId: GetLoggedInUserID(),
    pt_gender: "",
    race_white: "",
    race_hispanic: "",
    race_black: "",
    race_asian: "",
    race_native: "",
    race_pacific: "",
    race_other: "",
    race_other_txt: "",
    race_unk: "",
    pt_state: "",
    pt_education: "",
    pt_income: "",
    pt_employment: "",
    insure_comm: "",
    insure_mcare: "",
    insure_mcaid: "",
    insure_va: "",
    insure_oth: "",
    insure_none: false,
    insure_unk: false,
    lang_english: "",
    lang_spanish: "",
    lang_french: "",
    lang_german: "",
    lang_chinese: "",
    lang_tagolog: "",
    lang_viet: "",
    lang_korean: "",
    lang_arabic: "",
    lang_russian: "",
    lang_other: "",
    lang_other_txt: "",
    lang_unk: "",
    states: [],
  });

  const [showTopErrMsg, setShowTopErrMsg] = useState(false)

  const InitialHardErrList = {
    q16Empty: false,
    q17Empty: false,
    q17aEmpty: false,
    q18Empty: false,
    q19Empty: false,
    q20Empty: false,
    q21Empty: false,
    q22Empty: false,
    q23Empty: false,
    q23aEmpty: false,
  }
  const [hardErrList, setHardErrList] = useState(InitialHardErrList)


  async function loadUSStates() {
    const response = await PatientDemographicsService.GetUSStates();
    if (response?.status === HTTPResponse.OK) {
      const { data } = response;
      setPtDemographicsDetails((prev) => ({
        ...prev,
        states: data,
      }));
    }else if(response?.status === HTTPResponse.Unauthorized){
        HandleSessionTimeout();
    }
  }

  async function LoadData() {
    try {
      if (pid > 0) {

        const response = await PatientDemographicsService.GetAllDemographics(pid);
       if (response?.status === HTTPResponse.OK) {
          const { data } = response;
          setPtDemographicsDetails((prev) => ({
            ...prev,
            pt_gender: data.ptGender != null ? data.ptGender : "",// String(data.ptGender),
            race_white: data.raceWhite,
            race_hispanic: data.raceHispanic,
            race_black: data.raceBlack,
            race_asian: data.raceAsian,
            race_native: data.raceNative,
            race_pacific: data.racePacific,
            race_other: data.raceOther,
            race_other_txt: data.raceOtherTxt,
            race_unk: Number(data.raceUnk),
            pt_state: data.ptState,
            pt_education: data.ptEducation != null ? data.ptEducation : "",//String(data.ptEducation),
            pt_income: data.ptIncome != null ? data.ptIncome : "",//String(data.ptIncome),
            pt_employment: data.ptEmployment != null ? data.ptEmployment : "",//String(data.ptEmployment),
            insure_comm: data.insureComm,
            insure_mcare: data.insureMcare,
            insure_mcaid: data.insureMcaid,
            insure_va: data.insureVa,
            insure_oth: data.insureOth,
            insure_none: data.insureNone,
            insure_unk: data.insureUnk,
            lang_english: data.langEnglish,
            lang_spanish: data.langSpanish,
            lang_french: data.langFrench,
            lang_german: data.langGerman,
            lang_chinese: data.langChinese,
            lang_tagolog: data.langTagolog,
            lang_viet: data.langViet,
            lang_korean: data.langKorean,
            lang_arabic: data.langArabic,
            lang_russian: data.langRussian,
            lang_other: data.langOther,
            lang_other_txt: data.langOtherTxt,
            lang_unk: Number(data.langUnk),
          }))
          setSelectedState(data.stateValue != "" && data.stateValue != null ? data.stateValue : "Select State");

        }else if(response?.status === HTTPResponse.Unauthorized){
          HandleSessionTimeout();
        }
      }
    } catch (er) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const HandleDDChange = (item) => {
    setPtDemographicsDetails((prev) => ({
      ...prev,
      pt_state: item.stateid,
    }));
    setHardErrList((prev) => ({
      ...prev,
      q18Empty: false
    }))
  }


  const HandleFieldChange = (e) => {
    debugger
    const { name, id, value } = e.target;
    switch (name) {
      case "pt_employment": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value)//value,
        }));
        setHardErrList((prev) => ({
          ...prev,
          q21Empty: false
        }))
        break;
      }
      case "pt_gender": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value)//value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q16Empty: false
        }))
        break;
      }
      case "pt_education": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value)//value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q19Empty: false
        }))
        break;
      }
      case "pt_income": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: parseInt(value)//value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q20Empty: false
        }))
        break;
      }
      case "race_other_txt": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: value,
          race_other: true,
          race_unk: "",
        }));
        setHardErrList((prev) => ({
          ...prev,
          q17Empty: false,
          q17aEmpty: false
        }))
        break;
      }
      case "race_white": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.race_white,
          race_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q17Empty: false
        }))
        break;
      }
      case "race_hispanic": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.race_hispanic,
          race_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q17Empty: false
        }))
        break;
      }
      case "race_black": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.race_black,
          race_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q17Empty: false
        }))
        break;
      }
      case "race_asian": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.race_asian,
          race_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q17Empty: false
        }))
        break;
      }
      case "race_native": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.race_native,
          race_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q17Empty: false
        }))
        break;
      }
      case "race_pacific": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.race_pacific,
          race_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q17Empty: false
        }))
        break;
      }
      case "race_other": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.race_other,
          race_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q17Empty: false
        }))

        if (!isStringEmpty(value) && value === "on") {
          setPtDemographicsDetails((prev) => ({
            ...prev,
            race_other_txt: ""
          }))
        }
        break;
      }
      case "race_unk": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          race_white: "",
          race_hispanic: "",
          race_black: "",
          race_asian: "",
          race_native: "",
          race_pacific: "",
          race_other: "",
          race_other_txt: "",
        }));
        setHardErrList((prev) => ({
          ...prev,
          q17Empty: false
        }))
        break;
      }
      case "insure_comm": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.insure_comm,
          insure_none: "",
          insure_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q22Empty: false
        }))
        break;
      }
      case "insure_mcare": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.insure_mcare,
          insure_none: "",
          insure_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q22Empty: false
        }))
        break;
      }
      case "insure_mcaid": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.insure_mcaid,
          insure_none: "",
          insure_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q22Empty: false
        }))
        break;
      }
      case "insure_va": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.insure_va,
          insure_none: "",
          insure_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q22Empty: false
        }))
        break;
      }
      case "insure_oth": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.insure_oth,
          insure_none: "",
          insure_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q22Empty: false
        }))
        break;
      }
      case "insure_none": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: value === "on" ? true : false,
          insure_comm: false,
          insure_mcare: false,
          insure_mcaid: false,
          insure_va: false,
          insure_oth: false,
          insure_unk: false,
        }));
        setHardErrList((prev) => ({
          ...prev,
          q22Empty: false
        }))
        break;
      }
      case "insure_unk": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: value === "on" ? true : false,
          insure_comm: false,
          insure_mcare: false,
          insure_mcaid: false,
          insure_va: false,
          insure_oth: false,
          insure_none: false
        }));
        setHardErrList((prev) => ({
          ...prev,
          q22Empty: false
        }))
        break;
      }
      case "lang_english": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_english,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_spanish": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_spanish,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_french": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_french,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_german": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_german,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_chinese": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_chinese,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_tagolog": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_tagolog,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_viet": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_viet,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_korean": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_korean,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_arabic": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_arabic,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_russian": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_russian,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }
      case "lang_other": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: !prev.lang_other,
          lang_unk: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        if (!isStringEmpty(value) && value === "on") {
          setPtDemographicsDetails((prev) => ({
            ...prev,
            lang_other_txt: ""
          }))
        }
        break;
      }
      case "lang_other_txt": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: value,
          lang_other: true,
          lang_unk: "",
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23aEmpty: false,
          q23Empty: false
        }))
        break;
      }
      case "lang_unk": {
        setPtDemographicsDetails((prev) => ({
          ...prev,
          [name]: Number(value),
          lang_english: false,
          lang_spanish: false,
          lang_french: false,
          lang_german: false,
          lang_chinese: false,
          lang_tagolog: false,
          lang_viet: false,
          lang_korean: false,
          lang_arabic: false,
          lang_russian: false,
          lang_other: false,
          lang_other_txt: ""
        }));
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false
        }))
        break;
      }

      default:
        break;
    }
  }

  const ValidateForm = () => {
    let result = true;
    if (isStringEmpty(ptDemographicsDetails.pt_gender) || ptDemographicsDetails.pt_gender === "null") {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q16Empty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q16Empty: false
      }))
    }

    if (isStringEmpty(ptDemographicsDetails.race_white) && isStringEmpty(ptDemographicsDetails.race_hispanic) &&
      isStringEmpty(ptDemographicsDetails.race_black) && isStringEmpty(ptDemographicsDetails.race_asian) &&
      isStringEmpty(ptDemographicsDetails.race_native) && isStringEmpty(ptDemographicsDetails.race_pacific) &&
      isStringEmpty(ptDemographicsDetails.race_other) && isStringEmpty(ptDemographicsDetails.race_unk) ||
      (ptDemographicsDetails.race_white === false && ptDemographicsDetails.race_hispanic === false
        && ptDemographicsDetails.race_black === false && ptDemographicsDetails.race_asian === false &&
        ptDemographicsDetails.race_native === false && ptDemographicsDetails.race_pacific === false &&
        ptDemographicsDetails.race_other === false && ptDemographicsDetails.race_unk === "0")) {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q17Empty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q17Empty: false
      }))
    }
    if ((ptDemographicsDetails.race_other == "on" || ptDemographicsDetails.race_other === true) && isStringEmpty(ptDemographicsDetails.race_other_txt)) {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q17aEmpty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q17aEmpty: false
      }))
    }
    if (isStringEmpty(ptDemographicsDetails.pt_state) || ptDemographicsDetails.ptpt_state_gender === "null") {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q18Empty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q18Empty: false
      }))
    }
    if (isStringEmpty(ptDemographicsDetails.pt_education) || ptDemographicsDetails.pt_education === "null") {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q19Empty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q19Empty: false
      }))
    }
    if (isStringEmpty(ptDemographicsDetails.pt_income) || ptDemographicsDetails.pt_income === "null") {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q20Empty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q20Empty: false
      }))
    }
    if (isStringEmpty(ptDemographicsDetails.pt_employment) || ptDemographicsDetails.pt_employment === "null") {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q21Empty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q21Empty: false
      }))
    }
    if (isStringEmpty(ptDemographicsDetails.insure_comm) && isStringEmpty(ptDemographicsDetails.insure_mcare) &&
      isStringEmpty(ptDemographicsDetails.insure_mcaid) && isStringEmpty(ptDemographicsDetails.insure_va) &&
      isStringEmpty(ptDemographicsDetails.insure_oth) && isStringEmpty(ptDemographicsDetails.insure_none) &&
      isStringEmpty(ptDemographicsDetails.insure_unk) || (ptDemographicsDetails.insure_comm === false &&
        ptDemographicsDetails.insure_mcare === false && ptDemographicsDetails.insure_mcaid === false &&
        ptDemographicsDetails.insure_va === false && ptDemographicsDetails.insure_oth === false &&
        ptDemographicsDetails.insure_none === false && ptDemographicsDetails.insure_unk === false)) {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q22Empty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q22Empty: false
      }))
    }
    if (isStringEmpty(ptDemographicsDetails.lang_english) && isStringEmpty(ptDemographicsDetails.lang_spanish) &&
      isStringEmpty(ptDemographicsDetails.lang_french) && isStringEmpty(ptDemographicsDetails.lang_german) &&
      isStringEmpty(ptDemographicsDetails.lang_chinese) && isStringEmpty(ptDemographicsDetails.lang_tagolog) &&
      isStringEmpty(ptDemographicsDetails.lang_viet) && isStringEmpty(ptDemographicsDetails.lang_korean)
      && isStringEmpty(ptDemographicsDetails.lang_arabic) && isStringEmpty(ptDemographicsDetails.lang_russian)
      && isStringEmpty(ptDemographicsDetails.lang_other) && isStringEmpty(ptDemographicsDetails.lang_unk)
      || (ptDemographicsDetails.lang_english === false && ptDemographicsDetails.lang_lang_spanishenglish === false &&
        ptDemographicsDetails.lang_french === false && ptDemographicsDetails.lang_german === false &&
        ptDemographicsDetails.lang_chinese === false && ptDemographicsDetails.lang_tagolog === false &&
        ptDemographicsDetails.lang_viet === false && ptDemographicsDetails.lang_korean === false &&
        ptDemographicsDetails.lang_arabic === false && ptDemographicsDetails.lang_russian === false &&
        ptDemographicsDetails.lang_other === false)) {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q23Empty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q23Empty: false
      }))
    }
    if ((ptDemographicsDetails.lang_other == "on" || ptDemographicsDetails.lang_other === true) && isStringEmpty(ptDemographicsDetails.lang_other_txt)) {
      result = false
      setHardErrList((prev) => ({
        ...prev,
        q23aEmpty: true
      }))
    }
    else {
      setHardErrList((prev) => ({
        ...prev,
        q23aEmpty: false
      }))
    }
    return result;
  }

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      setHardErrList(InitialHardErrList);
      let isValid = validate ? ValidateForm() : true;
      if (isValid) {
        setShowTopErrMsg(false)
        const demographicModel = {
          patientId: pid,
          ptGender: ptDemographicsDetails.pt_gender,
          raceWhite: ptDemographicsDetails.race_white,
          raceHispanic: ptDemographicsDetails.race_hispanic,
          raceBlack: ptDemographicsDetails.race_black,
          raceAsian: ptDemographicsDetails.race_asian,
          raceNative: ptDemographicsDetails.race_native,
          racePacific: ptDemographicsDetails.race_pacific,
          raceOther: ptDemographicsDetails.race_other,
          raceOtherTxt: ptDemographicsDetails.race_other_txt,
          raceUnk: ptDemographicsDetails.race_unk,
          ptState: ptDemographicsDetails.pt_state,
          ptEducation: ptDemographicsDetails.pt_education,
          ptIncome: ptDemographicsDetails.pt_income,
          ptEmployment: ptDemographicsDetails.pt_employment,
          insureComm: ptDemographicsDetails.insure_comm,
          insureMcare: ptDemographicsDetails.insure_mcare,
          insureMcaid: ptDemographicsDetails.insure_mcaid,
          insureVa: ptDemographicsDetails.insure_va,
          insureOth: ptDemographicsDetails.insure_oth,
          insureNone: ptDemographicsDetails.insure_none,
          insureUnk: ptDemographicsDetails.insure_unk,
          langEnglish: ptDemographicsDetails.lang_english,
          langSpanish: ptDemographicsDetails.lang_spanish,
          langFrench: ptDemographicsDetails.lang_french,
          langGerman: ptDemographicsDetails.lang_german,
          langChinese: ptDemographicsDetails.lang_chinese,
          langTagolog: ptDemographicsDetails.lang_tagolog,
          langViet: ptDemographicsDetails.lang_viet,
          langKorean: ptDemographicsDetails.lang_korean,
          langArabic: ptDemographicsDetails.lang_arabic,
          langRussian: ptDemographicsDetails.lang_russian,
          langOther: ptDemographicsDetails.lang_other,
          langOtherTxt: ptDemographicsDetails.lang_other_txt,
          langUnk: ptDemographicsDetails.lang_unk,
          userId: GetLoggedInUserID(),
          isValidated: validate
        }
        if (pid > 0) {
          const response = await PatientDemographicsService.SaveDemographic(demographicModel);
          ToggleLoader(false);
          if (response.status !== HTTPResponse.OK){
            if(response?.status === HTTPResponse.Unauthorized){
              HandleSessionTimeout();
            }
            else
              throw response.error;
          }
          else
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
        }
        return true;
      }
      else {
       ToggleLoader(false);
        setShowTopErrMsg(true)
        return false;
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
    }
  }

  const [selectedState, setSelectedState] = useState('Select State');
  const [selectedStateId, setSelectedStateID] = useState(0);


  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.Demographics} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>PATIENT DEMOGRAPHICS</h4>
              </div>
              {showTopErrMsg && <div className="clean-error ">
                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
              </div>
              }
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div style={PageDesignConstants.PageDescriptionStyle}><i>This section will ask you about your patient’s demographic information.</i></div>
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>16.</span>
                        <span className="quest-text-pad">
                          What is the patient's sex? Please select one.
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <input type="radio"
                            id="q16Male1"
                            name="pt_gender"
                            value={1}
                            checked={
                              ptDemographicsDetails.pt_gender === 1//"1"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q16Male1">
                            <span className="radio-text-padding">Male</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <input type="radio"
                            id="q16Female2"
                            name="pt_gender"
                            value={2}
                            checked={
                              ptDemographicsDetails.pt_gender === 2//"2"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q16Female2">
                            <span className="radio-text-padding">Female</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <input type="radio"
                            id="q16OtherSex3"
                            name="pt_gender"
                            value={3}
                            checked={
                              ptDemographicsDetails.pt_gender === 3//"3"
                            }
                            onChange={HandleFieldChange} />

                          <label htmlFor="q16OtherSex3">
                            <span className="radio-text-padding">Other</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q16Empty} message={demographicScreen_Msgs.Q16} />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>17.</span>
                        <span className="quest-text-pad">
                          What is the patient's race/ethnicity? Please select all that apply.
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input type="checkbox"
                            id="race_white"
                            name="race_white"
                            checked={
                              ptDemographicsDetails.race_white
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="race_white">
                            <span className="radio-text-padding">White</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox"
                            id="race_hispanic"
                            name="race_hispanic"
                            checked={
                              ptDemographicsDetails.race_hispanic
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="race_hispanic">
                            <span className="radio-text-padding">
                              Hispanic, Latino or Spanish origin
                            </span></label>
                        </div>
                        <div className="">
                          <input type="checkbox"
                            id="race_black"
                            name="race_black"
                            checked={
                              ptDemographicsDetails.race_black
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="race_black">
                            <span className="radio-text-padding">
                              Black or African American
                            </span></label>
                        </div>
                        <div className="">
                          <input type="checkbox"
                            id="race_asian"
                            name="race_asian"
                            checked={
                              ptDemographicsDetails.race_asian
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="race_asian">
                            <span className="radio-text-padding">Asian</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox"
                            id="race_native"
                            name="race_native"
                            checked={
                              ptDemographicsDetails.race_native
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="race_native">
                            <span className="radio-text-padding">
                              American Indian or Alaska Native
                            </span></label>
                        </div>

                        <div className="">
                          <input type="checkbox"
                            id="race_pacific"
                            name="race_pacific"
                            checked={
                              ptDemographicsDetails.race_pacific
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="race_pacific" >
                            <span className="radio-text-padding">
                              Native Hawaiian or Other Pacific Islander
                            </span></label>
                        </div>
                        <div className="">
                          <input type="checkbox"
                            id="race_other"
                            name="race_other"
                            checked={
                              ptDemographicsDetails.race_other
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="race_other" >
                            <span className="radio-text-padding">
                              Other, please specify: <input className="input-dash" name="race_other_txt" type="text"
                                value={
                                  ptDemographicsDetails.race_other_txt
                                }
                                maxLength={100}
                                onChange={HandleFieldChange} />
                            </span></label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="q17Raceunknown"
                            name="race_unk"
                            value={1}
                            checked={
                              ptDemographicsDetails.race_unk === 1
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q17Raceunknown" >
                            <span className="radio-text-padding">Unknown</span></label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q17Empty} message={demographicScreen_Msgs.Q17} />
                      <ErrorField show={hardErrList.q17aEmpty} message={demographicScreen_Msgs.Q17a} />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="answer-list-text">
                        <div className="question question-weight">
                          <span>18.</span>
                          <span className="quest-text-pad">
                            What state does the patient reside in?
                          </span>
                        </div>
                        <div className="quest-text-pad">
                          <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic" className="custom-dropdown-button" style={{ width: '150px' }}>
                              {selectedState}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{
                              maxHeight: '150px', overflowY: 'scroll',
                              overflowX: 'hidden'
                            }}>
                              {ptDemographicsDetails.states.map((item) => (
                                <Dropdown.Item
                                  as="button"
                                  name="pt_state"
                                  key={item.stateid}
                                  value={item.stateid}
                                  className={`custom-dropdown-item ${item.statename === selectedState && 'custom-dropdown-item-selected'}`}
                                  style={{ width: "100% !important" }}
                                  onClick={() => { setSelectedState(item.statename); setSelectedStateID(item.stateid); HandleDDChange(item) }}
                                >
                                  {/* {ptDemographicsDetails.pt_state} */}
                                  {item.statename}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>


                        </div>
                      </div>
                      <ErrorField show={hardErrList.q18Empty} message={demographicScreen_Msgs.Q18} />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>19.</span>
                        <span className="quest-text-pad">
                          What is the highest level of education the patient has completed?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input
                            type="radio"
                            id="q19Education1"
                            name="pt_education"
                            value={1}
                            checked={
                              ptDemographicsDetails.pt_education === 1//"1"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q19Education1" >
                            <span className="radio-text-padding">Less than high school</span></label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="q19Education2"
                            name="pt_education"
                            value={2}
                            checked={
                              ptDemographicsDetails.pt_education === 2//"2"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q19Education2" >
                            <span className="radio-text-padding">High school</span></label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="q19Education3"
                            name="pt_education"
                            value={3}
                            checked={
                              ptDemographicsDetails.pt_education === 3//"3"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q19Education3" >
                            <span className="radio-text-padding">Some college</span></label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="q19Education4"
                            name="pt_education"
                            value={4}
                            checked={
                              ptDemographicsDetails.pt_education === 4//"4"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q19Education4" >
                            <span className="radio-text-padding">College degree</span></label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="q19Education5"
                            name="pt_education"
                            value={5}
                            checked={
                              ptDemographicsDetails.pt_education === 5//"5"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q19Education5" >
                            <span className="radio-text-padding">Graduate degree</span></label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="q19Education99"
                            name="pt_education"
                            value={99}
                            checked={
                              ptDemographicsDetails.pt_education === 99//"99"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q19Education99" >
                            <span className="radio-text-padding">Not documented/Unknown</span></label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q19Empty} message={demographicScreen_Msgs.Q19} />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>20.</span>
                        <span className="quest-text-pad">
                          What is the patient's household income?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input type="radio"
                            id="q20Income1"
                            name="pt_income"
                            value={1}
                            checked={
                              ptDemographicsDetails.pt_income === 1//"1"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Income1" >
                            <span className="radio-text-padding">Less than $25,000</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Income2"
                            name="pt_income"
                            value={2}
                            checked={
                              ptDemographicsDetails.pt_income === 2//"2"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Income2" >
                            <span className="radio-text-padding">$25,000 to $49,999 </span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Income3"
                            name="pt_income"
                            value={3}
                            checked={
                              ptDemographicsDetails.pt_income === 3//"3"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Income3" >
                            <span className="radio-text-padding">$50,000 to $74,999 </span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Income4"
                            name="pt_income"
                            value={4}
                            checked={
                              ptDemographicsDetails.pt_income === 4//"4"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Income4" >
                            <span className="radio-text-padding">$75,000 to $99,999</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Income5"
                            name="pt_income"
                            value={5}
                            checked={
                              ptDemographicsDetails.pt_income === 5//"5"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Income5" >
                            <span className="radio-text-padding">$100,000 to $149,999</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Income6"
                            name="pt_income"
                            value={6}
                            checked={
                              ptDemographicsDetails.pt_income === 6//"6"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Income6" >
                            <span className="radio-text-padding">$150,000 and over</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Income99"
                            name="pt_income"
                            value={99}
                            checked={
                              ptDemographicsDetails.pt_income === 99//"99"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Income99" >
                            <span className="radio-text-padding">Not documented/Unknown</span></label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q20Empty} message={demographicScreen_Msgs.Q20} />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>21.</span>
                        <span className="quest-text-pad">
                          What best describes the patient's employment status?
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input type="radio" id="q20Employment1"
                            name="pt_employment"
                            value={1}
                            checked={
                              ptDemographicsDetails.pt_employment === 1//"1"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Employment1" >
                            <span className="radio-text-padding">Employed full time</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Employment2"
                            name="pt_employment"
                            value={2}
                            checked={
                              ptDemographicsDetails.pt_employment === 2//"2"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Employment2" >
                            <span className="radio-text-padding">Employed part time</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Employment3"
                            name="pt_employment"
                            value={3}
                            checked={
                              ptDemographicsDetails.pt_employment === 3//"3"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Employment3" >
                            <span className="radio-text-padding">Student</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Employment4"
                            name="pt_employment"
                            value={4}
                            checked={
                              ptDemographicsDetails.pt_employment === 4//"4"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Employment4" >
                            <span className="radio-text-padding">Retired</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Employment5"
                            name="pt_employment"
                            value={5}
                            checked={
                              ptDemographicsDetails.pt_employment === 5//"5"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Employment5" >
                            <span className="radio-text-padding">On disability</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Employment6"
                            name="pt_employment"
                            value={6}
                            checked={
                              ptDemographicsDetails.pt_employment === 6//"6"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Employment6" >
                            <span className="radio-text-padding">Homemaker</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Employment7"
                            name="pt_employment"
                            value={7}
                            checked={
                              ptDemographicsDetails.pt_employment === 7//"7"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Employment7" >
                            <span className="radio-text-padding">Self-employed</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Employment8"
                            name="pt_employment"
                            value={8}
                            checked={
                              ptDemographicsDetails.pt_employment === 8//"8"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Employment8" >
                            <span className="radio-text-padding">Not employed</span></label>
                        </div>
                        <div className="">
                          <input type="radio" id="q20Employment9"
                            name="pt_employment"
                            value={9}
                            checked={
                              ptDemographicsDetails.pt_employment === 9//"9"
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="q20Employment9" >
                            <span className="radio-text-padding">Unknown</span></label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q21Empty} message={demographicScreen_Msgs.Q21} />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>22.</span>
                        <span className="quest-text-pad">
                          What type(s) of insurance does the patient currently have? Please
                          select all that apply.
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input type="checkbox" id="insure_comm"
                            name="insure_comm"
                            checked={
                              ptDemographicsDetails.insure_comm
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="insure_comm">
                            <span className="radio-text-padding">
                              Commercial/private insurance
                            </span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="insure_mcare"
                            name="insure_mcare"
                            checked={
                              ptDemographicsDetails.insure_mcare
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="insure_mcare">
                            <span className="radio-text-padding">Medicare</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="insure_mcaid"
                            name="insure_mcaid"
                            checked={
                              ptDemographicsDetails.insure_mcaid
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="insure_mcaid">
                            <span className="radio-text-padding">Medicaid</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="insure_va"
                            name="insure_va"
                            checked={
                              ptDemographicsDetails.insure_va
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="insure_va">
                            <span className="radio-text-padding">
                              Military insurance (VA or active military)
                            </span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="insure_oth"
                            name="insure_oth"
                            checked={
                              ptDemographicsDetails.insure_oth
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="insure_oth">
                            <span className="radio-text-padding">Other</span></label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="insure_none1"
                            name="insure_none"
                            checked={
                              ptDemographicsDetails.insure_none
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="insure_none1">
                            <span className="radio-text-padding">None</span></label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="insure_unk1"
                            name="insure_unk"
                            checked={
                              ptDemographicsDetails.insure_unk
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="insure_unk1">
                            <span className="radio-text-padding">Unknown</span></label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q22Empty} message={demographicScreen_Msgs.Q22} />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>23.</span>
                        <span className="quest-text-pad">
                          What is the patient's primary language spoken at home? Please select all
                          that apply.
                        </span>
                      </div>
                      <div className=" double-dig-answer">
                        <div className="">
                          <input type="checkbox" id="lang_english"
                            name="lang_english"
                            checked={
                              ptDemographicsDetails.lang_english
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_english">
                            <span className="radio-text-padding">English</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_spanish"
                            name="lang_spanish"
                            checked={
                              ptDemographicsDetails.lang_spanish
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_spanish">
                            <span className="radio-text-padding">
                              Spanish or Spanish Creole
                            </span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_french"
                            name="lang_french"
                            checked={
                              ptDemographicsDetails.lang_french
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_french">
                            <span className="radio-text-padding">French</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_german"
                            name="lang_german"
                            checked={
                              ptDemographicsDetails.lang_german
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_german">
                            <span className="radio-text-padding">German</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_chinese"
                            name="lang_chinese"
                            checked={
                              ptDemographicsDetails.lang_chinese
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_chinese">
                            <span className="radio-text-padding">Chinese</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_tagolog"
                            name="lang_tagolog"
                            checked={
                              ptDemographicsDetails.lang_tagolog
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_tagolog">
                            <span className="radio-text-padding">Tagalog</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_viet"
                            name="lang_viet"
                            checked={
                              ptDemographicsDetails.lang_viet
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_viet">
                            <span className="radio-text-padding">Vietnamese</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_korean"
                            name="lang_korean"
                            checked={
                              ptDemographicsDetails.lang_korean
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_korean">
                            <span className="radio-text-padding">Korean</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_arabic"
                            name="lang_arabic"
                            checked={
                              ptDemographicsDetails.lang_arabic
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_arabic">
                            <span className="radio-text-padding">Arabic</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_russian"
                            name="lang_russian"
                            checked={
                              ptDemographicsDetails.lang_russian
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_russian">
                            <span className="radio-text-padding">Russian</span></label>
                        </div>
                        <div className="">
                          <input type="checkbox" id="lang_other"
                            name="lang_other"
                            checked={
                              ptDemographicsDetails.lang_other
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_other">
                            <span className="radio-text-padding">
                              Other, please specify:{" "}
                              <input className="input-dash" name="lang_other_txt" type="text" value={
                                ptDemographicsDetails.lang_other_txt
                              }
                                maxLength={100}
                                onChange={HandleFieldChange} />
                            </span></label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            id="lang_unk"
                            name="lang_unk"
                            value={1}
                            checked={
                              ptDemographicsDetails.lang_unk === 1
                            }
                            onChange={HandleFieldChange} />
                          <label htmlFor="lang_unk">
                            <span className="radio-text-padding">Not documented/Unknown</span></label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q23Empty} message={demographicScreen_Msgs.Q23} />
                      <ErrorField show={hardErrList.q23aEmpty} message={demographicScreen_Msgs.Q23a} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default Demographics