import React, { useState, useEffect, useContext } from 'react'
import PatientDetails from '../../Components/PatientInformation';
import SaveAndNavigate from '../../Components/SaveAndNavigate';
import { PageDesignConstants, SideMenuItems, HTTPResponse, ToastMessages, ToastMessageType } from '../../Helpers/Enums';
import SideBarMenu from '../../Components/SideBarMenu';
import DateControl from '../../Components/DateControl';
import { CommonError_Msgs, TreatmentAfterAVA_Msgs } from "../../Helpers/HelperText";
import { TreatmentAfterAVAService } from "../../WebApiServices/TreatmentAfterAVA.service";
import ErrorField from "../../Components/ErrorField";
import SwitchedTreatment from '../../Components/SwitchedTreatment';
import {
    GetLocalStorageData,
    GetLoggedInUserID,
} from "../../Helpers/Utilities";
import { AppContext } from '../../Contexts/AppContextProvider';
const NUMBER_REGEX = /^[0-9]{0,9}$/;
const TreatmentAfterAVA = () => {

    const navigatePaths = {
        prevPage: "/Questionnaire",
        currPage: "/TreatmentAfterAVA",
        nextPage: "/Healthcare"
    }


    const pid = GetLocalStorageData("patientId") ?? 0;
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);
    const [frequencytime, setfrequencytime] = useState([]);
    const [steroidType, setSteroidType] = useState([]);
    const [indexdate, setindexdate] = useState("");
    const [endfollowupdate, setendfollowupdate] = useState("");
    const [avadiscdate, setavadiscdate] = useState("");
    const [minenddiscdate, setminenddiscdate] = useState("");
    const { ToggleLoader, ShowToast, HandleSessionTimeout } = useContext(AppContext);

    /* Main*/
    const [ITPTreatmentPostAVADetails, setITPTreatmentPostAVADetails] = useState({
        itptreatmentpostavaid: 0,
        post_ava_steroid_change: "",
        post_ava_new_ster_start: "",
        post_ava_disc_treat_unk: "",
        post_ava_other_treat_unk: "",

        q39Empty: false,
        q39EmptyError: false,

        q41checkbox1: false,
        q41showrepeater1: false,
        q41checkbox2: false,
        q41showrepeater2: false,
        q41radiobutton: false,
        q41Empty: false,

        q42Selection: "",
        q42Checkbox1Checked: false,
        q42Checkbox2Checked: false,
        q42Empty: false,

        q43Selection: "",
        q43Checkbox1Checked: false,
        q43Checkbox2Checked: false,
        q43Empty: false,

        defDateQ44: false,
        q44show: "",
        post_ava_splen: "",
        post_ava_splen_dt: "",
        post_ava_splen_dt_unk: "",
        q44DateInvalidSoft1: false,
        q44DateInvalidSoft2: false,
        q44DateInvalidSoft3: false,
        q44Empty: false,
        q44DataEmpty: false,
    });

    /*Q38*/
    const [showq38, setshowq38] = useState(false);
    const [showq38parentrepeater, setshowq38parentrepeater] = useState(false);
    const [q38MainSteroidReceivedDetails, setq38MainSteroidReceivedDetails] = useState(
        [{
            steroidreceivedid: 0,
            steroidtypeid: 0,
            frequencytimeid: 0,
            post_ava_b1_tx_change_doseFrequency: false,
            post_ava_b1_tx_change_discontinuation: false,
            post_ava_e1_tx_disc: "",
            post_ava_f1_tx_disc_dt: "",
            // post_ava_ster_disc_dt_unk: "",
            defEDateQ38: false,
            q38EDoseEdatechange: false,
            q38EdoseChangelessindexdt: false,
            showq38childrepeater: false,
            q38bEmpty: false,
            q3FSteroidDateInvalidSoft1: false,
            q3FSteroidDateInvalidSoft2: false,
            q3FSteroidDateInvalidSoft3: false,
            q3FSteroidDateInvalidSoft4: false,
            q39_empty: false,
            q39a_empty: false,
            q39e_empty: false,
            q39e_f_empty: false,
            q38Checkbox: false,
            showQ38f: false,
            steroidreceiveddosagechangeDetails: [{
                steroidreceiveddosagechangeid: 0,
                steroidtypeid: "",
                post_ava_b1x1_tx_change_dt: "",
                // post_ava_b1x1_tx_change_dt_unk: "",
                q3BSteroidDateInvalidSoft1: false,
                q3BSteroidDateInvalidSoft2: false,
                q3BSteroidDateInvalidSoft3: false,
                post_ava_c1x1_tx_dose: "",
                post_ava_d1x1_tx_dose_num: "",
                frequencytimeid: 0,
                defDateQ38: false,
                q38Dosedatechange: false,
                q38doseChangelessindexdt: false,
                q39child_empty: false,
                q38doselimitexceeded: false,
                q38freqlimitexceeded: false,
                q38RepeaterbEmpty: false,
                q38RepeatercEmpty: false,
                q38RepeaterdEmpty: false,
            }]
        },
        ]);
    /*Q38*/

    /*Q39*/
    /*const [showq39, setshowq39] = useState(false);*/  {/*Showing/Hiding Q40 logic removed - #11104*/ }
    const [showq39parentrepeater, setshowq39parentrepeater] = useState(false);
    const [q39SteroidCourseDetails, setq39SteroidCourseDetails] = useState([
        {
            steroidcourseid: 0,
            steroidtypeid: 0,
            new_tx_start_dt_b1: "",
            /*new_tx_start_dt_b1_unk: "",*/
            new_tx_dose_amt_c1: "",
            new_tx_dose_num_d1: "",
            frequencytimeid: "",
            new_tx_dose_chg_e1: "",
            new_tx_disc_i1: "",
            new_tx_disc_dt_j1: "",
            /*new_tx_disc_dt_j1_unk: "",*/
            showq39j: false,
            defDateQ39b: false,
            defDateQ39j: false,
            showq39childrepeater: false,

            q39aEmpty: false,
            q39bEmpty: false,
            q39bDateInvalidSoft1: false,
            q39bDateInvalidSoft2: false,
            q39bDateInvalidSoft3: false,
            q39cEmpty: false,
            q39dEmpty: false,
            q39eEmpty: false,
            q39iEmpty: false,
            q39jEmpty: false,
            q39jDateInvalidSoft1: false,
            q39jDateInvalidSoft2: false,
            q39jDateInvalidSoft3: false,
            q39doselimitexceeded: false,
            q39freqlimitexceeded: false,

            q39SteroidcoursedosagechangeDetails: [
                {
                    steroidcoursedosagechangeid: 0,
                    steroidtypeid: 0,
                    new_tx_chg_dt_f1x1: "",
                    /*new_tx_chg_dt_f1x1_unk: "",*/
                    new_tx_chg_dose_g1x1: "",
                    new_tx_chg_num_h1x1: "",
                    frequencytimeid: "",
                    defDateQ39f: false,

                    q39fEmpty: false,
                    q39fDateInvalidSoft1: false,
                    q39fDateInvalidSoft2: false,
                    q39fDateInvalidSoft3: false,
                    q39gEmpty: false,
                    q39hEmpty: false,
                    q39doselimitexceeded: false,
                    q39freqlimitexceeded: false,
                }
            ],
        },
    ]);
    /*Q39*/

    /*Q41*/
    const [q41ivigdatesDetails, setq41ivigdatesDetails] = useState([
        {
            ivigdatesid: 0,
            ivigtherapytypeid: 0,
            postAvaA1TxStartDt: "",
            postAvaA1TxStartDtUnk: "",
            postAvaB1TxEndDt: "",
            postAvaB1TxEndDtUnk: "",
            defDateQ41A: false,
            defDateQ41B: false,

            q41iviaEmpty: false,
            q41iviaDateInvalidSoft1: false,
            q41iviaDateInvalidSoft2: false,
            q41iviaDateInvalidSoft3: false,
            q41ivibEmpty: false,
            q41ivibDateInvalidSoft1: false,
            q41ivibDateInvalidSoft2: false,
            q41ivibDateInvalidSoft3: false,
        },
    ]);
    const [q41antiddatesDetails, setq41antiddatesDetails] = useState([
        {
            ivigdatesid: 0,
            ivigtherapytypeid: 0,
            postAvaA1TxStartDt: "",
            postAvaA1TxStartDtUnk: "",
            postAvaB1TxEndDt: "",
            postAvaB1TxEndDtUnk: "",
            defDateQ41A: false,
            defDateQ41B: false,

            q41antidaEmpty: false,
            q41antidaDateInvalidSoft1: false,
            q41antidaDateInvalidSoft2: false,
            q41antidaDateInvalidSoft3: false,
            q41antidbEmpty: false,
            q41antidbDateInvalidSoft1: false,
            q41antidbDateInvalidSoft2: false,
            q41antidbDateInvalidSoft3: false,
        },
    ]);
    /*Q41*/

    /*Q42*/
    const [showq42, setshowq42] = useState(false);
    const [showq42Checkbox1Section, setshowq42Checkbox1Section] = useState(false);
    const [showq42Checkbox2Section, setshowq42Checkbox2Section] = useState(false);
    const [q42itpdiscontinuationdatesCheckbox1Details, setq42itpdiscontinuationdatesCheckbox1Details] = useState([
        {
            Itpdiscontinuationdateid: 0,
            Itptreamenttypeid: 0,
            PostAvaTxDiscDt: "",
            PostAvaTxDiscDtUnk: "",
            defDateQ42: false,
            q42Empty: false,
            q42DateInvalidSoft1: false,
            q42DateInvalidSoft2: false,
            q42DateInvalidSoft3: false,
        }]);
    const [q42itpdiscontinuationdatesCheckbox2Details, setq42itpdiscontinuationdatesCheckbox2Details] = useState([
        {
            Itpdiscontinuationdateid: 0,
            Itptreamenttypeid: 0,
            PostAvaTxDiscDt: "",
            PostAvaTxDiscDtUnk: "",
            defDateQ42: false,
            q42Empty: false,
            q42DateInvalidSoft1: false,
            q42DateInvalidSoft2: false,
            q42DateInvalidSoft3: false,
        }]);
    /*Q42*/

    /*Q43*/
    const [showq43, setshowq43] = useState(false);
    const [showq43Checkbox1Section, setshowq43Checkbox1Section] = useState(false);
    const [showq43Checkbox2Section, setshowq43Checkbox2Section] = useState(false);
    const [q43otherItpTreatmentsModalCheckbox1Details, setq43otherItpTreatmentsModalCheckbox1Details] = useState([
        {
            Otheritptreatmentsid: 0,
            Itptreatmenttypeid: 0,
            PostAvaTxStartDt: "",
            PostAvaTxDisc: "",
            PostAvaTxDiscDt: "",
            q43Empty: false,
            q43aEmpty: false,
            q43bEmpty: false,
            q43cEmpty: false,

            defDate1Q43: false,
            q43Date1InvalidSoft1: false,
            q43Date1InvalidSoft2: false,
            q43Date1InvalidSoft3: false,

            defDate2Q43: false,
            q43Date2InvalidSoft1: false,
            q43Date2InvalidSoft2: false,
            q43Date2InvalidSoft3: false,
        }]);
    const [q43otherItpTreatmentsModalCheckbox2Details, setq43otherItpTreatmentsModalCheckbox2Details] = useState([
        {
            Otheritptreatmentsid: 0,
            Itptreatmenttypeid: 0,
            PostAvaTxStartDt: "",
            PostAvaTxDisc: "",
            PostAvaTxDiscDt: "",
            q43Empty: false,
            q43aEmpty: false,
            q43bEmpty: false,
            q43cEmpty: false,

            defDate1Q43: false,
            q43Date1InvalidSoft1: false,
            q43Date1InvalidSoft2: false,
            q43Date1InvalidSoft3: false,

            defDate2Q43: false,
            q43Date2InvalidSoft1: false,
            q43Date2InvalidSoft2: false,
            q43Date2InvalidSoft3: false,
        }]);
    /*Q43*/

    /*Q45*/
    const [showq45, setshowq45] = useState(false);
    const [Q45HandleControlState, setQ45HandleControlState] = useState({
        post_ava_switch_elt: false,
        Q45Empty: false,
        post_ava_switch_elt_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        post_ava_switch_romi: false,
        post_ava_switch_romi_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        post_ava_switch_lusu: false,
        post_ava_switch_lusu_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        post_ava_switch_steroid: false,
        post_ava_switch_steroid_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        post_ava_switch_ivig: false,
        post_ava_switch_ivig_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        post_ava_switch_antid: false,
        post_ava_switch_antid_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        post_ava_switch_ritux: false,
        post_ava_switch_ritux_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        post_ava_switch_fosta: false,
        post_ava_switch_fosta_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        post_ava_switch_immuno: false,
        post_ava_switch_immuno_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        //other op
        post_ava_switch_other: false,
        post_ava_switch_other_txt: "",
        post_ava_switch_other_txt_empty: false,
        post_ava_switch_other_Value: {
            itptreatmentsfollowingavaid: 0,
            itptreatmentsfollowingavatypeid: 0,
            post_ava_tx_switch_dt: "",
            post_ava_tx_switch_reason: "",
            post_ava_tx_switch_oth_txt: "",
            q45DateInvalidSoft1: false,
            q45DateInvalidSoft2: false,
            Q45EmptyFields: false,
            showSoftErrorMsg1: "",
            showSoftAErrorMsg: "",
            showSoftErrorMsg2: "",
            q45aEmptyErrorMsg: false,
            q45bEmptyErrorMsg: false,
            q45baEmptyErrorMsg: false,
        },
        post_ava_switch_none: "",
    });
    /*Q45*/


    useEffect(() => {
        async function FetchData() {
            if (!isNaN(pid) && Number(pid) !== 0) {

                ToggleLoader(true);
                await GettreatmentAfterAVAData();
                ToggleLoader(false);
            }
        }
        FetchData();
        //GettreatmentAfterAVAData();
    }, []);

    function formatInput(e) {

        let checkIfNum;
        if (e.key !== undefined) {
            checkIfNum = e.key === "e" || e.key === "-" || e.key == "E";
        } else if (e.keyCode !== undefined) {
            checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
    }

    function Handlechange(e) {
        debugger;
        let name = e.target.name;
        const splitNameArray = name.split("-");
        let id = e.target.id;

        if (e.target.name === "post_ava_steroid_change" && e.target.value === "1") {
            setshowq38parentrepeater(true);
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
                q39EmptyError: false,
            }));

            let Q38array = [...q38MainSteroidReceivedDetails];
            setq38MainSteroidReceivedDetails(Q38array);


        }

        else if (e.target.name === "post_ava_steroid_change" && e.target.value === "0") {
            setshowq38parentrepeater(false);
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
                q39EmptyError: false,
            }));
            setq38MainSteroidReceivedDetails([
                {
                    steroidreceivedid: 0,
                    frequencytimeid: 0,
                    post_ava_b1_tx_change_doseFrequency:false,
                    post_ava_b1_tx_change_discontinuation: false,
                    post_ava_e1_tx_disc: "",
                    post_ava_f1_tx_disc_dt: "",
                    // post_ava_ster_disc_dt_unk: "",
                    q3FSteroidDateInvalidSoft1: false,
                    q3FSteroidDateInvalidSoft2: false,
                    q3FSteroidDateInvalidSoft3: false,
                    q3FSteroidDateInvalidSoft4: false,
                    q39_empty: false,
                    q39a_empty: false,
                    q39e_empty: false,
                    q39e_f_empty: false,
                    defDateQ38: false,
                    q38Dosedatechange: false,
                    q38doseChangelessindexdt: false,
                    defEDateQ38: false,
                    q38EDoseEdatechange: false,
                    q38EdoseChangelessindexdt: false,
                    showq38childrepeater: false,
                    q38bEmpty: false,
                    showq38childrepeater: false,
                    steroidreceiveddosagechangeDetails: [{
                        steroidreceiveddosagechangeid: 0,
                        steroidtypeid: "",
                        post_ava_b1x1_tx_change_dt: "",
                        // post_ava_b1x1_tx_change_dt_unk: "",
                        post_ava_c1x1_tx_dose: "",
                        post_ava_d1x1_tx_dose_num: "",
                        q3BSteroidDateInvalidSoft1: false,
                        q3BSteroidDateInvalidSoft2: false,
                        q3BSteroidDateInvalidSoft3: false,
                        frequencytimeid: 0,
                        q39child_empty: false,
                        q38doselimitexceeded: false,
                        q38freqlimitexceeded: false,
                        q38RepeaterbEmpty: false,
                        q38RepeatercEmpty: false,
                        q38RepeaterdEmpty: false,
                    }],
                },
            ]);
        }

        else if (e.target.name === "q39Steroidtype-" + e.target.id) {

            let parentId = splitNameArray[1];
            let Q39array = [...q38MainSteroidReceivedDetails];
            Q39array[parentId].steroidtypeid = e.target.value;
            Q39array[parentId].q39a_empty = false;
            setq38MainSteroidReceivedDetails(Q39array);
        }

        // else if (e.target.name === "post_ava_ster_disc_dt_unk-" + e.target.id) {

        //     let parentId = splitNameArray[1];
        //     let Q39array = [...q38MainSteroidReceivedDetails];
        //     Q39array[parentId].post_ava_ster_disc_dt_unk = e.target.value;
        //     Q39array[parentId].post_ava_f1_tx_disc_dt = "";
        //     Q39array[parentId].defEDateQ38 = true;
        //     Q39array[parentId].q39e_f_empty = false;
        //     Q39array[parentId].q3FSteroidDateInvalidSoft1 = false;
        //     Q39array[parentId].q3FSteroidDateInvalidSoft2 = false;
        //     Q39array[parentId].q3FSteroidDateInvalidSoft3 = false;
        //     setq38MainSteroidReceivedDetails(Q39array);
        // }

        else if (e.target.name === "post_ava_b1_tx_change_doseFrequency-" + e.target.id) {
debugger
            let parentId = splitNameArray[1];
            let Q39array = [...q38MainSteroidReceivedDetails];
            Q39array[parentId].post_ava_b1_tx_change_doseFrequency = !Q39array[parentId].post_ava_b1_tx_change_doseFrequency;
            Q39array[parentId].q39e_empty = false;

            Q39array[parentId].q38bEmpty = false;
            Q39array[parentId].showq38childrepeater = Q39array[parentId].post_ava_b1_tx_change_doseFrequency==true?true:false;
            Q39array[parentId].steroidreceiveddosagechangeDetails = [{
                steroidreceiveddosagechangeid: 0,
                steroidtypeid: "",
                post_ava_b1x1_tx_change_dt: "",
                // post_ava_b1x1_tx_change_dt_unk: "",
                q3BSteroidDateInvalidSoft1: false,
                q3BSteroidDateInvalidSoft2: false,
                q3BSteroidDateInvalidSoft3: false,
                post_ava_c1x1_tx_dose: "",
                post_ava_d1x1_tx_dose_num: "",
                frequencytimeid: 0,
                defDateQ38: false,
                q38Dosedatechange: false,
                q38doseChangelessindexdt: false,
                q39child_empty: false,
                q38doselimitexceeded: false,
                q38freqlimitexceeded: false,
                q38RepeaterbEmpty: false,
                q38RepeatercEmpty: false,
                q38RepeaterdEmpty: false,
            }];

            setq38MainSteroidReceivedDetails(Q39array);
        }

        else if (e.target.name === "post_ava_b1_tx_change_discontinuation-" + e.target.id) {
            debugger;
            let parentId = splitNameArray[1];
            let Q39array = [...q38MainSteroidReceivedDetails];
            Q39array[parentId].post_ava_b1_tx_change_discontinuation = !Q39array[parentId].post_ava_b1_tx_change_discontinuation;
            Q39array[parentId].q39e_empty = false;
            Q39array[parentId].q39e_f_empty = false;
            Q39array[parentId].showQ38f=Q39array[parentId].post_ava_b1_tx_change_discontinuation==true?true:false;
           // Q39array[parentId].showq38childrepeater = false;
            Q39array[parentId].post_ava_f1_tx_disc_dt = "";
            Q39array[parentId].q38bEmpty = false;
            Q39array[parentId].defEDateQ38 = false;
            Q39array[parentId].q3FSteroidDateInvalidSoft1 = false;
            Q39array[parentId].q3FSteroidDateInvalidSoft2 = false;
            Q39array[parentId].q3FSteroidDateInvalidSoft3 = false;
            Q39array[parentId].q3FSteroidDateInvalidSoft4 = false;
            // Q39array[parentId].steroidreceiveddosagechangeDetails = [{
            //     steroidreceiveddosagechangeid: 0,
            //     steroidtypeid: "",
            //     post_ava_b1x1_tx_change_dt: "",
            //     // post_ava_b1x1_tx_change_dt_unk: "",
            //     q3BSteroidDateInvalidSoft1: false,
            //     q3BSteroidDateInvalidSoft2: false,
            //     q3BSteroidDateInvalidSoft3: false,
            //     post_ava_c1x1_tx_dose: "",
            //     post_ava_d1x1_tx_dose_num: "",
            //     frequencytimeid: 0,
            //     defDateQ38: false,
            //     q38Dosedatechange: false,
            //     q38doseChangelessindexdt: false,
            //     q39child_empty: false,
            //     q38doselimitexceeded: false,
            //     q38freqlimitexceeded: false,
            //     q38RepeaterbEmpty: false,
            //     q38RepeatercEmpty: false,
            //     q38RepeaterdEmpty: false,
            // }];
            setq38MainSteroidReceivedDetails(Q39array);
        }

        else if (e.target.name === "post_ava_e1_tx_disc_yes-" + e.target.id) {

            let parentId = splitNameArray[1];
            let Q39array = [...q38MainSteroidReceivedDetails];
            Q39array[parentId].post_ava_e1_tx_disc = e.target.value;
            Q39array[parentId].q39e_empty = false;
            Q39array[parentId].defEDateQ38 = false;
            Q39array[parentId].post_ava_f1_tx_disc_dt = "";
            
            // Q39array[parentId].post_ava_ster_disc_dt_unk = "";

            setq38MainSteroidReceivedDetails(Q39array);
        }

        else if (e.target.name === "post_ava_e1_tx_disc_no-" + e.target.id) {

            let parentId = splitNameArray[1];
            let Q39array = [...q38MainSteroidReceivedDetails];
            Q39array[parentId].post_ava_e1_tx_disc = e.target.value;
            Q39array[parentId].q39e_empty = false;
            Q39array[parentId].q39e_f_empty = false;
            Q39array[parentId].defEDateQ38 = false;
            Q39array[parentId].post_ava_f1_tx_disc_dt = "";
            Q39array[parentId].q3FSteroidDateInvalidSoft1 = false;
            Q39array[parentId].q3FSteroidDateInvalidSoft2 = false;
            Q39array[parentId].q3FSteroidDateInvalidSoft3 = false;
            Q39array[parentId].q3FSteroidDateInvalidSoft4 = false;
            // Q39array[parentId].post_ava_ster_disc_dt_unk = "";
            setq38MainSteroidReceivedDetails(Q39array);
        }

        // else if (e.target.name === "post_ava_b1x1_tx_change_dt_unk-" + splitNameArray[1] + "-" + splitNameArray[2]) {

        //     let parentId = splitNameArray[1];
        //     let childId = splitNameArray[2];

        //     let Q39array = [...q38MainSteroidReceivedDetails];
        //     Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt_unk = e.target.value;
        //     Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt = "";
        //     Q39array[parentId].steroidreceiveddosagechangeDetails[childId].defDateQ38 = true;
        //     Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q3BSteroidDateInvalidSoft1 = false;
        //     Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q3BSteroidDateInvalidSoft2 = false;
        //     Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q3BSteroidDateInvalidSoft3 = false;

        //     if ((Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt !== "" ||
        //         Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt_unk != "") &&
        //         Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_c1x1_tx_dose !== "" &&
        //         Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_d1x1_tx_dose_num !== "" &&
        //         Q39array[parentId].steroidreceiveddosagechangeDetails[childId].frequencytimeid !== "") {
        //         Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q39child_empty = false;
        //     }

        //     setq38MainSteroidReceivedDetails(Q39array);
        // }

        else if (e.target.name === "post_ava_c1x1_tx_dose-" + splitNameArray[1] + "-" + splitNameArray[2]) {
            if (!NUMBER_REGEX.test(e.target.value)) {
                return;
            }
            let parentId = splitNameArray[1];
            let childId = splitNameArray[2];

            let Q39array = [...q38MainSteroidReceivedDetails];
            Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_c1x1_tx_dose = e.target.value;
            Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q38RepeatercEmpty = false;
            if (e.target.value > 1500 || e.target.value < 1)
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q38doselimitexceeded = true;
            else
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q38doselimitexceeded = false;
            setq38MainSteroidReceivedDetails(Q39array);
        }

        else if (e.target.name === "post_ava_d1x1_tx_dose_num-" + splitNameArray[1] + "-" + splitNameArray[2]) {
            if (!NUMBER_REGEX.test(e.target.value)) {
                return;
            }
            let parentId = splitNameArray[1];
            let childId = splitNameArray[2];

            let Q39array = [...q38MainSteroidReceivedDetails];
            Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_d1x1_tx_dose_num = e.target.value;
            Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q38RepeaterdEmpty = (Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_d1x1_tx_dose_num !== "" &&
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].frequencytimeid != 0) ? false : true;
            if (e.target.value > 1500 || e.target.value < 1)
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q38freqlimitexceeded = true;
            else
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q38freqlimitexceeded = false;

            if ((Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt !== "") &&
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_c1x1_tx_dose !== "" &&
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_d1x1_tx_dose_num !== "" &&
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].frequencytimeid !== "") {
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q39child_empty = false;
            }
            setq38MainSteroidReceivedDetails(Q39array);
        }

        else if (e.target.name === "q38ChildFreq_time-" + splitNameArray[1] + "-" + splitNameArray[2]) {

            let parentId = splitNameArray[1];
            let childId = splitNameArray[2];

            let Q39array = [...q38MainSteroidReceivedDetails];
            Q39array[parentId].steroidreceiveddosagechangeDetails[childId].frequencytimeid = e.target.value;
            Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q38RepeaterdEmpty = false;

            if ((Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt !== "") &&
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_c1x1_tx_dose !== "" &&
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_d1x1_tx_dose_num !== "" &&
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].frequencytimeid !== "") {
                Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q39child_empty = false;
            }
            setq38MainSteroidReceivedDetails(Q39array);
        }

        else if (e.target.id === "post_ava_new_ster_start_Yes") {
            setshowq39parentrepeater(true);
            setITPTreatmentPostAVADetails((prev) => ({ ...prev, post_ava_new_ster_start: e.target.value, }));
        }

        else if (e.target.id === "post_ava_new_ster_start_No") {
            setshowq39parentrepeater(false);
            setITPTreatmentPostAVADetails((prev) => ({ ...prev, post_ava_new_ster_start: e.target.value, }));
            setq39SteroidCourseDetails([
                {
                    steroidcourseid: 0,
                    steroidtypeid: 0,
                    new_tx_start_dt_b1: "",
                    //new_tx_start_dt_b1_unk: "",
                    new_tx_dose_amt_c1: "",
                    new_tx_dose_num_d1: "",
                    frequencytimeid: "",
                    new_tx_dose_chg_e1: "",
                    new_tx_disc_i1: "",
                    new_tx_disc_dt_j1: "",
                    /*new_tx_disc_dt_j1_unk: "",*/
                    showq39j: false,
                    defDateQ39b: false,
                    defDateQ39j: false,
                    showq39childrepeater: false,

                    q39aEmpty: false,
                    q39bEmpty: false,
                    q39bDateInvalidSoft1: false,
                    q39bDateInvalidSoft2: false,
                    q39bDateInvalidSoft3: false,
                    q39cEmpty: false,
                    q39dEmpty: false,
                    q39eEmpty: false,
                    q39iEmpty: false,
                    q39jEmpty: false,
                    q39jDateInvalidSoft1: false,
                    q39jDateInvalidSoft2: false,
                    q39jDateInvalidSoft3: false,
                    q39doselimitexceeded: false,
                    q39freqlimitexceeded: false,

                    q39SteroidcoursedosagechangeDetails: [
                        {
                            steroidcoursedosagechangeid: 0,
                            steroidtypeid: 0,
                            new_tx_chg_dt_f1x1: "",
                            /*new_tx_chg_dt_f1x1_unk: "",*/
                            new_tx_chg_dose_g1x1: "",
                            new_tx_chg_num_h1x1: "",
                            frequencytimeid: "",

                            q39fEmpty: false,
                            q39fDateInvalidSoft1: false,
                            q39fDateInvalidSoft2: false,
                            q39fDateInvalidSoft3: false,
                            q39gEmpty: false,
                            q39hEmpty: false,
                            q39doselimitexceeded: false,
                            q39freqlimitexceeded: false,
                        }
                    ],
                },
            ]);
        }

        else if (e.target.name === 'steroidtype-' + e.target.id) {

            let Q39array = [...q39SteroidCourseDetails];
            Q39array[id].steroidtypeid = e.target.value;
            Q39array[id].q39aEmpty = false;
            setq39SteroidCourseDetails(Q39array);
        }

        //else if (e.target.name === 'new_tx_start_dt_b1_unk-' + e.target.id) {
        //    let Q39array = [...q39SteroidCourseDetails];
        //    Q39array[id].new_tx_start_dt_b1 = "";
        //    Q39array[id].new_tx_start_dt_b1_unk = e.target.value;
        //    Q39array[id].defDateQ39b = true;
        //    Q39array[id].q39bEmpty = false;
        //    Q39array[id].q39bDateInvalidSoft1 = false;
        //    Q39array[id].q39bDateInvalidSoft2 = false;
        //    Q39array[id].q39bDateInvalidSoft3 = false;
        //    setq39SteroidCourseDetails(Q39array);
        //}

        else if (e.target.name === 'new_tx_dose_amt_c1-' + e.target.id) {
            if (!NUMBER_REGEX.test(e.target.value)) {
                return;
            }
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[id].new_tx_dose_amt_c1 = e.target.value;
            Q39array[id].q39cEmpty = false;
            Q39array[id].q39doselimitexceeded = (e.target.value > 1500 || e.target.value < 1) ? true : false;
            setq39SteroidCourseDetails(Q39array);
        }

        else if (e.target.name === 'frequencytime-' + e.target.id) {
            if (!NUMBER_REGEX.test(e.target.value)) {
                return;
            }
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[id].frequencytimeid = e.target.value;
            if (Q39array[id].new_tx_dose_num_d1 != "" && Q39array[id].frequencytimeid != "") {
                Q39array[id].q39dEmpty = false;
            }
            setq39SteroidCourseDetails(Q39array);
        }

        else if (e.target.name === 'new_tx_dose_num_d1-' + e.target.id) {
            if (!NUMBER_REGEX.test(e.target.value)) {
                return;
            }
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[id].new_tx_dose_num_d1 = e.target.value;
            Q39array[id].q39freqlimitexceeded = (e.target.value > 1500 || e.target.value < 1) ? true : false;
            if (Q39array[id].new_tx_dose_num_d1 != "" && Q39array[id].frequencytimeid != "") {
                Q39array[id].q39dEmpty = false;
            }

            setq39SteroidCourseDetails(Q39array);

        }

        else if (e.target.name === 'new_tx_dose_chg_e1_Yes-' + e.target.id) {
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[id].new_tx_dose_chg_e1 = e.target.value;
            Q39array[id].q39eEmpty = false;
            Q39array[id].showq39childrepeater = true;
            Q39array[id].q39SteroidcoursedosagechangeDetails = [{
                steroidcoursedosagechangeid: 0,
                steroidtypeid: 0,
                new_tx_chg_dt_f1x1: "",
                /*new_tx_chg_dt_f1x1_unk: "",*/
                new_tx_chg_dose_g1x1: "",
                new_tx_chg_num_h1x1: "",
                frequencytimeid: "",
                defDateQ39f: false,

                q39fEmpty: false,
                q39fDateInvalidSoft1: false,
                q39fDateInvalidSoft2: false,
                q39fDateInvalidSoft3: false,
                q39gEmpty: false,
                q39hEmpty: false,
                q39doselimitexceeded: false,
                q39freqlimitexceeded: false,
            }];
            setq39SteroidCourseDetails(Q39array);
        }

        else if (e.target.name === 'new_tx_dose_chg_e1_No-' + e.target.id) {
            let id = e.target.id;
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[id].new_tx_dose_chg_e1 = e.target.value;
            Q39array[id].showq39childrepeater = false;
            Q39array[id].q39eEmpty = false;
            Q39array[id].q39SteroidcoursedosagechangeDetails = [{
                steroidcoursedosagechangeid: 0,
                steroidtypeid: 0,
                new_tx_chg_dt_f1x1: "",
                /*new_tx_chg_dt_f1x1_unk: "",*/
                new_tx_chg_dose_g1x1: "",
                new_tx_chg_num_h1x1: "",
                frequencytimeid: "",
                defDateQ39f: false,

                q39fEmpty: false,
                q39fDateInvalidSoft1: false,
                q39fDateInvalidSoft2: false,
                q39fDateInvalidSoft3: false,
                q39gEmpty: false,
                q39hEmpty: false,
                q39doselimitexceeded: false,
                q39freqlimitexceeded: false,
            }];
            setq39SteroidCourseDetails(Q39array);


        }

        else if (e.target.name === "new_tx_disc_i1_Yes-" + e.target.id) {
            let id = e.target.id;
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[id].new_tx_disc_i1 = e.target.value;
            Q39array[id].showq39j = true;
            Q39array[id].q39iEmpty = false;
            setq39SteroidCourseDetails(Q39array);
        }

        else if (e.target.name === "new_tx_disc_i1_No-" + e.target.id) {
            let id = e.target.id;
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[id].new_tx_disc_i1 = e.target.value;
            Q39array[id].new_tx_disc_dt_j1 = "";
            /*Q39array[id].new_tx_disc_dt_j1_unk = "";*/
            Q39array[id].defDateQ39j = true;
            Q39array[id].showq39j = false;
            Q39array[id].q39iEmpty = false;
            setq39SteroidCourseDetails(Q39array);
        }

        else if (e.target.name === "q38Checkbox-" + e.target.id) {
            debugger;
            let id = e.target.id;
            let Q39array = [...q38MainSteroidReceivedDetails];
            Q39array[id].q38Checkbox = !Q39array[id].q38Checkbox;
           // Q39array[id].showQ38f = Q39array[id].q38Checkbox;
            Q39array[id].post_ava_e1_tx_disc = "";
            Q39array[id].post_ava_f1_tx_disc_dt = "";
            Q39array[id].q39e_empty = false;
            Q39array[id].q39e_f_empty = false;
            Q39array[id].q3FSteroidDateInvalidSoft1 = false;
            Q39array[id].q3FSteroidDateInvalidSoft2 = false;
            Q39array[id].q3FSteroidDateInvalidSoft3 = false;
            Q39array[id].q3FSteroidDateInvalidSoft4 = false;
            setq38MainSteroidReceivedDetails(Q39array);

        }

        //else if (e.target.name === 'new_tx_disc_dt_j1_unk-' + e.target.id) {
        //    let id = e.target.id;
        //    let Q39array = [...q39SteroidCourseDetails];
        //    Q39array[id].new_tx_disc_dt_j1 = "";
        //    Q39array[id].new_tx_disc_dt_j1_unk = e.target.value;
        //    Q39array[id].defDateQ39j = true;
        //    Q39array[id].q39jEmpty = false;
        //    Q39array[id].q39jDateInvalidSoft1 = false;
        //    Q39array[id].q39jDateInvalidSoft2 = false;
        //    Q39array[id].q39jDateInvalidSoft3 = false;
        //    setq39SteroidCourseDetails(Q39array);
        //}

        //else if (e.target.name === "new_tx_chg_dt_f1x1_unk-" + splitNameArray[1] + "-" + splitNameArray[2]) {

        //    let parentId = splitNameArray[1];
        //    let childId = splitNameArray[2];
        //    let Q39array = [...q39SteroidCourseDetails];
        //    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_dt_f1x1 = "";
        //    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_dt_f1x1_unk = e.target.value;
        //    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].defDateQ39f = true;
        //    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39fEmpty = false;
        //    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39fDateInvalidSoft1 = false;
        //    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39fDateInvalidSoft2 = false;
        //    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39fDateInvalidSoft3 = false;
        //    setq39SteroidCourseDetails(Q39array);
        //}

        else if (e.target.name === "q39child_new_tx_chg_dose_g1x1-" + splitNameArray[1] + "-" + splitNameArray[2]) {
            if (!NUMBER_REGEX.test(e.target.value)) {
                return;
            }
            let parentId = splitNameArray[1];
            let childId = splitNameArray[2];
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_dose_g1x1 = e.target.value;
            Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39doselimitexceeded = (e.target.value > 1500 || e.target.value < 1) ? true : false;
            Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39gEmpty = false;
            setq39SteroidCourseDetails(Q39array);
        }

        else if (e.target.name === "q39child_new_tx_chg_num_h1x1-" + splitNameArray[1] + "-" + splitNameArray[2]) {
            if (!NUMBER_REGEX.test(e.target.value)) {
                return;
            }
            let parentId = splitNameArray[1];
            let childId = splitNameArray[2];
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_num_h1x1 = e.target.value;
            Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39freqlimitexceeded = (e.target.value > 1500 || e.target.value < 1) ? true : false;

            if (Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_num_h1x1 != "" && Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].frequencytimeid != "") {
                Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39hEmpty = false;
            }
            setq39SteroidCourseDetails(Q39array);
        }

        else if (e.target.name === "q39childfrequencytime-" + splitNameArray[1] + "-" + splitNameArray[2]) {

            if (!NUMBER_REGEX.test(e.target.value)) {
                return;
            }
            let parentId = splitNameArray[1];
            let childId = splitNameArray[2];
            let Q39array = [...q39SteroidCourseDetails];
            Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].frequencytimeid = e.target.value;
            if (Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_num_h1x1 != "" && Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].frequencytimeid != "") {
                Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39hEmpty = false;
            }
            setq39SteroidCourseDetails(Q39array);
        }

        /*Q41 region*/
        else if (e.target.name === "q41checkbox1") {
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q41checkbox1: !ITPTreatmentPostAVADetails.q41checkbox1,
                q41showrepeater1: !ITPTreatmentPostAVADetails.q41checkbox1,
                q41radiobutton: false,

                q41Empty: false,
            }));
            if (!ITPTreatmentPostAVADetails.q41checkbox1) {
                setq41ivigdatesDetails([{
                    ivigdatesid: 0,
                    ivigtherapytypeid: 0,
                    postAvaA1TxStartDt: "",
                    postAvaA1TxStartDtUnk: "",
                    postAvaB1TxEndDt: "",
                    postAvaB1TxEndDtUnk: "",
                    defDateQ41A: false,
                    defDateQ41B: false,

                    q41iviaEmpty: false,
                    q41iviaDateInvalidSoft1: false,
                    q41iviaDateInvalidSoft2: false,
                    q41iviaDateInvalidSoft3: false,
                    q41ivibEmpty: false,
                    q41ivibDateInvalidSoft1: false,
                    q41ivibDateInvalidSoft2: false,
                    q41ivibDateInvalidSoft3: false,
                }]);
            }
        }

        //else if (e.target.name === "postAvaA1TxStartDtUnkR1-" + id) {

        //    let id = e.target.id;
        //    let Q41array = [...q41ivigdatesDetails];
        //    Q41array[id].postAvaA1TxStartDt = "";
        //    Q41array[id].postAvaA1TxStartDtUnk = e.target.value;
        //    Q41array[id].defDateQ41A = true;
        //    Q41array[id].q41iviaEmpty = false;
        //    Q41array[id].q41iviaDateInvalidSoft1 = false;
        //    Q41array[id].q41iviaDateInvalidSoft2 = false;
        //    Q41array[id].q41iviaDateInvalidSoft3 = false;
        //    setq41ivigdatesDetails(Q41array);
        //}

        //else if (e.target.name === "postAvaB1TxEndDtUnkR1-" + id) {

        //    let id = e.target.id;
        //    let Q41array = [...q41ivigdatesDetails];
        //    Q41array[id].postAvaB1TxEndDt = "";
        //    Q41array[id].postAvaB1TxEndDtUnk = e.target.value;
        //    Q41array[id].defDateQ41B = true;
        //    Q41array[id].q41ivibEmpty = false;
        //    Q41array[id].q41ivibDateInvalidSoft1 = false;
        //    Q41array[id].q41ivibDateInvalidSoft2 = false;
        //    Q41array[id].q41ivibDateInvalidSoft3 = false;
        //    setq41ivigdatesDetails(Q41array);
        //}

        else if (e.target.name === "q41checkbox2") {
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q41checkbox2: !ITPTreatmentPostAVADetails.q41checkbox2,
                q41showrepeater2: !ITPTreatmentPostAVADetails.q41checkbox2,
                q41radiobutton: false,

                q41Empty: false,
            }));
            if (!ITPTreatmentPostAVADetails.q41checkbox2) {
                setq41antiddatesDetails([{
                    ivigdatesid: 0,
                    ivigtherapytypeid: 0,
                    postAvaA1TxStartDt: "",
                    postAvaA1TxStartDtUnk: "",
                    postAvaB1TxEndDt: "",
                    postAvaB1TxEndDtUnk: "",
                    defDateQ41A: false,
                    defDateQ41B: false,

                    q41antidaEmpty: false,
                    q41antidaDateInvalidSoft1: false,
                    q41antidaDateInvalidSoft2: false,
                    q41antidaDateInvalidSoft3: false,
                    q41antidbEmpty: false,
                    q41antidbDateInvalidSoft1: false,
                    q41antidbDateInvalidSoft2: false,
                    q41antidbDateInvalidSoft3: false,
                }]);
            }
        }

        //else if (e.target.name === "postAvaA1TxStartDtUnkR2-" + id) {

        //    let id = e.target.id;
        //    let Q41array = [...q41antiddatesDetails];
        //    Q41array[id].postAvaA1TxStartDt = "";
        //    Q41array[id].postAvaA1TxStartDtUnk = e.target.value;
        //    Q41array[id].defDateQ41A = true;
        //    Q41array[id].q41antidaEmpty = false;
        //    Q41array[id].q41antidaDateInvalidSoft1 = false;
        //    Q41array[id].q41antidaDateInvalidSoft2 = false;
        //    Q41array[id].q41antidaDateInvalidSoft3 = false;
        //    setq41antiddatesDetails(Q41array);
        //}

        else if (e.target.name === "postAvaB1TxEndDtUnkR2-" + id) {

            let id = e.target.id;
            let Q41array = [...q41antiddatesDetails];
            Q41array[id].postAvaB1TxEndDt = "";
            Q41array[id].postAvaB1TxEndDtUnk = e.target.value;
            Q41array[id].defDateQ41B = true;
            Q41array[id].q41antidbEmpty = false;
            Q41array[id].q41antidbDateInvalidSoft1 = false;
            Q41array[id].q41antidbDateInvalidSoft2 = false;
            Q41array[id].q41antidbDateInvalidSoft3 = false;
            setq41antiddatesDetails(Q41array);

        }

        else if (e.target.name === "q41radiobutton") {
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q41checkbox1: false,
                q41showrepeater1: false,
                q41checkbox2: false,
                q41showrepeater2: false,
                q41radiobutton: true,

                q41Empty: false,
            }));
            setq41ivigdatesDetails([{
                ivigdatesid: 0,
                ivigtherapytypeid: 0,
                postAvaA1TxStartDt: "",
                postAvaA1TxStartDtUnk: "",
                postAvaB1TxEndDt: "",
                postAvaB1TxEndDtUnk: "",
                defDateQ41A: false,
                defDateQ41B: false,

                q41iviaEmpty: false,
                q41iviaDateInvalidSoft1: false,
                q41iviaDateInvalidSoft2: false,
                q41iviaDateInvalidSoft3: false,
                q41ivibEmpty: false,
                q41ivibDateInvalidSoft1: false,
                q41ivibDateInvalidSoft2: false,
                q41ivibDateInvalidSoft3: false,
            }]);
            setq41antiddatesDetails([{
                ivigdatesid: 0,
                ivigtherapytypeid: 0,
                postAvaA1TxStartDt: "",
                postAvaA1TxStartDtUnk: "",
                postAvaB1TxEndDt: "",
                postAvaB1TxEndDtUnk: "",
                defDateQ41A: false,
                defDateQ41B: false,

                q41antidaEmpty: false,
                q41antidaDateInvalidSoft1: false,
                q41antidaDateInvalidSoft2: false,
                q41antidaDateInvalidSoft3: false,
                q41antidbEmpty: false,
                q41antidbDateInvalidSoft1: false,
                q41antidbDateInvalidSoft2: false,
                q41antidbDateInvalidSoft3: false,
            }]);
        }
        /*Q41 region*/

        /*Q42 region*/
        else if (e.target.name === "q42checkbox1") {
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q42Selection: "1",
                q42Checkbox1Checked: !ITPTreatmentPostAVADetails.q42Checkbox1Checked,
                post_ava_disc_treat_unk: false,
                q42Empty: false,
            }));
            if (!ITPTreatmentPostAVADetails.q42Checkbox1Checked) {
                setq42itpdiscontinuationdatesCheckbox1Details([
                    {
                        Itpdiscontinuationdateid: 0,
                        Itptreamenttypeid: 0,
                        PostAvaTxDiscDt: "",
                        PostAvaTxDiscDtUnk: "",
                        defDateQ42: false,
                        q42Empty: false,
                        q42DateInvalidSoft1: false,
                        q42DateInvalidSoft2: false,
                        q42DateInvalidSoft3: false,
                    }]);
            }
        }
        else if (e.target.name === "q42checkbox2") {
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q42Selection: "2",
                q42Checkbox2Checked: !ITPTreatmentPostAVADetails.q42Checkbox2Checked,
                post_ava_disc_treat_unk: false,
                q42Empty: false,
            }));
            if (!ITPTreatmentPostAVADetails.q42Checkbox2Checked) {
                setq42itpdiscontinuationdatesCheckbox2Details([
                    {
                        Itpdiscontinuationdateid: 0,
                        Itptreamenttypeid: 0,
                        PostAvaTxDiscDt: "",
                        PostAvaTxDiscDtUnk: "",
                        defDateQ42: false,
                        q42Empty: false,
                        q42DateInvalidSoft1: false,
                        q42DateInvalidSoft2: false,
                        q42DateInvalidSoft3: false,
                    }]);
            }
        }

        else if (e.target.name === "q42radiobutton") {

            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                post_ava_disc_treat_unk: true,
                q42Selection: "3",
                q42Checkbox1Checked: false,
                q42Checkbox2Checked: false,
                post_ava_disc_treat_unk: true,
                q42Empty: false,
            }));

            setq42itpdiscontinuationdatesCheckbox1Details([
                {
                    Itpdiscontinuationdateid: 0,
                    Itptreamenttypeid: 0,
                    PostAvaTxDiscDt: "",
                    PostAvaTxDiscDtUnk: "",
                    defDateQ42: false,
                    q42Empty: false,
                    q42DateInvalidSoft1: false,
                    q42DateInvalidSoft2: false,
                    q42DateInvalidSoft3: false,
                }]);

            setq42itpdiscontinuationdatesCheckbox2Details([
                {
                    Itpdiscontinuationdateid: 0,
                    Itptreamenttypeid: 0,
                    PostAvaTxDiscDt: "",
                    PostAvaTxDiscDtUnk: "",
                    defDateQ42: false,
                    q42Empty: false,
                    q42DateInvalidSoft1: false,
                    q42DateInvalidSoft2: false,
                    q42DateInvalidSoft3: false,
                }]);
        }
        /*Q42 region*/

        /*Q43 region*/
        else if (e.target.name === "q43checkbox1") {
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q43Selection: "1",
                q43Checkbox1Checked: !ITPTreatmentPostAVADetails.q43Checkbox1Checked,
                post_ava_other_treat_unk: false,
                q43Empty: false,
            }));

            let Q43array = { ...q43otherItpTreatmentsModalCheckbox1Details };
            Q43array[0].q43Empty = false;
            Q43array[0].q43aEmpty = false;
            Q43array[0].q43bEmpty = false;
            Q43array[0].q43cEmpty = false;

            Q43array[0].q43Date1InvalidSoft1 = false;
            Q43array[0].q43Date1InvalidSoft2 = false;
            Q43array[0].q43Date1InvalidSoft3 = false;

            Q43array[0].q43Date2InvalidSoft1 = false;
            Q43array[0].q43Date2InvalidSoft2 = false;
            Q43array[0].q43Date2InvalidSoft3 = false;
            setq43otherItpTreatmentsModalCheckbox1Details(Q43array);

            if (!ITPTreatmentPostAVADetails.q43Checkbox1Checked) {
                setq43otherItpTreatmentsModalCheckbox1Details([
                    {
                        Otheritptreatmentsid: 0,
                        Itptreatmenttypeid: 0,
                        PostAvaTxStartDt: "",
                        PostAvaTxDisc: "",
                        PostAvaTxDiscDt: "",
                        q43Empty: false,
                        q43aEmpty: false,
                        q43bEmpty: false,
                        q43cEmpty: false,

                        defDate1Q43: false,
                        q43Date1InvalidSoft1: false,
                        q43Date1InvalidSoft2: false,
                        q43Date1InvalidSoft3: false,

                        defDate2Q43: false,
                        q43Date2InvalidSoft1: false,
                        q43Date2InvalidSoft2: false,
                        q43Date2InvalidSoft3: false,
                    }]);
            }
        }
        else if (e.target.name === "q43Chk1radiobuttonYes") {
            let Q43array = { ...q43otherItpTreatmentsModalCheckbox1Details };
            Q43array[0].PostAvaTxDisc = e.target.value;
            Q43array[0].q43bEmpty = false;
            Q43array[0].q43cEmpty = false;
            Q43array[0].q43Date2InvalidSoft1 = false;
            Q43array[0].q43Date2InvalidSoft2 = false;
            Q43array[0].q43Date2InvalidSoft3 = false;
            setq43otherItpTreatmentsModalCheckbox1Details(Q43array);
        }
        else if (e.target.name === "q43Chk1radiobuttonNo") {
            let Q43array = { ...q43otherItpTreatmentsModalCheckbox1Details };
            Q43array[0].PostAvaTxDisc = e.target.value;
            Q43array[0].PostAvaTxDiscDt = "";
            Q43array[0].q43bEmpty = false;
            Q43array[0].q43cEmpty = false;
            Q43array[0].q43Date2InvalidSoft1 = false;
            Q43array[0].q43Date2InvalidSoft2 = false;
            Q43array[0].q43Date2InvalidSoft3 = false;
            setq43otherItpTreatmentsModalCheckbox1Details(Q43array);
        }

        else if (e.target.name === "q43checkbox2") {
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q43Selection: "2",
                q43Checkbox2Checked: !ITPTreatmentPostAVADetails.q43Checkbox2Checked,
                post_ava_other_treat_unk: false,
                q43Empty: false,
            }));

            let Q43array = { ...q43otherItpTreatmentsModalCheckbox2Details };
            Q43array[0].q43Empty = false;
            Q43array[0].q43aEmpty = false;
            Q43array[0].q43bEmpty = false;
            Q43array[0].q43cEmpty = false;

            Q43array[0].q43Date1InvalidSoft1 = false;
            Q43array[0].q43Date1InvalidSoft2 = false;
            Q43array[0].q43Date1InvalidSoft3 = false;

            Q43array[0].q43Date2InvalidSoft1 = false;
            Q43array[0].q43Date2InvalidSoft2 = false;
            Q43array[0].q43Date2InvalidSoft3 = false;
            setq43otherItpTreatmentsModalCheckbox2Details(Q43array);

            if (!ITPTreatmentPostAVADetails.q43Checkbox2Checked) {
                setq43otherItpTreatmentsModalCheckbox2Details([
                    {
                        Otheritptreatmentsid: 0,
                        Itptreatmenttypeid: 0,
                        PostAvaTxStartDt: "",
                        PostAvaTxDisc: "",
                        PostAvaTxDiscDt: "",
                        q43Empty: false,
                        q43aEmpty: false,
                        q43bEmpty: false,
                        q43cEmpty: false,

                        defDate1Q43: false,
                        q43Date1InvalidSoft1: false,
                        q43Date1InvalidSoft2: false,
                        q43Date1InvalidSoft3: false,

                        defDate2Q43: false,
                        q43Date2InvalidSoft1: false,
                        q43Date2InvalidSoft2: false,
                        q43Date2InvalidSoft3: false,
                    }]);
            }
        }
        else if (e.target.name === "q43Chk2radiobuttonYes") {
            let Q43array = { ...q43otherItpTreatmentsModalCheckbox2Details };
            Q43array[0].PostAvaTxDisc = e.target.value;
            Q43array[0].q43bEmpty = false;
            Q43array[0].q43cEmpty = false;
            Q43array[0].q43Date2InvalidSoft1 = false;
            Q43array[0].q43Date2InvalidSoft2 = false;
            Q43array[0].q43Date2InvalidSoft3 = false;
            setq43otherItpTreatmentsModalCheckbox2Details(Q43array);
        }
        else if (e.target.name === "q43Chk2radiobuttonNo") {
            let Q43array = { ...q43otherItpTreatmentsModalCheckbox2Details };
            Q43array[0].PostAvaTxDisc = e.target.value;
            Q43array[0].PostAvaTxDiscDt = "";
            Q43array[0].q43bEmpty = false;
            Q43array[0].q43cEmpty = false;
            Q43array[0].q43Date2InvalidSoft1 = false;
            Q43array[0].q43Date2InvalidSoft2 = false;
            Q43array[0].q43Date2InvalidSoft3 = false;
            setq43otherItpTreatmentsModalCheckbox2Details(Q43array);
        }

        else if (e.target.name === "q43radiobutton") {

            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                post_ava_other_treat_unk: true,
                q43Selection: "3",
                q43Checkbox1Checked: false,
                q43Checkbox2Checked: false,
                q43Empty: false,
            }));
            setq43otherItpTreatmentsModalCheckbox1Details([
                {
                    Otheritptreatmentsid: 0,
                    Itptreatmenttypeid: 0,
                    PostAvaTxStartDt: "",
                    PostAvaTxDisc: "",
                    PostAvaTxDiscDt: "",
                    q43Empty: false,
                    q43aEmpty: false,
                    q43bEmpty: false,
                    q43cEmpty: false,

                    defDate1Q43: false,
                    q43Date1InvalidSoft1: false,
                    q43Date1InvalidSoft2: false,
                    q43Date1InvalidSoft3: false,

                    defDate2Q43: false,
                    q43Date2InvalidSoft1: false,
                    q43Date2InvalidSoft2: false,
                    q43Date2InvalidSoft3: false,
                }]);
            setq43otherItpTreatmentsModalCheckbox2Details([
                {
                    Otheritptreatmentsid: 0,
                    Itptreatmenttypeid: 0,
                    PostAvaTxStartDt: "",
                    PostAvaTxDisc: "",
                    PostAvaTxDiscDt: "",
                    q43Empty: false,
                    q43aEmpty: false,
                    q43bEmpty: false,
                    q43cEmpty: false,

                    defDate1Q43: false,
                    q43Date1InvalidSoft1: false,
                    q43Date1InvalidSoft2: false,
                    q43Date1InvalidSoft3: false,

                    defDate2Q43: false,
                    q43Date2InvalidSoft1: false,
                    q43Date2InvalidSoft2: false,
                    q43Date2InvalidSoft3: false,
                }]);
        }
        /*Q43 region*/

        /* Q44 region*/
        else if (e.target.name === "q44radiobuttonYes") {

            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                post_ava_splen: "1",
                q44show: true,
                q44DateInvalidSoft1: false,
                q44DateInvalidSoft2: false,
                q44DateInvalidSoft3: false,
                q44Empty: false,
                q44DataEmpty: false,
            }));
        }
        else if (e.target.name === "q44radiobuttonNo") {

            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                post_ava_splen: "0",
                post_ava_splen_dt: "",
                post_ava_splen_dt_unk: "",
                q44show: false,
                q44DateInvalidSoft1: false,
                q44DateInvalidSoft2: false,
                q44DateInvalidSoft3: false,
                q44Empty: false,
                q44DataEmpty: false,
            }));
        }
        else if (e.target.name === "post_ava_splen_dt_unk") {

            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                post_ava_splen_dt: "",
                defDateQ44: true,
                post_ava_splen_dt_unk: "1",
                q44Empty: false,
                q44DataEmpty: false,
                q44DateInvalidSoft1: false,
                q44DateInvalidSoft2: false,
                q44DateInvalidSoft3: false,
            }));
        }
        /*Q44 region*/

        /*Q45 region*/
        else if (e.target.name === "post_ava_switch_elt") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_elt: !Q45HandleControlState.post_ava_switch_elt,
                post_ava_switch_elt_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,

                },
                post_ava_switch_none: ""
            }));
        }

        else if (e.target.name === "post_ava_switch_romi") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_romi: !Q45HandleControlState.post_ava_switch_romi,
                post_ava_switch_romi_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_none: ""
            }));
        }

        else if (e.target.name === "post_ava_switch_lusu") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_lusu: !Q45HandleControlState.post_ava_switch_lusu,
                post_ava_switch_lusu_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_none: ""
            }));

        }

        else if (e.target.name === "post_ava_switch_steroid") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_steroid: !Q45HandleControlState.post_ava_switch_steroid,
                post_ava_switch_steroid_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_none: ""
            }));

        }

        else if (e.target.name === "post_ava_switch_ivig") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_ivig: !Q45HandleControlState.post_ava_switch_ivig,
                post_ava_switch_ivig_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_none: ""
            }));

        }

        else if (e.target.name === "post_ava_switch_antid") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_antid: !Q45HandleControlState.post_ava_switch_antid,
                post_ava_switch_antid_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_none: ""
            }));

        }

        else if (e.target.name === "post_ava_switch_ritux") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_ritux: !Q45HandleControlState.post_ava_switch_ritux,
                post_ava_switch_ritux_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_none: ""
            }));
        }

        else if (e.target.name === "post_ava_switch_fosta") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_fosta: !Q45HandleControlState.post_ava_switch_fosta,
                post_ava_switch_fosta_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_none: ""
            }));
        }

        else if (e.target.name === "post_ava_switch_immuno") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_immuno: !Q45HandleControlState.post_ava_switch_immuno,
                post_ava_switch_immuno_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_none: ""
            }));
        }
        
        else if (e.target.name === "post_ava_switch_other") {
            setQ45HandleControlState((prev) => ({
                ...prev,
                Q45Empty: false,
                post_ava_switch_other: !Q45HandleControlState.post_ava_switch_other,
                post_ava_switch_other_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_none: "",
                post_ava_switch_other_txt: "",
                post_ava_switch_other_txt_empty: false
            }));
        }
        else if(e.target.name === "post_ava_switch_other_txt"){
            debugger
            setQ45HandleControlState((prev)=>({
                ...prev,
                post_ava_switch_other: true,
                post_ava_switch_other_txt: e.target.value,
                post_ava_switch_other_txt_empty: false

            }))
        }

        else if (e.target.name === "post_ava_switch_none") {
            setQ45HandleControlState({
                post_ava_switch_elt: false,
                Q45Empty: false,
                post_ava_switch_elt_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    Q45EmptyFields: false,
                    showSoftErrorMsg1: "",
                    showSoftErrorMsg2: "",
                    showSoftAErrorMsg: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_romi: false,
                post_ava_switch_romi_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    showSoftErrorMsg1: "",
                    Q45EmptyChk2: false,
                    showSoftAErrorMsg: "",
                    showSoftErrorMsg2: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_lusu: false,
                post_ava_switch_lusu_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    showSoftErrorMsg1: "",
                    showSoftErrorMsg2: "",
                    showSoftAErrorMsg: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_steroid: false,
                post_ava_switch_steroid_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    showSoftErrorMsg1: "",
                    showSoftErrorMsg2: "",
                    showSoftAErrorMsg: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_ivig: false,
                post_ava_switch_ivig_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    showSoftErrorMsg1: "",
                    showSoftAErrorMsg: "",
                    showSoftErrorMsg2: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_antid: false,
                post_ava_switch_antid_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    showSoftErrorMsg1: "",
                    showSoftAErrorMsg: "",
                    showSoftErrorMsg2: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_ritux: false,
                post_ava_switch_ritux_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    showSoftErrorMsg1: "",
                    showSoftAErrorMsg: "",
                    showSoftErrorMsg2: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_fosta: false,
                post_ava_switch_fosta_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    showSoftErrorMsg1: "",
                    showSoftAErrorMsg: "",
                    showSoftErrorMsg2: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_immuno: false,
                post_ava_switch_immuno_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    showSoftErrorMsg1: "",
                    showSoftAErrorMsg: "",
                    showSoftErrorMsg2: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                post_ava_switch_other: false,
                post_ava_switch_other_txt : "",
                post_ava_switch_other_txt_empty : false,
                post_ava_switch_other_Value: {
                    post_ava_tx_switch_dt: "",
                    post_ava_tx_switch_reason: "",
                    post_ava_tx_switch_oth_txt: "",
                    q45DateInvalidSoft1: false,
                    q45DateInvalidSoft2: false,
                    showSoftErrorMsg1: "",
                    showSoftAErrorMsg: "",
                    showSoftErrorMsg2: "",
                    q45aEmptyErrorMsg: false,
                    q45bEmptyErrorMsg: false,
                    q45baEmptyErrorMsg: false,
                },
                [e.target.name]: e.target.value,
            })
        }
        /*Q45 region*/

    }

    const GettreatmentAfterAVAData = async () => {
    try{
        const response = await TreatmentAfterAVAService.GetTreatmentAfterAVAData(pid);
        if (response?.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized)
                HandleSessionTimeout();
            else
                throw response.error;
        }else{

            setshowq38(response.data.showq38);
            /*setshowq39(response.data.showq39);*/
            setshowq42(response.data.showq42);
            setshowq42Checkbox1Section(response.data.showq42Checkbox1Section);
            setshowq42Checkbox2Section(response.data.showq42Checkbox2Section);
            setshowq43(response.data.showq43);
            setshowq43Checkbox1Section(response.data.showq43Checkbox1Section);
            setshowq43Checkbox2Section(response.data.showq43Checkbox2Section);
            setfrequencytime(response.data.frequencytime);
            setSteroidType(response.data.steroidtypeDetails);
            setindexdate(response.data.indexDate == null ? "" : response.data.indexDate);
            setendfollowupdate(response.data.endFollowUpDate == null ? "" : response.data.endFollowUpDate);
            setavadiscdate(response.data.avaDiscDate == null ? "" : response.data.avaDiscDate);

            let enddt = response.data.endFollowUpDate == null ? "" : response.data.endFollowUpDate;
            let discdt = response.data.avaDiscDate == null ? "" : response.data.avaDiscDate;
            let mindt;
            const listOfDates = [enddt, discdt];
            if (enddt == "" && discdt != "")
                mindt = discdt
            else if (enddt != "" && discdt == "")
                mindt = enddt
            else if (enddt == "" && discdt == "")
                mindt = "[min (ava_disc_date, end_of_followup)]"
            else if (enddt != "" && discdt != "") {
                let enddateObject = new Date(enddt);
                let discdateObject = new Date(discdt);
                if (enddateObject > discdateObject)
                    mindt = discdt;
                else
                    mindt = enddt;
            }
            setminenddiscdate(mindt);


            /*Main*/
            if (response.data.itptreatmentpostavaDetails != null) {

                setITPTreatmentPostAVADetails({
                    ...ITPTreatmentPostAVADetails,

                    itptreatmentpostavaid: response.data.itptreatmentpostavaDetails.itptreatmentpostavaid != null ? response.data.itptreatmentpostavaDetails.itptreatmentpostavaid : 0,

                    post_ava_steroid_change: response.data.itptreatmentpostavaDetails.post_ava_steroid_change != null ? response.data.itptreatmentpostavaDetails.post_ava_steroid_change : "",

                    post_ava_new_ster_start: response.data.itptreatmentpostavaDetails.post_ava_new_ster_start != null ? response.data.itptreatmentpostavaDetails.post_ava_new_ster_start : "",

                    post_ava_disc_treat_unk: response.data.itptreatmentpostavaDetails.post_ava_disc_treat_unk != null ? response.data.itptreatmentpostavaDetails.post_ava_disc_treat_unk : "",

                    post_ava_other_treat_unk: response.data.itptreatmentpostavaDetails.post_ava_other_treat_unk != null ? response.data.itptreatmentpostavaDetails.post_ava_other_treat_unk : "",

                    q44show: response.data.itptreatmentpostavaDetails.q44show,
                    post_ava_splen: response.data.itptreatmentpostavaDetails.post_ava_splen != null ? response.data.itptreatmentpostavaDetails.post_ava_splen : "",
                    post_ava_splen_dt: response.data.itptreatmentpostavaDetails.post_ava_splen_dt != null ? response.data.itptreatmentpostavaDetails.post_ava_splen_dt : "",

                    post_ava_splen_dt_unk: response.data.itptreatmentpostavaDetails.post_ava_splen_dt_unk != null ? response.data.itptreatmentpostavaDetails.post_ava_splen_dt_unk : "",

                    q39Empty: false,

                    q41checkbox1: response.data.itptreatmentpostavaDetails.q41Checkbox1Checked != null ? response.data.itptreatmentpostavaDetails.q41Checkbox1Checked : false,
                    q41showrepeater1: response.data.itptreatmentpostavaDetails.q41Checkbox1Checked != null ? response.data.itptreatmentpostavaDetails.q41Checkbox1Checked : false,

                    q41checkbox2: response.data.itptreatmentpostavaDetails.q41Checkbox2Checked != null ? response.data.itptreatmentpostavaDetails.q41Checkbox2Checked : false,
                    q41showrepeater2: response.data.itptreatmentpostavaDetails.q41Checkbox2Checked != null ? response.data.itptreatmentpostavaDetails.q41Checkbox2Checked : false,

                    q41radiobutton: response.data.itptreatmentpostavaDetails.post_ava_ivig_antid_use_unk != null ? response.data.itptreatmentpostavaDetails.post_ava_ivig_antid_use_unk : false,

                    q41Empty: false,

                    q42Selection: "",
                    q42Checkbox1Checked: response.data.itptreatmentpostavaDetails.q42Checkbox1Checked,
                    q42Checkbox2Checked: response.data.itptreatmentpostavaDetails.q42Checkbox2Checked,
                    q42Empty: false,


                    q43Selection: "",
                    q43Checkbox1Checked: response.data.itptreatmentpostavaDetails.q43Checkbox1Checked,
                    q43Checkbox2Checked: response.data.itptreatmentpostavaDetails.q43Checkbox2Checked,
                    q43Empty: false,

                    q44DateInvalidSoft1: false,
                    q44DateInvalidSoft2: false,
                    q44DateInvalidSoft3: false,
                    q44Empty: false,
                    q44DataEmpty: false,
                });


                let Q45array = Q45HandleControlState;

                Q45array.post_ava_switch_elt = response.data.itptreatmentpostavaDetails.q45Checkbox1Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox1Checked : false;
                Q45array.post_ava_switch_romi = response.data.itptreatmentpostavaDetails.q45Checkbox2Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox2Checked : false;
                Q45array.post_ava_switch_lusu = response.data.itptreatmentpostavaDetails.q45Checkbox3Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox3Checked : false;
                Q45array.post_ava_switch_steroid = response.data.itptreatmentpostavaDetails.q45Checkbox4Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox4Checked : false;
                Q45array.post_ava_switch_ivig = response.data.itptreatmentpostavaDetails.q45Checkbox5Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox5Checked : false;
                Q45array.post_ava_switch_antid = response.data.itptreatmentpostavaDetails.q45Checkbox6Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox6Checked : false;
                Q45array.post_ava_switch_ritux = response.data.itptreatmentpostavaDetails.q45Checkbox7Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox7Checked : false;
                Q45array.post_ava_switch_fosta = response.data.itptreatmentpostavaDetails.q45Checkbox8Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox8Checked : false;
                Q45array.post_ava_switch_immuno = response.data.itptreatmentpostavaDetails.q45Checkbox9Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox9Checked : false;
                Q45array.post_ava_switch_other = response.data.itptreatmentpostavaDetails.q45Checkbox10Checked != null ? response.data.itptreatmentpostavaDetails.q45Checkbox10Checked : false;
            
                Q45array.post_ava_switch_none = response.data.itptreatmentpostavaDetails.post_ava_disc_date_unk != null ? response.data.itptreatmentpostavaDetails.post_ava_disc_date_unk === true ? 1 : 0 : 0;

                setQ45HandleControlState(Q45array);

                if (response.data.itptreatmentpostavaDetails.post_ava_steroid_change == 1)
                    setshowq38parentrepeater(true);
                if (response.data.itptreatmentpostavaDetails.post_ava_new_ster_start == 1)
                    setshowq39parentrepeater(true);
            }

            /* setshowq39(response.data.showq39);*/
            setshowq45(response.data.showq45);

            if (response.data.steroidreceivedDetails != null && response.data.steroidreceivedDetails.length > 0) {

                let i = 0;
                let dataarray = [];

                for (i; i < response.data.steroidreceivedDetails.length; i++) {

                    let datacomponent = {
                        steroidreceivedid: response.data.steroidreceivedDetails[i].steroidreceivedid != null ? response.data.steroidreceivedDetails[i].steroidreceivedid : 0,
                        steroidtypeid: response.data.steroidreceivedDetails[i].steroidtypeid != null ? response.data.steroidreceivedDetails[i].steroidtypeid : 0,
                        post_ava_b1_tx_change_doseFrequency: response.data.steroidreceivedDetails[i].post_ava_b1_tx_change != null ? (response.data.steroidreceivedDetails[i].post_ava_b1_tx_change==1?true:false): false,
                        post_ava_b1_tx_change_discontinuation: response.data.steroidreceivedDetails[i].post_ava_b1_tx_change_discontinuation != null ?(response.data.steroidreceivedDetails[i].post_ava_b1_tx_change_discontinuation==1?true:false): false,
                        post_ava_e1_tx_disc: response.data.steroidreceivedDetails[i].post_ava_e1_tx_disc != null ? response.data.steroidreceivedDetails[i].post_ava_e1_tx_disc : "",
                        post_ava_f1_tx_disc_dt: response.data.steroidreceivedDetails[i].post_ava_f1_tx_disc_dt === null ? "" : response.data.steroidreceivedDetails[i].post_ava_f1_tx_disc_dt,
                        showq38childrepeater: (response.data.steroidreceivedDetails[i].steroidreceiveddosagechangeDetails != null &&
                            response.data.steroidreceivedDetails[i].steroidreceiveddosagechangeDetails.length > 0) ? true : false,
                        q38Checkbox: response.data.steroidreceivedDetails[i].post_ava_e1_tx_disc != null ? true : false,
                        showQ38f: (response.data.steroidreceivedDetails[i].post_ava_b1_tx_change_discontinuation != null && response.data.steroidreceivedDetails[i].post_ava_b1_tx_change_discontinuation!=0) ? true : false,
                        q39SteroidcoursedosagechangeDetails: [],
                        // post_ava_ster_disc_dt_unk: response.data.steroidreceivedDetails[i].post_ava_ster_disc_dt_unk != null ? response.data.steroidreceivedDetails[i].post_ava_ster_disc_dt_unk : "",
                        q38bEmpty: false,
                        defEDateQ38: false,
                        q39_empty: false,
                        q39a_empty: false,
                        q39e_empty: false,
                        q39e_f_empty: false,
                        q3FSteroidDateInvalidSoft1: false,
                        q3FSteroidDateInvalidSoft2: false,
                        q3FSteroidDateInvalidSoft3: false,
                        q3FSteroidDateInvalidSoft4: false,
                        steroidreceiveddosagechangeDetails: [],
                    };

                    if (response.data.steroidreceivedDetails[i].steroidreceiveddosagechangeDetails) {
                        let j = 0;

                        let childData = response.data.steroidreceivedDetails[i].steroidreceiveddosagechangeDetails;

                        for (j; j < childData.length; j++) {
                            // post_ava_b1x1_tx_change_dt_unk: childData[j].post_ava_b1x1_tx_change_dt_unk != null ? childData[j].post_ava_b1x1_tx_change_dt_unk : "",
                            let datasubcomponent = {
                                steroidreceiveddosagechangeid: childData[j].steroidreceiveddosagechangeid != null ? childData[j].steroidreceiveddosagechangeid : 0,
                                steroidreceivedid: childData[j].steroidreceivedid != null ? childData[j].steroidreceivedid : 0,
                                post_ava_b1x1_tx_change_dt: childData[j].post_ava_b1x1_tx_change_dt != null ? childData[j].post_ava_b1x1_tx_change_dt : "",
                                post_ava_c1x1_tx_dose: childData[j].post_ava_c1x1_tx_dose != null ? childData[j].post_ava_c1x1_tx_dose : "",
                                post_ava_d1x1_tx_dose_num: childData[j].post_ava_d1x1_tx_dose_num != null ? childData[j].post_ava_d1x1_tx_dose_num : "",
                                frequencytimeid: childData[j].frequencytimeid != null ? childData[j].frequencytimeid : "",
                                defDateQ38: false,
                                q3BSteroidDateInvalidSoft1: false,
                                q3BSteroidDateInvalidSoft2: false,
                                q3BSteroidDateInvalidSoft3: false,
                                q39child_empty: false,
                                q38doselimitexceeded: false,
                                q38freqlimitexceeded: false,
                                q38RepeaterbEmpty: false,
                                q38RepeatercEmpty: false,
                                q38RepeaterdEmpty: false,
                            };

                            datacomponent.steroidreceiveddosagechangeDetails.push(datasubcomponent);
                        }
                    }

                    dataarray.push(datacomponent);
                }

                setq38MainSteroidReceivedDetails(dataarray);
            }


            if (response.data.steroidcourseDetails != null && response.data.steroidcourseDetails.length > 0) {

                let i = 0;
                let dataarray = [];
                for (i; i < response.data.steroidcourseDetails.length; i++) {

                    let datacomponent = {

                        steroidcourseid:
                            response.data.steroidcourseDetails[i].steroidcourseid != null
                                ? response.data.steroidcourseDetails[i].steroidcourseid
                                : 0,
                        steroidtypeid:
                            response.data.steroidcourseDetails[i].steroidtypeid != null
                                ? response.data.steroidcourseDetails[i].steroidtypeid
                                : 0,
                        new_tx_start_dt_b1:
                            response.data.steroidcourseDetails[i].new_tx_start_dt_b1 != null
                                ? response.data.steroidcourseDetails[i].new_tx_start_dt_b1
                                : "",
                        //new_tx_start_dt_b1_unk:
                        //    response.data.steroidcourseDetails[i].new_tx_start_dt_b1_unk === true
                        //        ? "1"
                        //        : "",
                        new_tx_dose_amt_c1:
                            response.data.steroidcourseDetails[i].new_tx_dose_amt_c1 != null
                                ? response.data.steroidcourseDetails[i].new_tx_dose_amt_c1
                                : "",
                        new_tx_dose_num_d1:
                            response.data.steroidcourseDetails[i].new_tx_dose_num_d1 != null
                                ? response.data.steroidcourseDetails[i].new_tx_dose_num_d1
                                : "",
                        frequencytimeid:
                            response.data.steroidcourseDetails[i].frequencytimeid != null
                                ? response.data.steroidcourseDetails[i].frequencytimeid
                                : "",
                        new_tx_dose_chg_e1:
                            response.data.steroidcourseDetails[i].new_tx_dose_chg_e1 != null
                                ? response.data.steroidcourseDetails[i].new_tx_dose_chg_e1
                                : "",
                        new_tx_disc_i1:
                            response.data.steroidcourseDetails[i].new_tx_disc_i1 != null
                                ? response.data.steroidcourseDetails[i].new_tx_disc_i1
                                : "",
                        new_tx_disc_dt_j1:
                            response.data.steroidcourseDetails[i].new_tx_disc_dt_j1 != null
                                ? response.data.steroidcourseDetails[i].new_tx_disc_dt_j1
                                : "",
                        //new_tx_disc_dt_j1_unk:
                        //    response.data.steroidcourseDetails[i].new_tx_disc_dt_j1_unk === true
                        //        ? "1"
                        //        : "",
                        showq39j:
                            (response.data.steroidcourseDetails[i].new_tx_disc_i1 != null
                                && response.data.steroidcourseDetails[i].new_tx_disc_i1 == 1) ? true : false,
                        defDateQ39b: false,
                        defDateQ39j: false,
                        showq39childrepeater: (response.data.steroidcourseDetails[i].steroidcoursedosagechangeDetails != null &&
                            response.data.steroidcourseDetails[i].steroidcoursedosagechangeDetails.length > 0) ? true : false,
                        q39SteroidcoursedosagechangeDetails: [],

                        q39aEmpty: false,
                        q39bEmpty: false,
                        q39bDateInvalidSoft1: false,
                        q39bDateInvalidSoft2: false,
                        q39bDateInvalidSoft3: false,
                        q39cEmpty: false,
                        q39dEmpty: false,
                        q39eEmpty: false,
                        q39iEmpty: false,
                        q39jEmpty: false,
                        q39jDateInvalidSoft1: false,
                        q39jDateInvalidSoft2: false,
                        q39jDateInvalidSoft3: false,
                        q39doselimitexceeded: false,
                        q39freqlimitexceeded: false,
                    };

                    if (response.data.steroidcourseDetails[i].steroidcoursedosagechangeDetails.length > 0) {

                        //let subdataarray = [];
                        for (let j = 0; j < response.data.steroidcourseDetails[i].steroidcoursedosagechangeDetails.length; j++) {
                            let childData = response.data.steroidcourseDetails[i].steroidcoursedosagechangeDetails[j];

                            let datasubcomponent = {
                                steroidcoursedosagechangeid: childData.steroidcoursedosagechangeid != null ? childData.steroidcoursedosagechangeid : 0,
                                steroidtypeid: childData.steroidtypeid != null ? childData.steroidtypeid : 0,
                                new_tx_chg_dt_f1x1: childData.new_tx_chg_dt_f1x1 != null ? childData.new_tx_chg_dt_f1x1 : "",
                                /*new_tx_chg_dt_f1x1_unk: childData.new_tx_chg_dt_f1x1_unk != null ? childData.new_tx_chg_dt_f1x1_unk : "",*/
                                new_tx_chg_dose_g1x1: childData.new_tx_chg_dose_g1x1 != null ? childData.new_tx_chg_dose_g1x1 : "",
                                new_tx_chg_num_h1x1: childData.new_tx_chg_num_h1x1 != null ? childData.new_tx_chg_num_h1x1 : "",
                                frequencytimeid: childData.frequencytimeid != null ? childData.frequencytimeid : "",
                                defDateQ39f: false,

                                q39fEmpty: false,
                                q39fDateInvalidSoft1: false,
                                q39fDateInvalidSoft2: false,
                                q39fDateInvalidSoft3: false,
                                q39gEmpty: false,
                                q39hEmpty: false,
                                q39doselimitexceeded: false,
                                q39freqlimitexceeded: false,
                            };
                            datacomponent.q39SteroidcoursedosagechangeDetails.push(datasubcomponent);
                        }
                    }

                    dataarray.push(datacomponent);
                    setq39SteroidCourseDetails(dataarray);
                }
            }

            if (response.data.ivigdatesDetails != null && response.data.ivigdatesDetails.length > 0) {

                let i = 0;
                let dataarray = [];
                for (i; i < response.data.ivigdatesDetails.length; i++) {

                    let datacomponent = {
                        ivigdatesid:
                            response.data.ivigdatesDetails[i].ivigdatesid != null
                                ? response.data.ivigdatesDetails[i].ivigdatesid
                                : 0,
                        ivigtherapytypeid:
                            response.data.ivigdatesDetails[i].ivigtherapytypeid != null
                                ? response.data.ivigdatesDetails[i].ivigtherapytypeid
                                : 0,
                        postAvaA1TxStartDt:
                            response.data.ivigdatesDetails[i].postAvaA1TxStartDt != null
                                ? response.data.ivigdatesDetails[i].postAvaA1TxStartDt
                                : "",
                        postAvaA1TxStartDtUnk:
                            response.data.ivigdatesDetails[i].postAvaA1TxStartDtUnk == 1,
                        postAvaB1TxEndDt:
                            response.data.ivigdatesDetails[i].postAvaB1TxEndDt != null
                                ? response.data.ivigdatesDetails[i].postAvaB1TxEndDt
                                : "",
                        postAvaB1TxEndDtUnk:
                            response.data.ivigdatesDetails[i].postAvaB1TxEndDtUnk == 1,

                        defDateQ41A: false,
                        defDateQ41B: false,

                        q41iviaEmpty: false,
                        q41iviaDateInvalidSoft1: false,
                        q41iviaDateInvalidSoft2: false,
                        q41iviaDateInvalidSoft3: false,
                        q41ivibEmpty: false,
                        q41ivibDateInvalidSoft1: false,
                        q41ivibDateInvalidSoft2: false,
                        q41ivibDateInvalidSoft3: false,

                    };
                    dataarray.push(datacomponent);

                    setq41ivigdatesDetails(dataarray);
                }
            }

            if (response.data.antiddatesDetails != null && response.data.antiddatesDetails.length > 0) {

                let i = 0;
                let dataarray = [];
                for (i; i < response.data.antiddatesDetails.length; i++) {

                    let datacomponent = {
                        ivigdatesid:
                            response.data.antiddatesDetails[i].ivigdatesid != null
                                ? response.data.antiddatesDetails[i].ivigdatesid
                                : 0,
                        ivigtherapytypeid:
                            response.data.antiddatesDetails[i].ivigtherapytypeid != null
                                ? response.data.antiddatesDetails[i].ivigtherapytypeid
                                : 0,
                        postAvaA1TxStartDt:
                            response.data.antiddatesDetails[i].postAvaA1TxStartDt != null
                                ? response.data.antiddatesDetails[i].postAvaA1TxStartDt
                                : "",
                        postAvaA1TxStartDtUnk:
                            response.data.antiddatesDetails[i].postAvaA1TxStartDtUnk == 1,
                        postAvaB1TxEndDt:
                            response.data.antiddatesDetails[i].postAvaB1TxEndDt != null
                                ? response.data.antiddatesDetails[i].postAvaB1TxEndDt
                                : "",
                        postAvaB1TxEndDtUnk:
                            response.data.antiddatesDetails[i].postAvaB1TxEndDtUnk == 1,

                        defDateQ41A: false,
                        defDateQ41B: false,

                        q41antidaEmpty: false,
                        q41antidaDateInvalidSoft1: false,
                        q41antidaDateInvalidSoft2: false,
                        q41antidaDateInvalidSoft3: false,
                        q41antidbEmpty: false,
                        q41antidbDateInvalidSoft1: false,
                        q41antidbDateInvalidSoft2: false,
                        q41antidbDateInvalidSoft3: false,

                    };
                    dataarray.push(datacomponent);
                    setq41antiddatesDetails(dataarray);
                }
            }

            if (response.data.itpDiscontinuationDateCheckbox1Details != null && response.data.itpDiscontinuationDateCheckbox1Details.length > 0) {

                let i = 0;
                let dataarray = [];
                for (i; i < response.data.itpDiscontinuationDateCheckbox1Details.length; i++) {

                    let datacomponent = {
                        Itpdiscontinuationdateid:
                            response.data.itpDiscontinuationDateCheckbox1Details[i].itpdiscontinuationdateid != null
                                ? response.data.itpDiscontinuationDateCheckbox1Details[i].itpdiscontinuationdateid
                                : 0,
                        Itptreamenttypeid:
                            response.data.itpDiscontinuationDateCheckbox1Details[i].itptreamenttypeid != null
                                ? response.data.itpDiscontinuationDateCheckbox1Details[i].itptreamenttypeid
                                : 0,
                        PostAvaTxDiscDt:
                            response.data.itpDiscontinuationDateCheckbox1Details[i].postAvaTxDiscDt != null
                                ? response.data.itpDiscontinuationDateCheckbox1Details[i].postAvaTxDiscDt
                                : "",

                        defDateQ42: false,
                        q42Empty: false,
                        q42DateInvalidSoft1: false,
                        q42DateInvalidSoft2: false,
                        q42DateInvalidSoft3: false,

                    };
                    dataarray.push(datacomponent);
                    setq42itpdiscontinuationdatesCheckbox1Details(dataarray);
                }
            }

            if (response.data.itpDiscontinuationDateCheckbox2Details != null && response.data.itpDiscontinuationDateCheckbox2Details.length > 0) {

                let i = 0;
                let dataarray = [];
                for (i; i < response.data.itpDiscontinuationDateCheckbox2Details.length; i++) {

                    let datacomponent = {
                        Itpdiscontinuationdateid:
                            response.data.itpDiscontinuationDateCheckbox2Details[i].itpdiscontinuationdateid != null
                                ? response.data.itpDiscontinuationDateCheckbox2Details[i].itpdiscontinuationdateid
                                : 0,
                        Itptreamenttypeid:
                            response.data.itpDiscontinuationDateCheckbox2Details[i].itptreamenttypeid != null
                                ? response.data.itpDiscontinuationDateCheckbox2Details[i].itptreamenttypeid
                                : 0,
                        PostAvaTxDiscDt:
                            response.data.itpDiscontinuationDateCheckbox2Details[i].postAvaTxDiscDt != null
                                ? response.data.itpDiscontinuationDateCheckbox2Details[i].postAvaTxDiscDt
                                : "",

                        defDateQ42: false,
                        q42Empty: false,
                        q42DateInvalidSoft1: false,
                        q42DateInvalidSoft2: false,
                        q42DateInvalidSoft3: false,

                    };
                    dataarray.push(datacomponent);
                    setq42itpdiscontinuationdatesCheckbox2Details(dataarray);
                }
            }

            if (response.data.otherItpTreatmentsModalCheckbox1Details != null && response.data.otherItpTreatmentsModalCheckbox1Details.length > 0) {

                let i = 0;
                let dataarray = [];

                for (i; i < response.data.otherItpTreatmentsModalCheckbox1Details.length; i++) {

                    let datacomponent = {
                        Otheritptreatmentsid:
                            response.data.otherItpTreatmentsModalCheckbox1Details[i].otheritptreatmentsid != null
                                ? response.data.otherItpTreatmentsModalCheckbox1Details[i].otheritptreatmentsid
                                : 0,
                        Itptreatmenttypeid:
                            response.data.otherItpTreatmentsModalCheckbox1Details[i].itptreatmenttypeid != null
                                ? response.data.otherItpTreatmentsModalCheckbox1Details[i].itptreatmenttypeid
                                : 0,
                        PostAvaTxStartDt:
                            response.data.otherItpTreatmentsModalCheckbox1Details[i].postAvaTxStartDt != null
                                ? response.data.otherItpTreatmentsModalCheckbox1Details[i].postAvaTxStartDt
                                : "",
                        PostAvaTxDisc:
                            response.data.otherItpTreatmentsModalCheckbox1Details[i].postAvaTxDisc != null
                                ? response.data.otherItpTreatmentsModalCheckbox1Details[i].postAvaTxDisc
                                : "",
                        PostAvaTxDiscDt:
                            response.data.otherItpTreatmentsModalCheckbox1Details[i].postAvaTxDiscDt != null
                                ? response.data.otherItpTreatmentsModalCheckbox1Details[i].postAvaTxDiscDt
                                : "",
                        q43Empty: false,
                        q43aEmpty: false,
                        q43bEmpty: false,
                        q43cEmpty: false,

                        defDate1Q43: false,
                        q43Date1InvalidSoft1: false,
                        q43Date1InvalidSoft2: false,
                        q43Date1InvalidSoft3: false,
                        defDate2Q43: false,
                        q43Date2InvalidSoft1: false,
                        q43Date2InvalidSoft2: false,
                        q43Date2InvalidSoft3: false,


                    };
                    dataarray.push(datacomponent);
                    setq43otherItpTreatmentsModalCheckbox1Details(dataarray);
                }
            }

            if (response.data.otherItpTreatmentsModalCheckbox2Details != null && response.data.otherItpTreatmentsModalCheckbox2Details.length > 0) {

                let i = 0;
                let dataarray = [];

                for (i; i < response.data.otherItpTreatmentsModalCheckbox2Details.length; i++) {

                    let datacomponent = {
                        Otheritptreatmentsid:
                            response.data.otherItpTreatmentsModalCheckbox2Details[i].otheritptreatmentsid != null
                                ? response.data.otherItpTreatmentsModalCheckbox2Details[i].otheritptreatmentsid
                                : 0,
                        Itptreatmenttypeid:
                            response.data.otherItpTreatmentsModalCheckbox2Details[i].itptreatmenttypeid != null
                                ? response.data.otherItpTreatmentsModalCheckbox2Details[i].itptreatmenttypeid
                                : 0,
                        PostAvaTxStartDt:
                            response.data.otherItpTreatmentsModalCheckbox2Details[i].postAvaTxStartDt != null
                                ? response.data.otherItpTreatmentsModalCheckbox2Details[i].postAvaTxStartDt
                                : "",
                        PostAvaTxDisc:
                            response.data.otherItpTreatmentsModalCheckbox2Details[i].postAvaTxDisc != null
                                ? response.data.otherItpTreatmentsModalCheckbox2Details[i].postAvaTxDisc
                                : "",
                        PostAvaTxDiscDt:
                            response.data.otherItpTreatmentsModalCheckbox2Details[i].postAvaTxDiscDt != null
                                ? response.data.otherItpTreatmentsModalCheckbox2Details[i].postAvaTxDiscDt
                                : "",
                        q43Empty: false,
                        q43aEmpty: false,
                        q43bEmpty: false,
                        q43cEmpty: false,

                        defDate1Q43: false,
                        q43Date1InvalidSoft1: false,
                        q43Date1InvalidSoft2: false,
                        q43Date1InvalidSoft3: false,
                        defDate2Q43: false,
                        q43Date2InvalidSoft1: false,
                        q43Date2InvalidSoft2: false,
                        q43Date2InvalidSoft3: false,


                    };
                    dataarray.push(datacomponent);
                    setq43otherItpTreatmentsModalCheckbox2Details(dataarray);
                }
            }

            if (response.data.itpTreatmentsQ45Chk1TypeDetails != null && response.data.itpTreatmentsQ45Chk1TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk1TypeDetails.length; i++) {
                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_elt_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk1TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk1TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_elt_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk1TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk1TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_elt_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk1TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk1TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_elt_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk1TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk1TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_elt_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk1TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk1TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_elt_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_elt_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_elt_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_elt_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_elt_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_elt_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_elt_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_elt_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_elt_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_elt_Value.q45baEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45Chk1array);
                }
            }
            if (response.data.itpTreatmentsQ45Chk2TypeDetails != null && response.data.itpTreatmentsQ45Chk2TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk2TypeDetails.length; i++) {

                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_romi_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk2TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk2TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_romi_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk2TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk2TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_romi_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk2TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk2TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_romi_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk2TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk2TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_romi_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk2TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk2TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_romi_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_romi_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_romi_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_romi_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_romi_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_romi_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_romi_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_romi_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_romi_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_romi_Value.q45baEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45Chk1array);

                }
            }
            if (response.data.itpTreatmentsQ45Chk3TypeDetails != null && response.data.itpTreatmentsQ45Chk3TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk3TypeDetails.length; i++) {

                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_lusu_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk3TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk3TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_lusu_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk3TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk3TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_lusu_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk3TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk3TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_lusu_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk3TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk3TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_lusu_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk3TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk3TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_lusu_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_lusu_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_lusu_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_lusu_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_lusu_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_lusu_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_lusu_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_lusu_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_lusu_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_lusu_Value.q45baEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45Chk1array);

                }
            }
            if (response.data.itpTreatmentsQ45Chk4TypeDetails != null && response.data.itpTreatmentsQ45Chk4TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk4TypeDetails.length; i++) {

                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_steroid_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk4TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk4TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_steroid_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk4TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk4TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_steroid_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk4TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk4TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_steroid_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk4TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk4TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_steroid_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk4TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk4TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_steroid_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_steroid_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_steroid_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_steroid_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_steroid_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_steroid_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_steroid_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_steroid_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_steroid_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_steroid_Value.q45baEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45Chk1array);

                }
            }
            if (response.data.itpTreatmentsQ45Chk5TypeDetails != null && response.data.itpTreatmentsQ45Chk5TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk5TypeDetails.length; i++) {

                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_ivig_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk5TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk5TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_ivig_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk5TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk5TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_ivig_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk5TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk5TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_ivig_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk5TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk5TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_ivig_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk5TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk5TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_ivig_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_ivig_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_ivig_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_ivig_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_ivig_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_ivig_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_ivig_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_ivig_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_ivig_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_ivig_Value.q45baEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45Chk1array);

                }
            }
            if (response.data.itpTreatmentsQ45Chk6TypeDetails != null && response.data.itpTreatmentsQ45Chk6TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk6TypeDetails.length; i++) {

                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_antid_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk6TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk6TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_antid_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk6TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk6TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_antid_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk6TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk6TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_antid_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk6TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk6TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_antid_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk6TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk6TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_antid_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_antid_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_antid_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_antid_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_antid_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_antid_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_antid_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_antid_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_antid_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_antid_Value.q45baEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45Chk1array);

                }
            }
            if (response.data.itpTreatmentsQ45Chk7TypeDetails != null && response.data.itpTreatmentsQ45Chk7TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk7TypeDetails.length; i++) {

                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_ritux_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk7TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk7TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_ritux_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk7TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk7TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_ritux_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk7TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk7TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_ritux_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk7TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk7TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_ritux_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk7TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk7TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_ritux_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_ritux_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_ritux_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_ritux_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_ritux_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_ritux_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_ritux_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_ritux_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_ritux_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_ritux_Value.q45baEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45Chk1array);

                }
            }
            if (response.data.itpTreatmentsQ45Chk8TypeDetails != null && response.data.itpTreatmentsQ45Chk8TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk8TypeDetails.length; i++) {

                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_fosta_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk8TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk8TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_fosta_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk8TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk8TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_fosta_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk8TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk8TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_fosta_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk8TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk8TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_fosta_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk8TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk8TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_fosta_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_fosta_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_fosta_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_fosta_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_fosta_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_fosta_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_fosta_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_fosta_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_fosta_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_fosta_Value.q45baEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45Chk1array);

                }
            }
            if (response.data.itpTreatmentsQ45Chk9TypeDetails != null && response.data.itpTreatmentsQ45Chk9TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk9TypeDetails.length; i++) {

                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_immuno_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk9TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk9TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_immuno_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk9TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk9TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_immuno_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk9TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk9TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_immuno_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk9TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk9TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_immuno_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk9TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk9TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_immuno_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_immuno_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_immuno_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_immuno_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_immuno_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_immuno_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_immuno_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_immuno_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_immuno_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_immuno_Value.q45baEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45Chk1array);

                }
            }
            if (response.data.itpTreatmentsQ45Chk10TypeDetails != null && response.data.itpTreatmentsQ45Chk10TypeDetails.length > 0) {
                let i = 0;
                for (i; i < response.data.itpTreatmentsQ45Chk10TypeDetails.length; i++) {

                    let Q45Chk1array = { ...Q45HandleControlState };
                    Q45Chk1array.post_ava_switch_other_Value.itptreatmentsfollowingavaid = response.data.itpTreatmentsQ45Chk10TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk10TypeDetails[i].itptreatmentsfollowingavaid
                        : 0;
                    Q45Chk1array.post_ava_switch_other_Value.itptreatmentsfollowingavatypeid = response.data.itpTreatmentsQ45Chk10TypeDetails[i].itptreatmentsfollowingavaid != null
                        ? response.data.itpTreatmentsQ45Chk10TypeDetails[i].itptreatmentsfollowingavatypeid
                        : 0;
                    Q45Chk1array.post_ava_switch_other_Value.post_ava_tx_switch_dt = response.data.itpTreatmentsQ45Chk10TypeDetails[i].postAvaTxSwitchDt != null
                        ? response.data.itpTreatmentsQ45Chk10TypeDetails[i].postAvaTxSwitchDt
                        : "";
                    Q45Chk1array.post_ava_switch_other_Value.post_ava_tx_switch_reason = response.data.itpTreatmentsQ45Chk10TypeDetails[i].postAvaTxSwitchReason != null
                        ? response.data.itpTreatmentsQ45Chk10TypeDetails[i].postAvaTxSwitchReason
                        : "";
                    Q45Chk1array.post_ava_switch_other_Value.post_ava_tx_switch_oth_txt = response.data.itpTreatmentsQ45Chk10TypeDetails[i].postAvaTxSwitchOthTxt != null
                        ? response.data.itpTreatmentsQ45Chk10TypeDetails[i].postAvaTxSwitchOthTxt
                        : "";
                    Q45Chk1array.post_ava_switch_other_Value.q45DateInvalidSoft1 = false;
                    Q45Chk1array.post_ava_switch_other_Value.q45DateInvalidSoft2 = false;
                    Q45Chk1array.post_ava_switch_other_Value.Q45EmptyFields = false;
                    Q45Chk1array.post_ava_switch_other_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_other_Value.showSoftErrorMsg1 = "";
                    Q45Chk1array.post_ava_switch_other_Value.showSoftAErrorMsg = "";
                    Q45Chk1array.post_ava_switch_other_Value.showSoftErrorMsg2 = "";
                    Q45Chk1array.post_ava_switch_other_Value.q45aEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_other_Value.q45bEmptyErrorMsg = false;
                    Q45Chk1array.post_ava_switch_other_Value.q45baEmptyErrorMsg = false;
debugger
Q45Chk1array.post_ava_switch_other_txt = response.data.itptreatmentpostavaDetails.q45Checkbox10Checked != null ? 
                                                            response.data.itptreatmentpostavaDetails.q45Checkbox10Checked === true?
                                                            response.data.itpTreatmentsQ45Chk10TypeDetails[i].itptmtfollavaOtherTypeTxt : ""
                                                            : "";

                    setQ45HandleControlState(Q45Chk1array);

                }
            }
        }
    }catch{        
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
    }

    const HandleDateChange = (name, date, optionId, index) => {

        const splitNameArray = name.split("-");
        const lastIndex = name.lastIndexOf("-");
        let indexDateValue = indexdate != "" ? new Date(indexdate) : new Date();
        const id = name.slice(lastIndex + 1);
        switch (optionId) {
            case "post_ava_b1x1_tx_change_dt":
                {
                    let parentId = splitNameArray[1];
                    let childId = splitNameArray[2];
                    let Q39array = [...q38MainSteroidReceivedDetails];
                    Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt = date;
                    Q39array[parentId].steroidreceiveddosagechangeDetails[childId].defDateQ38 = false;
                    // Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt_unk = ""; ||    Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt_unk != ""


                    Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q3BSteroidDateInvalidSoft1 = indexdate == "" ? false : new Date(q38MainSteroidReceivedDetails[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt) < new Date(indexdate);
                    Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q3BSteroidDateInvalidSoft2 = avadiscdate == "" ? false : new Date(q38MainSteroidReceivedDetails[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt) > new Date(avadiscdate);
                    Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q3BSteroidDateInvalidSoft3 = endfollowupdate == "" ? false : new Date(q38MainSteroidReceivedDetails[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt) > new Date(endfollowupdate);
                    Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q38RepeaterbEmpty = false;



                    if ((Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_b1x1_tx_change_dt !== "") &&
                        Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_c1x1_tx_dose !== "" &&
                        Q39array[parentId].steroidreceiveddosagechangeDetails[childId].post_ava_d1x1_tx_dose_num !== "" &&
                        Q39array[parentId].steroidreceiveddosagechangeDetails[childId].frequencytimeid !== "") {
                        Q39array[parentId].steroidreceiveddosagechangeDetails[childId].q39child_empty = false;
                    }
                    setq38MainSteroidReceivedDetails(Q39array);
                }
                break;

            case "post_ava_f1_tx_disc_dt":
                {

                    let parentId = splitNameArray[1];
                    let Q39array = [...q38MainSteroidReceivedDetails];

                    Q39array[parentId].post_ava_f1_tx_disc_dt = date;
                    // Q39array[parentId].post_ava_ster_disc_dt_unk = "";
                    Q39array[parentId].defEDateQ38 = false;
                    Q39array[parentId].q39e_f_empty = false;

                    Q39array[parentId].q3FSteroidDateInvalidSoft1 = indexdate == "" ? false : new Date(q38MainSteroidReceivedDetails[parentId].post_ava_f1_tx_disc_dt) < new Date(indexdate);//q39e1_ster_chg_dt < indexDate
                    //Q39array[parentId].q3FSteroidDateInvalidSoft1 = q38MainSteroidReceivedDetails[parentId].post_ava_f1_tx_disc_dt == "" ? false : new Date(q38MainSteroidReceivedDetails[parentId].post_ava_f1_tx_disc_dt) < new Date(q38MainSteroidReceivedDetails[parentId].steroidreceiveddosagechangeDetails[parentId].post_ava_b1x1_tx_change_dt);//q39e1_ster_chg_dt < q39_ster_start_dt
                    Q39array[parentId].q3FSteroidDateInvalidSoft2 = avadiscdate == "" ? false : new Date(q38MainSteroidReceivedDetails[id].post_ava_f1_tx_disc_dt) > new Date(avadiscdate);
                    Q39array[parentId].q3FSteroidDateInvalidSoft3 = endfollowupdate == "" ? false : new Date(q38MainSteroidReceivedDetails[id].post_ava_f1_tx_disc_dt) > new Date(endfollowupdate);
                    for (let i = 0; i < Q39array[parentId].steroidreceiveddosagechangeDetails.length; i++) {
                        debugger;
                        Q39array[parentId].q3FSteroidDateInvalidSoft4 = Q39array[parentId].steroidreceiveddosagechangeDetails[i].post_ava_b1x1_tx_change_dt == "" ? false : new Date(q38MainSteroidReceivedDetails[id].post_ava_f1_tx_disc_dt) < new Date(Q39array[parentId].steroidreceiveddosagechangeDetails[i].post_ava_b1x1_tx_change_dt);
                        if (Q39array[parentId].q3FSteroidDateInvalidSoft4)
                            break;
                    }

                    setq38MainSteroidReceivedDetails(Q39array);
                }
                break;

            case "new_tx_start_dt_b1":
                {
                    let Q39array = [...q39SteroidCourseDetails];
                    Q39array[id].new_tx_start_dt_b1 = date;
                    /*Q39array[id].new_tx_start_dt_b1_unk = "";*/
                    Q39array[id].q39bEmpty = false;
                    Q39array[id].q39bDateInvalidSoft1 = indexdate == "" ? false : new Date(q39SteroidCourseDetails[id].new_tx_start_dt_b1) < new Date(indexdate);//q30_ster_start_dt < index_date
                    Q39array[id].q39bDateInvalidSoft2 = avadiscdate == "" ? false : new Date(q39SteroidCourseDetails[id].new_tx_start_dt_b1) > new Date(avadiscdate);//q30_ster_start_dt > ava_disc_dt
                    Q39array[id].q39bDateInvalidSoft3 = endfollowupdate == "" ? false : new Date(q39SteroidCourseDetails[id].new_tx_start_dt_b1) > new Date(endfollowupdate);//q30_ster_start_dt > end_of_followup
                    setq39SteroidCourseDetails(Q39array);
                }
                break;

            case "new_tx_chg_dt_f1x1":
                {

                    let parentId = splitNameArray[1];
                    let childId = splitNameArray[2];
                    let Q39array = [...q39SteroidCourseDetails];
                    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_dt_f1x1 = date;
                    /* Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_dt_f1x1_unk = "";*/
                    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39fEmpty = false;
                    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39fDateInvalidSoft1 = q39SteroidCourseDetails[parentId].q30_ster_start_dt == "" ? false : new Date(q39SteroidCourseDetails[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_dt_f1x1) < new Date(q39SteroidCourseDetails[parentId].new_tx_start_dt_b1);//q30e1_ster_chg_dt < q30_ster_start_dt
                    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39fDateInvalidSoft2 = avadiscdate == "" ? false : new Date(q39SteroidCourseDetails[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_dt_f1x1) > new Date(avadiscdate);//q30e1_ster_chg_dt > ava_disc_dt
                    Q39array[parentId].q39SteroidcoursedosagechangeDetails[childId].q39fDateInvalidSoft3 = endfollowupdate == "" ? false : new Date(q39SteroidCourseDetails[parentId].q39SteroidcoursedosagechangeDetails[childId].new_tx_chg_dt_f1x1) > new Date(endfollowupdate);//q30e1_ster_chg_dt > end_of_followup
                    setq39SteroidCourseDetails(Q39array);
                }
                break;

            case "new_tx_disc_dt_j1":
                {

                    let Q39array = [...q39SteroidCourseDetails];
                    Q39array[id].new_tx_disc_dt_j1 = date;
                    /*Q39array[id].new_tx_disc_dt_j1_unk = "";*/
                    Q39array[id].q39jEmpty = false;
                    Q39array[id].q39jDateInvalidSoft1 = q39SteroidCourseDetails[id].new_tx_start_dt_b1 == "" ? false : new Date(q39SteroidCourseDetails[id].new_tx_disc_dt_j1) < new Date(q39SteroidCourseDetails[id].new_tx_start_dt_b1);//q30i_ster_disc_dt < q30_ster_start_dt
                    Q39array[id].q39jDateInvalidSoft2 = avadiscdate == "" ? false : new Date(q39SteroidCourseDetails[id].new_tx_disc_dt_j1) > new Date(avadiscdate);//q30i_ster_disc_dt > ava_disc_dt
                    Q39array[id].q39jDateInvalidSoft3 = endfollowupdate == "" ? false : new Date(q39SteroidCourseDetails[id].new_tx_disc_dt_j1) > new Date(endfollowupdate);//q30i_ster_disc_dt > end_of_followup
                    setq39SteroidCourseDetails(Q39array);
                }
                break;

            case "postAvaA1TxStartDtR1":
                {

                    let Q41array = [...q41ivigdatesDetails];
                    Q41array[id].postAvaA1TxStartDt = date;
                    Q41array[id].postAvaA1TxStartDtUnk = "";
                    Q41array[id].defDateQ41A = false;
                    Q41array[id].q41iviaEmpty = false;
                    Q41array[id].q41iviaDateInvalidSoft1 = indexdate == "" ? false : new Date(q41ivigdatesDetails[id].postAvaA1TxStartDt) < new Date(indexdate);//q31a1_tx_start_dt < index_date
                    Q41array[id].q41iviaDateInvalidSoft2 = avadiscdate == "" ? false : new Date(q41ivigdatesDetails[id].postAvaA1TxStartDt) > new Date(avadiscdate);//q31a1_tx_start_dt > ava_disc_dt
                    Q41array[id].q41iviaDateInvalidSoft3 = endfollowupdate == "" ? false : new Date(q41ivigdatesDetails[id].postAvaA1TxStartDt) > new Date(endfollowupdate);//q31a1_tx_start_dt > end_of_followup
                    setq41ivigdatesDetails(Q41array);
                }
                break;

            case "postAvaB1TxEndDtR1":
                {

                    let Q41array = [...q41ivigdatesDetails];
                    Q41array[id].postAvaB1TxEndDt = date;
                    Q41array[id].postAvaB1TxEndDtUnk = "";
                    Q41array[id].defDateQ41B = false;
                    Q41array[id].q41ivibEmpty = false;
                    Q41array[id].q41ivibDateInvalidSoft1 = indexdate == "" ? false : new Date(q41ivigdatesDetails[id].postAvaB1TxEndDt) < new Date(indexdate);//q31b1_tx_end_dt < index_date
                    Q41array[id].q41ivibDateInvalidSoft2 = avadiscdate == "" ? false : new Date(q41ivigdatesDetails[id].postAvaB1TxEndDt) > new Date(avadiscdate);//q31b1_tx_end_dt > ava_disc_dt
                    Q41array[id].q41ivibDateInvalidSoft3 = endfollowupdate == "" ? false : new Date(q41ivigdatesDetails[id].postAvaB1TxEndDt) > new Date(endfollowupdate);//q31b1_tx_end_dt > end_of_followup
                    setq41ivigdatesDetails(Q41array);
                }
                break;

            case "postAvaA1TxStartDtR2":
                {

                    let Q41array = [...q41antiddatesDetails];
                    Q41array[id].postAvaA1TxStartDt = date;
                    Q41array[id].postAvaA1TxStartDtUnk = "";
                    Q41array[id].defDateQ41A = false;
                    Q41array[id].q41antidaEmpty = false;
                    Q41array[id].q41antidaDateInvalidSoft1 = indexdate == "" ? false : new Date(q41antiddatesDetails[id].postAvaA1TxStartDt) < new Date(indexdate);//q31a1_tx_start_dt < index_date
                    Q41array[id].q41antidaDateInvalidSoft2 = avadiscdate == "" ? false : new Date(q41antiddatesDetails[id].postAvaA1TxStartDt) > new Date(avadiscdate);//q31a1_tx_start_dt > ava_disc_dt
                    Q41array[id].q41antidaDateInvalidSoft3 = endfollowupdate == "" ? false : new Date(q41antiddatesDetails[id].postAvaA1TxStartDt) > new Date(endfollowupdate);//q31a1_tx_start_dt > end_of_followup
                    setq41antiddatesDetails(Q41array);
                }
                break;

            case "postAvaB1TxEndDtR2":
                {

                    let Q41array = [...q41antiddatesDetails];
                    Q41array[id].postAvaB1TxEndDt = date;
                    Q41array[id].postAvaB1TxEndDtUnk = "";
                    Q41array[id].defDateQ41B = false;
                    Q41array[id].q41antidbEmpty = false;
                    Q41array[id].q41antidbDateInvalidSoft1 = indexdate == "" ? false : new Date(q41antiddatesDetails[id].postAvaB1TxEndDt) < new Date(indexdate);//q31b1_tx_end_dt < index_date
                    Q41array[id].q41antidbDateInvalidSoft2 = avadiscdate == "" ? false : new Date(q41antiddatesDetails[id].postAvaB1TxEndDt) > new Date(avadiscdate);//q31b1_tx_end_dt > ava_disc_dt
                    Q41array[id].q41antidbDateInvalidSoft3 = endfollowupdate == "" ? false : new Date(q41antiddatesDetails[id].postAvaB1TxEndDt) > new Date(endfollowupdate);//q31b1_tx_end_dt > end_of_followup
                    setq41antiddatesDetails(Q41array);
                }
                break;

            case "post_ava_switch_elt":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_elt_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_elt_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_elt_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_elt_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_elt_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_elt_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Eltrombopag");
                    Q45array.post_ava_switch_elt_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Eltrombopag");
                    Q45array.post_ava_switch_elt_Value.showSoftAErrorMsg = "";


                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_romi":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_romi_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_romi_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_romi_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_romi_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_romi_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_romi_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Romiplostim");
                    Q45array.post_ava_switch_romi_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Romiplostim");
                    Q45array.post_ava_switch_romi_Value.showSoftAErrorMsg = "";
                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45array);
                }
                break;

            case "post_ava_switch_lusu":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_lusu_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_lusu_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_lusu_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_lusu_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_lusu_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_lusu_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Lusutrombopag");
                    Q45array.post_ava_switch_lusu_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Lusutrombopag");
                    Q45array.post_ava_switch_lusu_Value.showSoftAErrorMsg = "";
                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_steroid":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_steroid_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_steroid_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_steroid_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_steroid_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_steroid_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_steroid_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Steroids (i.e., dexamethasone, prednisone, prednisolone)");
                    Q45array.post_ava_switch_steroid_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Steroids (i.e., dexamethasone, prednisone, prednisolone)");

                    Q45array.post_ava_switch_steroid_Value.showSoftAErrorMsg = "";
                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;

                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_ivig":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_ivig_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_ivig_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_ivig_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_ivig_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_ivig_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_ivig_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Intravenous immunoglobulin (IVIG)");
                    Q45array.post_ava_switch_ivig_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Intravenous immunoglobulin (IVIG)");

                    Q45array.post_ava_switch_ivig_Value.showSoftAErrorMsg = "";
                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_antid":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_antid_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_antid_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_antid_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_antid_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_antid_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_antid_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Anti-D immunoglobulin");
                    Q45array.post_ava_switch_antid_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Anti-D immunoglobulin");

                    Q45array.post_ava_switch_antid_Value.showSoftAErrorMsg = "";
                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_ritux":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_ritux_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_ritux_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_ritux_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_ritux_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_ritux_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_ritux_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Rituximab");
                    Q45array.post_ava_switch_ritux_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Rituximab");
                    Q45array.post_ava_switch_ritux_Value.showSoftAErrorMsg = "";
                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_fosta":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_fosta_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_fosta_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_fosta_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_fosta_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_fosta_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_fosta_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Fostamatinib disodium");
                    Q45array.post_ava_switch_fosta_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Fostamatinib disodium");
                    Q45array.post_ava_switch_fosta_Value.showSoftAErrorMsg = "";
                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_immuno":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_immuno_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_immuno_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_immuno_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_immuno_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_immuno_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_immuno_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Immunosuppressants (i.e., azathioprine, cyclophosphamide, cyclosporine, danazol, dapsone, mycophenolate mofetil, vinca alkaloids [vinblastine, vinorelbine, vincristine])");
                    Q45array.post_ava_switch_immuno_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Immunosuppressants (i.e., azathioprine, cyclophosphamide, cyclosporine, danazol, dapsone, mycophenolate mofetil, vinca alkaloids [vinblastine, vinorelbine, vincristine])");
                    Q45array.post_ava_switch_immuno_Value.showSoftAErrorMsg = "";
                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            
            case "post_ava_switch_other":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_other_Value.post_ava_tx_switch_dt = date;
                    Q45array.post_ava_switch_other_Value.q45DateInvalidSoft1 = avadiscdate == "" ? false : new Date(Q45array.post_ava_switch_other_Value.post_ava_tx_switch_dt) < new Date(avadiscdate);//q45b1_tx_end_dt < ava_disc_dt
                    Q45array.post_ava_switch_other_Value.q45DateInvalidSoft2 = endfollowupdate == "" ? false : new Date(Q45array.post_ava_switch_other_Value.post_ava_tx_switch_dt) > new Date(endfollowupdate);//q45b1_tx_end_dt > end_of_followup
                    Q45array.post_ava_switch_other_Value.showSoftErrorMsg1 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft1.replace("[therapy]", "Other ITP treatment");
                    Q45array.post_ava_switch_other_Value.showSoftErrorMsg2 = TreatmentAfterAVA_Msgs.Q45DateInvalidSoft2.replace("[therapy]", "Other ITP treatment");
                    Q45array.post_ava_switch_other_Value.showSoftAErrorMsg = "";
                    Q45array.Q45Empty = false;
                    Q45array.q45aEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
                
            case "q42Checkbox1PostAvaTxDiscDt":
                {
                    let Q42array = { ...q42itpdiscontinuationdatesCheckbox1Details };
                    Q42array[0].PostAvaTxDiscDt = date;
                    Q42array[0].q42Empty = false;
                    Q42array[0].q42DateInvalidSoft1 = indexdate == "" ? false : new Date(date) < new Date(indexdate); //q32_itp_tx_disc_dt < index_date
                    Q42array[0].q42DateInvalidSoft2 = avadiscdate == "" ? false : new Date(date) > new Date(avadiscdate); //q32_itp_tx_disc_dt > ava_disc_dt
                    Q42array[0].q42DateInvalidSoft3 = endfollowupdate == "" ? false : new Date(date) > new Date(endfollowupdate); //q32_itp_tx_disc_dt > end_of_followup
                    setq42itpdiscontinuationdatesCheckbox1Details(Q42array);
                }
                break;
            case "q42Checkbox2PostAvaTxDiscDt":
                {
                    let Q42array = { ...q42itpdiscontinuationdatesCheckbox2Details };
                    Q42array[0].PostAvaTxDiscDt = date;
                    Q42array[0].q42Empty = false;
                    Q42array[0].q42DateInvalidSoft1 = indexdate == "" ? false : new Date(date) < new Date(indexdate); //q32_itp_tx_disc_dt < index_date
                    Q42array[0].q42DateInvalidSoft2 = avadiscdate == "" ? false : new Date(date) > new Date(avadiscdate); //q32_itp_tx_disc_dt > ava_disc_dt
                    Q42array[0].q42DateInvalidSoft3 = endfollowupdate == "" ? false : new Date(date) > new Date(endfollowupdate); //q32_itp_tx_disc_dt > end_of_followup
                    setq42itpdiscontinuationdatesCheckbox2Details(Q42array);
                }
                break;
            case "q43Checkbox1PostAvaTxStartDt":
                {

                    let Q43array = { ...q43otherItpTreatmentsModalCheckbox1Details };
                    Q43array[0].PostAvaTxStartDt = date;
                    Q43array[0].q43Empty = false;
                    Q43array[0].q43aEmpty = false;
                    Q43array[0].q43Date1InvalidSoft1 = indexdate == "" ? false : new Date(date) < new Date(indexdate); //q33_itp_tx_start_dt < index_date
                    Q43array[0].q43Date1InvalidSoft2 = avadiscdate == "" ? false : new Date(date) > new Date(avadiscdate); // q33_itp_tx_start_dt > ava_disc_dt
                    Q43array[0].q43Date1InvalidSoft3 = endfollowupdate == "" ? false : new Date(date) > new Date(endfollowupdate); //q33_itp_tx_start_dt > end_of_followup
                    setq43otherItpTreatmentsModalCheckbox1Details(Q43array);
                }
                break;
            case "q43Checkbox1PostAvaTxDiscDt":
                {
                    let Q43array = { ...q43otherItpTreatmentsModalCheckbox1Details };
                    Q43array[0].PostAvaTxDiscDt = date;
                    Q43array[0].q43Empty = false;
                    Q43array[0].q43cEmpty = false;
                    Q43array[0].q43Date2InvalidSoft1 = indexdate == "" ? false : new Date(date) < new Date(q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxStartDt); //q33_itp_tx_disc_dt < q33_itp_tx_start_dt
                    Q43array[0].q43Date2InvalidSoft2 = avadiscdate == "" ? false : new Date(date) > new Date(avadiscdate); // q33_itp_tx_start_dt > ava_disc_dt
                    Q43array[0].q43Date2InvalidSoft3 = endfollowupdate == "" ? false : new Date(date) > new Date(endfollowupdate); //q33_itp_tx_start_dt > end_of_followup
                    setq43otherItpTreatmentsModalCheckbox1Details(Q43array);
                }
                break;
            case "q43Checkbox2PostAvaTxStartDt":
                {

                    let Q43array = { ...q43otherItpTreatmentsModalCheckbox2Details };
                    Q43array[0].PostAvaTxStartDt = date;
                    Q43array[0].q43Empty = false;
                    Q43array[0].q43aEmpty = false;
                    Q43array[0].q43Date1InvalidSoft1 = indexdate == "" ? false : new Date(date) < new Date(indexdate); //q33_itp_tx_disc_dt < indexdate
                    Q43array[0].q43Date1InvalidSoft2 = avadiscdate == "" ? false : new Date(date) > new Date(avadiscdate); // q33_itp_tx_start_dt > ava_disc_dt
                    Q43array[0].q43Date1InvalidSoft3 = endfollowupdate == "" ? false : new Date(date) > new Date(endfollowupdate); //q33_itp_tx_start_dt > end_of_followup
                    setq43otherItpTreatmentsModalCheckbox2Details(Q43array);
                }
                break;
            case "q43Checkbox2PostAvaTxDiscDt":
                {
                    let Q43array = { ...q43otherItpTreatmentsModalCheckbox2Details };
                    Q43array[0].PostAvaTxDiscDt = date;
                    Q43array[0].q43Empty = false;
                    Q43array[0].q43cEmpty = false;
                    Q43array[0].q43Date2InvalidSoft1 = indexdate == "" ? false : new Date(date) < new Date(q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxStartDt); //q33_itp_tx_disc_dt < q33_itp_tx_start_dt
                    Q43array[0].q43Date2InvalidSoft2 = avadiscdate == "" ? false : new Date(date) > new Date(avadiscdate); // q33_itp_tx_start_dt > ava_disc_dt
                    Q43array[0].q43Date2InvalidSoft3 = endfollowupdate == "" ? false : new Date(date) > new Date(endfollowupdate); //q33_itp_tx_start_dt > end_of_followup
                    setq43otherItpTreatmentsModalCheckbox2Details(Q43array);
                }
                break;
            case "q44Date":
                {

                    setITPTreatmentPostAVADetails((prev) => ({
                        ...prev,
                        post_ava_splen_dt: date,
                        post_ava_splen_dt_unk: "",
                        defDateQ44: false,
                        q43Empty: false,
                        q44Empty: false,
                        q44DataEmpty: false,
                        q44DateInvalidSoft1: indexdate == "" ? false : new Date(date) < new Date(indexdate),//q34_splen_dt < index_date
                        q44DateInvalidSoft2: false,//avadiscdate == "" ? false : new Date(date) > new Date(avadiscdate),//q34_splen_dt > ava_disc_dt
                        q44DateInvalidSoft3: endfollowupdate == "" ? false : new Date(date) > new Date(endfollowupdate),//q34_splen_dt > end_of_followup
                    }));
                    break;
                }
            default:
                break;
        }
    }

    const HandleOptionsSelected = (name, value, optionId) => {

        switch (optionId) {
            case "post_ava_switch_elt":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_elt_Value.post_ava_tx_switch_reason = value;
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_elt_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_elt_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_romi":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_romi_Value.post_ava_tx_switch_reason = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_romi_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_romi_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_lusu":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_lusu_Value.post_ava_tx_switch_reason = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_lusu_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_lusu_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_steroid":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_steroid_Value.post_ava_tx_switch_reason = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_steroid_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_steroid_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_ivig":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_ivig_Value.post_ava_tx_switch_reason = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_ivig_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_ivig_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_antid":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_antid_Value.post_ava_tx_switch_reason = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_antid_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_antid_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_ritux":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_ritux_Value.post_ava_tx_switch_reason = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_ritux_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_ritux_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_fosta":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_fosta_Value.post_ava_tx_switch_reason = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_fosta_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_fosta_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_immuno":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_immuno_Value.post_ava_tx_switch_reason = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_immuno_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_immuno_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_other":
                {
                    debugger
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_other_Value.post_ava_tx_switch_reason = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_other_Value.q45bEmptyErrorMsg = false;
                    Q45array.post_ava_switch_other_Value.q45baEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                    Q45array.post_ava_switch_other_txt_empty= false//chk
                }
                break;

            default:
                break;
        }
    }

    const HandleOtherOptionValue = (othrVal, value, optionId) => {
        switch (optionId) {
            case "post_ava_switch_elt":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_elt_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_elt_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_elt_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_elt_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_romi":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_romi_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_romi_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_romi_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_romi_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_lusu":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_lusu_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_lusu_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_lusu_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_lusu_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_steroid":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_steroid_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_steroid_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_steroid_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_steroid_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_ivig":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_ivig_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_ivig_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_ivig_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_ivig_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_antid":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_antid_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_antid_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_antid_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_antid_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_ritux":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_ritux_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_ritux_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_ritux_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_ritux_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_fosta":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_fosta_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_fosta_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_fosta_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_fosta_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;
            case "post_ava_switch_immuno":
                {
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_immuno_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_immuno_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_immuno_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_immuno_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                }
                break;

            case "post_ava_switch_other":
                {
                    debugger
                    let Q45array = { ...Q45HandleControlState };
                    Q45array.post_ava_switch_other_Value.post_ava_tx_switch_reason = othrVal
                    Q45array.post_ava_switch_other_Value.post_ava_tx_switch_oth_txt = value
                    Q45array.Q45Empty = false;
                    Q45array.post_ava_switch_other_Value.q45baEmptyErrorMsg = false;
                    Q45array.post_ava_switch_other_Value.q45bEmptyErrorMsg = false;
                    setQ45HandleControlState(Q45array);
                    Q45array.post_ava_switch_other_txt_empty = false//chk
                }
                break;
            default:
                break;
        }
    }

    function addavaDosageDetailsRow(e, id, data) {
        let Q38array = [...q38MainSteroidReceivedDetails];
        Q38array[id].steroidreceiveddosagechangeDetails.push({
            steroidreceiveddosagechangeid: 0,
            steroidtypeid: "",
            post_ava_b1x1_tx_change_dt: "",
            // post_ava_b1x1_tx_change_dt_unk: "",
            post_ava_c1x1_tx_dose: "",
            post_ava_d1x1_tx_dose_num: "",
            frequencytimeid: 0,
            q39child_empty: false,
            q38doselimitexceeded: false,
            q38freqlimitexceeded: false,
            q3BSteroidDateInvalidSoft1: false,
            q3BSteroidDateInvalidSoft2: false,
            q3BSteroidDateInvalidSoft3: false,
            q38RepeaterbEmpty: false,
            q38RepeatercEmpty: false,
            q38RepeaterdEmpty: false,
        });
        setq38MainSteroidReceivedDetails(Q38array);
    }

    //Add MAin Repeater
    function addQ38RepeaterMain() {
        setq38MainSteroidReceivedDetails((prev) => [
            ...prev,
            {
                steroidreceivedid: 0,
                steroidtypeid: 0,
                post_ava_b1_tx_change_doseFrequency: false,
                post_ava_b1_tx_change_discontinuation: false,
                post_ava_e1_tx_disc: "",
                post_ava_f1_tx_disc_dt: "",
                // post_ava_ster_disc_dt_unk: "",
                defDateQ38: false,
                q38Dosedatechange: false,
                q38doseChangelessindexdt: false,
                defEDateQ38: false,
                q38EDoseEdatechange: false,
                q38EdoseChangelessindexdt: false,
                showq38childrepeater: false,
                q38bEmpty: false,
                q39_empty: false,
                q39a_empty: false,
                q39e_empty: false,
                q39e_f_empty: false,
                q3FSteroidDateInvalidSoft1: false,
                q3FSteroidDateInvalidSoft2: false,
                q3FSteroidDateInvalidSoft3: false,
                q3FSteroidDateInvalidSoft4: false,
                q38Checkbox: false,
                showQ38f: false,
                steroidreceiveddosagechangeDetails: [{
                    steroidreceiveddosagechangeid: 0,
                    steroidtypeid: "",
                    post_ava_b1x1_tx_change_dt: "",
                    q3BSteroidDateInvalidSoft1: false,
                    q3BSteroidDateInvalidSoft2: false,
                    q3BSteroidDateInvalidSoft3: false,
                    post_ava_c1x1_tx_dose: "",
                    post_ava_d1x1_tx_dose_num: "",
                    frequencytimeid: 0,
                    defDateQ38: false,
                    q38Dosedatechange: false,
                    q38doseChangelessindexdt: false,
                    q39child_empty: false,
                    q38doselimitexceeded: false,
                    q38freqlimitexceeded: false,
                    q38RepeaterbEmpty: false,
                    q38RepeatercEmpty: false,
                    q38RepeaterdEmpty: false,
                }]
            },
        ]);
    }

    const deletedosage = (e, parentid, childid) => {
        let Q38array = [...q38MainSteroidReceivedDetails];
        Q38array[parentid].steroidreceiveddosagechangeDetails.splice(childid, 1);
        setq38MainSteroidReceivedDetails(Q38array);
    }

    function deleteq38SteroidReceivedMainDetails(id) {
        const newq38SteroidReceivedDetailsArray = [...q38MainSteroidReceivedDetails];
        newq38SteroidReceivedDetailsArray.splice(id, 1);
        let ii = newq38SteroidReceivedDetailsArray;
        setq38MainSteroidReceivedDetails(newq38SteroidReceivedDetailsArray);
    }

    /*Q39 functions*/

    function addsteroidcourseDetailsRow(e) {
        let index = q39SteroidCourseDetails.length;
        setq39SteroidCourseDetails((prev) => [
            ...prev,
            {
                steroidcourseid: 0,
                steroidtypeid: 0,
                new_tx_start_dt_b1: "",
                /*new_tx_start_dt_b1_unk: "",*/
                new_tx_dose_amt_c1: "",
                new_tx_dose_num_d1: "",
                frequencytimeid: "",
                new_tx_dose_chg_e1: "",
                new_tx_disc_i1: "",
                new_tx_disc_dt_j1: "",
                /*new_tx_disc_dt_j1_unk: "",*/
                showq39j: false,
                defDateQ39b: false,
                defDateQ39j: false,
                showq39childrepeater: false,

                q39aEmpty: false,
                q39bEmpty: false,
                q39bDateInvalidSoft1: false,
                q39bDateInvalidSoft2: false,
                q39bDateInvalidSoft3: false,
                q39cEmpty: false,
                q39dEmpty: false,
                q39eEmpty: false,
                q39iEmpty: false,
                q39jEmpty: false,
                q39jDateInvalidSoft1: false,
                q39jDateInvalidSoft2: false,
                q39jDateInvalidSoft3: false,
                q39doselimitexceeded: false,
                q39freqlimitexceeded: false,

                q39SteroidcoursedosagechangeDetails: [
                    {
                        steroidcoursedosagechangeid: 0,
                        steroidtypeid: 0,
                        new_tx_chg_dt_f1x1: "",
                        /*new_tx_chg_dt_f1x1_unk: "",*/
                        new_tx_chg_dose_g1x1: "",
                        new_tx_chg_num_h1x1: "",
                        frequencytimeid: "",

                        q39fEmpty: false,
                        q39fDateInvalidSoft1: false,
                        q39fDateInvalidSoft2: false,
                        q39fDateInvalidSoft3: false,
                        q39gEmpty: false,
                        q39hEmpty: false,
                        q39doselimitexceeded: false,
                        q39freqlimitexceeded: false,
                    }
                ],
            },
        ]);
    }

    function deleteq39SteroidCourseDetails(id) {

        const newq39SteroidCourseDetailsArray = [...q39SteroidCourseDetails];
        newq39SteroidCourseDetailsArray.splice(id, 1);
        let ii = newq39SteroidCourseDetailsArray;
        setq39SteroidCourseDetails(newq39SteroidCourseDetailsArray);
    }

    const addsteroidcoursedosagechangeDetailsRow = (e, id, data) => {


        let Q39array = [...q39SteroidCourseDetails];
        Q39array[id].q39SteroidcoursedosagechangeDetails.push({
            steroidcoursedosagechangeid: 0,
            steroidtypeid: 0,
            new_tx_chg_dt_f1x1: "",
            /*new_tx_chg_dt_f1x1_unk: "",*/
            new_tx_chg_dose_g1x1: "",
            new_tx_chg_num_h1x1: "",
            frequencytimeid: "",

            q39fEmpty: false,
            q39fDateInvalidSoft1: false,
            q39fDateInvalidSoft2: false,
            q39fDateInvalidSoft3: false,
            q39gEmpty: false,
            q39hEmpty: false,
            q39doselimitexceeded: false,
            q39freqlimitexceeded: false,
        });
        setq39SteroidCourseDetails(Q39array);
    }

    const deletechildRpeaterQ39 = (e, parentid, childid) => {

        let Q39array = [...q39SteroidCourseDetails];
        Q39array[parentid].q39SteroidcoursedosagechangeDetails.splice(childid, 1);
        setq39SteroidCourseDetails(Q39array);
    }

    /*Q39 functions*/

    /*Q41 Functions*/
    function addQ41iviRow(e) {

        setq41ivigdatesDetails((prev) => [
            ...prev,
            {
                ivigdatesid: 0,
                ivigtherapytypeid: 0,
                postAvaA1TxStartDt: "",
                postAvaA1TxStartDtUnk: "",
                postAvaB1TxEndDt: "",
                postAvaB1TxEndDtUnk: "",
                defDateQ41A: false,
                defDateQ41B: false,

                q41iviaEmpty: false,
                q41iviaDateInvalidSoft1: false,
                q41iviaDateInvalidSoft2: false,
                q41iviaDateInvalidSoft3: false,
                q41ivibEmpty: false,
                q41ivibDateInvalidSoft1: false,
                q41ivibDateInvalidSoft2: false,
                q41ivibDateInvalidSoft3: false,
            }
        ]);
    }

    function deleteq41ivigDetails(id) {
        const q41ivigdatesDetailsArray = [...q41ivigdatesDetails];
        q41ivigdatesDetailsArray.splice(id, 1);
        setq41ivigdatesDetails(q41ivigdatesDetailsArray);
    }

    function addQ41antidRow(e) {
        setq41antiddatesDetails((prev) => [
            ...prev,
            {
                ivigdatesid: 0,
                ivigtherapytypeid: 0,
                postAvaA1TxStartDt: "",
                postAvaA1TxStartDtUnk: "",
                postAvaB1TxEndDt: "",
                postAvaB1TxEndDtUnk: "",
                defDateQ41A: false,
                defDateQ41B: false,

                q41antidaEmpty: false,
                q41antidaDateInvalidSoft1: false,
                q41antidaDateInvalidSoft2: false,
                q41antidaDateInvalidSoft3: false,
                q41antidbEmpty: false,
                q41antidbDateInvalidSoft1: false,
                q41antidbDateInvalidSoft2: false,
                q41antidbDateInvalidSoft3: false,
            }
        ]);
    }

    function deleteq41antidDetails(id) {
        const q41antiddatesDetailsArray = [...q41antiddatesDetails];
        q41antiddatesDetailsArray.splice(id, 1);
        setq41antiddatesDetails(q41antiddatesDetailsArray);
    }

    function validate() {
        debugger;

        /*Q38 region start*/
        let Q38hasError = false;
        if (showq38) {
            let q39SteroidEmpty = ITPTreatmentPostAVADetails.post_ava_steroid_change === "" ? true : false;
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q39EmptyError: q39SteroidEmpty,
            }));
            Q38hasError = q39SteroidEmpty;
            let q39array = [...q38MainSteroidReceivedDetails];
            if (ITPTreatmentPostAVADetails.post_ava_steroid_change === "1" || ITPTreatmentPostAVADetails.post_ava_steroid_change === 1) {
                for (let i = 0; i < q38MainSteroidReceivedDetails.length; i++) {

                    q39array.forEach((element, i) => {
                        //parent && element.post_ava_ster_disc_dt_unk === ""
debugger;
                        q39array[i].q39a_empty = (element.steroidtypeid === 0 || element.steroidtypeid == undefined) ? true : false;
                        q39array[i].q38bEmpty = (element.post_ava_b1_tx_change_doseFrequency === "" && element.post_ava_b1_tx_change_discontinuation==="")
                                                ||(element.post_ava_b1_tx_change_doseFrequency === false && element.post_ava_b1_tx_change_discontinuation===false)
                                                ? true : false;
                        q39array[i].q39e_f_empty = element.post_ava_b1_tx_change_discontinuation === true && element.post_ava_f1_tx_disc_dt === "" ? true : false;

                        if (element.q38Checkbox) {
                            q39array[i].q39e_empty = element.post_ava_e1_tx_disc === "" ? true : false;
                        }
                        q39array[i].q3FSteroidDateInvalidSoft1 = false;
                        q39array[i].q3FSteroidDateInvalidSoft2 = false;
                        q39array[i].q3FSteroidDateInvalidSoft3 = false;
                        q39array[i].q3FSteroidDateInvalidSoft4 = false;

                        if (element.post_ava_b1_tx_change_doseFrequency == true) {
                            //child 
                            // ||item.post_ava_b1x1_tx_change_dt_unk === ""
                            element.steroidreceiveddosagechangeDetails.forEach((item, id) => {

                                item.q38RepeaterbEmpty =
                                    item.post_ava_b1x1_tx_change_dt === "";
                                item.q38RepeatercEmpty =
                                    item.post_ava_c1x1_tx_dose === "";
                                item.q38RepeaterdEmpty =
                                    item.post_ava_d1x1_tx_dose_num === "" ||
                                    item.frequencytimeid === "" || item.frequencytimeid === 0;

                                item.q3BSteroidDateInvalidSoft1 = false;
                                item.q3BSteroidDateInvalidSoft2 = false;
                                item.q3BSteroidDateInvalidSoft3 = false;

                                if (item.post_ava_c1x1_tx_dose != "")
                                    item.q38doselimitexceeded = (item.post_ava_c1x1_tx_dose > 1500 || item.post_ava_c1x1_tx_dose < 1) ? true : false;
                                if (item.post_ava_d1x1_tx_dose_num != "")
                                    item.q38freqlimitexceeded = (item.post_ava_d1x1_tx_dose_num > 1500 || item.post_ava_d1x1_tx_dose_num < 1) ? true : false;


                                if (item.q38RepeaterbEmpty || item.q38RepeatercEmpty || item.q38RepeaterdEmpty || item.q38doselimitexceeded || item.q38freqlimitexceeded) {
                                    Q38hasError = true;
                                }
                            });
                        }

                        if (q39array[i].q38bEmpty || q39array[i].q39a_empty || q39array[i].q39e_empty || q39array[i].q39e_f_empty) {
                            Q38hasError = true;
                        }
                    });

                    // push update array
                    setq38MainSteroidReceivedDetails(q39array);
                }
            }
        }
        /*Q38 region end*/


        /*Q39 region start*/
        let q39Empty = false;
        let q39ParentRepeaterhasError = false;
        let q39ChildRepeaterhasError = false;
        /*if (showq39) {*/
        q39Empty = ITPTreatmentPostAVADetails.post_ava_new_ster_start === "" ? true : false;
        setITPTreatmentPostAVADetails((prev) => ({
            ...prev,
            q39Empty: q39Empty,
        }));

        let q39SteroidCoursearray = q39SteroidCourseDetails;
        if (ITPTreatmentPostAVADetails.post_ava_new_ster_start == "1") {
            for (let i = 0; i < q39SteroidCourseDetails.length; i++) {

                q39SteroidCoursearray[i].q39aEmpty = q39SteroidCourseDetails[i].steroidtypeid == 0;
                q39SteroidCoursearray[i].q39bEmpty = (q39SteroidCourseDetails[i].new_tx_start_dt_b1 === "") ? true : false;
                q39SteroidCoursearray[i].q39cEmpty = q39SteroidCourseDetails[i].new_tx_dose_amt_c1 === "";
                q39SteroidCoursearray[i].q39dEmpty = (q39SteroidCourseDetails[i].new_tx_dose_num_d1 === "" || q39SteroidCourseDetails[i].frequencytimeid === "" || q39SteroidCourseDetails[i].frequencytimeid === "0") ? true : false;
                q39SteroidCoursearray[i].q39eEmpty = q39SteroidCourseDetails[i].new_tx_dose_chg_e1 === "";
                q39SteroidCoursearray[i].q39iEmpty = q39SteroidCourseDetails[i].new_tx_disc_i1 === "";
                q39SteroidCoursearray[i].q39jEmpty = (q39SteroidCourseDetails[i].new_tx_disc_i1 === "1" && q39SteroidCourseDetails[i].new_tx_disc_dt_j1 === "") ? true : false;

                q39SteroidCoursearray[i].q39bDateInvalidSoft1 = false;
                q39SteroidCoursearray[i].q39bDateInvalidSoft2 = false;
                q39SteroidCoursearray[i].q39bDateInvalidSoft3 = false;

                q39SteroidCoursearray[i].q39jDateInvalidSoft1 = false;
                q39SteroidCoursearray[i].q39jDateInvalidSoft2 = false;
                q39SteroidCoursearray[i].q39jDateInvalidSoft3 = false;
                if (q39SteroidCourseDetails[i].new_tx_dose_amt_c1 != "")
                    q39SteroidCoursearray[i].q39doselimitexceeded = (q39SteroidCourseDetails[i].new_tx_dose_amt_c1 > 1500 || q39SteroidCourseDetails[i].new_tx_dose_amt_c1 < 1) ? true : false;
                if (q39SteroidCourseDetails[i].new_tx_dose_num_d1 != "")
                    q39SteroidCoursearray[i].q39freqlimitexceeded = (q39SteroidCourseDetails[i].new_tx_dose_num_d1 > 1500 || q39SteroidCourseDetails[i].new_tx_dose_num_d1 < 1) ? true : false;

                if (q39SteroidCoursearray[i].q39aEmpty ||
                    q39SteroidCoursearray[i].q39bEmpty ||
                    q39SteroidCoursearray[i].q39cEmpty ||
                    q39SteroidCoursearray[i].q39dEmpty ||
                    q39SteroidCoursearray[i].q39eEmpty ||
                    q39SteroidCoursearray[i].q39iEmpty ||
                    q39SteroidCoursearray[i].q39jEmpty ||
                    q39SteroidCoursearray[i].q39doselimitexceeded ||
                    q39SteroidCoursearray[i].q39freqlimitexceeded
                )
                    q39ParentRepeaterhasError = true;

                //let q39Steroidcoursedosagechangearray = q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails;
                if (q39SteroidCourseDetails[i].new_tx_dose_chg_e1 == 1) {
                    for (let j = 0; j < q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails.length; j++) {

                        q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39fEmpty = (q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].new_tx_chg_dt_f1x1 === "") ? true : false;

                        q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39gEmpty = q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].new_tx_chg_dose_g1x1 === "";

                        q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39hEmpty = (q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].new_tx_chg_num_h1x1 === "" || q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].frequencytimeid === "" || q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].frequencytimeid === "0") ? true : false;

                        q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39fDateInvalidSoft1 = false;
                        q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39fDateInvalidSoft2 = false;
                        q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39fDateInvalidSoft3 = false;
                        if (q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].new_tx_chg_dose_g1x1 != "")
                            q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39doselimitexceeded = (q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].new_tx_chg_dose_g1x1 > 1500 || q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].new_tx_chg_dose_g1x1 < 1) ? true : false;
                        if (q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39freqlimitexceeded != "")
                            q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39freqlimitexceeded = (q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].new_tx_chg_num_h1x1 > 1500 || q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].new_tx_chg_num_h1x1 < 1) ? true : false;

                        if (q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39fEmpty ||
                            q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39gEmpty ||
                            q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39hEmpty ||
                            q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39doselimitexceeded ||
                            q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j].q39freqlimitexceeded
                        )
                            q39ChildRepeaterhasError = true;
                    }
                }
            }

            setq39SteroidCourseDetails(q39SteroidCoursearray);
        }
        /*}*/
        /*Q39 region end*/


        /*Q41 region start*/
        let q41hasError = (ITPTreatmentPostAVADetails.q41checkbox1 == false && ITPTreatmentPostAVADetails.q41checkbox2 == false &&
            ITPTreatmentPostAVADetails.q41radiobutton == false) ? true : false;
        setITPTreatmentPostAVADetails((prev) => ({
            ...prev,
            q41Empty: q41hasError,
        }));
        let q41array1 = [...q41ivigdatesDetails];
        let Q41Repeater1hasError = false;
        if (ITPTreatmentPostAVADetails.q41checkbox1) {
            for (let i = 0; i < q41ivigdatesDetails.length; i++) {
                q41array1[i].q41iviaEmpty = (q41ivigdatesDetails[i].postAvaA1TxStartDt == "" && q41ivigdatesDetails[i].postAvaA1TxStartDtUnk != 1) ? true : false;
                q41array1[i].q41ivibEmpty = (q41ivigdatesDetails[i].postAvaB1TxEndDt == "" && q41ivigdatesDetails[i].postAvaB1TxEndDtUnk != 1) ? true : false;
                q41array1[i].q41iviaDateInvalidSoft1 = false;
                q41array1[i].q41iviaDateInvalidSoft2 = false;
                q41array1[i].q41iviaDateInvalidSoft3 = false;
                q41array1[i].q41ivibDateInvalidSoft1 = false;
                q41array1[i].q41ivibDateInvalidSoft2 = false;
                q41array1[i].q41ivibDateInvalidSoft3 = false;


                if (q41array1[i].q41iviaEmpty || q41array1[i].q41ivibEmpty)
                    Q41Repeater1hasError = true;
            }
            setq41ivigdatesDetails(q41array1);
        }


        let q41array2 = [...q41antiddatesDetails];
        let Q41Repeater2hasError = false;
        if (ITPTreatmentPostAVADetails.q41checkbox2) {
            for (let i = 0; i < q41antiddatesDetails.length; i++) {
                q41array2[i].q41antidaEmpty = (q41antiddatesDetails[i].postAvaA1TxStartDt == "" && q41antiddatesDetails[i].postAvaA1TxStartDtUnk != 1) ? true : false;
                q41array2[i].q41antidbEmpty = (q41antiddatesDetails[i].postAvaB1TxEndDt == "" && q41antiddatesDetails[i].postAvaB1TxEndDtUnk != 1) ? true : false;

                q41array2[i].q41antidaDateInvalidSoft1 = false;
                q41array2[i].q41antidaDateInvalidSoft2 = false;
                q41array2[i].q41antidaDateInvalidSoft3 = false;
                q41array2[i].q41antidbDateInvalidSoft1 = false;
                q41array2[i].q41antidbDateInvalidSoft2 = false;
                q41array2[i].q41antidbDateInvalidSoft3 = false;

                if (q41array2[i].q41antidaEmpty || q41array2[i].q41antidbEmpty)
                    Q41Repeater2hasError = true;
            }
            setq41antiddatesDetails(q41array2);
        }
        /*Q41 region end*/

        /*Q42 region start*/
        let q42hasError = false;
        let Q42DatehasError = false;
        if (showq42) {
            if (!ITPTreatmentPostAVADetails.post_ava_disc_treat_unk && !ITPTreatmentPostAVADetails.q42Checkbox1Checked &&
                !ITPTreatmentPostAVADetails.q42Checkbox2Checked)
                q42hasError = true;
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q42Empty: q42hasError,
            }));

            if (showq42Checkbox1Section) {
                let q42array1 = q42itpdiscontinuationdatesCheckbox1Details;
                if (ITPTreatmentPostAVADetails.q42Checkbox1Checked) {
                    q42array1[0].q42Empty = (q42itpdiscontinuationdatesCheckbox1Details[0].PostAvaTxDiscDt == "") ? true : false;
                    q42array1[0].q42DateInvalidSoft1 = false;
                    q42array1[0].q42DateInvalidSoft2 = false;
                    q42array1[0].q42DateInvalidSoft3 = false;
                    if (q42array1[0].q42Empty)
                        Q42DatehasError = true;
                    setq42itpdiscontinuationdatesCheckbox1Details(q42array1);
                }
            }

            if (showq42Checkbox2Section) {
                let q42array2 = q42itpdiscontinuationdatesCheckbox2Details;
                if (ITPTreatmentPostAVADetails.q42Checkbox2Checked) {
                    q42array2[0].q42Empty = (q42itpdiscontinuationdatesCheckbox2Details[0].PostAvaTxDiscDt == "") ? true : false;
                    q42array2[0].q42DateInvalidSoft1 = false;
                    q42array2[0].q42DateInvalidSoft2 = false;
                    q42array2[0].q42DateInvalidSoft3 = false;
                    if (q42array2[0].q42Empty)
                        Q42DatehasError = true;
                    setq42itpdiscontinuationdatesCheckbox2Details(q42array2);
                }
            }
        }
        /*Q42 region end*/

        /*Q43 region start*/
        let q43hasError = false;
        let Q43DatehasError = false;
        if (showq43) {
            if (!ITPTreatmentPostAVADetails.post_ava_other_treat_unk && !ITPTreatmentPostAVADetails.q43Checkbox1Checked &&
                !ITPTreatmentPostAVADetails.q43Checkbox2Checked)
                q43hasError = true;
            setITPTreatmentPostAVADetails((prev) => ({
                ...prev,
                q43Empty: q43hasError,
            }));

            if (showq43Checkbox1Section) {
                let q43array1 = q43otherItpTreatmentsModalCheckbox1Details;
                if (ITPTreatmentPostAVADetails.q43Checkbox1Checked) {
                    q43array1[0].q43Empty = (q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxStartDt == "" || q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDisc === "") ? true : false;
                    q43array1[0].q43aEmpty = (q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxStartDt == "") ? true : false;
                    q43array1[0].q43bEmpty = (q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDisc === "") ? true : false;

                    q43array1[0].q43cEmpty = (q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDisc == "1" && q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDiscDt == "") ? true : false;
                    //q43array1[0].q43CheckDataEmpty = (q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDisc == 1 && q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDiscDt == "")
                    q43array1[0].q43Date1InvalidSoft1 = false;
                    q43array1[0].q43Date1InvalidSoft2 = false;
                    q43array1[0].q43Date1InvalidSoft3 = false;

                    q43array1[0].q43Date2InvalidSoft1 = false;
                    q43array1[0].q43Date2InvalidSoft2 = false;
                    q43array1[0].q43Date2InvalidSoft3 = false;

                    if (q43array1[0].q43Empty || q43array1[0].q43aEmpty || q43array1[0].q43bEmpty || q43array1[0].q43cEmpty)
                        Q43DatehasError = true;
                    setq43otherItpTreatmentsModalCheckbox1Details(q43array1);
                }
            }

            if (showq43Checkbox2Section) {
                let q43array2 = q43otherItpTreatmentsModalCheckbox2Details;
                if (ITPTreatmentPostAVADetails.q43Checkbox2Checked) {
                    q43array2[0].q43Empty = (q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxStartDt == "" || q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDisc === "") ? true : false;
                    q43array2[0].q43aEmpty = (q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxStartDt == "") ? true : false;
                    q43array2[0].q43bEmpty = (q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDisc === "") ? true : false;
                    q43array2[0].q43cEmpty = (q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDisc == "1" && q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDiscDt == "") ? true : false;
                    //q43array2[0].q43CheckDataEmpty = (q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDisc == 1 && q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDiscDt == "")
                    q43array2[0].q43Date1InvalidSoft1 = false;
                    q43array2[0].q43Date1InvalidSoft2 = false;
                    q43array2[0].q43Date1InvalidSoft3 = false;

                    q43array2[0].q43Date2InvalidSoft1 = false;
                    q43array2[0].q43Date2InvalidSoft2 = false;
                    q43array2[0].q43Date2InvalidSoft3 = false;

                    if (q43array2[0].q43Empty || q43array2[0].q43aEmpty || q43array2[0].q43bEmpty || q43array2[0].q43cEmpty)
                        Q43DatehasError = true;

                    setq43otherItpTreatmentsModalCheckbox2Details(q43array2);
                }
            }
        }
        /*Q43 region end*/

        /*Q44 region start*/
        let q44hasError = false;
        if (ITPTreatmentPostAVADetails.post_ava_splen === "")
            q44hasError = true;
        setITPTreatmentPostAVADetails((prev) => ({
            ...prev,
            q44Empty: q44hasError,
        }));

        let q44DatahasError = false;
        if (ITPTreatmentPostAVADetails.post_ava_splen == 1 && ITPTreatmentPostAVADetails.post_ava_splen_dt == "" && (ITPTreatmentPostAVADetails.post_ava_splen_dt_unk == "" || ITPTreatmentPostAVADetails.post_ava_splen_dt_unk == "0"))
            q44DatahasError = true;
        setITPTreatmentPostAVADetails((prev) => ({
            ...prev,
            q44DataEmpty: q44DatahasError,
            q44DateInvalidSoft1: false,
            q44DateInvalidSoft2: false,
            q44DateInvalidSoft3: false,
        }));
        /*Q44 region end*/

        /*Q45 region start*/
        let Q45hasError = false;
        if (showq45) {
            debugger
            if (Q45HandleControlState.post_ava_switch_elt === false && Q45HandleControlState.post_ava_switch_romi === false && Q45HandleControlState.post_ava_switch_lusu === false
                && Q45HandleControlState.post_ava_switch_steroid === false && Q45HandleControlState.post_ava_switch_ivig === false && Q45HandleControlState.post_ava_switch_antid === false &&
                Q45HandleControlState.post_ava_switch_ritux === false && Q45HandleControlState.post_ava_switch_fosta === false && Q45HandleControlState.post_ava_switch_immuno === false && 
                Q45HandleControlState.post_ava_switch_other === false   &&
                (Q45HandleControlState.post_ava_switch_none === "" || Q45HandleControlState.post_ava_switch_none === 0)) {
                Q45HandleControlState.Q45Empty = true
            }

            if (Q45HandleControlState.post_ava_switch_elt === true) {
                Q45HandleControlState.Q45Empty = false

                if (Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_elt_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_elt_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Eltrombopag");
                    Q45HandleControlState.post_ava_switch_elt_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_elt_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_elt_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_elt_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_elt_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_elt_Value.q45DateInvalidSoft2 = false;
                }
                if (Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_elt_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_elt_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_elt_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_elt_Value.q45baEmptyErrorMsg = false;
                }
            }
            else {
                Q45HandleControlState.post_ava_switch_elt_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_elt_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_elt_Value.q45baEmptyErrorMsg = false;
            }

            if (Q45HandleControlState.post_ava_switch_romi === true) {
                Q45HandleControlState.Q45Empty = false
                if (Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_romi_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_romi_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Romiplostim");
                    Q45HandleControlState.post_ava_switch_romi_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_romi_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_romi_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_romi_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_romi_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_romi_Value.q45DateInvalidSoft2 = false;

                }
                if (Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_romi_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_romi_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_romi_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_romi_Value.q45baEmptyErrorMsg = false;
                }
            }
            else {
                Q45HandleControlState.post_ava_switch_romi_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_romi_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_romi_Value.q45baEmptyErrorMsg = false;
            }


            if (Q45HandleControlState.post_ava_switch_lusu === true) {
                Q45HandleControlState.Q45Empty = false
                if (Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_lusu_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Lusutrombopag");
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_lusu_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45DateInvalidSoft2 = false;

                }
                if (Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_lusu_Value.q45baEmptyErrorMsg = false;
                }
            }
            else {
                Q45HandleControlState.post_ava_switch_lusu_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_lusu_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_lusu_Value.q45baEmptyErrorMsg = false;
            }


            if (Q45HandleControlState.post_ava_switch_steroid === true) {
                Q45HandleControlState.Q45Empty = false
                if (Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_steroid_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Steroids (i.e., dexamethasone, prednisone, prednisolone)");
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_steroid_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45DateInvalidSoft2 = false;

                }
                if (Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_steroid_Value.q45baEmptyErrorMsg = false;
                }
            }
            else {
                Q45HandleControlState.post_ava_switch_steroid_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_steroid_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_steroid_Value.q45baEmptyErrorMsg = false;
            }


            if (Q45HandleControlState.post_ava_switch_ivig === true) {
                Q45HandleControlState.Q45Empty = false
                if (Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_ivig_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Intravenous immunoglobulin (IVIG)");
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_ivig_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45DateInvalidSoft2 = false;

                }
                if (Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_ivig_Value.q45baEmptyErrorMsg = false;
                }
            }
            else {
                Q45HandleControlState.post_ava_switch_ivig_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_ivig_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_ivig_Value.q45baEmptyErrorMsg = false;
            }


            if (Q45HandleControlState.post_ava_switch_antid === true) {
                if (Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_antid_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_antid_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Anti-D immunoglobulin");
                    Q45HandleControlState.post_ava_switch_antid_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_antid_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_antid_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_antid_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_antid_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_antid_Value.q45DateInvalidSoft2 = false;

                }
                if (Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_antid_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_antid_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_antid_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_antid_Value.q45baEmptyErrorMsg = false;
                }
            }
            else {
                Q45HandleControlState.post_ava_switch_antid_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_antid_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_antid_Value.q45baEmptyErrorMsg = false;
            }


            if (Q45HandleControlState.post_ava_switch_ritux === true) {

                Q45HandleControlState.Q45Empty = false
                if (Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_ritux_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Rituximab");
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_ritux_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45DateInvalidSoft2 = false;

                }
                if (Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_ritux_Value.q45baEmptyErrorMsg = false;
                }
            }
            else {
                Q45HandleControlState.post_ava_switch_ritux_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_ritux_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_ritux_Value.q45baEmptyErrorMsg = false;
            }


            if (Q45HandleControlState.post_ava_switch_fosta === true) {
                Q45HandleControlState.Q45Empty = false
                if (Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_fosta_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Fostamatinib disodium");
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_fosta_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45DateInvalidSoft2 = false;

                }
                if (Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_fosta_Value.q45baEmptyErrorMsg = false;
                }
            }
            else {
                Q45HandleControlState.post_ava_switch_fosta_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_fosta_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_fosta_Value.q45baEmptyErrorMsg = false;
            }

            if (Q45HandleControlState.post_ava_switch_immuno === true) {
                Q45HandleControlState.Q45Empty = false
                if (Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_immuno_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Immunosuppressants (i.e., azathioprine, cyclophosphamide, cyclosporine, danazol, dapsone, mycophenolate mofetil, vinca alkaloids [vinblastine, vinorelbine, vincristine])");
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_immuno_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45DateInvalidSoft2 = false;

                }
                if (Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_immuno_Value.q45baEmptyErrorMsg = false;
                }
            }
            else {
                Q45HandleControlState.post_ava_switch_immuno_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_immuno_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_immuno_Value.q45baEmptyErrorMsg = false;
            }
            
            if (Q45HandleControlState.post_ava_switch_other === true) {
                Q45HandleControlState.Q45Empty = false
                if (Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_dt === "") {
                    Q45HandleControlState.post_ava_switch_other_Value.q45aEmptyErrorMsg = true;
                    Q45HandleControlState.post_ava_switch_other_Value.showSoftAErrorMsg = TreatmentAfterAVA_Msgs.Q45a1EmptyErrorMsg.replace("[Therapy]", "Other ITP treatment");
                    Q45HandleControlState.post_ava_switch_other_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_other_Value.q45DateInvalidSoft2 = false;
                }
                else {
                    Q45HandleControlState.post_ava_switch_other_Value.q45aEmptyErrorMsg = false;
                    Q45HandleControlState.post_ava_switch_other_Value.showSoftAErrorMsg = "";
                    Q45HandleControlState.post_ava_switch_other_Value.q45DateInvalidSoft1 = false;
                    Q45HandleControlState.post_ava_switch_other_Value.q45DateInvalidSoft2 = false;

                }
                if (Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_reason === 0 || Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_reason === "") {
                    Q45HandleControlState.post_ava_switch_other_Value.q45bEmptyErrorMsg = true;
                } else {
                    Q45HandleControlState.post_ava_switch_other_Value.q45bEmptyErrorMsg = false;
                }
                if (Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_reason === 7 &&
                    Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_oth_txt === "") {
                    Q45HandleControlState.post_ava_switch_other_Value.q45baEmptyErrorMsg = true;
                }
                else {
                    Q45HandleControlState.post_ava_switch_other_Value.q45baEmptyErrorMsg = false;
                }
                if(Q45HandleControlState.post_ava_switch_other_txt === ""){
                    Q45HandleControlState.post_ava_switch_other_txt_empty = true
                }else{
                    Q45HandleControlState.post_ava_switch_other_txt_empty = false                    
                }

            }
            else {
                Q45HandleControlState.post_ava_switch_other_Value.q45aEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_other_Value.q45bEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_other_Value.q45baEmptyErrorMsg = false;
                Q45HandleControlState.post_ava_switch_other_txt_empty = false;
            }

            if (Q45HandleControlState.Q45Empty === true ||
                Q45HandleControlState.post_ava_switch_elt_Value.q45aEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_romi_Value.q45aEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_lusu_Value.q45aEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_steroid_Value.q45aEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_ivig_Value.q45aEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_antid_Value.q45aEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_ritux_Value.q45aEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_fosta_Value.q45aEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_immuno_Value.q45aEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_other_Value.q45aEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_elt_Value.q45bEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_romi_Value.q45bEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_steroid_Value.q45bEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_ivig_Value.q45bEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_antid_Value.q45bEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_ritux_Value.q45bEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_fosta_Value.q45bEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_immuno_Value.q45bEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_lusu_Value.q45bEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_other_Value.q45bEmptyErrorMsg === true ||
                
                Q45HandleControlState.post_ava_switch_elt_Value.q45baEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_romi_Value.q45baEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_steroid_Value.q45baEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_ivig_Value.q45baEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_antid_Value.q45baEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_ritux_Value.q45baEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_fosta_Value.q45baEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_immuno_Value.q45baEmptyErrorMsg === true ||
                Q45HandleControlState.post_ava_switch_lusu_Value.q45baEmptyErrorMsg === true || Q45HandleControlState.post_ava_switch_other_Value.q45baEmptyErrorMsg === true
                || Q45HandleControlState.post_ava_switch_other_txt_empty === true) {
                Q45hasError = true
            }
        }
        /*Q45 region end*/

        if (Q38hasError ||
            q39Empty || q39ParentRepeaterhasError || q39ChildRepeaterhasError ||
            q41hasError || Q41Repeater1hasError || Q41Repeater2hasError ||
            q42hasError || Q42DatehasError ||
            q43hasError || Q43DatehasError ||
            q44hasError || q44DatahasError ||
            Q45hasError)
            return true;
        else
            return false;
    }

    const onsaveclick = async (validateForm) => {
        try{
            debugger
            ToggleLoader(true);
            let status =validateForm? validate():false;
            if (status === true) {

                setShowTopErrMsg(true);
                ToggleLoader(false);
            }
            else {
                debugger;
                setShowTopErrMsg(false);
                let data = {
                    TreatementAfterAVADetailsModal: {
                        Patientid: GetLocalStorageData("patientId") ?? 0,
                        UserID: GetLoggedInUserID(),
                        IsValidated:validateForm,
                        itptreatmentpostavaDetails: [],
                        itptreatmentpostavaDetails: {
                            itptreatmentpostavaid: ITPTreatmentPostAVADetails.itptreatmentpostavaid,
                            post_ava_steroid_change: ITPTreatmentPostAVADetails.post_ava_steroid_change,
                            post_ava_new_ster_start: ITPTreatmentPostAVADetails.post_ava_new_ster_start,
                            post_ava_ivig_antid_use_unk: ITPTreatmentPostAVADetails.q41radiobutton,
                            post_ava_disc_treat_unk: ITPTreatmentPostAVADetails.post_ava_disc_treat_unk,
                            post_ava_other_treat_unk: ITPTreatmentPostAVADetails.post_ava_other_treat_unk,
                            post_ava_splen: ITPTreatmentPostAVADetails.post_ava_splen,
                            post_ava_splen_dt: ITPTreatmentPostAVADetails.post_ava_splen_dt,
                            post_ava_splen_dt_unk: ITPTreatmentPostAVADetails.post_ava_splen_dt_unk,
                            q43Empty: false,
                            q44Empty: false,
                            q44DataEmpty: false,
                            q44DateInvalidSoft1: false,
                            q44DateInvalidSoft2: false,
                            q44DateInvalidSoft3: false,
                            post_ava_disc_date_unk: Q45HandleControlState.post_ava_switch_none === "1" || Q45HandleControlState.post_ava_switch_none === 1 ? true : false,
                        },
                        steroidreceivedDetails: [],
                        steroidcourseDetails: [],
                        ivigdatesDetails: [],
                        antiddatesDetails: [],
                        itpDiscontinuationDateCheckbox1Details: [],
                        itpDiscontinuationDateCheckbox2Details: [],
                        itpTreatmentsQ45Chk1TypeDetails: [],
                        itpTreatmentsQ45Chk2TypeDetails: [],
                        itpTreatmentsQ45Chk3TypeDetails: [],
                        itpTreatmentsQ45Chk4TypeDetails: [],
                        itpTreatmentsQ45Chk5TypeDetails: [],
                        itpTreatmentsQ45Chk6TypeDetails: [],
                        itpTreatmentsQ45Chk7TypeDetails: [],
                        itpTreatmentsQ45Chk8TypeDetails: [],
                        itpTreatmentsQ45Chk9TypeDetails: [],
                        itpTreatmentsQ45Chk10TypeDetails:[],
                        itpDiscontinuationDateCheckbox1Details: [],
                        itpDiscontinuationDateCheckbox2Details: [],
                        otherItpTreatmentsModalCheckbox1Details: [],
                        otherItpTreatmentsModalCheckbox2Details: [],
                    },
                };

                if (q38MainSteroidReceivedDetails.length > 0) {

                    for (let i = 0; i < q38MainSteroidReceivedDetails.length; i++) {
                        let q39SteroidReceivedData = {
                            steroidreceivedid: q38MainSteroidReceivedDetails[i].steroidreceivedid,
                            steroidtypeid: q38MainSteroidReceivedDetails[i].steroidtypeid,
                            post_ava_b1_tx_change: q38MainSteroidReceivedDetails[i].post_ava_b1_tx_change_doseFrequency==true?1:0,
                            post_ava_b1_tx_change_discontinuation: q38MainSteroidReceivedDetails[i].post_ava_b1_tx_change_discontinuation==true?1:0,
                            post_ava_e1_tx_disc: q38MainSteroidReceivedDetails[i].post_ava_e1_tx_disc,
                            post_ava_f1_tx_disc_dt: q38MainSteroidReceivedDetails[i].post_ava_f1_tx_disc_dt,
                            // post_ava_ster_disc_dt_unk: q38MainSteroidReceivedDetails[i].post_ava_ster_disc_dt_unk,
                            steroidreceiveddosagechangeDetails: [],
                        };


                        if (q38MainSteroidReceivedDetails[i].steroidreceiveddosagechangeDetails.length > 0) {
                            for (let j = 0; j < q38MainSteroidReceivedDetails[i].steroidreceiveddosagechangeDetails.length; j++) {
                                let childData = q38MainSteroidReceivedDetails[i].steroidreceiveddosagechangeDetails[j];
                                let q39childData = {
                                    steroidreceiveddosagechangeid: childData.steroidreceiveddosagechangeid,
                                    steroidreceivedid: childData.steroidreceivedid,
                                    steroidtypeid: childData.steroidtypeid,
                                    post_ava_b1x1_tx_change_dt: childData.post_ava_b1x1_tx_change_dt,
                                    // post_ava_b1x1_tx_change_dt_unk: Boolean(childData.post_ava_b1x1_tx_change_dt_unk),
                                    post_ava_c1x1_tx_dose: childData.post_ava_c1x1_tx_dose,
                                    post_ava_d1x1_tx_dose_num: childData.post_ava_d1x1_tx_dose_num,
                                    frequencytimeid: childData.frequencytimeid,
                                }
                                q39SteroidReceivedData.steroidreceiveddosagechangeDetails.push(q39childData);
                            }
                        }
                        data.TreatementAfterAVADetailsModal.steroidreceivedDetails.push(q39SteroidReceivedData);
                    }
                }

                if (q39SteroidCourseDetails.length > 0) {
                    for (let i = 0; i < q39SteroidCourseDetails.length; i++) {

                        let q39SteroidCourseData = {
                            steroidcourseid: q39SteroidCourseDetails[i].steroidcourseid,
                            steroidtypeid: q39SteroidCourseDetails[i].steroidtypeid,
                            new_tx_start_dt_b1: q39SteroidCourseDetails[i].new_tx_start_dt_b1,
                            /*new_tx_start_dt_b1_unk: q39SteroidCourseDetails[i].new_tx_start_dt_b1_unk,*/
                            new_tx_dose_amt_c1: q39SteroidCourseDetails[i].new_tx_dose_amt_c1,
                            new_tx_dose_num_d1: q39SteroidCourseDetails[i].new_tx_dose_num_d1,
                            frequencytimeid: q39SteroidCourseDetails[i].frequencytimeid,
                            new_tx_dose_chg_e1: q39SteroidCourseDetails[i].new_tx_dose_chg_e1,
                            new_tx_disc_i1: q39SteroidCourseDetails[i].new_tx_disc_i1,
                            new_tx_disc_dt_j1: q39SteroidCourseDetails[i].new_tx_disc_dt_j1,
                            /*new_tx_disc_dt_j1_unk: q39SteroidCourseDetails[i].new_tx_disc_dt_j1_unk,*/
                            steroidcoursedosagechangeDetails: [],
                        };

                        if (q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails.length > 0) {
                            for (let j = 0; j < q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails.length; j++) {

                                let childData = q39SteroidCourseDetails[i].q39SteroidcoursedosagechangeDetails[j];
                                let q39childData = {
                                    steroidcoursedosagechangeid: childData.steroidcoursedosagechangeid,
                                    steroidtypeid: childData.steroidtypeid,
                                    new_tx_chg_dt_f1x1: childData.new_tx_chg_dt_f1x1,
                                    /*new_tx_chg_dt_f1x1_unk: childData.new_tx_chg_dt_f1x1_unk,*/
                                    new_tx_chg_dose_g1x1: childData.new_tx_chg_dose_g1x1,
                                    new_tx_chg_num_h1x1: childData.new_tx_chg_num_h1x1,
                                    frequencytimeid: childData.frequencytimeid,
                                }
                                q39SteroidCourseData.steroidcoursedosagechangeDetails.push(q39childData);
                            }
                        }

                        data.TreatementAfterAVADetailsModal.steroidcourseDetails.push(q39SteroidCourseData);
                    }
                }

                if (ITPTreatmentPostAVADetails.q41showrepeater1) {
                    for (let i = 0; i < q41ivigdatesDetails.length; i++) {

                        let q41iviData = {
                            ivigdatesid: q41ivigdatesDetails[i].ivigdatesid,
                            ivigtherapytypeid: 1,
                            postAvaA1TxStartDt: q41ivigdatesDetails[i].postAvaA1TxStartDt,
                            postAvaA1TxStartDtUnk: q41ivigdatesDetails[i].postAvaA1TxStartDtUnk == 1,
                            postAvaB1TxEndDt: q41ivigdatesDetails[i].postAvaB1TxEndDt,
                            postAvaB1TxEndDtUnk: q41ivigdatesDetails[i].postAvaB1TxEndDtUnk == 1,
                        };
                        data.TreatementAfterAVADetailsModal.ivigdatesDetails.push(q41iviData);
                    }
                }

                if (ITPTreatmentPostAVADetails.q41showrepeater2) {
                    for (let i = 0; i < q41antiddatesDetails.length; i++) {

                        let q41antidData = {
                            ivigdatesid: q41antiddatesDetails[i].ivigdatesid,
                            ivigtherapytypeid: 2,
                            postAvaA1TxStartDt: q41antiddatesDetails[i].postAvaA1TxStartDt,
                            postAvaA1TxStartDtUnk: q41antiddatesDetails[i].postAvaA1TxStartDtUnk == 1,
                            postAvaB1TxEndDt: q41antiddatesDetails[i].postAvaB1TxEndDt,
                            postAvaB1TxEndDtUnk: q41antiddatesDetails[i].postAvaB1TxEndDtUnk == 1,
                        };
                        data.TreatementAfterAVADetailsModal.antiddatesDetails.push(q41antidData);
                    }
                }

                if (ITPTreatmentPostAVADetails.q42Checkbox1Checked) {
                    let q42Data = {
                        Itpdiscontinuationdateid: q42itpdiscontinuationdatesCheckbox1Details[0].Itpdiscontinuationdateid,
                        Itptreamenttypeid: 7,
                        PostAvaTxDiscDt: q42itpdiscontinuationdatesCheckbox1Details[0].PostAvaTxDiscDt,
                        q42Empty: false,
                        q42DateInvalidSoft1: false,
                        q42DateInvalidSoft2: false,
                        q42DateInvalidSoft3: false,
                    };
                    data.TreatementAfterAVADetailsModal.itpDiscontinuationDateCheckbox1Details.push(q42Data);

                }

                if (ITPTreatmentPostAVADetails.q42Checkbox2Checked) {
                    let q42Data = {
                        Itpdiscontinuationdateid: q42itpdiscontinuationdatesCheckbox2Details[0].Itpdiscontinuationdateid,
                        Itptreamenttypeid: 9,
                        PostAvaTxDiscDt: q42itpdiscontinuationdatesCheckbox2Details[0].PostAvaTxDiscDt,
                        q42Empty: false,
                        q42DateInvalidSoft1: false,
                        q42DateInvalidSoft2: false,
                        q42DateInvalidSoft3: false,
                    };
                    data.TreatementAfterAVADetailsModal.itpDiscontinuationDateCheckbox2Details.push(q42Data);

                }


                if (ITPTreatmentPostAVADetails.q43Checkbox1Checked) {
                    let q43Data = {
                        Otheritptreatmentsid: q43otherItpTreatmentsModalCheckbox1Details[0].Otheritptreatmentsid,
                        Itptreatmenttypeid: 7,
                        PostAvaTxStartDt: q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxStartDt,
                        PostAvaTxDisc: q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDisc,
                        PostAvaTxDiscDt: q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDiscDt,
                        q43Empty: false,

                        defDate1Q43: false,
                        q43Date1InvalidSoft1: false,
                        q43Date1InvalidSoft2: false,
                        q43Date1InvalidSoft3: false,

                        defDate2Q43: false,
                        q43Date2InvalidSoft1: false,
                        q43Date2InvalidSoft2: false,
                        q43Date2InvalidSoft3: false,
                    };
                    data.TreatementAfterAVADetailsModal.otherItpTreatmentsModalCheckbox1Details.push(q43Data);

                    // for (let i = 0; i < q42itpdiscontinuationdatesCheckbox2Details.length; i++) {

                    //     let q42Data = {
                    //         Itpdiscontinuationdateid: q42itpdiscontinuationdatesCheckbox2Details[i].Itpdiscontinuationdateid,
                    //         Itptreamenttypeid: 9,
                    //         PostAvaTxDiscDt: q42itpdiscontinuationdatesCheckbox2Details[i].PostAvaTxDiscDt,
                    //     };
                    //     data.TreatementAfterAVADetailsModal.itpDiscontinuationDateCheckbox2Details.push(q42Data);
                    // }
                }



                //Q45
                if (Q45HandleControlState.post_ava_switch_elt) {
                    let q45Chk1Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_elt_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 1,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_oth_txt,
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk1TypeDetails.push(q45Chk1Data);
                }

                if (Q45HandleControlState.post_ava_switch_romi) {
                    let q45Chk2Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_romi_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 2,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_oth_txt,
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk2TypeDetails.push(q45Chk2Data);
                }

                if (Q45HandleControlState.post_ava_switch_lusu) {
                    let q45Chk3Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_lusu_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 3,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_oth_txt,
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk3TypeDetails.push(q45Chk3Data);
                }

                if (Q45HandleControlState.post_ava_switch_steroid) {
                    let q45Chk4Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_steroid_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 4,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_oth_txt,
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk4TypeDetails.push(q45Chk4Data);
                }

                if (Q45HandleControlState.post_ava_switch_ivig) {
                    let q45Chk5Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_ivig_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 5,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_oth_txt,
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk5TypeDetails.push(q45Chk5Data);
                }

                if (Q45HandleControlState.post_ava_switch_antid) {
                    let q45Chk6Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_antid_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 6,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_oth_txt,
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk6TypeDetails.push(q45Chk6Data);
                }

                if (Q45HandleControlState.post_ava_switch_ritux) {
                    let q45Chk7Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_ritux_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 7,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_oth_txt,
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk7TypeDetails.push(q45Chk7Data);
                }

                if (Q45HandleControlState.post_ava_switch_fosta) {
                    let q45Chk8Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_fosta_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 8,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_oth_txt,
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk8TypeDetails.push(q45Chk8Data);
                }

                if (Q45HandleControlState.post_ava_switch_immuno) {
                    let q45Chk9Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_immuno_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 9,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_oth_txt,
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk9TypeDetails.push(q45Chk9Data);
                }

                if (Q45HandleControlState.post_ava_switch_other) {
                    let q45Chk10Data = {
                        itptreatmentsfollowingavaid: Q45HandleControlState.post_ava_switch_other_Value.itptreatmentsfollowingavaid,
                        itptreatmentsfollowingavatypeid: 11,
                        postAvaTxSwitchDt: Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_dt,
                        PostAvaTxSwitchReason: Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_reason,
                        PostAvaTxSwitchOthTxt: Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_oth_txt,
                        ItptmtfollavaOtherTypeTxt: Q45HandleControlState.post_ava_switch_other_txt
                    };
                    data.TreatementAfterAVADetailsModal.itpTreatmentsQ45Chk10TypeDetails.push(q45Chk10Data);
                }
                //Q45

                if (ITPTreatmentPostAVADetails.q43Checkbox2Checked) {
                    let q43Data = {
                        Otheritptreatmentsid: q43otherItpTreatmentsModalCheckbox2Details[0].Otheritptreatmentsid,
                        Itptreatmenttypeid: 9,
                        PostAvaTxStartDt: q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxStartDt,
                        PostAvaTxDisc: q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDisc,
                        PostAvaTxDiscDt: q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDiscDt,
                        q43Empty: false,

                        defDate1Q43: false,
                        q43Date1InvalidSoft1: false,
                        q43Date1InvalidSoft2: false,
                        q43Date1InvalidSoft3: false,

                        defDate2Q43: false,
                        q43Date2InvalidSoft1: false,
                        q43Date2InvalidSoft2: false,
                        q43Date2InvalidSoft3: false,
                    };
                    data.TreatementAfterAVADetailsModal.otherItpTreatmentsModalCheckbox2Details.push(q43Data);

                }

                let response = await TreatmentAfterAVAService.SaveTreatmentAfterAVAData(
                    data.TreatementAfterAVADetailsModal
                );
                if (response?.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized)
                        HandleSessionTimeout();
                    else
                        throw response.error;
                }else{
                    await GettreatmentAfterAVAData();
                    ToggleLoader(false);
                    ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                }
                return true;
            }
            return false;
        }catch(error){
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    };

    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths}
                            Save={onsaveclick} />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.TreatmentHistoryAfterAVA} />
                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">

                            <div className="survey-section-content-header">
                                <h4>OTHER ITP-RELATED TREATMENTS FOLLOWING AVA INITIATION</h4>
                            </div>
                            {showTopErrMsg && (
                                <div className="clean-error ">
                                    <ErrorField
                                        show={showTopErrMsg}
                                        message={CommonError_Msgs.Hard_Empty}
                                    />
                                </div>
                            )}
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div style={{ ...PageDesignConstants.PageDescriptionStyle }}>
                                        <i>This section will ask you detailed questions about ITP treatments that the patient received
                                            following AVA initiation.
                                        </i>
                                    </div>
                                    <div className="question-answer">
                                        {/*{(showq38 || showq39) &&*/} {/*This logic removed - #11104*/}
                                        <div>
                                            <div className="question sub-header-weight">
                                                <span className="">Steroids</span>
                                            </div>
                                            {showq38 &&
                                                <div>
                                                    <div className="question-bot-sapce">
                                                        <div className="question question-weight">
                                                            <span>39.</span>
                                                            <span className="quest-text-pad">
                                                                Did the patient's dose or frequency of steroid use change
                                                                following AVA initiation on <span style={{ color: PageDesignConstants.fontColor }}> {indexdate}</span> and before {" "}
                                                                <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>? A change could include discontinuing
                                                                steroid use.
                                                            </span>
                                                        </div>
                                                        <div className="double-dig-answer">
                                                            {/* course loop section */}

                                                            <div className="answer-list-text">
                                                                <input
                                                                    type="radio"
                                                                    id='q38Yes'
                                                                    name="post_ava_steroid_change"
                                                                    value={1}
                                                                    checked={
                                                                        ITPTreatmentPostAVADetails.post_ava_steroid_change == "1" ||
                                                                        ITPTreatmentPostAVADetails.post_ava_steroid_change == 1
                                                                    }
                                                                    onChange={Handlechange} />
                                                                <label>
                                                                    <span className="radio-text-padding">Yes</span></label>
                                                            </div>

                                                            {showq38parentrepeater &&
                                                                <>
                                                                    {
                                                                        q38MainSteroidReceivedDetails.length > 0 ? (q38MainSteroidReceivedDetails.map((data, idx) => (
                                                                            <div className="dependent-section" key={idx} style={{ marginTop: '10px' }}>
                                                                                <span className="question-weight"></span>
                                                                                {idx != 0 &&
                                                                                    <div className="delete-btn-div">
                                                                                        <img
                                                                                            width="16px"
                                                                                            src="../Assets/images/Icon-material-delete.png"
                                                                                            alt=""
                                                                                            onClick={(e) => deleteq38SteroidReceivedMainDetails(idx)}
                                                                                        />
                                                                                    </div>
                                                                                }


                                                                                <div className="sub-question-bot-sapce-ul">
                                                                                    <div className="answer-list-text">
                                                                                        <span className="question-weight">a.</span>
                                                                                        <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                            <span className="question-weight">
                                                                                                Type of steroid received:
                                                                                            </span>

                                                                                        </span>
                                                                                    </div>
                                                                                    {steroidType?.map((i) => {
                                                                                        return (
                                                                                            <div className="answer-list-text answer-pad-left" key={idx}>
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={idx}
                                                                                                    key={i.steroidtypeid}
                                                                                                    value={i.steroidtypeid}
                                                                                                    name={"q39Steroidtype-" + idx}
                                                                                                    checked={
                                                                                                        data.steroidtypeid == i.steroidtypeid
                                                                                                    }
                                                                                                    onClick={Handlechange} />

                                                                                                <label >
                                                                                                    <span className="radio-text-padding">
                                                                                                        {i.steroidtypeText}
                                                                                                    </span></label>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                    <ErrorField
                                                                                        show={data.q39a_empty ? true : data.q39a_empty}
                                                                                        message={TreatmentAfterAVA_Msgs.Q38aEmpty}
                                                                                    />
                                                                                </div>


                                                                                <div className="sub-question-bot-sapce-ul">
                                                                                    <div className="answer-list-text question-weight">
                                                                                        <span>b.</span>
                                                                                        <span className="quest-text-pad">
                                                                                            Would you like to enter change(s) in steroid dose/frequency or discontinuation of steroids?
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="answer-list-text answer-pad-left">
                                                                                        <input type="checkbox"
                                                                                            name={"post_ava_b1_tx_change_doseFrequency-" + idx}
                                                                                            
                                                                                            id={idx}
                                                                                            checked={
                                                                                                data.post_ava_b1_tx_change_doseFrequency
                                                                                            }
                                                                                            onChange={Handlechange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Dose or frequency change(s)</span>
                                                                                    </div>
                                                                                    {/*Child*/}
                                                                                    {data.showq38childrepeater &&
                                                                                        <>
                                                                                            <div className="dependent-sub-section margin-left-20" >
                                                                                                <div className="sub-question-bot-sapce" style={{ marginTop: "20px" }}>
                                                                                                    {
                                                                                                        data.steroidreceiveddosagechangeDetails.length > 0 ? (data.steroidreceiveddosagechangeDetails.map((childdata, childidx) => (
                                                                                                            <div className="loop-section-ul" >

                                                                                                                <div className="loop-head">
                                                                                                                    <span className="question-weight">Dose {childidx + 1}</span>
                                                                                                                    {childidx != 0 &&
                                                                                                                        <div className="delete-btn-div"
                                                                                                                            onClick={(e) =>
                                                                                                                                deletedosage(
                                                                                                                                    e,
                                                                                                                                    idx,
                                                                                                                                    childidx
                                                                                                                                )
                                                                                                                            }>
                                                                                                                            <img
                                                                                                                                width="16px"
                                                                                                                                src="../Assets/images/Icon-material-delete.png"
                                                                                                                                alt=""
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </div>

                                                                                                                <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                                                                    <div className="answer-list-text">
                                                                                                                        <span className="question-weight">c.</span>
                                                                                                                        <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                                                            <span className="question-weight">
                                                                                                                                Date of dose change:{" "}
                                                                                                                            </span>
                                                                                                                            <DateControl
                                                                                                                                ctrlId={"post_ava_b1x1_tx_change_dt-" + idx + "-" + childidx}
                                                                                                                                HandleDateChange={HandleDateChange}
                                                                                                                                optionId={"post_ava_b1x1_tx_change_dt"}
                                                                                                                                defDate={childdata.defDateQ38}
                                                                                                                                date={childdata.post_ava_b1x1_tx_change_dt}
                                                                                                                            />
                                                                                                                            (If only month and year are known, please enter “15” for
                                                                                                                            the day)
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    <ErrorField
                                                                                                                        show={q38MainSteroidReceivedDetails[idx].steroidreceiveddosagechangeDetails[childidx].q3BSteroidDateInvalidSoft1}
                                                                                                                        message={TreatmentAfterAVA_Msgs.Q39BSteroidDateInvalidSoft1}
                                                                                                                    />
                                                                                                                    <ErrorField
                                                                                                                        show={q38MainSteroidReceivedDetails[idx].steroidreceiveddosagechangeDetails[childidx].q3BSteroidDateInvalidSoft2}
                                                                                                                        message={TreatmentAfterAVA_Msgs.Q39BSteroidDateInvalidSoft2}
                                                                                                                    />
                                                                                                                    <ErrorField
                                                                                                                        show={q38MainSteroidReceivedDetails[idx].steroidreceiveddosagechangeDetails[childidx].q3BSteroidDateInvalidSoft3}
                                                                                                                        message={TreatmentAfterAVA_Msgs.Q39BSteroidDateInvalidSoft3}
                                                                                                                    />
                                                                                                                    <ErrorField
                                                                                                                        show={childdata.q38RepeaterbEmpty}
                                                                                                                        message={TreatmentAfterAVA_Msgs.Q38RepeaterbEmpty}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                                                                    <div className="answer-list-text">
                                                                                                                        <span className="question-weight">d.</span>
                                                                                                                        <span className="quest-text-pad">
                                                                                                                            <span className="question-weight">New Dosage: </span>
                                                                                                                            <input className="sub-input-min-dash" type="text"
                                                                                                                                id={childidx}
                                                                                                                                name={"post_ava_c1x1_tx_dose-" + idx + "-" + childidx}
                                                                                                                                value={childdata.post_ava_c1x1_tx_dose}
                                                                                                                                onKeyDown={formatInput}
                                                                                                                                onChange={Handlechange} /> mg
                                                                                                                           

                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    <ErrorField
                                                                                                                        show={childdata.q38RepeatercEmpty}
                                                                                                                        message={TreatmentAfterAVA_Msgs.Q38RepeatercEmpty}
                                                                                                                    />
                                                                                                                    <ErrorField
                                                                                                                        show={childdata.q38doselimitexceeded}
                                                                                                                        message={TreatmentAfterAVA_Msgs.QdosageFreq}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                                                                    <div className="answer-list-text">
                                                                                                                        <span className="question-weight">e.</span>
                                                                                                                        <span className="quest-text-pad">
                                                                                                                            <span className="question-weight">New Frequency: </span>
                                                                                                                            <input className="sub-input-min-dash" type="text"
                                                                                                                                id={childidx}
                                                                                                                                name={"post_ava_d1x1_tx_dose_num-" + idx + "-" + childidx}
                                                                                                                                value={childdata.post_ava_d1x1_tx_dose_num}
                                                                                                                                onKeyDown={formatInput}
                                                                                                                                onChange={Handlechange} />
                                                                                                                            time(s) per
                                                                                                                            <select
                                                                                                                                name={"q38ChildFreq_time-" + idx + "-" + childidx}
                                                                                                                                className="freq-dropdown"
                                                                                                                                onChange={Handlechange}
                                                                                                                                value={childdata.frequencytimeid}
                                                                                                                            >
                                                                                                                                <option value={0}>Select</option>
                                                                                                                                {frequencytime?.map((i) => {
                                                                                                                                    return (
                                                                                                                                        <option
                                                                                                                                            key={i.frequencytimeid}
                                                                                                                                            value={i.frequencytimeid}
                                                                                                                                        >
                                                                                                                                            {i.frequencytimetxt}
                                                                                                                                        </option>
                                                                                                                                    );
                                                                                                                                })}
                                                                                                                            </select>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    <ErrorField
                                                                                                                        show={childdata.q38RepeaterdEmpty}
                                                                                                                        message={TreatmentAfterAVA_Msgs.Q38RepeaterdEmpty}
                                                                                                                    />
                                                                                                                    <ErrorField
                                                                                                                        show={childdata.q38freqlimitexceeded}
                                                                                                                        message={TreatmentAfterAVA_Msgs.QdosageFreq}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))) : (<></>)}






                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="sub-question-bot-sapce-ul mt-2">
                                                                                                <div className="answer-list-text" onClick={(e) =>
                                                                                                    addavaDosageDetailsRow(
                                                                                                        e, idx, data
                                                                                                    )}>
                                                                                                    <a>
                                                                                                        <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                                                                    </a>
                                                                                                    <a className="radio-text-padding">
                                                                                                        Click here to add another dose change
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    <div className="answer-list-text answer-pad-left">
                                                                                        <input type="checkbox"
                                                                                            name={"post_ava_b1_tx_change_discontinuation-" + idx}
                                                                                         
                                                                                            id={idx}
                                                                                            checked={
                                                                                                data.post_ava_b1_tx_change_discontinuation }
                                                                                            onChange={Handlechange}
                                                                                        />
                                                                                        <span className="radio-text-padding">Discontinuation</span>
                                                                                    </div>
                                                                                    {/*Child*/}
                                                                                    {data.showQ38f &&
                                                                                        <>
                                                                                    <div className="dependent-sub-section margin-left-20">
                                                                                                        <div className="sub-question-bot-sapce content-indent-option">
                                                                                                            <div className="answer-list-text">
                                                                                                                <span className="question-weight question-weight">
                                                                                                                    g.
                                                                                                                </span>
                                                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                                                    <span className="question-weight">
                                                                                                                        <span className="question-weight">
                                                                                                                            Date of discontinuation:{" "}
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                    <DateControl
                                                                                                                        ctrlId={"post_ava_f1_tx_disc_dt-" + idx}
                                                                                                                        HandleDateChange={HandleDateChange}
                                                                                                                        optionId={"post_ava_f1_tx_disc_dt"}
                                                                                                                        defDate={data.defEDateQ38}
                                                                                                                        date={data.post_ava_f1_tx_disc_dt}
                                                                                                                    />
                                                                                                                    (If only month and year are known, please enter “15” for the day)
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <ErrorField
                                                                                                                show={q38MainSteroidReceivedDetails[idx].q3FSteroidDateInvalidSoft4}
                                                                                                                message={TreatmentAfterAVA_Msgs.Q38gDateInvalidSoft4}
                                                                                                            />

                                                  
                                                                                                        </div>
                                                                                                        <ErrorField
                                                                                                show={data.q39e_f_empty ? true : data.q39e_f_empty}
                                                                                                message={TreatmentAfterAVA_Msgs.Q38fEmpty}
                                                                                            />
                                                                                                        <ErrorField
                                                                                                            show={q38MainSteroidReceivedDetails[idx].q3FSteroidDateInvalidSoft1}
                                                                                                            message={TreatmentAfterAVA_Msgs.Q39FDateInvalidSoft1}
                                                                                                        />
                                                                                                        <ErrorField
                                                                                                            show={q38MainSteroidReceivedDetails[idx].q3FSteroidDateInvalidSoft2}
                                                                                                            message={TreatmentAfterAVA_Msgs.Q39FDateInvalidSoft2}
                                                                                                        />
                                                                                                        <ErrorField
                                                                                                            show={q38MainSteroidReceivedDetails[idx].q3FSteroidDateInvalidSoft3}
                                                                                                            message={TreatmentAfterAVA_Msgs.Q39FDateInvalidSoft3}
                                                                                                        />
                                                                                                    </div>
                                                                                                    </>
                                                                                    }
                                                                                    <ErrorField
                                                                                        show={data.q38bEmpty}
                                                                                        message={TreatmentAfterAVA_Msgs.Q38bEmpty}
                                                                                    />
                                                                                </div>


                                                                                <div className="">
                                                                                    {/* <div className="sub-question-bot-sapce-ul mt-2">
                                                                                        <div className="answer-list-text">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                id={idx}
                                                                                                name={"q38Checkbox-" + idx}
                                                                                                checked={data.q38Checkbox}
                                                                                                onChange={(e) => Handlechange(e)} />
                                                                                            <span className="radio-text-padding">Click here to enter a discontinuation</span>
                                                                                        </div>
                                                                                    </div> */}
                                                                                    {/* {data.showQ38f &&
                                                                                        <>
                                                                                            <div className="answer-list-text question-weight">
                                                                                                <span>f.</span>
                                                                                                <span className="quest-text-pad">
                                                                                                Did the patient discontinue this steroid treatment following AVA initiation on  <span style={{ color: PageDesignConstants.fontColor }}>{indexdate}</span> and before{" "}
                                                                                                    <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>?
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="answer-list-text answer-pad-left">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={idx}
                                                                                                    name={"post_ava_e1_tx_disc_yes-" + idx}
                                                                                                    value={1}
                                                                                                    checked={
                                                                                                        data.post_ava_e1_tx_disc === "1" ||
                                                                                                        data.post_ava_e1_tx_disc === 1
                                                                                                    }
                                                                                                    onChange={Handlechange} />
                                                                                                <label >
                                                                                                    <span className="radio-text-padding">Yes</span></label>
                                                                                            </div>

                                                                                            {data.post_ava_e1_tx_disc === 1 || data.post_ava_e1_tx_disc === "1"
                                                                                                ? (
                                                                                                    <div className="dependent-sub-section margin-left-20">
                                                                                                        <div className="sub-question-bot-sapce content-indent-option">
                                                                                                            <div className="answer-list-text">
                                                                                                                <span className="question-weight question-weight">
                                                                                                                    g.
                                                                                                                </span>
                                                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                                                    <span className="question-weight">
                                                                                                                        <span className="question-weight">
                                                                                                                            Date of discontinuation:{" "}
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                    <DateControl
                                                                                                                        ctrlId={"post_ava_f1_tx_disc_dt-" + idx}
                                                                                                                        HandleDateChange={HandleDateChange}
                                                                                                                        optionId={"post_ava_f1_tx_disc_dt"}
                                                                                                                        defDate={data.defEDateQ38}
                                                                                                                        date={data.post_ava_f1_tx_disc_dt}
                                                                                                                    />
                                                                                                                    (If only month and year are known, please enter “15” for the day)
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            <ErrorField
                                                                                                                show={q38MainSteroidReceivedDetails[idx].q3FSteroidDateInvalidSoft4}
                                                                                                                message={TreatmentAfterAVA_Msgs.Q38gDateInvalidSoft4}
                                                                                                            />

                                                  
                                                                                                        </div>
                                                                                                        <ErrorField
                                                                                                            show={q38MainSteroidReceivedDetails[idx].q3FSteroidDateInvalidSoft1}
                                                                                                            message={TreatmentAfterAVA_Msgs.Q39FDateInvalidSoft1}
                                                                                                        />
                                                                                                        <ErrorField
                                                                                                            show={q38MainSteroidReceivedDetails[idx].q3FSteroidDateInvalidSoft2}
                                                                                                            message={TreatmentAfterAVA_Msgs.Q39FDateInvalidSoft2}
                                                                                                        />
                                                                                                        <ErrorField
                                                                                                            show={q38MainSteroidReceivedDetails[idx].q3FSteroidDateInvalidSoft3}
                                                                                                            message={TreatmentAfterAVA_Msgs.Q39FDateInvalidSoft3}
                                                                                                        />
                                                                                                    </div>
                                                                                                ) : (<></>)}

                                                                                            <div className="answer-list-text answer-pad-left">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    id={idx}
                                                                                                    name={"post_ava_e1_tx_disc_no-" + idx}
                                                                                                    value={0}
                                                                                                    checked={
                                                                                                        data.post_ava_e1_tx_disc === "0" ||
                                                                                                        data.post_ava_e1_tx_disc === 0
                                                                                                    }
                                                                                                    onChange={Handlechange} />
                                                                                                <label >
                                                                                                    <span className="radio-text-padding">No</span></label>
                                                                                            </div>
                                                                                            <ErrorField
                                                                                                show={data.q39e_f_empty ? true : data.q39e_f_empty}
                                                                                                message={TreatmentAfterAVA_Msgs.Q38fEmpty}
                                                                                            />
                                                                                            <ErrorField
                                                                                                show={data.q39e_empty ? true : data.q39e_empty}
                                                                                                message={TreatmentAfterAVA_Msgs.Q38eEmpty}
                                                                                            />
                                                                                        </>
                                                                                    } */}
                                                                                </div>

                                                                            </div>
                                                                        ))) : (<></>)}
                                                                    <div className="sub-question-bot-sapce-ul mt-2">

                                                                        <div className="answer-list-text" onClick={addQ38RepeaterMain}>
                                                                            <a>
                                                                                <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                                            </a>
                                                                            <a className="radio-text-padding">
                                                                                Click here to enter dose information for a different steroid from <span style={{ color: PageDesignConstants.fontColor }}><b>{indexdate}</b></span> to <span style={{ color: PageDesignConstants.fontColor }}><b>{minenddiscdate}</b></span>.
                                                                            </a>
                                                                            {/* <input type="checkbox"><span class="radio-text-padding">Click here to enter another dose change for <span>[therapy]</span> </span> */}
                                                                        </div>
                                                                    </div></>
                                                            }


                                                            <div className="answer-list-text">
                                                                <input
                                                                    type="radio"
                                                                    id='q38No'
                                                                    name="post_ava_steroid_change"
                                                                    value={0}
                                                                    checked={
                                                                        ITPTreatmentPostAVADetails.post_ava_steroid_change === "0" ||
                                                                        ITPTreatmentPostAVADetails.post_ava_steroid_change === 0
                                                                    }
                                                                    onChange={Handlechange} />
                                                                <label>
                                                                    <span className="radio-text-padding">No</span></label>
                                                            </div>
                                                            <ErrorField
                                                                show={ITPTreatmentPostAVADetails.q39EmptyError}
                                                                message={TreatmentAfterAVA_Msgs.Q39EmptySteroid}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* This is Start of Q39 */}
                                            {/*{showq39 &&*/} {/*Showing/Hiding Q40 logic removed - #11104*/}
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>40.</span>
                                                    <span className="quest-text-pad">
                                                        Did the patient initiate a course of steroids following AVA
                                                        initiation on <span style={{ color: PageDesignConstants.fontColor }}>{indexdate}</span> and before{" "}
                                                        <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>?
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="answer-list-text">
                                                        <input type="radio"
                                                            id="post_ava_new_ster_start_Yes"
                                                            name='post_ava_new_ster_start'
                                                            value={1}
                                                            checked={
                                                                ITPTreatmentPostAVADetails.post_ava_new_ster_start === "1" ||
                                                                ITPTreatmentPostAVADetails.post_ava_new_ster_start === 1
                                                            }
                                                            onChange={Handlechange} />
                                                        <span className="radio-text-padding">Yes</span>
                                                    </div>
                                                    {/*Parent*/}
                                                    {showq39parentrepeater &&
                                                        <>
                                                            {
                                                                q39SteroidCourseDetails.length > 0 ? (q39SteroidCourseDetails.map((data, idx) => (
                                                                    <div className="dependent-section" style={{ marginTop: '10px' }}>
                                                                        <div className="loop-head">
                                                                            <span className="question-weight"></span>
                                                                            {idx != 0 &&
                                                                                <div className="delete-btn-div">
                                                                                    <img
                                                                                        width="16px"
                                                                                        src="../Assets/images/Icon-material-delete.png"
                                                                                        alt=""
                                                                                        onClick={(e) => deleteq39SteroidCourseDetails(idx)}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">a.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Type of steroid received:
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            {steroidType?.map((i) => {
                                                                                return (
                                                                                    <div className="answer-list-text answer-pad-left">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={idx}
                                                                                            key={i.steroidtypeid}
                                                                                            value={i.steroidtypeid}
                                                                                            name={"steroidtype-" + idx}
                                                                                            checked={
                                                                                                data.steroidtypeid == i.steroidtypeid
                                                                                            }
                                                                                            onChange={Handlechange} />

                                                                                        <label htmlFor={i.steroidtypeid}>
                                                                                            <span className="radio-text-padding">
                                                                                                {i.steroidtypeText}
                                                                                            </span></label>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39aEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q39aEmpty}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">b.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of steroid initiation:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"new_tx_start_dt_b1"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"new_tx_start_dt_b1-" + idx}
                                                                                        defDate={data.defDateQ39b}
                                                                                        date={data.new_tx_start_dt_b1} />
                                                                                    (If only month and year are known, please enter “15” for the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39bDateInvalidSoft1}
                                                                                message={TreatmentAfterAVA_Msgs.Q39bDateInvalidSoft1}
                                                                            />
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39bDateInvalidSoft2}
                                                                                message={TreatmentAfterAVA_Msgs.Q39bDateInvalidSoft2}
                                                                            />
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39bDateInvalidSoft3}
                                                                                message={TreatmentAfterAVA_Msgs.Q39bDateInvalidSoft3}
                                                                            />
                                                                            {/*<div className="answer-list-text answer-pad-left">*/}
                                                                            {/*    <input type="radio"*/}
                                                                            {/*        name={"new_tx_start_dt_b1_unk-" + idx}*/}
                                                                            {/*        value={1}*/}
                                                                            {/*        id={idx}*/}
                                                                            {/*        checked={*/}
                                                                            {/*            data.new_tx_start_dt_b1_unk === "1" ||*/}
                                                                            {/*            data.new_tx_start_dt_b1_unk === 1*/}
                                                                            {/*        }*/}
                                                                            {/*        onChange={Handlechange}*/}
                                                                            {/*    />*/}
                                                                            {/*    <span className="radio-text-padding">*/}
                                                                            {/*        Date of steroid initiation is unknown*/}
                                                                            {/*    </span>*/}
                                                                            {/*</div>*/}
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39bEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q39bEmpty}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">c.</span>
                                                                                <span className="quest-text-pad">
                                                                                    <span className="question-weight">Dosage: </span>{" "}
                                                                                    <input className="sub-input-min-dash"
                                                                                        type="text"
                                                                                        value={data.new_tx_dose_amt_c1}
                                                                                        onChange={Handlechange}
                                                                                        id={idx}
                                                                                        name={"new_tx_dose_amt_c1-" + idx}
                                                                                    />mg
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39cEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q39cEmpty}
                                                                            />
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39doselimitexceeded}
                                                                                message={TreatmentAfterAVA_Msgs.QdosageFreq}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">d.</span>
                                                                                <span className="quest-text-pad">
                                                                                    <span className="question-weight">Frequency: </span>
                                                                                    <input className="sub-input-min-dash"
                                                                                        type="text"
                                                                                        value={data.new_tx_dose_num_d1}
                                                                                        onChange={Handlechange}
                                                                                        id={idx}
                                                                                        onKeyDown={formatInput}
                                                                                        name={"new_tx_dose_num_d1-" + idx}
                                                                                    />
                                                                                    time(s) per
                                                                                    <select
                                                                                        name={"frequencytime-" + idx}
                                                                                        id={idx}
                                                                                        className="freq-dropdown"
                                                                                        onChange={Handlechange}
                                                                                        value={data.frequencytimeid}
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        {frequencytime?.map((i) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={i.frequencytimeid}
                                                                                                    value={i.frequencytimeid}
                                                                                                >
                                                                                                    {i.frequencytimetxt}
                                                                                                </option>
                                                                                            );
                                                                                        })}
                                                                                    </select>
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39dEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q39dEmpty}
                                                                            />
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39freqlimitexceeded}
                                                                                message={TreatmentAfterAVA_Msgs.QdosageFreq}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul">
                                                                            <div className="answer-list-text question-weight">
                                                                                <span>e.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Did the patient's steroid dose or frequency change prior to{" "}
                                                                                    <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span> {""}?
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"
                                                                                    name={"new_tx_dose_chg_e1_Yes-" + idx}
                                                                                    value={1}
                                                                                    id={idx}
                                                                                    checked={
                                                                                        data.new_tx_dose_chg_e1 === "1" ||
                                                                                        data.new_tx_dose_chg_e1 === 1
                                                                                    }
                                                                                    onChange={Handlechange}
                                                                                />
                                                                                <span className="radio-text-padding">Yes</span>
                                                                            </div>
                                                                            {/*Child*/}
                                                                            {data.showq39childrepeater &&
                                                                                <>
                                                                                    <div className="dependent-sub-section margin-left-20">
                                                                                        <div className="sub-question-bot-sapce">
                                                                                            <div className="sub-question-bot-sapce-none">
                                                                                                <div className="question-weight">
                                                                                                    <p className="margin-bot">
                                                                                                        Please enter all steroid dose changes following AVA
                                                                                                        initiation on <span style={{ color: PageDesignConstants.fontColor }}>{indexdate}</span> and before{" "}
                                                                                                        <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>



                                                                                            {
                                                                                                data.q39SteroidcoursedosagechangeDetails.length > 0 ? (data.q39SteroidcoursedosagechangeDetails.map((childdata, childidx) => (
                                                                                                    <div className="">
                                                                                                        <div className="dependent-sub-loop-section"  >
                                                                                                            <div className="loop-head">
                                                                                                                <span className="question-weight">Dose {childidx + 1}</span>
                                                                                                                {childidx != 0 &&
                                                                                                                    <div className="delete-btn-div"
                                                                                                                        onClick={(e) =>
                                                                                                                            deletechildRpeaterQ39(
                                                                                                                                e,
                                                                                                                                idx,
                                                                                                                                childidx
                                                                                                                            )
                                                                                                                        }>
                                                                                                                        <img
                                                                                                                            width="16px"
                                                                                                                            src="../Assets/images/Icon-material-delete.png"
                                                                                                                            alt=""
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                <div className="answer-list-text">
                                                                                                                    <span className="question-weight">f.</span>
                                                                                                                    <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                                                        <span className="question-weight">
                                                                                                                            Date of dose change:{" "}
                                                                                                                        </span>
                                                                                                                        <DateControl
                                                                                                                            optionId={"new_tx_chg_dt_f1x1"}
                                                                                                                            HandleDateChange={HandleDateChange}
                                                                                                                            ctrlId={"new_tx_chg_dt_f1x1-" + idx + "-" + childidx}
                                                                                                                            defDate={childdata.defDateQ39f}
                                                                                                                            date={childdata.new_tx_chg_dt_f1x1} />
                                                                                                                        (If only month and year are known, please enter “15” for the day)
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <ErrorField
                                                                                                                    show={q39SteroidCourseDetails[idx].q39SteroidcoursedosagechangeDetails[childidx].q39fDateInvalidSoft1}
                                                                                                                    message={TreatmentAfterAVA_Msgs.Q39fDateInvalidSoft1}
                                                                                                                />
                                                                                                                <ErrorField
                                                                                                                    show={q39SteroidCourseDetails[idx].q39SteroidcoursedosagechangeDetails[childidx].q39fDateInvalidSoft2}
                                                                                                                    message={TreatmentAfterAVA_Msgs.Q39fDateInvalidSoft2}
                                                                                                                />
                                                                                                                <ErrorField
                                                                                                                    show={q39SteroidCourseDetails[idx].q39SteroidcoursedosagechangeDetails[childidx].q39fDateInvalidSoft3}
                                                                                                                    message={TreatmentAfterAVA_Msgs.Q39fDateInvalidSoft3}
                                                                                                                />
                                                                                                                {/*<div className="answer-list-text answer-pad-left">*/}
                                                                                                                {/*    <input type="radio"*/}
                                                                                                                {/*        name={"new_tx_chg_dt_f1x1_unk-" + idx + "-" + childidx}*/}
                                                                                                                {/*        value={1}*/}
                                                                                                                {/*        id={childidx}*/}
                                                                                                                {/*        checked={*/}
                                                                                                                {/*            childdata.new_tx_chg_dt_f1x1_unk === "1" ||*/}
                                                                                                                {/*            childdata.new_tx_chg_dt_f1x1_unk === 1*/}
                                                                                                                {/*        }*/}
                                                                                                                {/*        onChange={Handlechange}*/}
                                                                                                                {/*    />*/}
                                                                                                                {/*    <span className="radio-text-padding">*/}
                                                                                                                {/*        Date of therapy discontinuation is unknown*/}
                                                                                                                {/*    </span>*/}
                                                                                                                {/*</div>*/}
                                                                                                                <ErrorField
                                                                                                                    show={q39SteroidCourseDetails[idx].q39SteroidcoursedosagechangeDetails[childidx].q39fEmpty}
                                                                                                                    message={TreatmentAfterAVA_Msgs.Q39fEmpty}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                <div className="answer-list-text">
                                                                                                                    <span className="question-weight">g.</span>
                                                                                                                    <span className="quest-text-pad">
                                                                                                                        <span className="question-weight">Dosage: </span>
                                                                                                                        <input className="sub-input-min-dash"
                                                                                                                            type="text"
                                                                                                                            value={childdata.new_tx_chg_dose_g1x1}
                                                                                                                            onChange={Handlechange}
                                                                                                                            onKeyDown={formatInput}
                                                                                                                            id={childidx}
                                                                                                                            name={"q39child_new_tx_chg_dose_g1x1-" + idx + "-" + childidx}
                                                                                                                        />{" "}
                                                                                                                       mg
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <ErrorField
                                                                                                                    show={q39SteroidCourseDetails[idx].q39SteroidcoursedosagechangeDetails[childidx].q39gEmpty}
                                                                                                                    message={TreatmentAfterAVA_Msgs.Q39gEmpty}
                                                                                                                />
                                                                                                                <ErrorField
                                                                                                                    show={q39SteroidCourseDetails[idx].q39SteroidcoursedosagechangeDetails[childidx].q39doselimitexceeded}
                                                                                                                    message={TreatmentAfterAVA_Msgs.QdosageFreq}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                                                                                <div className="answer-list-text">
                                                                                                                    <span className="question-weight">h.</span>
                                                                                                                    <span className="quest-text-pad">
                                                                                                                        <span className="question-weight">
                                                                                                                            Frequency:{" "}
                                                                                                                        </span>
                                                                                                                        <input
                                                                                                                            className="sub-input-min-dash"
                                                                                                                            type="text"
                                                                                                                            value={childdata.new_tx_chg_num_h1x1}
                                                                                                                            onChange={Handlechange}
                                                                                                                            id={childidx}
                                                                                                                            onKeyDown={formatInput}
                                                                                                                            name={"q39child_new_tx_chg_num_h1x1-" + idx + "-" + childidx}
                                                                                                                        />
                                                                                                                        time(s) per
                                                                                                                        <select
                                                                                                                            name={"q39childfrequencytime-" + idx + "-" + childidx}
                                                                                                                            id={childidx}
                                                                                                                            className="freq-dropdown"
                                                                                                                            onChange={Handlechange}
                                                                                                                            value={childdata.frequencytimeid}
                                                                                                                        >
                                                                                                                            <option value={0}>Select</option>
                                                                                                                            {frequencytime?.map((i) => {
                                                                                                                                return (
                                                                                                                                    <option
                                                                                                                                        key={i.frequencytimeid}
                                                                                                                                        value={i.frequencytimeid}
                                                                                                                                    >
                                                                                                                                        {i.frequencytimetxt}
                                                                                                                                    </option>
                                                                                                                                );
                                                                                                                            })}
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <ErrorField
                                                                                                                    show={q39SteroidCourseDetails[idx].q39SteroidcoursedosagechangeDetails[childidx].q39hEmpty}
                                                                                                                    message={TreatmentAfterAVA_Msgs.Q39hEmpty}
                                                                                                                />
                                                                                                                <ErrorField
                                                                                                                    show={q39SteroidCourseDetails[idx].q39SteroidcoursedosagechangeDetails[childidx].q39freqlimitexceeded}
                                                                                                                    message={TreatmentAfterAVA_Msgs.QdosageFreq}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>



                                                                                                ))) : (<></>)}






                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="sub-question-bot-sapce answer-pad-left mt-2">
                                                                                        <div className="answer-list-text" onClick={(e) =>
                                                                                            addsteroidcoursedosagechangeDetailsRow(
                                                                                                e, idx, data
                                                                                            )
                                                                                        }>
                                                                                            <a>
                                                                                                <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                                                            </a>
                                                                                            <a className="radio-text-padding"
                                                                                            >
                                                                                                Click here to add another dose change
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"
                                                                                    name={"new_tx_dose_chg_e1_No-" + idx}
                                                                                    value={0}
                                                                                    id={idx}
                                                                                    checked={
                                                                                        data.new_tx_dose_chg_e1 === "0" ||
                                                                                        data.new_tx_dose_chg_e1 === 0
                                                                                    }
                                                                                    onChange={Handlechange}
                                                                                />
                                                                                <span className="radio-text-padding">No</span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39eEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q39eEmpty}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul">
                                                                            <div className="answer-list-text question-weight">
                                                                                <span>i.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Did the patient discontinue steroids following AVA
                                                                                    initiation on <span style={{ color: PageDesignConstants.fontColor }}>{indexdate}</span> and before{" "}
                                                                                    <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>?
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"
                                                                                    name={"new_tx_disc_i1_Yes-" + idx}
                                                                                    value={1}
                                                                                    id={idx}
                                                                                    checked={
                                                                                        data.new_tx_disc_i1 === "1" ||
                                                                                        data.new_tx_disc_i1 === 1
                                                                                    }
                                                                                    onChange={Handlechange}
                                                                                />
                                                                                <span className="radio-text-padding">Yes</span>
                                                                            </div>
                                                                            {data.showq39j &&
                                                                                <div className="dependent-sub-section margin-left-20">
                                                                                    <div className="sub-question-bot-sapce content-indent-option">
                                                                                        <div className="answer-list-text">
                                                                                            <span className="question-weight">j.</span>
                                                                                            <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                                <span className="question-weight">
                                                                                                    Date of discontinuation:{" "}
                                                                                                </span>
                                                                                                <DateControl
                                                                                                    optionId={"new_tx_disc_dt_j1"}
                                                                                                    HandleDateChange={HandleDateChange}
                                                                                                    ctrlId={"new_tx_disc_dt_j1-" + idx}
                                                                                                    defDate={data.defDateQ39j}
                                                                                                    date={data.new_tx_disc_dt_j1} />
                                                                                                (If only month and year are known, please enter “15” for the day)
                                                                                            </span>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={q39SteroidCourseDetails[idx].q39jDateInvalidSoft1}
                                                                                            message={TreatmentAfterAVA_Msgs.Q39jDateInvalidSoft1}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={q39SteroidCourseDetails[idx].q39jDateInvalidSoft2}
                                                                                            message={TreatmentAfterAVA_Msgs.Q39jDateInvalidSoft2}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={q39SteroidCourseDetails[idx].q39jDateInvalidSoft3}
                                                                                            message={TreatmentAfterAVA_Msgs.Q39jDateInvalidSoft3}
                                                                                        />
                                                                                        {/*<div className="answer-list-text answer-pad-left">*/}

                                                                                        {/*    <input type="radio"*/}
                                                                                        {/*        name={"new_tx_disc_dt_j1_unk-" + idx}*/}
                                                                                        {/*        value={1}*/}
                                                                                        {/*        id={idx}*/}
                                                                                        {/*        checked={*/}
                                                                                        {/*            data.new_tx_disc_dt_j1_unk === "1" ||*/}
                                                                                        {/*            data.new_tx_disc_dt_j1_unk === 1*/}
                                                                                        {/*        }*/}
                                                                                        {/*        onChange={Handlechange}*/}
                                                                                        {/*    />*/}
                                                                                        {/*    <span className="radio-text-padding">*/}
                                                                                        {/*        Date of therapy discontinuation is unknown*/}
                                                                                        {/*    </span>*/}
                                                                                        {/*</div>*/}
                                                                                        <ErrorField
                                                                                            show={q39SteroidCourseDetails[idx].q39jEmpty}
                                                                                            message={TreatmentAfterAVA_Msgs.Q39jEmpty}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"
                                                                                    name={"new_tx_disc_i1_No-" + idx}
                                                                                    value={0}
                                                                                    id={idx}
                                                                                    checked={
                                                                                        data.new_tx_disc_i1 === "0" ||
                                                                                        data.new_tx_disc_i1 === 0
                                                                                    }
                                                                                    onChange={Handlechange}
                                                                                />
                                                                                <span className="radio-text-padding">No</span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q39SteroidCourseDetails[idx].q39iEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q39iEmpty}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))) : (<></>)}
                                                            <div className="sub-question-bot-sapce-ul mt-2">
                                                                <div className="answer-list-text" onClick={addsteroidcourseDetailsRow}>
                                                                    <a>
                                                                        <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                                    </a>
                                                                    <a className="radio-text-padding">
                                                                        Click here to enter another initiation of a different steroid from <span style={{ color: PageDesignConstants.fontColor }}><b>{indexdate}</b></span> to <span style={{ color: PageDesignConstants.fontColor }}><b>{minenddiscdate}</b></span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="answer-list-text">
                                                        <input type="radio"
                                                            id="post_ava_new_ster_start_No"
                                                            name='post_ava_new_ster_start'
                                                            value={0}
                                                            checked={
                                                                ITPTreatmentPostAVADetails.post_ava_new_ster_start === "0" ||
                                                                ITPTreatmentPostAVADetails.post_ava_new_ster_start === 0
                                                            }
                                                            onChange={Handlechange} />
                                                        <span className="radio-text-padding">No</span>
                                                    </div>
                                                    <ErrorField
                                                        show={ITPTreatmentPostAVADetails.q39Empty}
                                                        message={TreatmentAfterAVA_Msgs.Q39Empty}
                                                    />
                                                </div>
                                            </div>
                                            {/*}*/}
                                            {/* This is End of Q39 */}
                                        </div>
                                        {/*}*/}

                                        {/* IVIG and anti-D immunoglobulin */}
                                        {/* 41 */}
                                        <div>
                                            <div className="question mt-3 sub-header-weight">
                                                <span className="">IVIG and anti-D immunoglobulin</span>
                                            </div>
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>41.</span>
                                                    <span className="quest-text-pad">
                                                        Did the patient use IVIG or anti-D immunoglobulin following AVA
                                                        initiation on <span style={{ color: PageDesignConstants.fontColor }}>{indexdate}</span> and before {""}
                                                        <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>? Please select which treatment(s) the
                                                        patient used.
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    {/*Q41 1st Set*/}
                                                    <div className="answer-list-text">
                                                        <input
                                                            type="checkbox"
                                                            id="q41checkbox1"
                                                            name='q41checkbox1'
                                                            checked={ITPTreatmentPostAVADetails.q41checkbox1}
                                                            onChange={(e) => Handlechange(e)} />
                                                        <span className="radio-text-padding">IVIG</span>
                                                    </div>
                                                    {ITPTreatmentPostAVADetails.q41showrepeater1 &&
                                                        <div className="dependent-section">
                                                            <div className="">
                                                                {q41ivigdatesDetails.length > 0 ? (q41ivigdatesDetails.map((data, idx) => (
                                                                    <div className="loop-section-ul">
                                                                        <div className="loop-head">
                                                                            <span className="question-weight">Set {idx + 1}</span>
                                                                            {idx != 0 &&
                                                                                <div className="delete-btn-div">
                                                                                    <img
                                                                                        width="16px"
                                                                                        src="../Assets/images/Icon-material-delete.png"
                                                                                        alt=""
                                                                                        onClick={(e) => deleteq41ivigDetails(idx)}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">a.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of dose start:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"postAvaA1TxStartDtR1"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"postAvaA1TxStartDt-" + idx}
                                                                                        defDate={data.defDateQ41A}
                                                                                        date={data.postAvaA1TxStartDt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q41ivigdatesDetails[idx].q41iviaDateInvalidSoft1}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41iviaDateInvalidSoft1.replace("[therapy]", "IVIG"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q41ivigdatesDetails[idx].q41iviaDateInvalidSoft2}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41iviaDateInvalidSoft2.replace("[therapy]", "IVIG"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q41ivigdatesDetails[idx].q41iviaDateInvalidSoft3}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41iviaDateInvalidSoft3.replace("[therapy]", "IVIG"))}
                                                                            />
                                                                            {/*<div className="answer-list-text answer-pad-left">*/}
                                                                            {/*    <input type="radio"*/}
                                                                            {/*        name={"postAvaA1TxStartDtUnkR1-" + idx}*/}
                                                                            {/*        value={1}*/}
                                                                            {/*        id={idx}*/}
                                                                            {/*        checked={*/}
                                                                            {/*            data.postAvaA1TxStartDtUnk == 1*/}
                                                                            {/*        }*/}
                                                                            {/*        onChange={Handlechange}*/}
                                                                            {/*    />*/}
                                                                            {/*    <span className="radio-text-padding">*/}
                                                                            {/*        Date of therapy start is unknown*/}
                                                                            {/*    </span>*/}
                                                                            {/*</div>*/}
                                                                            <ErrorField
                                                                                show={q41ivigdatesDetails[idx].q41iviaEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q41iviaEmpty}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">b.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of dose end:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"postAvaB1TxEndDtR1"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"postAvaB1TxEndDt-" + idx}
                                                                                        defDate={data.defDateQ41B}
                                                                                        date={data.postAvaB1TxEndDt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day.)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q41ivigdatesDetails[idx].q41ivibDateInvalidSoft1}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41ivibDateInvalidSoft1.replace("[therapy]", "IVIG"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q41ivigdatesDetails[idx].q41ivibDateInvalidSoft2}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41ivibDateInvalidSoft2.replace("[therapy]", "IVIG"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q41ivigdatesDetails[idx].q41ivibDateInvalidSoft3}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41ivibDateInvalidSoft3.replace("[therapy]", "IVIG"))}
                                                                            />
                                                                            {/*<div className="answer-list-text answer-pad-left">*/}
                                                                            {/*    <input type="radio"*/}
                                                                            {/*        name={"postAvaB1TxEndDtUnkR1-" + idx}*/}
                                                                            {/*        value={1}*/}
                                                                            {/*        id={idx}*/}
                                                                            {/*        checked={*/}
                                                                            {/*            data.postAvaB1TxEndDtUnk == 1*/}
                                                                            {/*        }*/}
                                                                            {/*        onChange={Handlechange}*/}
                                                                            {/*    />*/}
                                                                            {/*    <span className="radio-text-padding">*/}
                                                                            {/*        Date of therapy end is unknown*/}
                                                                            {/*    </span>*/}
                                                                            {/*</div>*/}
                                                                            <ErrorField
                                                                                show={q41ivigdatesDetails[idx].q41ivibEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q41ivibEmpty}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))) : (<></>)}
                                                                <div className="sub-question-bot-sapce-ul">
                                                                    <div className="answer-list-text" onClick={addQ41iviRow}>
                                                                        <a>
                                                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                                        </a>
                                                                        <a className="radio-text-padding">
                                                                            Click here to add another set of dates
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {/*Q41 1st Set*/}

                                                    {/*Q41 2nd Set*/}
                                                    <div className="answer-list-text">
                                                        <input type="checkbox"
                                                            id="q41checkbox2"
                                                            name='q41checkbox2'
                                                            checked={ITPTreatmentPostAVADetails.q41checkbox2}
                                                            onChange={(e) => Handlechange(e)} />
                                                        <span className="radio-text-padding">Anti-D immunoglobulin</span>
                                                    </div>
                                                    {ITPTreatmentPostAVADetails.q41showrepeater2 &&
                                                        <div className="dependent-section">
                                                            <div className="">
                                                                {q41antiddatesDetails.length > 0 ? (q41antiddatesDetails.map((data, idx) => (
                                                                    <div className="loop-section-ul">
                                                                        <div className="loop-head">
                                                                            <span className="question-weight">Set {idx + 1}</span>
                                                                            {idx != 0 &&
                                                                                <div className="delete-btn-div">
                                                                                    <img
                                                                                        width="16px"
                                                                                        src="../Assets/images/Icon-material-delete.png"
                                                                                        alt=""
                                                                                        onClick={(e) => deleteq41antidDetails(idx)}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">a.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of dose start:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"postAvaA1TxStartDtR2"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"postAvaA1TxStartDt-" + idx}
                                                                                        defDate={data.defDateQ41A}
                                                                                        date={data.postAvaA1TxStartDt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q41antiddatesDetails[idx].q41antidaDateInvalidSoft1}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41antidaDateInvalidSoft1.replace("[therapy]", "Anti-D immunoglobulin"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q41antiddatesDetails[idx].q41antidaDateInvalidSoft2}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41antidaDateInvalidSoft2.replace("[therapy]", "Anti-D immunoglobulin"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q41antiddatesDetails[idx].q41antidaDateInvalidSoft3}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41antidaDateInvalidSoft3.replace("[therapy]", "Anti-D immunoglobulin"))}
                                                                            />
                                                                            {/*<div className="answer-list-text answer-pad-left">*/}
                                                                            {/*    <input type="radio"*/}
                                                                            {/*        name={"postAvaA1TxStartDtUnkR2-" + idx}*/}
                                                                            {/*        value={1}*/}
                                                                            {/*        id={idx}*/}
                                                                            {/*        checked={*/}
                                                                            {/*            data.postAvaA1TxStartDtUnk == 1*/}
                                                                            {/*        }*/}
                                                                            {/*        onChange={Handlechange}*/}
                                                                            {/*    />*/}
                                                                            {/*    <span className="radio-text-padding">*/}
                                                                            {/*        Date of therapy start is unknown*/}
                                                                            {/*    </span>*/}
                                                                            {/*</div>*/}
                                                                            <ErrorField
                                                                                show={q41antiddatesDetails[idx].q41antidaEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q41antidaEmpty}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul answer-pad-left">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">b.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of dose end:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"postAvaB1TxEndDtR2"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"postAvaB1TxEndDt-" + idx}
                                                                                        defDate={data.defDateQ41B}
                                                                                        date={data.postAvaB1TxEndDt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q41antiddatesDetails[idx].q41antidbDateInvalidSoft1}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41antidbDateInvalidSoft1.replace("[therapy]", "Anti-D immunoglobulin"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q41antiddatesDetails[idx].q41antidbDateInvalidSoft2}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41antidbDateInvalidSoft2.replace("[therapy]", "Anti-D immunoglobulin"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q41antiddatesDetails[idx].q41antidbDateInvalidSoft3}
                                                                                message={(TreatmentAfterAVA_Msgs.Q41antidbDateInvalidSoft3.replace("[therapy]", "Anti-D immunoglobulin"))}
                                                                            />
                                                                            {/*<div className="answer-list-text answer-pad-left">*/}
                                                                            {/*    <input type="radio"*/}
                                                                            {/*        name={"postAvaB1TxEndDtUnkR2-" + idx}*/}
                                                                            {/*        value={1}*/}
                                                                            {/*        id={idx}*/}
                                                                            {/*        checked={*/}
                                                                            {/*            data.postAvaB1TxEndDtUnk == 1*/}
                                                                            {/*        }*/}
                                                                            {/*        onChange={Handlechange}*/}
                                                                            {/*    />*/}
                                                                            {/*    <span className="radio-text-padding">*/}
                                                                            {/*        Date of therapy end is unknown*/}
                                                                            {/*    </span>*/}
                                                                            {/*</div>*/}
                                                                            <ErrorField
                                                                                show={q41antiddatesDetails[idx].q41antidbEmpty}
                                                                                message={TreatmentAfterAVA_Msgs.Q41antidbEmpty}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))) : (<></>)}
                                                                <div className="sub-question-bot-sapce-ul">
                                                                    <div className="answer-list-text" onClick={addQ41antidRow}>
                                                                        <a>
                                                                            <img width="18px" src="../Assets/images/plus.png" alt="" />
                                                                        </a>
                                                                        <a className="radio-text-padding">
                                                                            Click here to add another set of dates
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {/*Q41 2nd Set*/}

                                                    <div className="answer-list-text">
                                                        <input type="radio"
                                                            id="q41radiobutton"
                                                            name='q41radiobutton'
                                                            value={3}
                                                            checked={
                                                                ITPTreatmentPostAVADetails.q41radiobutton === true
                                                            }
                                                            onChange={Handlechange} />
                                                        <span className="radio-text-padding">
                                                            Patient used neither IVIG nor Anti-D immunoglobulin during this
                                                            time
                                                        </span>
                                                    </div>
                                                </div>
                                                <ErrorField
                                                    show={ITPTreatmentPostAVADetails.q41Empty}
                                                    message={TreatmentAfterAVA_Msgs.Q41Empty}
                                                />
                                            </div>
                                        </div>
                                        {/* 41 */}
                                        {/*IVIG and anti-D immunoglobulin*/}

                                        {/* Other ITP treatments */}
                                        {/* 42 */}
                                        {showq42 &&
                                            <>
                                                <div className="question mt-3 sub-header-weight">
                                                    <span className="">Other ITP treatments</span>
                                                </div>
                                                <div className="question-bot-sapce">
                                                    <div className="question question-weight">
                                                        <span>42.</span>
                                                        <span className="quest-text-pad">
                                                            Discontinuation of other ITP treatments: You noted that the
                                                            patient was on the following therapies at the time of AVA
                                                            initiation on <span style={{ color: PageDesignConstants.fontColor }}>{indexdate}</span>. Did the patient discontinue
                                                            any of these therapies following AVA initiation on{" "}
                                                            <span style={{ color: PageDesignConstants.fontColor }}>{indexdate}</span> and before {""}
                                                            <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>?
                                                            <br />
                                                            Please select the ITP treatments that the patient discontinued.
                                                        </span>
                                                    </div>
                                                    <div className="double-dig-answer">
                                                        {showq42Checkbox1Section &&
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="q42checkbox1"
                                                                        name='q42checkbox1'
                                                                        checked={ITPTreatmentPostAVADetails.q42Checkbox1Checked}
                                                                        onChange={(e) => Handlechange(e)} />
                                                                    <span className="radio-text-padding">Rituximab</span>
                                                                </div>
                                                                {ITPTreatmentPostAVADetails.q42Checkbox1Checked &&
                                                                    <div className="dependent-section">
                                                                        <div className="">
                                                                            <div className="answer-list-text">
                                                                                <span className="" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of discontinuation:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"q42Checkbox1PostAvaTxDiscDt"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"q42Checkbox1PostAvaTxDiscDt"}
                                                                                        defDate={q42itpdiscontinuationdatesCheckbox1Details[0].defDateQ42}
                                                                                        date={q42itpdiscontinuationdatesCheckbox1Details[0].PostAvaTxDiscDt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q42itpdiscontinuationdatesCheckbox1Details[0].q42Empty}
                                                                                message={TreatmentAfterAVA_Msgs.Q42Date1Empty}
                                                                            />
                                                                            <ErrorField
                                                                                show={q42itpdiscontinuationdatesCheckbox1Details[0].q42DateInvalidSoft1}
                                                                                message={(TreatmentAfterAVA_Msgs.Q42DateInvalidSoft1.replace("[therapy]", "Rituximab"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q42itpdiscontinuationdatesCheckbox1Details[0].q42DateInvalidSoft2}
                                                                                message={(TreatmentAfterAVA_Msgs.Q42DateInvalidSoft2.replace("[therapy]", "Rituximab"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q42itpdiscontinuationdatesCheckbox1Details[0].q42DateInvalidSoft3}
                                                                                message={(TreatmentAfterAVA_Msgs.Q42DateInvalidSoft3.replace("[therapy]", "Rituximab"))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {showq42Checkbox2Section &&
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="q42checkbox2"
                                                                        name='q42checkbox2'
                                                                        checked={ITPTreatmentPostAVADetails.q42Checkbox2Checked}
                                                                        onChange={(e) => Handlechange(e)} />
                                                                    <span className="radio-text-padding">
                                                                        Immunosuppressants (i.e., azathioprine, cyclophosphamide,
                                                                        cyclosporine, danazol, dapsone, mycophenolate mofetil, vinca
                                                                        alkaloids [vinblastine, vinorelbine, vincristine])
                                                                    </span>
                                                                </div>
                                                                {ITPTreatmentPostAVADetails.q42Checkbox2Checked &&
                                                                    <div className="dependent-section">
                                                                        <div className="">
                                                                            <div className="answer-list-text">
                                                                                <span className="" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of discontinuation:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"q42Checkbox2PostAvaTxDiscDt"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"q42Checkbox2PostAvaTxDiscDt"}
                                                                                        defDate={q42itpdiscontinuationdatesCheckbox2Details[0].defDateQ42}
                                                                                        date={q42itpdiscontinuationdatesCheckbox2Details[0].PostAvaTxDiscDt} />
                                                                                    (If only month and year are known, please enter “15” for
                                                                                    the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q42itpdiscontinuationdatesCheckbox2Details[0].q42Empty}
                                                                                message={TreatmentAfterAVA_Msgs.Q42Date2Empty}
                                                                            />
                                                                            <ErrorField
                                                                                show={q42itpdiscontinuationdatesCheckbox2Details[0].q42DateInvalidSoft1}
                                                                                message={(TreatmentAfterAVA_Msgs.Q42DateInvalidSoft1.replace("[therapy]", "Immunosuppressants"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q42itpdiscontinuationdatesCheckbox2Details[0].q42DateInvalidSoft2}
                                                                                message={(TreatmentAfterAVA_Msgs.Q42DateInvalidSoft2.replace("[therapy]", "Immunosuppressants"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q42itpdiscontinuationdatesCheckbox2Details[0].q42DateInvalidSoft3}
                                                                                message={(TreatmentAfterAVA_Msgs.Q42DateInvalidSoft3.replace("[therapy]", "Immunosuppressants"))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        <div>
                                                            <div className="answer-list-text">
                                                                <input type="radio"
                                                                    id="q42radiobutton"
                                                                    name='q42radiobutton'
                                                                    value={3}
                                                                    checked={ITPTreatmentPostAVADetails.post_ava_disc_treat_unk}
                                                                    onChange={(e) => Handlechange(e)} />
                                                                <span className="radio-text-padding">
                                                                    Patient did not discontinue any of these ITP treatments
                                                                    following AVA initiation on{" "}
                                                                    <span style={{ color: PageDesignConstants.fontColor, fontWeight: "500" }}>{indexdate}</span> and before <span style={{ color: PageDesignConstants.fontColor, fontWeight: "500" }}>{minenddiscdate}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ErrorField
                                                            show={ITPTreatmentPostAVADetails.q42Empty}
                                                            message={TreatmentAfterAVA_Msgs.Q42Empty}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {/* 42 */}
                                        {/* Other ITP treatments */}

                                        {/* 43 to 45 */}
                                        <div>
                                            {/* 43 */}
                                            {showq43 &&
                                                <div className="question-bot-sapce">
                                                    <div className="question question-weight">
                                                        <span>43.</span>
                                                        <span className="quest-text-pad">
                                                            Initiation of other new ITP treatments: You noted that the patient
                                                            was not on any of these treatments at the time of AVA initiation.
                                                            Did the patient start on any of these treatments following AVA
                                                            initiation on <span style={{ color: PageDesignConstants.fontColor }}>{indexdate}</span> and before{" "}
                                                            <span style={{ color: PageDesignConstants.fontColor }}>{minenddiscdate}</span>?
                                                        </span>
                                                    </div>
                                                    <div className="double-dig-answer">
                                                        <span className="question-weight">Please select all ITP treatments that the patient initiated.</span>


                                                        {/*Check 1*/}
                                                        {showq43Checkbox1Section &&
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="q43checkbox1"
                                                                        name='q43checkbox1'
                                                                        checked={ITPTreatmentPostAVADetails.q43Checkbox1Checked}
                                                                        onChange={(e) => Handlechange(e)} />
                                                                    <span className="radio-text-padding">Rituximab</span>
                                                                </div>

                                                                {ITPTreatmentPostAVADetails.q43Checkbox1Checked &&
                                                                    <div className="dependent-section">


                                                                        <div className="sub-question-bot-sapce-ul">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">a.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of initiation:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"q43Checkbox1PostAvaTxStartDt"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"q43Checkbox1PostAvaTxStartDt"}
                                                                                        defDate={q43otherItpTreatmentsModalCheckbox1Details[0].defDate1Q43}
                                                                                        date={q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxStartDt} />
                                                                                    (If only month and year are known, please enter “15” for the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox1Details[0].q43Date1InvalidSoft1}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43Date1InvalidSoft1.replace("[therapy]", "Rituximab"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox1Details[0].q43Date1InvalidSoft2}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43Date1InvalidSoft2.replace("[therapy]", "Rituximab"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox1Details[0].q43Date1InvalidSoft3}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43Date1InvalidSoft3.replace("[therapy]", "Rituximab"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox1Details[0].q43aEmpty}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43aEmpty.replace("[therapy]", "Rituximab"))}
                                                                            />
                                                                        </div>


                                                                        <div className="sub-question-bot-sapce-ul">

                                                                            <div className="answer-list-text question-weight">
                                                                                <span>b.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Did the patient discontinue <span style={{ color: PageDesignConstants.fontColor }}>Rituximab</span>?
                                                                                </span>
                                                                            </div>

                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"
                                                                                    id="q43Chk1radiobuttonYes"
                                                                                    name='q43Chk1radiobuttonYes'
                                                                                    value={1}
                                                                                    checked={q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDisc == "1" ||
                                                                                        q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDisc == 1 ? true : false}
                                                                                    onChange={(e) => Handlechange(e)} />
                                                                                <span className="radio-text-padding">Yes</span>
                                                                            </div>


                                                                            {q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDisc == 1 &&
                                                                                <>
                                                                                    <div className="dependent-sub-section margin-left-20">
                                                                                        <div className="answer-list-text">
                                                                                            <span className="question-weight">c.</span>
                                                                                            <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                                <span className="question-weight">
                                                                                                    Date of discontinuation:{" "}
                                                                                                </span>
                                                                                                <DateControl
                                                                                                    optionId={"q43Checkbox1PostAvaTxDiscDt"}
                                                                                                    HandleDateChange={HandleDateChange}
                                                                                                    ctrlId={"q43Checkbox1PostAvaTxDiscDt"}
                                                                                                    defDate={q43otherItpTreatmentsModalCheckbox1Details[0].defDate2Q43}
                                                                                                    date={q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDiscDt} />
                                                                                                (If only month and year are known, please enter “15” for the day)
                                                                                            </span>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={q43otherItpTreatmentsModalCheckbox1Details[0].q43Date2InvalidSoft1}
                                                                                            message={(TreatmentAfterAVA_Msgs.Q43Date2InvalidSoft1.replace("[therapy]", "Rituximab"))}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={q43otherItpTreatmentsModalCheckbox1Details[0].q43Date2InvalidSoft2}
                                                                                            message={(TreatmentAfterAVA_Msgs.Q43Date2InvalidSoft2.replace("[therapy]", "Rituximab"))}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={q43otherItpTreatmentsModalCheckbox1Details[0].q43Date2InvalidSoft3}
                                                                                            message={(TreatmentAfterAVA_Msgs.Q43Date2InvalidSoft3.replace("[therapy]", "Rituximab"))}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={q43otherItpTreatmentsModalCheckbox1Details[0].q43cEmpty}
                                                                                            message={(TreatmentAfterAVA_Msgs.Q43cEmpty.replace("[therapy]", "Rituximab"))}
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                            }


                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"
                                                                                    id="q43Chk1radiobuttonNo"
                                                                                    name='q43Chk1radiobuttonNo'
                                                                                    value={0}
                                                                                    checked={q43otherItpTreatmentsModalCheckbox1Details[0].PostAvaTxDisc == "0" ? true : false}
                                                                                    onChange={(e) => Handlechange(e)} />
                                                                                <span className="radio-text-padding">No</span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox1Details[0].q43bEmpty}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43bEmpty.replace("[therapy]", "Rituximab"))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {/*Check 1*/}

                                                        {/*Check 2*/}
                                                        {showq43Checkbox2Section &&
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="q43checkbox2"
                                                                        name='q43checkbox2'
                                                                        checked={ITPTreatmentPostAVADetails.q43Checkbox2Checked}
                                                                        onChange={(e) => Handlechange(e)} />
                                                                    <span className="radio-text-padding">
                                                                        Immunosuppressants (i.e., azathioprine, cyclophosphamide,
                                                                        cyclosporine, danazol, dapsone, mycophenolate mofetil, vinca
                                                                        alkaloids [vinblastine, vinorelbine, vincristine])
                                                                    </span>
                                                                </div>
                                                                {ITPTreatmentPostAVADetails.q43Checkbox2Checked &&
                                                                    <div className="dependent-section">
                                                                        <div className="sub-question-bot-sapce-ul">
                                                                            <div className="answer-list-text">
                                                                                <span className="question-weight">a.</span>
                                                                                <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                    <span className="question-weight">
                                                                                        Date of initiation:{" "}
                                                                                    </span>
                                                                                    <DateControl
                                                                                        optionId={"q43Checkbox2PostAvaTxStartDt"}
                                                                                        HandleDateChange={HandleDateChange}
                                                                                        ctrlId={"q43Checkbox2PostAvaTxStartDt"}
                                                                                        defDate={q43otherItpTreatmentsModalCheckbox2Details[0].defDate1Q43}
                                                                                        date={q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxStartDt} />
                                                                                    (If only month and year are known, please enter “15” for the day)
                                                                                </span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox2Details[0].q43Date1InvalidSoft1}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43Date1InvalidSoft1.replace("[therapy]", "Immunosuppressants"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox2Details[0].q43Date1InvalidSoft2}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43Date1InvalidSoft2.replace("[therapy]", "Immunosuppressants"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox2Details[0].q43Date1InvalidSoft3}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43Date1InvalidSoft3.replace("[therapy]", "Immunosuppressants"))}
                                                                            />
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox2Details[0].q43aEmpty}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43aEmpty.replace("[therapy]", "Immunosuppressants"))}
                                                                            />
                                                                        </div>
                                                                        <div className="sub-question-bot-sapce-ul">
                                                                            <div className="answer-list-text question-weight">
                                                                                <span>b.</span>
                                                                                <span className="quest-text-pad">
                                                                                    Did the patient discontinue <span style={{ color: PageDesignConstants.fontColor }}>Immunosuppressants</span>?
                                                                                </span>
                                                                            </div>
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"
                                                                                    id="q43Chk2radiobuttonYes"
                                                                                    name='q43Chk2radiobuttonYes'
                                                                                    value={1}
                                                                                    checked={q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDisc == 1 ? true : false}
                                                                                    onChange={(e) => Handlechange(e)} />
                                                                                <span className="radio-text-padding">Yes</span>
                                                                            </div>
                                                                            {q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDisc == 1 &&
                                                                                <>
                                                                                    <div className="dependent-sub-section margin-left-20">
                                                                                        <div className="answer-list-text">
                                                                                            <span className="question-weight">c.</span>
                                                                                            <span className="quest-text-pad" style={{ display: 'flex' }}>
                                                                                                <span className="question-weight">
                                                                                                    Date of discontinuation:{" "}
                                                                                                </span>
                                                                                                <DateControl
                                                                                                    optionId={"q43Checkbox2PostAvaTxDiscDt"}
                                                                                                    HandleDateChange={HandleDateChange}
                                                                                                    ctrlId={"q43Checkbox2PostAvaTxDiscDt"}
                                                                                                    defDate={q43otherItpTreatmentsModalCheckbox2Details[0].defDate2Q43}
                                                                                                    date={q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDiscDt} />
                                                                                                (If only month and year are known, please enter “15” for the day)
                                                                                            </span>
                                                                                        </div>
                                                                                        <ErrorField
                                                                                            show={q43otherItpTreatmentsModalCheckbox2Details[0].q43Date2InvalidSoft1}
                                                                                            message={(TreatmentAfterAVA_Msgs.Q43Date2InvalidSoft1.replace("[therapy]", "Immunosuppressants"))}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={q43otherItpTreatmentsModalCheckbox2Details[0].q43Date2InvalidSoft2}
                                                                                            message={(TreatmentAfterAVA_Msgs.Q43Date2InvalidSoft2.replace("[therapy]", "Immunosuppressants"))}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={q43otherItpTreatmentsModalCheckbox2Details[0].q43Date2InvalidSoft3}
                                                                                            message={(TreatmentAfterAVA_Msgs.Q43Date2InvalidSoft3.replace("[therapy]", "Immunosuppressants"))}
                                                                                        />
                                                                                        <ErrorField
                                                                                            show={q43otherItpTreatmentsModalCheckbox2Details[0].q43cEmpty}
                                                                                            message={(TreatmentAfterAVA_Msgs.Q43cEmpty.replace("[therapy]", "Immunosuppressants"))}
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            <div className="answer-list-text answer-pad-left">
                                                                                <input type="radio"
                                                                                    id="q43Chk2radiobuttonNo"
                                                                                    name='q43Chk2radiobuttonNo'
                                                                                    value={0}
                                                                                    checked={q43otherItpTreatmentsModalCheckbox2Details[0].PostAvaTxDisc == "0" ? true : false}
                                                                                    onChange={(e) => Handlechange(e)} />
                                                                                <span className="radio-text-padding">No</span>
                                                                            </div>
                                                                            <ErrorField
                                                                                show={q43otherItpTreatmentsModalCheckbox2Details[0].q43bEmpty}
                                                                                message={(TreatmentAfterAVA_Msgs.Q43bEmpty.replace("[therapy]", "Immunosuppressants"))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {/*Check 2 End*/}

                                                        <div>
                                                            <div className="answer-list-text">
                                                                <input type="radio"
                                                                    id="q43radiobutton"
                                                                    name='q43radiobutton'
                                                                    value={3}
                                                                    checked={ITPTreatmentPostAVADetails.post_ava_other_treat_unk}
                                                                    onChange={(e) => Handlechange(e)} />
                                                                <span className="radio-text-padding">
                                                                    Patient did not initiate any of these ITP treatments following AVA initiation on <span style={{ color: PageDesignConstants.fontColor, fontWeight: "500" }}>{indexdate}</span> and before <span style={{ color: PageDesignConstants.fontColor, fontWeight: "500" }}>{minenddiscdate}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ErrorField
                                                            show={ITPTreatmentPostAVADetails.q43Empty}
                                                            message={TreatmentAfterAVA_Msgs.Q43Empty}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            {/* 43 */}

                                            {/* 44 */}
                                            <div className="question-bot-sapce">
                                                <div className="answer-list-text question-weight">
                                                    <span>44.</span>
                                                    <span className="quest-text-pad">
                                                        Did the patient receive a splenectomy any time after AVA
                                                        initiation on <span style={{ color: PageDesignConstants.fontColor }}>{indexdate}</span>?
                                                    </span>
                                                </div>
                                                <div className="double-dig-answer">
                                                    <div className="answer-list-text">
                                                        <input type="radio"
                                                            id="q44radiobuttonYes"
                                                            name='q44radiobuttonYes'
                                                            value={1}
                                                            checked={ITPTreatmentPostAVADetails.post_ava_splen == "1" ? true : false}
                                                            onChange={(e) => Handlechange(e)} />
                                                        <span className="radio-text-padding">Yes</span>
                                                    </div>
                                                    {ITPTreatmentPostAVADetails.q44show &&
                                                        <div className="dependent-section">
                                                            <div className="sub-question-bot-sapce-ul">
                                                                <div className="answer-list-text">
                                                                    <span className="" style={{ display: 'flex' }}>
                                                                        <span className="question-weight">
                                                                            Date of splenectomy:{" "}
                                                                        </span>
                                                                        <DateControl
                                                                            optionId={"q44Date"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"q44Date"}
                                                                            defDate={ITPTreatmentPostAVADetails.defDateQ44}
                                                                            date={ITPTreatmentPostAVADetails.post_ava_splen_dt} />
                                                                        <div>
                                                                            <span>
                                                                                (If only month and year are known, please enter “15” for the day)
                                                                            </span>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <ErrorField
                                                                    show={ITPTreatmentPostAVADetails.q44DateInvalidSoft1}
                                                                    message={TreatmentAfterAVA_Msgs.Q44DateInvalidSoft1}
                                                                />
                                                                {/*<ErrorField*/}
                                                                {/*    show={ITPTreatmentPostAVADetails.q44DateInvalidSoft2}*/}
                                                                {/*    message={TreatmentAfterAVA_Msgs.Q44DateInvalidSoft2}*/}
                                                                {/*/>*/}
                                                                <ErrorField
                                                                    show={ITPTreatmentPostAVADetails.q44DateInvalidSoft3}
                                                                    message={TreatmentAfterAVA_Msgs.Q44DateInvalidSoft3}
                                                                />
                                                                <div className="answer-list-text">
                                                                    <input type="radio"
                                                                        name={"post_ava_splen_dt_unk"}
                                                                        value={1}
                                                                        id={"post_ava_splen_dt_unk"}
                                                                        checked={
                                                                            ITPTreatmentPostAVADetails.post_ava_splen_dt_unk == "1" || ITPTreatmentPostAVADetails.post_ava_splen_dt_unk == 1 ? true : false
                                                                        }
                                                                        onChange={Handlechange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        Date of splenectomy is unknown
                                                                    </span>
                                                                </div>
                                                                <ErrorField
                                                                    show={ITPTreatmentPostAVADetails.q44DataEmpty}
                                                                    message={TreatmentAfterAVA_Msgs.Q44DataEmpty}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="answer-list-text">
                                                        <input type="radio"
                                                            id="q44radiobuttonNo"
                                                            name='q44radiobuttonNo'
                                                            value={0}
                                                            checked={ITPTreatmentPostAVADetails.post_ava_splen == "0" ? true : false}
                                                            onChange={(e) => Handlechange(e)} />
                                                        <span className="radio-text-padding">No</span>
                                                    </div>
                                                    <ErrorField
                                                        show={ITPTreatmentPostAVADetails.q44Empty}
                                                        message={TreatmentAfterAVA_Msgs.Q44Empty}
                                                    />
                                                </div>
                                            </div>
                                            {/* 44 */}


                                            {/* 45 */}
                                            {showq45 ?
                                                (
                                                    <div className="question-bot-sapce">
                                                        <div className="answer-list-text question-weight">
                                                            <span>45.</span>
                                                            <span className="quest-text-pad">
                                                                Did the patient switch to any of the following ITP treatments
                                                                following AVA discontinuation on <span style={{ color: PageDesignConstants.fontColor }}>{avadiscdate}</span>?
                                                            </span>
                                                        </div>
                                                        <div className="double-dig-answer">
                                                            <span className="question-weight">Type of ITP treatment:</span>
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_elt"
                                                                        name={"post_ava_switch_elt"}
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_elt
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_elt" >
                                                                        <span className="radio-text-padding">Eltrombopag</span></label>
                                                                </div>
                                                                {Q45HandleControlState.post_ava_switch_elt &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_elt"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_elt"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_elt_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_elt_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_elt_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_elt_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_elt_Value.showSoftErrorMsg2}
                                                                            // EmptyFields={Q45HandleControlState.post_ava_switch_elt_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_elt_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_elt_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_elt_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_elt_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_romi"
                                                                        name="post_ava_switch_romi"
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_romi
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_romi" >
                                                                        <span className="radio-text-padding">Romiplostim</span></label>
                                                                </div>
                                                                {Q45HandleControlState.post_ava_switch_romi &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_romi"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_romi"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_romi_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_romi_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_romi_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_romi_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_romi_Value.showSoftErrorMsg2}
                                                                            EmptyFields={Q45HandleControlState.post_ava_switch_romi_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_romi_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_romi_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_romi_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_romi_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_lusu"
                                                                        name="post_ava_switch_lusu"
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_lusu
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_lusu" >
                                                                        <span className="radio-text-padding">Lusutrombopag</span></label>
                                                                </div>
                                                                {Q45HandleControlState.post_ava_switch_lusu &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_lusu"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_lusu"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_lusu_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_lusu_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_lusu_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_lusu_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_lusu_Value.showSoftErrorMsg2}
                                                                            EmptyFields={Q45HandleControlState.post_ava_switch_lusu_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_lusu_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_lusu_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_lusu_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_lusu_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_steroid"
                                                                        name="post_ava_switch_steroid"
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_steroid
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_steroid" >
                                                                        <span className="radio-text-padding">Steroids (i.e., dexamethasone, prednisone, prednisolone)</span></label>
                                                                </div>
                                                                {Q45HandleControlState.post_ava_switch_steroid &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_steroid"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_steroid"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_steroid_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_steroid_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_steroid_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_steroid_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_steroid_Value.showSoftErrorMsg2}
                                                                            EmptyFields={Q45HandleControlState.post_ava_switch_steroid_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_steroid_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_steroid_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_steroid_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_steroid_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_ivig"
                                                                        name="post_ava_switch_ivig"
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_ivig
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_ivig" >
                                                                        <span className="radio-text-padding">Intravenous immunoglobulin (IVIG)</span></label>
                                                                </div>
                                                                {Q45HandleControlState.post_ava_switch_ivig &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_ivig"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_ivig"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_ivig_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_ivig_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_ivig_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_ivig_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_ivig_Value.showSoftErrorMsg2}
                                                                            EmptyFields={Q45HandleControlState.post_ava_switch_ivig_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_ivig_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_ivig_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_ivig_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_ivig_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_antid"
                                                                        name="post_ava_switch_antid"
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_antid
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_antid" >
                                                                        <span className="radio-text-padding">Anti-D immunoglobulin</span></label>
                                                                </div>
                                                                {Q45HandleControlState.post_ava_switch_antid &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_antid"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_antid"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_antid_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_antid_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_antid_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_antid_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_antid_Value.showSoftErrorMsg2}
                                                                            EmptyFields={Q45HandleControlState.post_ava_switch_antid_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_antid_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_antid_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_antid_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_antid_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_ritux"
                                                                        name="post_ava_switch_ritux"
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_ritux
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_ritux" >
                                                                        <span className="radio-text-padding">Rituximab</span></label>
                                                                </div>
                                                                {Q45HandleControlState.post_ava_switch_ritux &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_ritux"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_ritux"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_ritux_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_ritux_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_ritux_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_ritux_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_ritux_Value.showSoftErrorMsg2}
                                                                            EmptyFields={Q45HandleControlState.post_ava_switch_ritux_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_ritux_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_ritux_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_ritux_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_ritux_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_fosta"
                                                                        name="post_ava_switch_fosta"
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_fosta
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_fosta" >
                                                                        <span className="radio-text-padding">
                                                                            Fostamatinib disodium
                                                                        </span></label>
                                                                </div>
                                                                {Q45HandleControlState.post_ava_switch_fosta &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_fosta"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_fosta"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_fosta_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_fosta_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_fosta_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_fosta_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_fosta_Value.showSoftErrorMsg2}
                                                                            EmptyFields={Q45HandleControlState.post_ava_switch_fosta_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_fosta_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_fosta_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_fosta_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_fosta_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>
                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_immuno"
                                                                        name="post_ava_switch_immuno"
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_immuno
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_immuno" >
                                                                        <span className="radio-text-padding">
                                                                            Immunosuppressants (i.e., azathioprine, cyclophosphamide,
                                                                            cyclosporine, danazol, dapsone, mycophenolate mofetil, vinca
                                                                            alkaloids [vinblastine, vinorelbine, vincristine])
                                                                        </span></label>
                                                                </div>
                                                                {Q45HandleControlState.post_ava_switch_immuno &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_immuno"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_immuno"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_immuno_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_immuno_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_immuno_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_immuno_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_immuno_Value.showSoftErrorMsg2}
                                                                            EmptyFields={Q45HandleControlState.post_ava_switch_immuno_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_immuno_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_immuno_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_immuno_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_immuno_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>

                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="checkbox"
                                                                        id="post_ava_switch_other"
                                                                        name="post_ava_switch_other"
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_other
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_other" >
                                                                        <span className="radio-text-padding">
                                                                            Other, please specify:{" "}
                                                                            <input
                                                                            name="post_ava_switch_other_txt"
                                                                            className="input-dash"
                                                                            type="text"
                                                                            maxLength={250}
                                                                            value={
                                                                                Q45HandleControlState.post_ava_switch_other_txt
                                                                            }
                                                                            onChange={Handlechange}/>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            <ErrorField
                                                                show={Q45HandleControlState.post_ava_switch_other_txt_empty ? true : Q45HandleControlState.post_ava_switch_other_txt_empty}
                                                                message={TreatmentAfterAVA_Msgs.Q45OtherItpTmtEmptyErrorMsg}
                                                            />

                                                                {Q45HandleControlState.post_ava_switch_other &&
                                                                    <div className="dependent-section">
                                                                        <SwitchedTreatment
                                                                            optionId={"post_ava_switch_other"}
                                                                            HandleDateChange={HandleDateChange}
                                                                            ctrlId={"post_ava_switch_other"}
                                                                            HandleOptionsSelected={HandleOptionsSelected}
                                                                            HandleOtherOptionValue={HandleOtherOptionValue}
                                                                            date={Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_dt}
                                                                            selectedValue={Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_reason}
                                                                            selectedOthervalue={Q45HandleControlState.post_ava_switch_other_Value.post_ava_tx_switch_oth_txt}
                                                                            showSoftError1={Q45HandleControlState.post_ava_switch_other_Value.q45DateInvalidSoft1 ? true : false}
                                                                            showSoftError2={Q45HandleControlState.post_ava_switch_other_Value.q45DateInvalidSoft2 ? true : false}
                                                                            showSoftErrorMsg1={Q45HandleControlState.post_ava_switch_other_Value.showSoftErrorMsg1}
                                                                            showSoftErrorMsg2={Q45HandleControlState.post_ava_switch_other_Value.showSoftErrorMsg2}
                                                                            EmptyFields={Q45HandleControlState.post_ava_switch_other_Value.Q45EmptyFields ? true : false}
                                                                            q45AEmpty={Q45HandleControlState.post_ava_switch_other_Value.q45aEmptyErrorMsg}
                                                                            q45BEmpty={Q45HandleControlState.post_ava_switch_other_Value.q45bEmptyErrorMsg}
                                                                            q45BAEmpty={Q45HandleControlState.post_ava_switch_other_Value.q45baEmptyErrorMsg}
                                                                            showSoftAErrorMsg={Q45HandleControlState.post_ava_switch_other_Value.showSoftAErrorMsg}
                                                                        />
                                                                    </div>}
                                                            </div>


                                                            <div>
                                                                <div className="answer-list-text">
                                                                    <input type="radio"
                                                                        id="post_ava_switch_none"
                                                                        name="post_ava_switch_none"
                                                                        value={1}
                                                                        checked={
                                                                            Q45HandleControlState.post_ava_switch_none === "1" || Q45HandleControlState.post_ava_switch_none === 1
                                                                        }
                                                                        onChange={Handlechange} />
                                                                    <label htmlFor="post_ava_switch_none" >
                                                                        <span className="radio-text-padding">None of the above</span></label>
                                                                </div>
                                                            </div>
                                                            <ErrorField
                                                                show={Q45HandleControlState.Q45Empty ? true : Q45HandleControlState.Q45Empty}
                                                                message={TreatmentAfterAVA_Msgs.Q45EmptyErrorMsg}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            {/* 45 */}
                                        </div>
                                        {/* 43 to 45 */}
                                    </div>
                                </div>
                            </div>





                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths}
                                    Save={onsaveclick} />
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>

    )
}

export default TreatmentAfterAVA;